import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import logoImg from '../../../../images/logo_only.png';
import { MAIN_WEBSITE_URL } from '../../../base/config';
import { getRedirectPath } from '../../../base/routes';
import { TEMPORARY_USER_MODE } from '../../authentication/authenticationSlice';
import { IsSavingInfo } from '../../globals/components/Utils';
import { getListViewDefaultDateSelector } from '../../inspections/slices/inspectionsListSlice';

type LogoProps = {
    isAuthenticated: boolean;
    isSaving: boolean;
    className?: string;
}
const Logo = (props: LogoProps): React.ReactElement => {
    const temporaryUserMode = sessionStorage.getItem(TEMPORARY_USER_MODE) || location.pathname.includes("/direct/login/");

    const getUrl = (): string => {
        const date = useSelector(getListViewDefaultDateSelector);
        const redirectTo = getRedirectPath(date);
        return props.isAuthenticated ? redirectTo : MAIN_WEBSITE_URL;
    };
    const logo = <img src={logoImg} />;
    if (props.isSaving) {
        return (
            <IsSavingInfo
                on='click'
                trigger={<div>{
                    <div className='logo'>{logo}</div>
                }</div>}
                position='bottom center'
            />
        );
    }
    if (props.isAuthenticated) {
        return (<div className={twMerge('logo', props.className)}>
            {
                temporaryUserMode ? logo :
                    <Link to={getUrl()}>
                        {logo}
                    </Link>
            }
        </div >);
    }
    return (<div  className={twMerge('logo', props.className)}><a href={getUrl()}>
        {logo}
    </a>
    </div>);
};
export default Logo;
