import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Feature } from '../../../backend_api/models';
import { useCheckHasPermission } from '../../../base/hooks';
import { getFeaturesSelector, getPermissionsSelector } from '../../../base/selectors';
import { getComplianceIsEnabledSelector } from '../../compliance/selectors/ComplianceSelectors';

export enum SearchContextEnum {
    Products = 'products',
    Inspections = 'inspections'
}
export type SearchContext = { id: SearchContextEnum; url: string, placeholderText: string, query: string, switcherText: string };

export const searchContext: Record<SearchContextEnum, SearchContext> = {
    'products': {
        id: SearchContextEnum.Products,
        url: '/compliance/products',
        query: 'q',
        placeholderText: 'compliance.search.search_products',
        switcherText: 'compliance.search.switch_to_products',
    },
    'inspections': {
        id: SearchContextEnum.Inspections,
        url: '/inspections',
        query: 'search',
        placeholderText: 'search.search_inspections',
        switcherText: 'search.switch_to_inspections',
    }
}
export const useCurrentSearchContext = () => {
    const features = useSelector(getFeaturesSelector);
    const permissions = useSelector(getPermissionsSelector);
    const productComplianceEnabled = useSelector(getComplianceIsEnabledSelector);
    const inspectionsEnabled = features.includes(Feature.Quality) && useCheckHasPermission(permissions, 'u_inspections'); 
    const showSearchField = inspectionsEnabled || productComplianceEnabled;
    const [context, setContext] = useState<SearchContext>(() => {
        if (productComplianceEnabled) {
            return searchContext[SearchContextEnum.Products];
        }
        if (features.includes(Feature.Quality)) {
            return searchContext[SearchContextEnum.Inspections];
        }
        return null;
    });
    const location = useLocation();
    const showContextSwitcher = inspectionsEnabled && productComplianceEnabled;
    const changeContext = (context: SearchContextEnum) => setContext(searchContext[context]);

    useMemo(() => {
        if (location.pathname.includes('compliance') && productComplianceEnabled && context.id !== SearchContextEnum.Products) {
            setContext(searchContext[SearchContextEnum.Products])
        }
        if (location.pathname.includes('inspections') && inspectionsEnabled && context.id !== SearchContextEnum.Inspections) {
            setContext(searchContext[SearchContextEnum.Inspections]);
        }
    }, [location.pathname]);
    return { context, changeContext, showContextSwitcher, showSearchField };
}

export default useCurrentSearchContext;
