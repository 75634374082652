import * as React from "react";
import { useIntl } from "react-intl";
import { ButtonTypes } from "../../../base/ui/components/buttons/Button";
import QModal, { ActionTypes } from "../../../base/ui/components/modals/Modal";

export default function DeleteDocumentConfirmModal(props: {
    archiveDocument: () => void;
    deleteDocument: () => void;
    open: boolean;
    close: () => void;
}) {
    const { open, archiveDocument, deleteDocument, close } = props;
    const intl = useIntl();
    
    return (
        <QModal
            isOpen={open}
            width={454}
            header={intl.formatMessage({
                id: "production_units.detailed_page.context_menu.delete_document",
            })}
            content={
                <>
                    <div
                        className="text-wrap mb-4"
                        dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                                id: "production_units.detailed_page.context_menu.delete_document_confirmation_1",
                            }),
                        }}
                    ></div>
                    {
                        archiveDocument && (
                            <div
                                className="text-wrap mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                        id: "production_units.detailed_page.context_menu.delete_document_confirmation_2",
                                    }),
                                }}
                            ></div>
                        )
                    }
                </>
            }
            onClose={close}
            actionItems={
                archiveDocument ? [
                {
                    type: ActionTypes.Action,
                    buttonType: ButtonTypes.Secondary,
                    text: "production_units.detailed_page.context_menu.archive",
                    event: () => {
                        archiveDocument();
                        close();
                    },
                    icon: "operation_archive",
                },
                {
                    type: ActionTypes.Action,
                    buttonType: ButtonTypes.Danger,
                    text: "production_units.detailed_page.context_menu.yes_delete_document",
                    event: () => {
                        deleteDocument();
                        close();
                    },
                    icon: "operation_delete",
                },
                {
                    type: ActionTypes.Action,
                    buttonType: ButtonTypes.Plain,
                    text: "globals.confirmation.cancel",
                    event: close,
                },
            ] : [
                {
                    type: ActionTypes.Action,
                    buttonType: ButtonTypes.Danger,
                    text: "production_units.detailed_page.context_menu.yes_delete_document",
                    event: () => {
                        deleteDocument();
                        close();
                    },
                    icon: "operation_delete",
                },
                {
                    type: ActionTypes.Action,
                    buttonType: ButtonTypes.Plain,
                    text: "globals.confirmation.cancel",
                    event: close,
                },
            ]}
        />
    );
}
