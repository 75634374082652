import React, { Fragment, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Divider, Form, Grid, Header, Popup } from 'semantic-ui-react';
import { Feature } from '../../../../backend_api/models';
import Image from '../../../../base/components/Image';
import { getFeaturesSelector } from '../../../../base/selectors';
import { AppThunk } from '../../../../base/types';
import { getPrettyDate } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { Approval } from '../../../approval/components';
import globals from '../../../globals';
import DatePicker from '../../../globals/components/DatePicker';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import HasReadonlyAccess from '../../../globals/components/access/HasReadonlyAccess';
import { InspectorSelector } from '../../../users/components';
import { UserLine } from '../../../users/components/Widgets';
import Watchers from '../../../watchers/components/Watchers2';
import { isInspectionLockedOrDone, isInspectionPartOfCombinedInspection } from '../../Utils';
import { datePickerChange, handleAction, handleChange } from '../../actions/editInspectionActions';
import { Inspection } from '../../types';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import SupplierQCSelector from './SupplierQCSelector';


const InfoIcon = globals.components.Utils.InfoIcon;
const TextItem = globals.components.TextItem;

type InspectorLineProps = {
    inspection: Inspection;
    key: string;
    sameForAll: boolean;
    isOnlyOne: boolean;
    hideHeading?: boolean;
    hasInspectionTypes: boolean;
};

const InspectorLine = (props: InspectorLineProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { inspection, hasInspectionTypes, isOnlyOne, sameForAll, key, hideHeading = false } = props;
    const inspectionId = inspection.inspection_id;
    const locked = inspection.locked || isInspectionPartOfCombinedInspection(inspection);
    const done = inspection.status === 'report' || inspection.status === 'ongoing';
    const hasSupplierQc = useSelector(getFeaturesSelector).includes(Feature.SupplierQc);
    const getDisabledUser = (): any => {
        return inspection.assigned_user && inspection.assigned_user.disabled ? <Fragment><FormattedMessage id='users.disabled_user_label' /> - <UserLine user={inspection.assigned_user} /></Fragment> : null
    }
    const [disabledUserLabel, setDisabledUser] = useState(getDisabledUser());
    const disabled = isInspectionLockedOrDone(inspection);
    const renderLine = useMemo(() => {
        return (
            <Fragment key={key}>
                {(!hideHeading && !inspection.is_master) && <h3 className='py-2'>
                    <Header.Content className='space-x-1 flex'>
                        {hasInspectionTypes && <div className='flex space-x-1'><SplitShipmentsMarker inspection={inspection} /><InspectionTypeMarker inspections={[inspection]} /></div>}
                        {!inspection.is_master && <span>{inspection.item_number} {inspection.item_number && <span>-</span>} {inspection.item_name}</span>}
                        {!inspection.is_master && <Popup
                            trigger={<span><InfoIcon compact={true} /></span>}
                            className='w-144'
                            flowing={true}
                            hoverable
                        >
                            <Popup.Content className='pb-2'>
                                {inspection.item_image && <div className='border-b -mx-4 px-4 mb-4'><Image className='w-12 mb-4 ' url={inspection.item_image} /></div>}
                                <TextItem label={intl.formatMessage({ id: 'inspections.total_qty' })} text={inspection.total_quantity} />
                                <TextItem label={intl.formatMessage({ id: 'inspections.sample_qty' })} text={inspection.sample_quantity} />
                                {inspection.supplier_entity && <TextItem className='' label={intl.formatMessage({ id: 'inspections.supplier_name' })} text={inspection.supplier_entity.name} />}
                                {inspection.linked_production_unit && <TextItem label={intl.formatMessage({ id: 'inspections.production_unit_name' })} text={inspection.linked_production_unit.name} />}
                                {inspection.linked_production_unit && <TextItem label={intl.formatMessage({ id: 'inspections.production_unit_number' })} text={inspection.linked_production_unit.number} />}
                                {inspection.custom_field_values.map((cfv) => {
                                    return cfv.value === '' ? '' : <TextItem label={cfv.name} text={cfv.value} key={'cfv_' + cfv.id} />;
                                })}
                            </Popup.Content>
                        </Popup>}
                    </Header.Content>
                </h3>}
                <Grid>
                    <Grid.Row stretched className='inspectorLine'>
                        <Grid.Column width={16} verticalAlign='top' className=''>
                            <Form.Group>
                                {hasInspectionTypes && <Fragment>
                                    {sameForAll && !isOnlyOne && <Form.Field inline className=''>
                                        <SplitShipmentsMarker inspection={inspection} /><InspectionTypeMarker type='editInspection' inspections={[inspection]} />
                                    </Form.Field>}
                                </Fragment>}
                                <Form.Field inline className='' width={5}>
                                    <label><FormattedMessage id='inspections.scheduled_inspection_date' /></label>
                                    {!done && !locked &&
                                        <HasReadonlyAccess type='inspection' actionId='scheduled_inspection_date' inspection={inspection}>
                                            <DatePicker
                                                name='scheduled_inspection_date'
                                                date={inspection.scheduled_inspection_date || null}
                                                disabled={disabled}
                                                handleChange={(dp): AppThunk => dispatch(datePickerChange(dp, 'scheduled_inspection_date', inspectionId))}
                                            />
                                        </HasReadonlyAccess>}
                                    {(locked || done) && <div>{getPrettyDate(inspection.scheduled_inspection_date) || 'Date not set'}</div>}
                                </Form.Field>
                                <Form.Field inline width={5}>
                                    <label><FormattedMessage id='inspections.assigned_user_id' /></label>
                                    <HasReadonlyAccess type='inspection' actionId='assigned_user_id' inspection={inspection}>
                                        <InspectorSelector
                                            handleChange={(item): AppThunk => {
                                                if (inspection.assigned_user && item.value === inspection.assigned_user.id) {
                                                    setDisabledUser(inspection.assigned_user.disabled ? getDisabledUser() : null);
                                                } else {
                                                    setDisabledUser(null);
                                                }
                                                return dispatch(handleAction(item, inspection.inspection_id));
                                            }}
                                            id={inspection.inspection_id}
                                            selectedInspector={inspection.assigned_user_id}
                                            disabled={disabled}
                                            showUnassigned={true}
                                            showEmailInSelector={true}
                                            fixedWidth={false}
                                            key={'inspector_selector_' + inspection.inspection_id}
                                            disabledUserLabel={disabledUserLabel}
                                        />
                                    </HasReadonlyAccess>
                                </Form.Field>
                            </Form.Group >
                            <Form.Field width={4}>
                                {hasSupplierQc && <HasReadonlyAccess actionId='supplier_qc' type='inspection' inspection={inspection}>
                                    <SupplierQCSelector inspection={inspection} handleChange={(evt, item): AppThunk => dispatch(handleChange(evt, item, inspection.inspection_id))} label={intl.formatMessage({ id: 'edit_inspection.inspection_is_supplier_qc' })} />
                                </HasReadonlyAccess>}
                            </Form.Field>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} verticalAlign='top' className='item'>
                            <HasFeatureAccess type='inspection' feature='manage_watchers' inspection={inspection}>
                                <Watchers
                                    inspection={inspection}
                                    locked={locked}
                                    done={done}
                                    handleChange={handleChange}
                                    key={'watcher_' + inspection.inspection_id}
                                />
                            </HasFeatureAccess>
                        </Grid.Column>
                        <Grid.Column width={8} verticalAlign='top' className='item'>
                            <HasFeatureAccess type='inspection' showDisabled={true} feature='setup_approval_flow' inspection={inspection}>
                                <Approval
                                    inspection={inspection}
                                    handleChange={(evt, item): AppThunk => dispatch(handleChange(evt, item, inspection.inspection_id))}
                                    disabled={locked || done}
                                />
                            </HasFeatureAccess>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
            </Fragment >
        );
    }, [inspection.scheduled_inspection_date, inspection.assigned_user, inspection.supplier_qc, inspection.assigned_user_id, inspection.assigned_user, inspection.watchers, inspection.approval_flow, inspection.item_number, inspection.item_name, inspection.total_quantity, inspection.sample_quantity, inspection.custom_field_values, inspection.inspection_type, sameForAll, hideHeading, intl.locale]);
    return renderLine;
}

export default InspectorLine;
