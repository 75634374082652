/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldDefinitionDocumentationType = {
    type: InputCustomFieldDefinitionDocumentationType.type;
};

export namespace InputCustomFieldDefinitionDocumentationType {

    export enum type {
        DOCUMENTATION_TYPE = 'DocumentationType',
    }


}

