import {
    AuditChecklistHeader,
    AuditChecklistWithContent,
    AuditCheckpoint,
    AuditCustomFieldDate,
    AuditCustomFieldDateValue,
    AuditCustomFieldMultipleChoiceValue,
    AuditCustomFieldNumber,
    AuditCustomFieldNumberValue,
    AuditCustomFieldOptionIds,
    AuditCustomFieldText,
    AuditCustomFieldTextValue,
    AuditCustomFieldValue,
    AuditCustomFieldSupplierDocumentValue,
    AuditCustomFieldDocumentValue
} from "../../../../backend_api_2";

export function doesAuditHaveAnyMandatoryCheckpoints(checklist: AuditChecklistWithContent): boolean {
    return checklist?.headers.some(doesChecklistHeaderHaveAnyMandatoryCheckpoints);
}

export function doesChecklistHeaderHaveAnyMandatoryCheckpoints(checklistHeader: AuditChecklistHeader): boolean {
    return checklistHeader?.checkpoints.some(doesCheckpointRequiresInputOrMedia);
}

export function doesCheckpointRequiresInputOrMedia(checkpoint: AuditCheckpoint): boolean {
    return doesCheckpointRequireMandatoryInput(checkpoint) || doesCheckpointRequireMandatoryMedia(checkpoint);
}

export function doesCheckpointRequireMandatoryInput(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.NOT_APPLICABLE) {
        return false;
    }
    if (!checkpoint.mandatory) {
        return false;
    }

    if (checkpoint.mandatory) {
        return doesCheckpointRequireStatus(checkpoint)
            || doesTextInputCheckpointRequireInput(checkpoint)
            || doesTextTableCheckpointRequireInput(checkpoint)
            || doesMultipleChoiceCheckpointRequireInput(checkpoint)
            || doesPUCustomFieldCheckpointRequireInput(checkpoint)
            || doesSupplierCustomFieldCheckpointRequireInput(checkpoint)
    }

    return false;
}

function doesCheckpointRequireMandatoryMedia(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.NOT_APPLICABLE) {
        return false;
    }
    if (checkpoint.is_picture_mandatory) {
        return checkpoint.observed_file_resources.length == 0;
    }

    return false;
}

function doesCheckpointRequireStatus(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.status_enabled) {
        return checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.EMPTY;
    }

    return false;
}

export function doesTextInputCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.TEXT_INPUTS
        && !!checkpoint.text_inputs
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.text_inputs?.inputs.every(input => {
            const value = input.value;
            return value == undefined || value == null || value == "";
        });
    }
    return false;
}

export function doesTextInputCheckpointHaveInput(checkpoint: AuditCheckpoint): boolean {
    return checkpoint.text_inputs?.inputs?.some(input => {
        const value = input.value;
        return value != undefined && value == null && value == "";
    });
}

export function doesTextTableCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.TEXT_TABLE
        && !!checkpoint.text_table
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.text_table?.values.every(v => {
            const value = v.value;
            return value == undefined || value == null || value == "";
        });
    }
    return false;
}

export function doesTextTableCheckpointHaveInput(checkpoint: AuditCheckpoint): boolean {
    return checkpoint.text_table?.values?.some(v => {
        const value = v.value;
        return value == undefined && value == null && value == "";
    });
}

export function doesMultipleChoiceCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.MULTIPLE_CHOICE
        && !!checkpoint.multiple_choice
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.multiple_choice?.options.every(option => !option.chosen);
    }
    return false;
}

export function doesPUCustomFieldCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.production_unit_custom_fields
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.production_unit_custom_fields.some(c => !doesCustomFieldHaveInput(c));
    }
    return false;
}

export function doesSupplierCustomFieldCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if (checkpoint.supplier_custom_fields
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.supplier_custom_fields.some(c => !doesCustomFieldHaveInput(c));
    }
    return false;
}

function doesCustomFieldHaveInput(customField: AuditCustomFieldValue): boolean {
    return doesCustomFieldHaveTextInput(customField)
        && doesCustomFieldHaveNumberInput(customField)
        && doesCustomFieldHaveDateInput(customField)
        && doesCustomFieldHaveMultipleChoiceInput(customField)
        && doesCustomFieldHaveSupplierDocumentInput(customField);
}

function isTextCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type === "Text";
}

function doesCustomFieldHaveTextInput(customField: AuditCustomFieldValue): boolean {
    if (isTextCustomField(customField)) {
        const value = (customField?.value as AuditCustomFieldTextValue);
        if (value?.new_value?.type === "text") {
            const textValue = value.new_value as AuditCustomFieldText;
            return textValue.text !== undefined && textValue.text !== null && textValue.text !== "";
        }
        return false;
    }
    return true;
}

function isNumberCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type === "Number";
}

function doesCustomFieldHaveNumberInput(customField: AuditCustomFieldValue): boolean {
    if (isNumberCustomField(customField)) {
        const value = (customField?.value as AuditCustomFieldNumberValue);
        if (value?.new_value?.type === "number") {
            const numberValue = value.new_value as AuditCustomFieldNumber;
            return numberValue.number !== undefined && numberValue.number !== null && numberValue.number !== "";
        }
        return false;
    }
    return true;
}

function isDateCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type === "Date";
}

function doesCustomFieldHaveDateInput(customField: AuditCustomFieldValue): boolean {
    if (isDateCustomField(customField)) {
        const value = (customField?.value as AuditCustomFieldDateValue);
        if (value?.new_value?.type === "date") {
            const dateValue = value.new_value as AuditCustomFieldDate;
            return dateValue.date !== undefined && dateValue.date !== null && dateValue.date !== "";
        }
        return false;
    }
    return true;
}

function isMultipleChoiceCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type === "MultipleChoice";
}

function doesCustomFieldHaveMultipleChoiceInput(customField: AuditCustomFieldValue): boolean {
    if (isMultipleChoiceCustomField(customField)) {
        const value = (customField?.value as AuditCustomFieldMultipleChoiceValue);
        if (value?.new_value?.type === "option_ids") {
            const optionValue = value.new_value as AuditCustomFieldOptionIds;
            return optionValue.option_ids !== undefined && optionValue.option_ids !== null && optionValue.option_ids.length > 0;
        }
        return false;
    }
    return true;
}

function isSupplierDocumentCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type === "SupplierDocument";
}

function doesCustomFieldHaveSupplierDocumentInput(customField: AuditCustomFieldValue): boolean {
    if (isSupplierDocumentCustomField(customField)) {
        const value = (customField?.value as AuditCustomFieldSupplierDocumentValue);

        // Check new documents
        const hasNewDocuments = value.new_documents !== undefined &&
            value.new_documents !== null &&
            value.new_documents.length > 0;

        // Check existing documents for valid ones
        const hasValidExistingDocuments = value.existing_documents !== undefined &&
            value.existing_documents !== null &&
            value.existing_documents.some(doc => {
                // Document is considered valid if it's not in an invalid status
                return isDocumentValid(doc);
            });

        return hasNewDocuments || hasValidExistingDocuments;
    }
    return true;
}

// Helper function to check if a document is valid (not expired, deleted, archived, etc.)
function isDocumentValid(document: AuditCustomFieldDocumentValue): boolean {
    // If there's no status, we consider it valid
    if (!document.status) {
        return true;
    }

    // Consider the document invalid if it's EXPIRED, ARCHIVED, DELETED, or REJECTED
    const invalidStatuses = [
        AuditCustomFieldDocumentValue.status.EXPIRED,
        AuditCustomFieldDocumentValue.status.ARCHIVED,
        AuditCustomFieldDocumentValue.status.DELETED
    ];

    return !invalidStatuses.includes(document.status);
}

