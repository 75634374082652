import MapboxLanguage from '@mapbox/mapbox-gl-language';
import mapboxgl, { MapMouseEvent, Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { ReportLocation as Location } from '../../../../backend_api/models/ReportLocation';
import { useTranslation } from '../../../hooks';
import { ComponentOrStringType } from '../../../types';
import { getLocaleLanguageString } from '../../../utils';
import Button from '../buttons/Button';
import StatusIcon from '../status/StatusIcon';
import { Sizes } from '../types';

type Props = {
    className?: string;
    location?: Location;
    secondLocation?: Location;
    edit?: boolean;
    zoomLevel?: number;
    changeLocationName?: ComponentOrStringType;
    onPress?(e: MapMouseEvent): void;
    onLocationChangeConfirm?(loc): void;
    onLocationChangeCancel?(): void;
};

const DEFAULT_ZOOM_LEVEL = 12;
const MAPBOX_TOKEN = 'pk.eyJ1IjoibWFwYm94LWFkbWluLXFhcm1hIiwiYSI6ImNtOG9jOHoyeTAxMWcybXNoOGltaDNtZzkifQ.SvXYXVg1ZPnd-Jy0GBjtMA';
const Map = (props: Props): React.ReactElement => {
    const { className, onPress, zoomLevel, onLocationChangeConfirm, onLocationChangeCancel, changeLocationName, edit = false } = props;
    const [secondLocation, setSecondLocation] = useState(null);
    const [location, setLocation] = useState(props.location);
    const [showPopup, setShwowPopup] = useState(false);
    const [justUpdated, setJustUpdated] = useState(false);
    const [markers, setMarkers] = useState([]);
    const mapRef = useRef(null)
    const popRef = useRef(null)
    const mapContainerRef = useRef();
    const intl = useIntl();
    let markerMain = new mapboxgl.Marker({ color: 'green' }).setLngLat([location?.longitude, location?.latitude])

    const changeLocation = (change) => {
        setShwowPopup(false);
        if (change) {
            onLocationChangeConfirm?.(secondLocation);
            setLocation(secondLocation);
            mapRef.current._markers[1].remove();
            mapRef.current._markers[0].remove();
            markerMain = new mapboxgl.Marker({ color: 'green' }).setLngLat([secondLocation.longitude, secondLocation.latitude]).addTo(mapRef.current);
            mapRef.current.setCenter([secondLocation.longitude, secondLocation.latitude]);
            setJustUpdated(true);
        } else {
            onLocationChangeCancel?.();
        }
    }
    const popupHTML = <div className='flex flex-col space-y-3  w-[350px]'>
        <div>{useTranslation('location.map.change_the_location_to_here')}</div>
        <div className='text-sm text-secondary'>{useTranslation('location.map.new_coordinates')}: {secondLocation?.longitude.toFixed(6)} - {secondLocation?.latitude.toFixed(6)}</div>
        <div className='flex space-x-2'>
            <Button primary size={Sizes.Small} onPress={() => changeLocation(true)}><FormattedMessage id='location.map.update_location.yes_update' /></Button>
            <Button size={Sizes.Small} onPress={() => changeLocation(false)}><FormattedMessage id='globals.cancel' /></Button>
        </div>
    </div>
    const getLocale = () => {
        const lang = getLocaleLanguageString(intl.locale);
        if (lang === 'zh') {
            return 'zh-Hans';
        }
        return lang;
    }
    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_TOKEN;
        const m = new mapboxgl.Map({
            container: mapContainerRef.current,
            center: [location?.longitude, location?.latitude],
            zoom: zoomLevel || DEFAULT_ZOOM_LEVEL,
            style: 'mapbox://styles/mapbox/streets-v11',
        });
        markerMain.addTo(m);
        m.on('click', (e) => {
            onPress && onPress(e);
            if (props.edit) {
                if (popRef?.current) {
                    popRef.current.style.left = e.point.x + 'px';
                    popRef.current.style.top = e.point.y + 'px';
                    popRef?.current?.click();
                    setShwowPopup(true);

                }
                setSecondLocation({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
            }
        });
        m.addControl(new MapboxLanguage({
            defaultLanguage: getLocale(),
        }));
        m.on('movestart', () => {
            setShwowPopup(false);
        })
        mapRef.current = m;
        return () => {
            mapRef.current.remove()
        }
    }, [])
    useEffect(() => {
        if (markers.length > 0) {
            markers[0].addTo(mapRef.current);
            markers.slice(1).forEach((marker) => {
                marker.remove();
            });
        }
    }, [markers])
    useEffect(() => {
        if (!secondLocation) return
        const secondMarker = new Marker().setLngLat([secondLocation?.longitude, secondLocation?.latitude]);
        setMarkers([secondMarker, ...markers]);
    }, [edit && secondLocation]);

    return <div id='map-container' className='w-full h-full' ref={mapContainerRef} key={'maop'}>
        {edit && <div className='absolute top-0 left-0 z-10 text-base bg-surface-secondary text-on-surface-secondary p-3 m-3 rounded-md items-center gap-x-2 flex'>
            {justUpdated && <StatusIcon status='approved' size={Sizes.Small} />}
            {useTranslation(justUpdated ? 'location.map.location_updated' : 'location.map.click_to_change_location')}
        </div>}
        <Popup open={showPopup} offset={[-10, 30]} closeOnEscape closeOnTriggerMouseLeave closeOnPortalMouseLeave position='top center' trigger={<div className='absolute' id='popup' ref={popRef}>POP</div>} className='absolute bg-white p-8 z-10' id='popup'>{popupHTML}</Popup>
    </div>
}
export default Map;
