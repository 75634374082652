import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EditContextMenuButton from '../../../base/components/basic/EditContextMenuButton';
import Group from '../../groups/components/Group';
import { ControlledImmediateGroupsSelector } from '../../groups/components/GroupsSelector';
import { useGroups } from '../../groups/hooks';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getGroupsById } from '../../users/selectors';

type Props = {
    className?: string;
    groupIds?: string[];
    productionUnitId: string;
    onGroupAdded(groupId: string): void
    onGroupRemoved(groupId: string): void
    allowedToEdit: boolean
};

const DetailedProductionUnitGroups = (props: Props): React.ReactElement => {
    const { groupIds } = props;
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    const groupsById = useSelector(getGroupsById)

    const selectableGroups = useGroups().filter(g => !g.is_supplier_group);

    return <div>
        <h2 className="text-primary font-bold text-2xl !mt-6 !mb-2"><FormattedMessage id="production_units.detailed_page.groups.heading" /></h2>
        <ContentSection
            noHeaderBorder={true}
            content={<div className="flex">
                <div className="flex-1">
                    {!edit && groupIds.length === 0 && <div><FormattedMessage id='production_units.detailed_page.no_groups_added' /></div>}
                    {edit && <p><FormattedMessage id='production_units.detailed_page.groups.add_or_remove' /></p>}

                    {edit && <ControlledImmediateGroupsSelector
                        onGroupAdded={props.onGroupAdded}
                        onGroupRemoved={props.onGroupRemoved}
                        className=''
                        selectableGroups={selectableGroups}
                        selectedGroupIds={props.groupIds}
                    />}

                    {!edit && <div className='pb-4'>
                        {groupIds
                            .map((groupId) => {
                                const group = groupsById[groupId];

                                if (group) {
                                    return <Group key={'group_' + groupId} group={groupsById[groupId]} />
                                }
                                return null
                            })
                            .filter(g => g != null)}
                    </div>}
                </div>
                <div className="">
                    {props.allowedToEdit && <EditContextMenuButton onEdit={setEdit} />}
                </div>
            </div>}
        />
    </div>
}
export default DetailedProductionUnitGroups;
