import React from 'react';
import { useIntl } from 'react-intl';
import { TextWithTranslation, TextWithTranslationTranslationMethodEnum } from '../../../backend_api/models';
import { TextWithTranslation as TextWithTranslation2 } from '../../../backend_api_2/models';
import { getLocaleLanguageString } from '../../utils';

type CombinedTextWithTranslation = TextWithTranslation | TextWithTranslation2;

export function transformTextWithTranslationV2toV1(twt2: TextWithTranslation2): TextWithTranslation {
    let translationMethod: TextWithTranslationTranslationMethodEnum | undefined = undefined;

    if (twt2.translation_method) {
        switch (twt2.translation_method) {
            case 'manuel':
                translationMethod = TextWithTranslationTranslationMethodEnum.Manuel;
                break;
            case 'manual':
                translationMethod = TextWithTranslationTranslationMethodEnum.Manual;
                break;
            case 'automatic':
                translationMethod = TextWithTranslationTranslationMethodEnum.Automatic;
                break;
        }
    }

    return {
        language: twt2.language,
        text: twt2.text,
        translation_method: translationMethod,
        translations: twt2.translations as { [key: string]: string } | null
    };
}

export function useLocaleLanguageString() {
    const intl = useIntl();
    return getLocaleLanguageString(intl.locale);
}

export function TranslateTextWithTranslation(props: { twt: CombinedTextWithTranslation }) {
    const locale = useLocaleLanguageString()
    return <div className='flex items-center space-x-1 '>
        {/* TODO: colors: icon color should be based on Figma variable */}
        {/* <Icon name='translate' className='border rounded border-secondary text-secondary ' /> */}
        <div>{translateTextWithTranslation(props.twt, locale)}</div></div>;
}

export function translateTextWithTranslation(twt: CombinedTextWithTranslation | null, locale: string | null): string | null {
    if (!twt) {
        return null
    }
    if (!locale) {
        return twt.text
    }
    const translations = twt.translations
    if (!translations) {
        return twt.text
    }
    const fromTranslations = translations[locale]
    if (fromTranslations) {
        return fromTranslations
    }
    return twt.text
}

