import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Modal, Popup, Radio } from 'semantic-ui-react';
import { InspectionsWeekviewSupplierOrders } from '../../../backend_api/models';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import { DEFAULT_DATE_FORMAT } from '../../../base/config';
import { getPermissionsSelector } from '../../../base/selectors';
import { getMoment } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import DatePicker from '../../globals/components/DatePicker';
import UsersSelector from '../../users/components/UsersSelector';
import { hasPlannedInspections } from '../Utils';
import { WeekViewStoreObject, getWeekViewDataFiltered, storeWeekViewData } from '../slices/weekViewSlice';
import { CombinedInspectionLabel } from './combine/CombinedInspections';
import { InspectionsWeekviewInspectionExtended, WeekViewItemType } from './dashboard/views/weekView/WeekView2';
import { InspectionTypeMarker } from './inspectionTypes/InspectionTypes';

type ReassignOrderProps = {
    className?: string;
    orderId: string;
    supplierObj: InspectionsWeekviewSupplierOrders;
    date?: Date;
    assignedUserId?: string;
    itemType: WeekViewItemType;
    trigger: React.ReactElement;
};

const WeekViewReassignOrder = (props: ReassignOrderProps): React.ReactElement => {
    const { className, trigger, orderId, supplierObj, date, assignedUserId, itemType } = props;
    const hasBooking = useSelector(getPermissionsSelector).includes('u_show_booking_info');
    const modalOpen = useSelector(getShowModalSelector);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const getOrders = () => {
        const ret = [];
        supplierObj.orders.forEach((order) => {
            if (order.combined && order.inspections[0].status === 'planned') {
                ret.push(order);
            }
            if (!order.combined) {
                ret.push(order);
            }
        });
        return ret;
    }
    const getInspections = () => {
        let ret = [];
        orders && orders.forEach((order) => {
            if (order.combined && order.inspections[0].status === 'planned') {
                ret.push(order.inspections[0]);
            } else {

                ret = ret.concat(order.inspections.filter((insp) => {
                    return insp.status === 'planned';
                }));
            }
        })
        return ret;
    }
    const updateSelectedInspections = (inspection: InspectionsWeekviewInspectionExtended) => {
        const ids = [...selectedInspections];
        if (ids.includes(inspection)) {
            const index = ids.indexOf(inspection);
            ids.splice(index, 1);
        } else {
            ids.push(inspection);
        }
        setSelectedInspections(ids);
    }

    const [_date, setDate] = useState(date);
    const [_assignedUserId, setAssignedUserId] = useState(assignedUserId);
    const [selectedInspections, setSelectedInspections] = useState(getInspections());
    const [orders, setOrders] = useState(getOrders());
    const [isBooking, setIsBooking] = useState(itemType === 'booked');
    const [isAssigned, setIsAssigned] = useState(itemType === 'user');
    const [isPlanned, setIsPlanned] = useState(itemType === 'planned');
    const [isSupplierQC, setIsSupplierQC] = useState(itemType === 'supplier_qc');
    const canAssignBooking = orders[0] && orders[0].features.includes('order_change_booking_data');
    const canBook = hasBooking && canAssignBooking;
    const hasItems = hasPlannedInspections(getInspections());
    const assignOk = (isAssigned && _assignedUserId !== null);
    const canSave = (selectedInspections.length > 0) && assignOk || !isAssigned;
    const cancel = () => {
        init();
        dispatch(showModal({ show: false, id: orderId }));
    }
    const save = () => {
        const wd = getMoment(date).isoWeekday() - 1;
        const d = getMoment(date).add(-wd, 'd').format(DEFAULT_DATE_FORMAT);
        const storeObj: WeekViewStoreObject = {
            inspection_ids: [],
            assigned_user_id: _assignedUserId || null,
            supplier_qc: isSupplierQC,
        };
        isBooking ? storeObj['booking_date'] = _date : storeObj['scheduled_inspection_date'] = _date;
        selectedInspections.forEach((inspection) => {
            storeObj.inspection_ids.push(inspection.inspection_id);
        })
        dispatch(storeWeekViewData(storeObj));
        dispatch(showModal({ show: false, id: orderId }));
        dispatch(getWeekViewDataFiltered({ date: d }));
    }
    const init = () => {
        setSelectedInspections(getInspections());
        setIsBooking(itemType === 'booked');
        setIsSupplierQC(itemType === 'supplier_qc');
        setIsAssigned(itemType === 'user');
        setIsPlanned(itemType === 'planned');
        setDate(date);
        setAssignedUserId(assignedUserId);
    }
    useEffect(() => {
        setOrders(getOrders());
    }, [supplierObj]);

    useEffect(() => {
        init();
    }, [orders]);

    const setAllFalse = () => {
        setIsBooking(false);
        setIsSupplierQC(false);
        setIsPlanned(false);
        setIsAssigned(false);
        setAssignedUserId(null);
    }
    const modal = hasItems ? <Modal
        open={modalOpen[orderId] && modalOpen[orderId].show}
        onOpen={() => {
            dispatch(showModal({ show: true, id: orderId }))
        }}
        style={{ maxWidth: '800px' }}
        onClose={() => dispatch(showModal({ show: false, id: orderId }))}
        trigger={<div><Popup trigger={trigger}>
            <FormattedMessage id='inspections.week_view.reassign_order.change_inspector_and_date.popup_text' />
        </Popup></div>}
    >
        <Modal.Header><FormattedMessage id='inspections.week_view.reassign_order.change_inspector_and_date.heading' /></Modal.Header>
        <Modal.Content>
            <div className='font-medium pb-1 text-secondary'>{supplierObj.supplier && supplierObj.supplier.name || intl.formatMessage({ id: 'inspections.week_view.reassign_order.no_supplier' })}</div>
            <div className='flex pb-2 flex-col'>
                {orders.map((order) => {
                    const inspections = order.combined ? (order.inspections[0].status === 'planned' && order.inspections[0].sub_inspections) || [] : order.inspections;
                    if (hasPlannedInspections(inspections)) {
                        return <div key={'order_' + order.id}>
                            {<div className='flex font-medium py-2 space-x-2 items-center mt-3'>
                                <div className='items-center flex space-x-2'>{order.combined && <Checkbox onChange={() => updateSelectedInspections(order.inspections[0] as InspectionsWeekviewInspectionExtended)} checked={(order.inspections[0].status === 'planned' && selectedInspections.includes(order.inspections[0]))} />}<div>{order.order_number}</div> {order.combined && <div><CombinedInspectionLabel noData={true} type='dashboard' /></div>}</div>
                            </div>}
                            {inspections.map((inspection) => {
                                return inspection.status === 'planned' && <div className='space-x-2 flex items-center py-1' key={inspection.inspection_id}>
                                    {!order.combined && <Checkbox disabled={order.combined} onChange={() => updateSelectedInspections(inspection as InspectionsWeekviewInspectionExtended)} checked={(inspection.status === 'planned' && selectedInspections.includes(inspection))} />}
                                    <InspectionTypeMarker showInfo={true} type='weekView' inspections={[inspection]} /><div>{inspection.item_number}. {inspection.item_name}</div></div>
                            })}
                        </div>
                    }
                })}
            </div>
            <div className='flex flex-col space-y-3 pt-4'>
                <DatePicker className='text-3xl mb-2' date={_date} disabled={isBooking && !canBook}
                    autoFocus={false}
                    handleChange={(d): void => {
                        setDate(d !== null ? new Date(getMoment(d).format(DEFAULT_DATE_FORMAT)) : null);
                    }}
                />
                <Radio name='r' className='w-72' checked={isBooking} onChange={() => {
                    setAllFalse();
                    setIsBooking(true);
                }} label={intl.formatMessage({ id: 'inspections.week_view.reassign_order.booking' })}
                    disabled={!(canBook)} />
                <Radio checked={isSupplierQC} name='r' className='w-72' onChange={() => {
                    setAllFalse();
                    setIsSupplierQC(true);
                }} label={intl.formatMessage({ id: 'inspections.week_view.reassign_order.supplier_qc' })} />
                <Radio checked={isPlanned} name='r' className='w-72' onChange={() => {
                    setAllFalse();
                    setIsPlanned(true);
                }} label={intl.formatMessage({ id: 'inspections.week_view.reassign_order.planned' })} />
                <Radio checked={isAssigned} name='r' className='w-72' onChange={() => {
                    setAllFalse();
                    setIsAssigned(true);
                }} label={intl.formatMessage({ id: 'inspections.week_view.reassign_order.assigned_to' })} />
                <UsersSelector className={isAssigned && !assignOk && 'border rounded-lg border-danger'} showUnassigned value={[_assignedUserId]} showEmailInSelector allowMultipleSelection={false} handleChange={(item) => {
                    setAllFalse();
                    setAssignedUserId(item.value);
                    setIsAssigned(true);
                    setIsPlanned(item.value === null);
                }}
                    placeholder={intl.formatMessage({ id: 'inspections.week_view.reassign_order.select_inspector' })} />
            </div>
        </Modal.Content>
        <Modal.Actions>
            <Button disabled={!canSave} primary onClick={() => save()}><FormattedMessage id='globals.save' /></Button>
            <Button onClick={() => cancel()}><FormattedMessage id='globals.cancel' /></Button>
        </Modal.Actions>
    </Modal> : null;
    return <div className={classnames('', className)}>{modal}</div>
}
export default WeekViewReassignOrder;


