import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup, Table, TableRow } from 'semantic-ui-react';
import { AuditReportCustomField, AuditReportCustomFieldsDate, AuditReportCustomFieldsDateTypeEnum, AuditReportCustomFieldsMultipleChoice, AuditReportCustomFieldsMultipleChoiceTypeEnum, AuditReportCustomFieldsNumber, AuditReportCustomFieldsNumberTypeEnum, AuditReportCustomFieldsDocumentation, AuditReportCustomFieldsDocumentationTypeEnum, AuditReportCustomFieldsDocumentationDocument, AuditReportCustomFieldsDocumentationDocumentStatusEnum, AuditReportCustomFieldsText, AuditReportCustomFieldsTextTypeEnum } from '../../../../../../backend_api/models';
import Tag from '../../../../../../base/components/basic/Tag';
import { translateTextWithTranslation } from '../../../../../../base/components/basic/TextWithTranslation';
import { getPrettyDate } from '../../../../../../base/utils';
import Icon from '../../../../../../base/ui/components/icons/Icon';
import { Sizes } from '../../../../../../base/components/types';
import DocumentationTypeTooltipContent from '../../../../../supplierDocuments/components/DocumentationTypeTooltipContent';

export function renderCustomFieldValueRow(
    customFieldValue: AuditReportCustomField,
    locale: string | null
) {
    const isDocumentationType = customFieldValue.value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation;

    return (
        <TableRow>
            <Table.Cell>
                {translateTextWithTranslation(customFieldValue.name, locale)}
                {isDocumentationType && (
                    <Popup trigger={<Icon size={Sizes.XLarge} name='operation_get-info' />} on="hover">
                        <DocumentationTypeTooltipContent documentationType={customFieldValue.value as AuditReportCustomFieldsDocumentation} />
                    </Popup>
                )}
            </Table.Cell>
            <Table.Cell className='border-l'>
                {renderContent(customFieldValue, locale)}
            </Table.Cell>
        </TableRow >
    );
}

function renderContent(
    customFieldValue: AuditReportCustomField,
    locale: string | null
) {
    if (didAuditorChangeCustomFieldValue(customFieldValue)) {
        return renderWhenChanged(customFieldValue, locale);
    }
    if (didAuditorDidConfirmCustomFieldValue(customFieldValue)) {
        return renderWhenConfirmed(customFieldValue, locale);
    }
    return renderWhenNotTouched(customFieldValue, locale);
}

function renderWhenChanged(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderPreviousCustomFieldValue(customFieldValue.value, locale)}
            <span> &rarr; </span>
            {renderCustomFieldValue(customFieldValue.value, locale)}
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_changed'} />
        </Popup>
    )
}

function renderWhenNotTouched(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderCustomFieldValue(customFieldValue.value, locale)}
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_not_changed'} />
        </Popup>
    )
}

function renderWhenConfirmed(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderCustomFieldValue(customFieldValue.value, locale)}
            <span> &#10003; </span>
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_confirmed'} />
        </Popup>
    )
}

export function didAuditorDidConfirmCustomFieldValue(wrapper: AuditReportCustomField) {

    if (!wrapper.set_by_auditor) {
        return false;
    }

    const value = wrapper.value;

    if (value.type === AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        return value.options.every(option => option.selected === option.previously_selected)

    } else if (value.type === AuditReportCustomFieldsDateTypeEnum.Date) {
        return value.date === value.previous_date;

    } else if (value.type === AuditReportCustomFieldsTextTypeEnum.Text) {
        return value.text === value.previous_text;

    } else if (value.type === AuditReportCustomFieldsNumberTypeEnum.Number) {
        return value.number === value.previous_number;
    } else if (value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        return false // no confirmation for this custom field type
    }
}

export function didAuditorChangeCustomFieldValue(wrapper: AuditReportCustomField) {
    if (!wrapper.set_by_auditor) {
        return false;
    }
    const value = wrapper.value;

    if (value.type === AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        return value.options.some(function (option) {
            return option.previously_selected !== option.selected;
        });
    } else if (value.type === AuditReportCustomFieldsDateTypeEnum.Date) {
        return value.date !== value.previous_date;
    } else if (value.type === AuditReportCustomFieldsTextTypeEnum.Text) {
        return value.text !== value.previous_text;
    } else if (value.type === AuditReportCustomFieldsNumberTypeEnum.Number) {
        return value.number !== value.previous_number;
    } else if (value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        return false // no confirmation for this custom field type
    }
}

export function renderPreviousCustomFieldValue(
    value: AuditReportCustomFieldsMultipleChoice | AuditReportCustomFieldsText | AuditReportCustomFieldsDate | AuditReportCustomFieldsNumber | AuditReportCustomFieldsDocumentation,
    locale: string | null) {
    if (value.type == AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        const selectedOptions = value.options.filter(function (option) { return option.previously_selected });
        if (selectedOptions.length > 0) {
            return selectedOptions.map(function (option) {
                return (<Tag className={'m-1'} key={'acf_' + option.name}>{translateTextWithTranslation(option.name, locale)}</Tag>);
            });
        }
    }
    else if (value.type == AuditReportCustomFieldsDateTypeEnum.Date) {
        if (value.previous_date) {
            return (<span>{getPrettyDate(value.previous_date)}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsTextTypeEnum.Text) {
        if (value.previous_text) {
            return (<span>{value.previous_text}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsNumberTypeEnum.Number) {
        if (value.previous_number) {
            return (<span>{value.previous_number}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        const previousDocuments = value.documents.filter(doc => doc.previously_added);
        return (
            <div className="flex flex-col">
                {previousDocuments.length === 0 ? (
                    <span className="text-gray-500 italic">No active document</span>
                ) : (
                    previousDocuments.map((document) => (
                        <div key={document.media_resources[0].id} className="flex items-center justify-between py-1">
                            <div className="flex items-center gap-2">
                                <span>{translateTextWithTranslation(value.documentation_type_name, locale)}</span>
                                <Tag className={
                                    document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'bg-green-500 text-white' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'bg-yellow-500 text-white' :
                                            'bg-gray-500 text-white'
                                }>
                                    {document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'Approved' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'In review' :
                                            document.status}
                                </Tag>
                            </div>
                            {document.expiry_date && (
                                <div className="flex items-center gap-1">
                                    <span>Expires {getPrettyDate(document.expiry_date)}</span>
                                    <span className="text-gray-500">Previously added</span>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        );
    }

    return renderEmptyValue();
}

export function renderCustomFieldValue(
    value: AuditReportCustomFieldsMultipleChoice | AuditReportCustomFieldsText | AuditReportCustomFieldsDate | AuditReportCustomFieldsNumber | AuditReportCustomFieldsDocumentation,
    locale: string | null) {
    if (value.type == AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        const selectedOptions = value.options.filter(function (option) { return option.selected });
        if (selectedOptions.length > 0) {
            return selectedOptions.map(function (option) {
                return (<Tag className={'m-1'} key={'acf_' + option.name}>{translateTextWithTranslation(option.name, locale)}</Tag>);
            });
        }
    }
    else if (value.type == AuditReportCustomFieldsDateTypeEnum.Date) {
        if (value.date) {
            return (<span>{getPrettyDate(value.date)}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsTextTypeEnum.Text) {
        if (value.text) {
            return (<span>{value.text}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsNumberTypeEnum.Number) {
        if (value.number) {
            return (<span>{value.number}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        const currentDocuments = value.documents.filter(doc => !doc.previously_added);
        return (
            <div className="flex flex-col">
                {currentDocuments.length === 0 ? (
                    <span className="text-gray-500 italic">No active document</span>
                ) : (
                    currentDocuments.map((document) => (
                        <div key={document.media_resources[0].id} className="flex items-center justify-between py-1">
                            <div className="flex items-center gap-2">
                                <Tag className={
                                    document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'bg-green-500 text-white' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'bg-yellow-500 text-white' :
                                            'bg-gray-500 text-white'
                                }>
                                    {document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'Approved' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'In review' :
                                            document.status}
                                </Tag>
                            </div>
                            <div className="flex items-center gap-1">
                                {document.expiry_date && (
                                    <span>Expires {getPrettyDate(document.expiry_date)}</span>
                                )}
                                <span className="text-status-approved">Document added</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    }

    return renderEmptyValue();
}

function renderEmptyValue() {
    return <span className='italic text-secondary'><FormattedMessage id='globals.no_value' /></span>
}