import qs from 'query-string';
import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Confirm, Dimmer, Dropdown, Grid, Icon, Loader, Pagination, Segment, Table } from 'semantic-ui-react';
import { AdminCtxUserFeatures } from '../../../backend_api/models';
import { AdminCtxUser } from '../../../backend_api/models/AdminCtxUser';
import { UserRoleBrief } from '../../../backend_api/models/UserRoleBrief';
import { Feature } from '../../../backend_api_2';
import { AppState } from '../../../base/types';
import { compareObjects, deepCopy, getLocaleLanguageString } from '../../../base/utils';
import { invalidateUserSession, resetTwoFactorSecret } from '../../authentication/authenticationSlice';
import { Locale } from '../../globals';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import ListButton from '../../globals/components/ListButton';
import { getLocales as locales } from '../../globals/selectors';
import { sortGroupsSelector } from '../../groups/selectors';
import { GroupData } from '../../groups/types';
import { checkHasFeature } from '../../profile/containers/HasFeature';
import { ProfileState } from '../../profile/types';
import { getAdminUsers, getGroups, getLocales, getUsers, setUsersPagination, showCreateUserDialog, updateUser } from '../actions/actions';
import { setUsersFiltersVisibilty } from '../actions/filterActions';
import User from '../components/User';
import {
    getAdminUsersIsFetching, getCreateUserDialogIsOpen,
    getProfileSelector, getRolesSelector, getUsersCurrentPage, getUsersFiltersVisibility, getUsersHiddenFilters, getUsersItemsPerPage, getUsersLastPageFilters,
    getGroups as groupsSelector,
    getAdminUsers as usersSelector
} from '../selectors';
import { DropdownValue } from '../types';
import CreateUserModal from './CreateUserModal';
import FiltersContainer from './FiltersContainer';

type OwnProps = {
    statusUpdatingUser: AdminCtxUser;
};

type StateProps = {
    users: AdminCtxUser[];
    isFetching: boolean;
    locales: Locale[];
    showCreateUser: boolean;
    groups: GroupData[];
    profile: ProfileState;
    roles: UserRoleBrief[];
    filtersVisible: boolean;
    filtersActiveHidden: string[];
    currentPage: number;
    lastPage: number;
    itemsPerPage: number;
};


type DispatchProps = {
    actions: {
        getUsers: typeof getUsers;
        getAdminUsers: typeof getAdminUsers;
        getLocales: typeof getLocales;
        getGroups: typeof getGroups;
        updateUser: typeof updateUser;
        showCreateUserDialog: typeof showCreateUserDialog;
        resetTwoFactorSecret: typeof resetTwoFactorSecret;
        invalidateUserSession: typeof invalidateUserSession;
        setUsersFiltersVisibilty: typeof setUsersFiltersVisibilty;
        setUsersPagination: typeof setUsersPagination;
    };
};

const initialState = { statusUpdatingUser: null, twoFactorModalHandler: '', invalidateSessionHandler: '', usersLoaded: false, openGroup: '' };
type State = Readonly<typeof initialState>;
type UsersProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps & RouteComponentProps<unknown>;

const itemsPerPageOptions = [
    { key: '25', value: 25, text: '25' },
    { key: '50', value: 50, text: '50' },
    { key: '100', value: 100, text: '100' }
]

class UsersContainer extends Component<UsersProps, State> {
    readonly state: State = initialState;
    public constructor(props: UsersProps) {
        super(props);
    }

    shouldComponentUpdate(nextProps): boolean {
        return !nextProps.isFetching;
    }

    public componentDidMount(): void {
        this.props.actions.getAdminUsers();
        this.props.actions.getLocales();
        this.props.actions.getGroups();
        this.props.actions.setUsersPagination({});
        this.props.actions.setUsersFiltersVisibilty(true)
    }

    public componentDidUpdate(prevProps): void {

        if (!this.state.usersLoaded && this.props.users) {
            this.setState({ usersLoaded: true })
        }
        if (!this.state.usersLoaded && !this.props.isFetching) {
            this.setState({ usersLoaded: true })
        }

        if (this.props.users && (!prevProps.users ||
            (this.props.users.length !== prevProps.users.length) ||
            (this.props.users.length > this.props.itemsPerPage && this.props.lastPage === 1))
        ) {
            this.props.actions.setUsersPagination({
                lastPage: Math.ceil(this.props.users.length / this.props.itemsPerPage)
            })
        }
        if (this.isLocationPagePropChanged(prevProps)) {
            this.props.actions.setUsersPagination({})
        }
        if (this.isLocationSearchPropChanged(prevProps)) {
            this.props.actions.setUsersPagination(
                this.props.history.action === 'POP' ? {} : {
                    currentPage: 1
                })
        }
    }

    public isLocationSearchPropChanged = (prevProps): boolean => {
        const params = qs.parse(this.props.location.search);
        const paramsPrev = qs.parse(prevProps.location.search);
        if (params.page) {
            delete params.page;
        }
        if (paramsPrev.page) {
            delete paramsPrev.page;
        }
        if (!compareObjects(params, paramsPrev)) {
            let hasChanged = true;
            const pCopy = deepCopy(params);
            const pCopyPrev = deepCopy(paramsPrev);
            hasChanged = !compareObjects(pCopy, pCopyPrev);
            return hasChanged;
        }
        return false;
    }
    public isLocationPagePropChanged = (prevProps): boolean => {
        const params = qs.parse(this.props.location.search);
        const paramsPrev = qs.parse(prevProps.location.search);
        return (params.page || paramsPrev.page) && params.page !== paramsPrev.page ? true : false;
    }

    public showCreateUserHandler = (): void => {
        this.props.actions.showCreateUserDialog(true);
    }
    public hideCreateUserHandler = (): void => {
        this.props.actions.showCreateUserDialog(false);
    }

    public changeRoleHandler = (userId: string, role: DropdownValue): void => {
        this.props.actions.updateUser(userId, { user_role_id: role });
    }
    public changeGroupsHandler = (userId: string, groups: GroupData[]): void => {
        const newGroups = groups.map((group) => {
            return { id: group };
        });
        this.props.actions.updateUser(userId, { groups: newGroups });
    }

    public statusChangeUserHandler = (user: AdminCtxUser): void => {
        this.setState({ statusUpdatingUser: user });
    }

    public changeItemsPerPageHandler = (event, { value }: { value: string | number }): void => {
        this.props.actions.setUsersPagination({
            currentPage: 1,
            lastPage: Math.ceil(this.props.users.length / +value),
            itemsPerPage: value,
        });
    }

    public confirmStatusChangeUser = (): void => {
        this.props.actions.updateUser(this.state.statusUpdatingUser.id, { active: !this.state.statusUpdatingUser.active });
        this.setState({ statusUpdatingUser: null });
    }
    public cancelStatusChangeUser = (): void => {
        this.setState({ statusUpdatingUser: null });
    }

    public resetTwoFactorAuth = (id: string): void => {
        this.closeTwoFactorModal();
        this.props.actions.resetTwoFactorSecret({ userId: id });
    };
    public invalidateUserSession = (id: string): void => {
        this.closeTwoFactorModal();
        this.props.actions.invalidateUserSession(id);
    };

    public openTwoFactorModal = (id: string): void => {
        this.setState({ twoFactorModalHandler: id });
    }

    public closeTwoFactorModal = (): void => {
        this.setState({ twoFactorModalHandler: '' });
    }
    
    public toggleInvalidateSession = (show: boolean, userId?: string): void => {
        this.setState({ invalidateSessionHandler: show ? userId : '' });
    }

    public handlePaginationChange = (e, { activePage = '1' }: { activePage?: string | number }): void => this.props.actions.setUsersPagination({ currentPage: activePage ? activePage : 1 });

    public pagination = (): any => {
        const { currentPage, itemsPerPage, lastPage } = this.props;
        const length = this.props.users ? this.props.users.length : 0;
        return {
            from: ((currentPage - 1) * itemsPerPage),
            to: currentPage === lastPage && lastPage !== 1 ? length : currentPage * itemsPerPage
        };
    };

    public showUsersPerPage = (users: any[]): any[] => {
        const { from, to } = this.pagination();
        return users.slice(from, to)
    }

    public render(): React.ReactElement {
        const formatMessage = this.props.intl.formatMessage;
        const showGroups = true;
        const canManage = checkHasFeature(this.props.profile.profile.permissions, 'u_users_admin');
        const canReset2FA = checkHasFeature(this.props.profile.profile.organization_features, Feature.TWO_FACTOR_AUTHENTICATION);

        const setOpenGroup = (id: string) => {
            this.setState({ openGroup: id });
        }
        const usersContent = this.props.users ? this.showUsersPerPage(this.props.users).map((user: AdminCtxUser) => {
            return (<HasFeatureAccess type='user' feature={AdminCtxUserFeatures.Manage} user={user} key={user.id}>
                <User disabled={!user.active}
                    roles={this.props.roles}
                    disableUser={this.statusChangeUserHandler}
                    user={user}
                    profile={this.props.profile}
                    roleOptions={this.getRoles()}
                    groups={sortGroupsSelector(this.props.groups)}
                    changerole={this.changeRoleHandler}
                    showGroups={showGroups}
                    invalidateSessionModalVisibility={this.state.invalidateSessionHandler}
                    toggleinvalidateSessionModal={this.toggleInvalidateSession}
                    twoFactorModalVisibility={this.state.twoFactorModalHandler}
                    twoFactorModalOpen={this.openTwoFactorModal}
                    twoFactorModalClose={this.closeTwoFactorModal}
                    resetTwoFactor={this.resetTwoFactorAuth}
                    language={getLocaleLanguageString(this.props.intl.locale)}
                    changegroups={this.changeGroupsHandler}
                    openGroup={this.state.openGroup === user.id}
                    setOpenGroup={setOpenGroup}
                    invalidateUserSession={this.invalidateUserSession}
                />
            </HasFeatureAccess>);
        }) : null;
        let createUserModal;
        if (this.props.showCreateUser) {
            console.log('this.props.groups ', this.props.groups)
            createUserModal = (
                <CreateUserModal
                    showCreateUser={this.props.showCreateUser}
                    cancel={this.hideCreateUserHandler}
                    roles={this.getRoles()}
                    groups={this.props.groups}
                    locales={this.props.locales}
                />);
        }
        let cols = 7;
        let groupsHeader = null;
        if (showGroups) {
            cols = 8;
            groupsHeader = <Table.HeaderCell><FormattedMessage id='users.groups' /></Table.HeaderCell>;
        }
        let statusUpdatingUserName = '';
        if (this.state.statusUpdatingUser) {
            statusUpdatingUserName = this.state.statusUpdatingUser.firstname + ' ' + this.state.statusUpdatingUser.lastname;
        }

        const confirmHeader: string = formatMessage(
            { id: this.state.statusUpdatingUser && this.state.statusUpdatingUser.active ? 'users.are_you_sure_you_want_to_disable_the_user' : 'users.are_you_sure_you_want_to_activate_the_user' }
        );
        const confirmContent: string = formatMessage(
            { id: this.state.statusUpdatingUser && this.state.statusUpdatingUser.active ? 'users.click_ok_if_you_want_to_disable_the_user' : 'users.click_ok_if_you_want_to_activate_the_user' }
        );
        const confirmOkLabel: string = formatMessage({ id: 'globals.ok' });
        const confirmCancelLabel: string = formatMessage({ id: 'globals.cancel' });

        const PaginationElement = (): React.ReactElement => (
            <Pagination
                activePage={this.props.currentPage}
                size='mini'
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={null}
                lastItem={null}
                prevItem={{ content: '<', icon: false }}
                nextItem={{ content: '>', icon: false }}
                totalPages={this.props.lastPage}
                onPageChange={this.handlePaginationChange}
            />
        )

        const ItemPerPageSelect = (): React.ReactElement => (
            <Dropdown selection compact
                options={itemsPerPageOptions}
                defaultValue={itemsPerPageOptions[0].value}
                onChange={this.changeItemsPerPageHandler} />
        )

        return (
            <Grid>
                <Dimmer inverted active={this.props.isFetching || !this.state.usersLoaded}>
                    <Loader className='dimLoader'><FormattedMessage id='users.loading_data.please_wait' /></Loader>
                </Dimmer>
                <DocumentTitle title={this.props.intl.formatMessage({ id: 'page_title.users' })} />
                <div className='pageContainer'>
                    <div className='listMenu mb-4'>
                        <div className='flex space-x-4 items-center'><h2 className='mb-0'><FormattedMessage id='users.users' /></h2>
                            {!this.props.filtersVisible &&
                                <div className=''>
                                    <span className='link filterSelector' onClick={(): void => this.props.actions.setUsersFiltersVisibilty(true)}><FormattedMessage id='inspections.filtres' /></span>
                                    {this.props.filtersActiveHidden.length > 0 && <span className='ml-2 text-sm text-secondary'>{this.props.filtersActiveHidden.length}<FormattedMessage id='inspections.active' /></span>}
                                </div>}
                        </div>
                        <HasFeatureAccess feature='u_add_users'>
                            <ListButton onClick={this.showCreateUserHandler} size='small' primary data-test-id='add-user-btn'><FormattedMessage id='users.add_user' /></ListButton>
                        </HasFeatureAccess>
                    </div>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <FiltersContainer roles={this.getRoles()} filtersActiveHidden={this.props.filtersActiveHidden} />
                        </Grid.Column>
                    </Grid.Row>
                    {this.props.users && this.props.users.length && this.state.usersLoaded ?
                        <Segment.Group raised>
                            <Segment>
                                <Table celled striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell><FormattedMessage id='users.name' /></Table.HeaderCell>
                                            <Table.HeaderCell><FormattedMessage id='users.email_address' /></Table.HeaderCell>
                                            <Table.HeaderCell><FormattedMessage id='users.role' /></Table.HeaderCell>
                                            {groupsHeader}
                                            {canManage && canReset2FA ?
                                                <Table.HeaderCell><FormattedMessage id='twoFactor.users.resets' /></Table.HeaderCell> : ''}
                                            {canManage && <Table.HeaderCell><FormattedMessage id='users.invalidate_user_session' /></Table.HeaderCell>}
                                            <Table.HeaderCell><FormattedMessage id='users.latest_activity' /></Table.HeaderCell>
                                            <Table.HeaderCell><FormattedMessage id='users.deactivate_user'/></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {usersContent}
                                    </Table.Body>
                                    {this.props.lastPage > 1 && <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={cols} >
                                                <div className='tablePaginator'>
                                                    {ItemPerPageSelect()}
                                                    {PaginationElement()}
                                                    <Button onClick={this.showCreateUserHandler} size='small' primary><FormattedMessage id='users.add_user' /></Button>
                                                </div>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>}
                                </Table>
                                {createUserModal}
                                <Confirm
                                    open={this.state.statusUpdatingUser !== null}
                                    header={confirmHeader}
                                    content={confirmContent + statusUpdatingUserName}
                                    onCancel={this.cancelStatusChangeUser}
                                    onConfirm={this.confirmStatusChangeUser}
                                    cancelButton={confirmCancelLabel}
                                    confirmButton={confirmOkLabel}
                                />
                            </Segment>
                        </Segment.Group>
                        : this.state.usersLoaded ?
                            <div className='no-users'>
                                <div className='users-info'><Icon color='red' name='warning circle' />
                                    <FormattedMessage id='users.no_users_found' /></div>
                            </div> : null}
                </div>
            </Grid>
        );
    }
    public getRoles(): { key: string; value: string; text: string }[] {
        const profileRoleId: string = this.props.profile.profile.user_role_id;
        const lang = getLocaleLanguageString(this.props.intl.locale);
        const rolesList: UserRoleBrief[] = this.props.roles;
        let accessLevel = 0;
        rolesList.forEach(element => {
            if (element.id === profileRoleId) {
                accessLevel = element.access_level;
            }
        });
        const roles = [];
        rolesList.forEach((role: UserRoleBrief) => {
            if (role.access_level <= accessLevel) {
                roles.push({ key: role.id, value: role.id, text: role.name[lang] || role.name.C });
            }
        });
        return roles;
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        actions: bindActionCreators({
            getUsers,
            getAdminUsers,
            getLocales,
            getGroups,
            updateUser,
            showCreateUserDialog,
            resetTwoFactorSecret,
            invalidateUserSession,
            setUsersFiltersVisibilty,
            setUsersPagination
        }, dispatch),
    };
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        users: usersSelector(state),
        isFetching: getAdminUsersIsFetching(state),
        locales: locales(state),
        showCreateUser: getCreateUserDialogIsOpen(state),
        groups: groupsSelector(state),
        profile: getProfileSelector(state),
        roles: getRolesSelector(state),
        filtersVisible: getUsersFiltersVisibility(state),
        filtersActiveHidden: getUsersHiddenFilters(state),
        currentPage: getUsersCurrentPage(state),
        lastPage: getUsersLastPageFilters(state),
        itemsPerPage: getUsersItemsPerPage(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(UsersContainer)));
