import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { useCheckHasPermission, useTranslation } from '../../../base/hooks';
import { getPermissionsSelector } from '../../../base/selectors';
import Button from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon, { LineHeights } from '../../../base/ui/components/icons/Icon';
import { Sizes } from '../../../base/ui/components/types';
import { DetailedTag } from '../../../compliance_api/models/DetailedTag';

type Props = {
    className?: string;
    tag: DetailedTag;
    isBase: boolean;
    deleteFunction;
    show: boolean;
    onShow(show: boolean);
    showInput?: boolean;
    onAddTag?(catId, tagId);
    updateNewTag?(tagname: string)
};

const ComplianceAddAndDeleteTag = (props: Props): React.ReactElement => {
    const { className, tag, deleteFunction, isBase, onShow, onAddTag, updateNewTag, showInput = false } = props;
    const intl = useIntl();
    const permissions = useSelector(getPermissionsSelector);
    const userCanEditTags = useCheckHasPermission(permissions, 'u_compliance_edit_tags');
    const addToName = isBase ? tag.category_name : tag.path[tag.path.length - 1];
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);
    return <><div className='flex flex-col'>
        {!show && <div className='flex gap-x-1 items-center px-1'>
            {userCanEditTags && <Popup trigger={<span><Button className='text-xs py-1 px-2' size={Sizes.Small} onPress={() => {
                onShow(true);
                setShow(true);
            }}><FormattedMessage id='compliance.category_selector.add_tag' /></Button></span>}>
                {useTranslation(isBase ? 'compliance.category_selector.add_sibling_tag_popup_text' : 'compliance.tags.add_sub_tag_to_tag', { tag: <span className='font-bold'>{addToName}</span> })}</Popup>}
            {!isBase && userCanEditTags && <Popup trigger={<span><DeleteConfirm type='alert'
                alertText={<span>{intl.formatMessage({ id: 'compliance.tags.remove_tag_warning_text' }, { tagName: tag.name }).toString()}</span>}
                alertHeader={<span>{intl.formatMessage({ id: 'compliance.tags.remove_tag_warning_header' }, { tagName: tag.name })}</span>}
                deleteFunction={deleteFunction}
                trigger={
                    <Icon name='operation_delete' lineHeight={LineHeights.Base} />
                } /></span>}><FormattedMessage id='compliance.tags.remove_tag' values={{ tagName: <span className='font-bold'>{tag.name}</span> }} /></Popup>

            }
        </div>}
    </div>
        {showInput && show && <div className='flex w-full flex-col space-y-1 gap-1 py-2 p-1 m-1'>
            <form
                className='space-y-1'
                onSubmit={(e) => {
                    onAddTag?.(tag.category_id, tag.tag_id)
                    setShow(false);
                    e.preventDefault();
                }}>
                <Field type='text'
                    className={'w-full'}
                    onChange={(value) => updateNewTag?.(value)}
                    placeholder={intl.formatMessage({ id: 'compliance.tags_browser.enter_new_tag' })}
                    autoFocus
                />
                <div className='flex gap-2'>
                    <Button size={Sizes.XSmall} className='' secondary onPress={() => {
                        onAddTag?.(tag.category_id, tag.tag_id)
                        setShow(false);
                    }}><FormattedMessage id='compliance.category_selector.save_new_tag' /></Button>
                    <Button size={Sizes.XSmall} className='' onPress={() => setShow(false)}><FormattedMessage id='globals.cancel' /></Button>
                </div>
            </form>
        </div>}
    </>
}
export default ComplianceAddAndDeleteTag;
