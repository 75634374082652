import classnames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Popup } from 'semantic-ui-react';
import Icon from '../../../base/components/basic/Icon';
import { ComponentOrStringType } from '../../../base/types';
import { twMerge } from '../../../base/utils';

type Props = {
    headerText?: string | React.ReactElement;
    subHeaderText?: string | React.ReactElement;
    headerTextExpanded?: string | React.ReactElement;
    headerActions?: React.ReactElement;
    footer?: ComponentOrStringType;
    content: React.ReactElement;
    expandedContent?: React.ReactElement;
    showExpanded?: boolean;
    className?: string;
    contentClassName?: string;
    noPadding?: boolean;
    noHeaderBorder?: boolean;
    headerOutside?: boolean;
}

const ContentSection = (props: Props) => {
    const { showExpanded = false, noPadding = false, content, expandedContent, headerText, headerActions, headerTextExpanded, subHeaderText, footer, className, contentClassName, noHeaderBorder = false, headerOutside = false } = props;
    const [expanded, setExpanded] = useState(false);
    const close = () => setExpanded(false);
    const getHeader = (isOutside: boolean) => {
        const HeaderTag = isOutside ? 'h2' : 'h3';
        const headerClasses = isOutside
            ? 'text-primary !mt-6 !mb-2 font-bold text-2xl'
            : classnames('w-full flex m-0 p-0 font-medium text-large', { 'justify-between cursor-pointer': showExpanded });

        return <HeaderTag className={headerClasses} onClick={() => showExpanded ? setExpanded(!expanded) : null}>
            {headerText}
            {showExpanded && <Popup
                position='top center'
                trigger={<div><Icon className='text-secondary' name='open_in_full' /></div>}
            ><FormattedMessage id='corrective_actions.popup_more_info' /></Popup>}
        </HeaderTag>;
    };
    return (
        <div>
            {headerText && headerOutside && (
                getHeader(headerOutside)
            )}
            <div className={twMerge('pt-4 pb-6 bg-white border mb-4 rounded-md', !noPadding && 'px-4', className)}>
                {headerText && !headerOutside && <div className={twMerge('pb-4', !noPadding && '-mx-4 px-4', noPadding && 'px-4', !noHeaderBorder && 'border-b')}>
                    <div className='flex justify-between w-full'>
                        <div className='flex flex-row w-full items-center'>
                            {getHeader(false)}
                        </div>
                        <div className='flex whitespace-nowrap'>
                            {headerActions}
                        </div>
                    </div>
                </div>}
                <div className={twMerge('justify-center flex flex-col', headerText && !headerOutside && 'pt-4', contentClassName)}>
                    {subHeaderText && <div className='font-bold text-large pb-2'>{subHeaderText}</div>}
                    {content}
                    {footer && <div className={twMerge('border-t pt-2 -mb-2', noPadding && 'px-4', !noPadding && '-mx-4 px-4 ')}>{footer}</div>}
                </div>
                {showExpanded &&
                    <Modal
                        onClose={() => setExpanded(false)}
                        open={expanded}
                        className='w-full flex'
                        style={{ width: '90%', maxWidth: '1200px' }}
                    >
                        <Modal.Content>
                            <h3 className='pl-4'>{headerTextExpanded || headerText}</h3>
                            <div className='border-b -mx-6' />
                            {expandedContent}
                        </Modal.Content>
                        <Modal.Actions><Button onClick={() => close()}><FormattedMessage id='corrective_actions.content_section.close' /></Button></Modal.Actions>
                    </Modal>
                }
            </div>
        </div>
    )
}

export default ContentSection;