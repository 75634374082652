/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditReportCustomFieldsDocumentationDocument } from './AuditReportCustomFieldsDocumentationDocument';
import type { DisplayUser } from './DisplayUser';
import type { Group } from './Group';
import type { TextWithTranslation } from './TextWithTranslation';

/**
 *
 *
 */
export type AuditReportCustomFieldsDocumentation = {
    documentation_type_code: string;
    documentation_type_description: TextWithTranslation;
    documentation_type_id: string;
    documentation_type_name: TextWithTranslation;
    documents: Array<AuditReportCustomFieldsDocumentationDocument>;
    requires_approval: boolean;
    requires_expiry: boolean;
    responsible_groups: Array<Group>;
    responsible_users: Array<DisplayUser>;
    type: AuditReportCustomFieldsDocumentation.type;
};

export namespace AuditReportCustomFieldsDocumentation {

    export enum type {
        DOCUMENTATION = 'Documentation',
    }


}

