import React from 'react';
import { useSelector } from 'react-redux';
import { getProfile } from '../selectors';

interface HasFeatureProps {
    feature: string;
    elseElement?: any;
    children?: any;
}

const HasFeature = (props: HasFeatureProps): React.ReactElement => {
    const profile = useSelector(getProfile)
    const features = profile.permissions;
    if (!props.feature || checkHasFeature(features, props.feature)) {
        return props.children;
    }
    if (props.elseElement) {
        return props.elseElement;
    }
    return null;
}


const checkHasFeature = (features: string[], feature: string): boolean => {
    if (!features) {
        return false;
    }
    if (!features.length) {
        return false;
    }
    return features.indexOf(feature) !== -1;
};

export default HasFeature;
export { checkHasFeature };

