/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 114.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditReportCustomFieldsDocumentationDocument,
    AuditReportCustomFieldsDocumentationDocumentFromJSON,
    AuditReportCustomFieldsDocumentationDocumentFromJSONTyped,
    AuditReportCustomFieldsDocumentationDocumentToJSON,
    DisplayUser,
    DisplayUserFromJSON,
    DisplayUserFromJSONTyped,
    DisplayUserToJSON,
    Group,
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
    TextWithTranslation,
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuditReportCustomFieldsDocumentation
 */
export interface AuditReportCustomFieldsDocumentation {
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    documentation_type_code: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    documentation_type_description: TextWithTranslation;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    documentation_type_id: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    documentation_type_name: TextWithTranslation;
    /**
     * 
     * @type {Array<AuditReportCustomFieldsDocumentationDocument>}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    documents: Array<AuditReportCustomFieldsDocumentationDocument>;
    /**
     * 
     * @type {boolean}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    requires_approval: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    requires_expiry: boolean;
    /**
     * 
     * @type {Array<Group>}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    responsible_groups: Array<Group>;
    /**
     * 
     * @type {Array<DisplayUser>}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    responsible_users: Array<DisplayUser>;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentation
     */
    type: AuditReportCustomFieldsDocumentationTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AuditReportCustomFieldsDocumentationTypeEnum {
    Documentation = 'Documentation'
}

export function AuditReportCustomFieldsDocumentationFromJSON(json: any): AuditReportCustomFieldsDocumentation {
    return AuditReportCustomFieldsDocumentationFromJSONTyped(json, false);
}

export function AuditReportCustomFieldsDocumentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditReportCustomFieldsDocumentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentation_type_code': json['documentation_type_code'],
        'documentation_type_description': TextWithTranslationFromJSON(json['documentation_type_description']),
        'documentation_type_id': json['documentation_type_id'],
        'documentation_type_name': TextWithTranslationFromJSON(json['documentation_type_name']),
        'documents': ((json['documents'] as Array<any>).map(AuditReportCustomFieldsDocumentationDocumentFromJSON)),
        'requires_approval': json['requires_approval'],
        'requires_expiry': json['requires_expiry'],
        'responsible_groups': ((json['responsible_groups'] as Array<any>).map(GroupFromJSON)),
        'responsible_users': ((json['responsible_users'] as Array<any>).map(DisplayUserFromJSON)),
        'type': json['type'],
    };
}

export function AuditReportCustomFieldsDocumentationToJSON(value?: AuditReportCustomFieldsDocumentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentation_type_code': value.documentation_type_code,
        'documentation_type_description': TextWithTranslationToJSON(value.documentation_type_description),
        'documentation_type_id': value.documentation_type_id,
        'documentation_type_name': TextWithTranslationToJSON(value.documentation_type_name),
        'documents': ((value.documents as Array<any>).map(AuditReportCustomFieldsDocumentationDocumentToJSON)),
        'requires_approval': value.requires_approval,
        'requires_expiry': value.requires_expiry,
        'responsible_groups': ((value.responsible_groups as Array<any>).map(GroupToJSON)),
        'responsible_users': ((value.responsible_users as Array<any>).map(DisplayUserToJSON)),
        'type': value.type,
    };
}


