/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 112.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextWithTranslation,
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
} from './';

/**
 * Simple supplier document for listing production unit.
 * @export
 * @interface SimpleSupplierDocument
 */
export interface SimpleSupplierDocument {
    /**
     * 
     * @type {boolean}
     * @memberof SimpleSupplierDocument
     */
    archived: boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleSupplierDocument
     */
    documentation_type_code: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleSupplierDocument
     */
    documentation_type_id: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof SimpleSupplierDocument
     */
    documentation_type_name: TextWithTranslation;
    /**
     * 
     * @type {string}
     * @memberof SimpleSupplierDocument
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleSupplierDocument
     */
    state: SimpleSupplierDocumentStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof SimpleSupplierDocument
     */
    valid_from_date: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SimpleSupplierDocument
     */
    valid_to_date: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum SimpleSupplierDocumentStateEnum {
    FileAdded = 'file_added',
    WaitingForApproval = 'waiting_for_approval',
    Approved = 'approved',
    Rejected = 'rejected',
    Active = 'active',
    Expired = 'expired'
}

export function SimpleSupplierDocumentFromJSON(json: any): SimpleSupplierDocument {
    return SimpleSupplierDocumentFromJSONTyped(json, false);
}

export function SimpleSupplierDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleSupplierDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived': json['archived'],
        'documentation_type_code': json['documentation_type_code'],
        'documentation_type_id': json['documentation_type_id'],
        'documentation_type_name': TextWithTranslationFromJSON(json['documentation_type_name']),
        'id': json['id'],
        'state': json['state'],
        'valid_from_date': (json['valid_from_date'] === null ? null : new Date(json['valid_from_date'])),
        'valid_to_date': (json['valid_to_date'] === null ? null : new Date(json['valid_to_date'])),
    };
}

export function SimpleSupplierDocumentToJSON(value?: SimpleSupplierDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived': value.archived,
        'documentation_type_code': value.documentation_type_code,
        'documentation_type_id': value.documentation_type_id,
        'documentation_type_name': TextWithTranslationToJSON(value.documentation_type_name),
        'id': value.id,
        'state': value.state,
        'valid_from_date': (value.valid_from_date === null ? null : value.valid_from_date.toISOString().substr(0,10)),
        'valid_to_date': (value.valid_to_date === null ? null : value.valid_to_date.toISOString().substr(0,10)),
    };
}


