import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Feature, ListableProductionUnit } from '../../../backend_api/models';
import LoadMore from '../../../base/components/LoadMore';
import Loader from '../../../base/components/Loader';
import NoHits from '../../../base/components/NoHits';
import SearchBox from '../../../base/components/SearchBox';
import ToTop from '../../../base/components/ToTop';
import Button from '../../../base/components/basic/Button';
import { Breakpoints, DEFAULT_SEARCH_RESULT_PAGE_SIZE } from '../../../base/config';
import { getFeaturesSelector } from '../../../base/selectors';
import { Context } from '../../../base/types';
import { isUndefinedOrNull, preventDefaultAndStopPropagation, useIsMobileDevice, useMaxWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import AddFilters from '../../filters/components/AddFilters';
import FiltersList, { FiltersListTypes } from '../../filters/components/FiltersListNew';
import { hasActiveFiltersSelector } from '../../filters/selectors';
import PageContainer from '../../globals/components/PageContainer';
import { SUPPLIER_LIST_PR_PAGE } from '../../suppliers/components/SuppliersListNew';
import { useIsAllowedToCreateProductionUnits } from '../hooks/useIsAllowedToCreateProductionUnits';
import { createProductionUnitWithoutSupplier, getListableProductionUnits, getPaginatedListableProductionUnitsSelector, getProductionUnitsCustomFieldsList, getProductionUnitsListLoadingSelector } from '../slice/productionUnitsSlice';
import { BasicProductionUnitList } from './BasicProductionUnitList';
import { CreateProductionUnitModal } from './CreateProductionUnitModal';
import { NewProductionUnitParams } from './NewProductionUnitParams';
import Icon from '../../../base/ui/components/icons/Icon';

type Props = {
    productionUnits?: ListableProductionUnit[];
}

export const ProductionUnitList = (props: Props): React.ReactElement => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const locationParsed = qs.parse(location.search as string);
    const isMaxMD = useMaxWidthBreak(Breakpoints.MD);
    const canShowStatus = useSelector(getFeaturesSelector).includes(Feature.SupplierCompliance);
    const hasFilters = useSelector(hasActiveFiltersSelector);
    const paginatedPUs = useSelector(getPaginatedListableProductionUnitsSelector);
    const productionUnits = !isUndefinedOrNull(props.productionUnits) ? props.productionUnits : paginatedPUs && paginatedPUs.production_units;
    const currentFilters = locationParsed.scf_ids && (locationParsed.scf_ids && JSON.parse(locationParsed.scf_ids as string)) as any || null;
    const isLoading = useSelector(getProductionUnitsListLoadingSelector);
    const [total, setTotal] = useState<number>(null);
    const [count, setCount] = useState<number>(null);
    const [limit, setLimit] = useState<number>(DEFAULT_SEARCH_RESULT_PAGE_SIZE);
    const [status, setStatus] = useState<string[]>(null);
    const [offset, setOffset] = useState<number>(0);
    const [query, setQuery] = useState<string>(null);
    const isMobileDevice = useIsMobileDevice();

    const filters: FiltersListTypes[] = ['production-unit-custom-fields-filter', 'documentation_type', 'documentation_state'];

    if (canShowStatus) {
        filters.push('production-unit-status-filter');
    }

    const clear = () => {
        history.push('/production_units');
    };
    const getProductionUnitsList = (limit?: number, offset?: number, search?: string, customFields?: any, status?: string[]) => {
        dispatch(getListableProductionUnits({ search, custom_fields: customFields, status, pagination: { limit, offset } }));
    }

    const isAllowedToCreate = useIsAllowedToCreateProductionUnits();

    /* useEffect(() => {
        if (isUndefinedOrNull(props.productionUnits)) {
            getProductionUnitsList(DEFAULT_SEARCH_RESULT_PAGE_SIZE, 0);
        }
    }, []); */

    useEffect(() => {
        if (productionUnits) {
            setOffset(paginatedPUs.pagination.offset);
            setLimit(paginatedPUs.pagination.limit);
            setTotal(paginatedPUs.pagination.total);
            setCount(parseInt(paginatedPUs.pagination.limit as unknown as string, 10) + parseInt(paginatedPUs.pagination.offset as unknown as string, 10));
        }
    }, [paginatedPUs]);

    useEffect(() => {
        const l = qs.parse(location.search);
        let query;
        if (l['query']) {
            setQuery(l['query'] as string);
            query = l['query'];
        } else {
            setQuery(null);
        }
        const cfs = currentFilters && Object.values(currentFilters);
        const status = !isUndefinedOrNull(l['production_unit_status']) ? typeof l['production_unit_status'] === 'string' ? [l['production_unit_status']] : l['production_unit_status'] : null;
        setStatus(status);
        setLimit(0);
        getProductionUnitsList(limit || SUPPLIER_LIST_PR_PAGE, 0, query, cfs, status);

    }, [history.location.search]);

    useEffect(() => {
        dispatch(getProductionUnitsCustomFieldsList());
    }, []);
    function onUserWantedToCreateNewProductionUnit(params: NewProductionUnitParams) {
        /* TODO: Groups */
        dispatch(createProductionUnitWithoutSupplier({
            production_unit_name: params.name,
            production_unit_number: params.number,
            production_unit_address: [],
            production_unit_group_ids: (params.groups || []).map(g => g.id)
        })).then((result) => {
            if (result && result.payload && result.payload.id) {
                const productionUnitId = result.payload.id
                history.push('/production_units/' + productionUnitId)
            }
        })
    }
    const noHits = () => {
        let advice: string | React.ReactElement = hasFilters && <span className='space-x-1'><span><FormattedMessage id='suppliers.filters.no_hits.change_filters' /></span>
            <span className='link' onClick={(e) => {
                clear();
                preventDefaultAndStopPropagation(e);
            }}><FormattedMessage id='suppliers.filters.no_hits.clear' /></span></span>;
        if (query) {
            advice = hasFilters ? <span><FormattedMessage id='suppliers.filters.no_hits.change_your_query_or_clear_filters_1' /> <span className='link' onClick={(e) => {
                clear();
                preventDefaultAndStopPropagation(e);

            }}><FormattedMessage id='suppliers.filters.no_hits.clear' /></span></span> : <span><FormattedMessage id='suppliers.filters.no_hits.change_query' /></span>
        }
        if (total === 0) {
            return <NoHits
                heading={<FormattedMessage id='production_units.list.search.no_production_units' />}
                advice={<span>{advice}</span>}
            // advice={<span>Try changing your query or <span className='link' onClick={() => clear()}>clear your filters</span></span>}
            />
        }
    }
    const search = (query?: string) => {
        setQuery(query);
        setCount(50);
        const cfs = currentFilters && Object.values(currentFilters);
        getProductionUnitsList(limit, 0, query, cfs);
    }
    const content = <div className='px-2'>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.product_units' })} />
        <div className=' my-4 flex justify-between items-center'>
            <div className='w-full flex flex-row space-x-2'>
                <SearchBox
                    path='production_units'
                    className='sm:w-1/2 w-full'
                    onSearch={(q) => search(q)}
                    isSearching={isLoading}
                    placeholderText={intl.formatMessage({ id: 'search_box.button_label' })}
                    q={query}
                />
                {!isMaxMD && <AddFilters />}
            </div>
            {isAllowedToCreate && (
                <div className='flex justify-center sm:justify-end'>
                    <CreateProductionUnitModal
                        groupsEnabled={true}
                        allowExistingProductionUnits={true}
                        onExistingProductionUnitSelected={(productionUnitId) => history.push('/production_units/' + productionUnitId)}
                        onUserWantedToCreateProductionUnit={onUserWantedToCreateNewProductionUnit}
                        renderButton={() => <Button secondary medium className='flex flex-row items-center text-on-surface-brand gap-1 whitespace-nowrap'>
                            <Icon name='operation_add' className='text-on-surface-brand'/>
                            <FormattedMessage id='production_unit.create_production_unit' />
                        </Button>}
                        selectableProductionUnits={productionUnits || []}
                    />
                </div>
            )}
        </div>
        {isMaxMD && <div className='p-2 justify-center w-full flex'><AddFilters /></div>}
        <FiltersList
            context={Context.ProductionUnits}
            filters={filters}
            isFetching={isLoading}
        />
        
        <Loader active={isLoading}>
            <div>
                <BasicProductionUnitList
                    productionUnits={productionUnits}
                    /* not used on this list*/
                    confirmDeleteTextId='globals.confirmation.delete'
                    containerClassName='mb-0'
                    listClassName={'border-b sm:border-b-0 sm:border-t border-x'}

                />
            </div>
        </Loader>
        <ToTop left={isMobileDevice ? '35%' : '90%'} />
        {total > 0 && <LoadMore
            onLoadMore={(limit: number, offset: number, count: number, search?: string, status?: string[]) => {
                const cfs = currentFilters && Object.values(currentFilters);
                getProductionUnitsList(limit || SUPPLIER_LIST_PR_PAGE, offset, search, cfs, status);

            }}
            chunk={limit}
            total={total}
            count={count}
            search={query}
            status={status as any}
            isLoading={isLoading}
            className='border border-t-0'
        />}
    </div>;

    return <PageContainer
        header={<FormattedMessage id='production_units.production_units' />}
    >
        {content}
        {noHits()}
    </PageContainer>;
}