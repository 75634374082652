/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Feature {
    BFF_PRODUCTION_UNIT_LIST_VIEW = 'bff_production_unit_list_view',
    DEVELOPER = 'developer',
    VIDEO = 'video',
    AUTOMATIC_TRANSLATION = 'automatic_translation',
    TWO_FACTOR_AUTHENTICATION = 'two_factor_authentication',
    INDEPENDENT_PRODUCTION_UNITS = 'independent_production_units',
    SUPPLIER_COMPLIANCE = 'supplier_compliance',
    PRODUCT_COMPLIANCE = 'product_compliance',
    SUPPLIER_BOOKING = 'supplier_booking',
    DEFECT_CATEGORY = 'defect_category',
    SUPPLIER_QC = 'supplier_qc',
    QUALITY = 'quality',
}
