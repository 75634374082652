import classnames from 'classnames';
import qs from 'query-string';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { ListUsersUser } from '../../../../../backend_api/models/ListUsersUser';
import LightBox from '../../../../../base/components/LightBox';
import Loader from '../../../../../base/components/Loader';
import Spinner from '../../../../../base/components/Spinner';
import ToTop from '../../../../../base/components/ToTop';
import { useCheckHasPermission } from '../../../../../base/hooks';
import { getLayoutSelector, getPermissionsSelector } from '../../../../../base/selectors';
import { getObjectByIdCount, useIsMobileDevice } from '../../../../../base/utils';
import { useAppDispatch } from '../../../../../store';
import { getApprovalConclusion } from '../../../../approval/actions';
import { getComments as loadComments } from '../../../../comments/actions';
import Comments from '../../../../comments/components/Comments';
import WriteComment from '../../../../comments/components/WriteComment';
import { getComments } from '../../../../comments/selectors';
import { CorrectiveActionRelation } from '../../../../correctiveActions/types';
import { HasFeature } from '../../../../profile/containers';
import { getOrderUsers } from '../../../../users/actions/actions';
import { getOrderUsersByIdSelector, getOrderUsersSelector } from '../../../../users/selectors';
import Watchers from '../../../../watchers/components/WatchersReport';
import { getChangingBulkStatus, getReportImagesSelector, getReportIsFetchingSelector } from '../../../selectors/reportSelectors';
import { getInspectionReport, getReportSelector } from '../../../slices/inspectionReportSlice';
import { ReportState } from '../../../types';
import OrderInspectionsList from '../../dashboard/views/inspectionsList/OrderInspectionsList';
import Defects from '../Defects';
import { ImageCommentType, getImageComment } from '../ImageComment';
import Info from '../Info';
import ReportApproval from '../ReportApproval';
import SideSwitcher from '../SideSwitcher';
import Checkpoints from '../checkpoints/Checkpoints';
import CheckpointsSummary from '../checkpoints/CheckpointsSummary';
import InspectionReportDefects from '../defects/InspectionReportDefects';
import DownloadReport from './DownloadReport';
import InspectionsCorrectiveActionsList from './InspectionsCorrectiveActionsList';
import ReportHeader from './ReportHeader';
import ReportMatrix from './ReportMatrix';
import ReportToggler from './ReportToggler';

type OwnProps = {
    inspectionId: string;
};
type ReportProps = OwnProps;

const Report2 = (props: ReportProps): React.ReactElement => {
    const isMobile = useIsMobileDevice();
    const layout = useSelector(getLayoutSelector);
    const permissions = useSelector(getPermissionsSelector);
    const intl = useIntl();
    const location = useLocation();
    const [orderId, setOrderId] = useState<string>((): string => (qs.parse(location.search).orderId && qs.parse(location.search).orderId !== 'undefined') ? qs.parse(location.search).orderId as string : '');
    const isFp = qs.parse(location.search).overview === 'true';
    const isFrontpage = qs.parse(location.search).overview === 'true';
    const dispatch = useAppDispatch();
    const inspectionId = props.inspectionId;
    const reportData: ReportState = useSelector(getReportSelector);
    const inReportState = reportData.inspection && reportData.inspection.status == 'report'
    const hasGroupedInspections = useCheckHasPermission(permissions, 'u_grouped_inspections');
    const users: ListUsersUser[] = useSelector(getOrderUsersSelector);
    const usersById = useSelector(getOrderUsersByIdSelector);
    const isFetching = useSelector(getReportIsFetchingSelector);
    const [showSwitcher, setShowSwitcher] = useState(false);
    const [openContainers, setOpenContainers] = useState([]);
    const dontScrollToTop = layout.dontScrollToTopAfterAction;
    const [report, setReportData] = useState<ReportState>(null);
    const comments = useSelector(getComments);
    const commentsCnt = comments ? getObjectByIdCount(comments) : 0;
    const approvalExpanded = layout.reportApprovalExpanded;
    const bodyFixed = layout.bodyFixed;
    const settingBulkApproval = useSelector(getChangingBulkStatus);
    const images = useSelector(getReportImagesSelector).images as any;
    const items = useSelector(getReportImagesSelector).items as ImageCommentType[];

    useEffect(() => {
        if (settingBulkApproval) {
            dispatch(getInspectionReport({ inspectionId: props.inspectionId, orderId }));
        }
    }, [settingBulkApproval])

    useEffect(() => {
        if (location.hash) {
            document.location.href = location.hash;
        }
    }, [location.hash]);

    useEffect(() => {
        dispatch(getInspectionReport({ inspectionId: props.inspectionId, orderId }));
        dispatch(getApprovalConclusion())
        if (isMobile && !approvalExpanded) {
            dispatch(loadComments(props.inspectionId));
        }
        setShowSwitcher(false)
        if (!dontScrollToTop) {
            window.scrollTo(0, isMobile ? 62 : 0);
        }
    }, [props.inspectionId]);
    useEffect(() => {
        if (reportData && reportData.order && reportData.inspection.inspection_id === props.inspectionId) {
            dispatch(getOrderUsers(reportData.inspection.order_id, true));
            setReportData(reportData);
            if (location.hash) {
                setTimeout(() => {
                    document.location.href = location.hash;
                }, 1500);
            } else {
                if (!dontScrollToTop) {
                    window.scrollTo(0, isMobile ? 62 : 0);
                }
            }
            setOrderId(reportData.order.order_id);
        }

        return (): void => {
            setReportData(null);
        }
    }, [reportData]);
    const updateOpenContainers = (id: string): void => {
        const elementsOpen = [...openContainers];
        if (elementsOpen.includes(id)) {
            const index = elementsOpen.indexOf(id);
            elementsOpen.splice(index, 1);
        } else {
            elementsOpen.push(id);
        }
        setOpenContainers(elementsOpen);
    };
    const getContainer = (container: React.ReactElement, id: string, headers: (string | React.ReactElement)[], togglable?: boolean): React.ReactElement => {
        const open = togglable ? openContainers.includes(id) : true;
        const h1 = [];
        headers.forEach((h) => {
            if (typeof (h) === 'string') {
                h1.push(<FormattedMessage id={h} />);
            } else {
                h1.push(h);
            }
        })
        return <Segment.Group raised data-id={id ? id : null}>
            <Segment secondary onClick={(): void => togglable ? updateOpenContainers(id) : null} className='header'>
                {togglable && <Icon name={open ? 'caret down' : 'caret right'} />}
                <Header as='h3'>{h1}</Header>
            </Segment>
            {<Segment className={open ? 'show' : 'hide'}>
                {container}
            </Segment>}
        </Segment.Group>;
    }
    const reportContent = useMemo(() => {
        if (report) {
            const inspections = report.order.inspections || [];
            const inspection = report.inspection;
            const isCombinedInspection = report.inspection.is_master;
            const hasItems = inspections.length > 1;
            const canManageWatchers = inspection.features.includes('manage_watchers');
            const canCreateComments = inspection.features.includes('create_comments');
            const isAllowedToCreateCA = inspection.features.includes('corrective_action_for_quality.create');
            const metaItems = items.map((item) => getImageComment(item, inspection));


            const reportCorrectiveActions = <HasFeature feature='u_corrective_actions_inspection'>
                <InspectionsCorrectiveActionsList
                    inspectionId={inspectionId}
                    relation={CorrectiveActionRelation.Inspection}
                    name={report.inspection.item_number + ': ' + report.inspection.item_name}
                    isAllowedToCreateCA={isAllowedToCreateCA} />
            </HasFeature>

            let pageTitle;
            if (isFp) {
                pageTitle = intl.formatMessage({ id: 'page_title.report_order_and_order_no' }, { orderNo: inspection.order_number });
            } else {
                if (isCombinedInspection) {
                    pageTitle = intl.formatMessage({ id: 'page_title.report_combined_order_no' }, { orderNo: inspection.order_number });

                } else {
                    pageTitle = 'PO ' + report.order.order_number + '. ' + inspection.item_number + ': ' + inspection.item_name + '. ' + intl.formatMessage({ id: 'page_title.report' });
                }
            }
            const fpContainer = <Fragment>
                <Segment.Group raised className='reportOverview'>
                    <Segment secondary className='top'>
                        <Header as='h3'><FormattedMessage id='report.order_list.header' /></Header>
                        <DownloadReport
                            inspectionId={report.inspection.inspection_id}
                            order={report.order}
                            isGroupedInspection={hasGroupedInspections}
                            showLabel={true} />
                    </Segment>
                    <Segment>
                        <OrderInspectionsList
                            isGrouped={hasGroupedInspections}
                            order={report.order}
                            showInspectionTypes={true}
                            showDefects={true}
                        />
                    </Segment>
                </Segment.Group>
                <ReportMatrix orderId={orderId} />
            </Fragment>;
            const downloadButton =
                <DownloadReport
                    inspectionId={inspection.inspection_id}
                    isGroupedInspection={false}
                    showLabel={false} />

            const headerContainer = <div className={classnames('headerContainer', { expanded: approvalExpanded })}>
                <ReportHeader
                    inspection={inspection}
                    isCombinedInspection={isCombinedInspection}
                    isFrontpage={isFrontpage}
                    showLabels={true}
                />
            </div>
            const infoContainer = <div className={classnames('infoContainer _c', { expanded: approvalExpanded })}>
                <Info
                    report={report}
                    inspectionId={inspectionId}
                    downloadButton={downloadButton}
                />
            </div>;
            const summaryContainer = <div className='summaryContainer _c'>
                <Segment.Group raised data-id='report-summary-section'>
                    <Segment secondary>
                        <Header as='h3'><FormattedMessage id='inspections.summary' /></Header>
                    </Segment>
                    <Segment>
                        <div className='flex w-full flex-1 space-x-4 -my-4 flex-col sm:flex-row'>
                            <div className='w-full sm:w-1/2 sm:border-r py-4 border-b pr-4'>
                                <div className=''>
                                    <Defects
                                        inspection={inspection}
                                        type='summary'
                                        setDefectSeverity={(): void => null}
                                        key={'defects_summary'}
                                    />
                                </div>
                            </div>
                            <div className='w-full sm:w-1/2 py-4'>
                                <CheckpointsSummary id='' />
                            </div>
                        </div>
                    </Segment>
                </Segment.Group>
            </div>;
            const defectsContainer = <div className='defectsContainer _c'>
                <Segment.Group raised>
                    <Segment secondary>
                        <Header as='h3'><FormattedMessage id='inspections.defects' /></Header>
                    </Segment>
                    <Segment>
                        <InspectionReportDefects inspection={inspection} />
                    </Segment>
                </Segment.Group>


            </div>;
            const checkpointsContainer = <div className='checkpointsContainer _c'>
                <Checkpoints inspection={inspection} />
            </div>;
            // TODO: going forward we should use the ReportWatchers component (/src/modules/watchers/components/ReportWatchers.tsx). It's already used on the Audit report page
            const watchers = <Watchers
                currentWatchers={report.inspection.watchers}
                usersById={usersById}
                inspectionId={report.inspection.inspection_id}
                canManageWatchers={canManageWatchers}
            />;
            const watchersContainer = <div className='watchersContainer _c'>
                {getContainer(<Fragment>
                    {watchers}
                </Fragment>, 'report-watchers', [<Fragment key='watchers_0'><FormattedMessage id='inspections.watchers' /></Fragment>], false)}

            </div>;
            const commentsContainer = <div className='commentsContainer _c'>
                {getContainer(<Fragment>
                    {canCreateComments && <WriteComment type='inspection' id={inspectionId} showModal={false} />}
                    <Comments typeId={inspectionId} inspectionId={inspectionId} inspection={report.inspection} showCollapsed={true} />
                </Fragment>, 'report-comments', [<Fragment key='comments_0'><FormattedMessage id='inspections.comments' /> ({commentsCnt})</Fragment>], isMobile)}

            </div>;
            const approvalContainer = <div className={classnames('approvalContainer', '_c', { expanded: approvalExpanded })}>
                {!isMobile && <ReportApproval inspection={inspection} />}
                {isMobile && <Fragment>
                    <ReportApproval inspection={inspection} watchers={watchers} />
                </Fragment>}
            </div>;
            return (<div className={classnames('pageContainer', 'reportPage2', { bodyFixed })}>
                <DocumentTitle title={pageTitle} />
                {!isFrontpage && !hasGroupedInspections && !isCombinedInspection && <ReportToggler
                    order={report.order}
                    show={showSwitcher}
                    toggle={setShowSwitcher}
                    selectedInspectionId={inspectionId}
                />}
                <div className='heading'>{headerContainer}</div>
                {inspections && hasItems && <div className='col0'>
                    {hasGroupedInspections &&
                        <SideSwitcher
                            inspections={report.order.inspections}
                            currentInspectionId={inspectionId}
                            isFrontpage={isFp}
                            isCollapsed={false}
                            orderId={orderId}
                        />
                    }
                </div>}

                {isFrontpage && fpContainer}
                {!isFrontpage && <Fragment>
                    <LightBox images={images} metaItems={metaItems} />
                    {!isMobile && <Fragment>
                        <Loader active={isFetching}>
                            <div className='colsMain'>
                                <div className='col1'>
                                    {infoContainer}
                                    {summaryContainer}
                                    {defectsContainer}
                                    {checkpointsContainer}
                                </div>
                                <div className='col2 z-2'>
                                    {approvalContainer}

                                    {inReportState && reportCorrectiveActions}
                                    {watchersContainer}
                                    {!isFetching && commentsContainer}
                                </div>
                            </div>
                        </Loader>
                    </Fragment>}
                    {isMobile && <Fragment>
                        {approvalContainer}
                        {infoContainer}
                        {summaryContainer}

                        {defectsContainer}
                        {checkpointsContainer}
                    </Fragment>}

                </Fragment>}

                <ToTop />

            </div >);
        }
    }, [report, isFetching, commentsCnt, isFp, showSwitcher, reportData.inspection, dontScrollToTop, users, usersById, intl.locale]);

    return reportContent || <Spinner active={true} label={intl.formatMessage({ id: 'inspections.loading_report_please_wait' })} />;
};
export default Report2;


