import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "semantic-ui-react";
import {
    ListItems,
    ListItem,
    ArrowTypeEnum,
} from "../../../base/ui/components/listitems/ListItem";
import { FileResource } from "../../../backend_api/models";
import Button from "../../../base/ui/components/buttons/Button";
import DocumentationTypesFilter from "../../filters/components/DocumentationTypesFilter";
import { useAppDispatch } from "../../../store";
import { createSupplierDocument, fetchSupplierDocument, productionUnitsSliceActions } from "../slice/productionUnitsSlice";

export default function CreateSupplierDocumentModal(props: {
    productionUnitId: string;
    fileResource: FileResource;
    open: boolean;
    close: () => void;
}) {
    const { open, fileResource, close, productionUnitId } = props;
    const dispatch = useAppDispatch();
    
    const [selectedTypes, setSelectedTypes] = React.useState<string[]>([]);

    const openFileInNewWindowTab = () => {
        window.open(fileResource?.preview_url, "_blank");
    };

    const createNewSupplierDocument = async () => {
        const newSupplierDocumentRequest = await dispatch(createSupplierDocument({
            media_resource_id: fileResource.id,
            documentation_type_id: selectedTypes[0],
            production_unit_id: productionUnitId
        }));
        if (newSupplierDocumentRequest.payload?.supplier_document_id) {
            dispatch(fetchSupplierDocument({ id: newSupplierDocumentRequest.payload?.supplier_document_id }));
            close();
        }
    };

    return (
        <Modal open={open} closeOnDimmerClick closeOnEscape size="tiny">
            <Modal.Header>
                <FormattedMessage id="audits.edit.add_attachments" />
            </Modal.Header>
            <Modal.Content className="gap-6">
                <div className="flex flex-col gap-6">
                    <ListItems className="border border-default rounded-md">
                        <ListItem
                            icon={"object_file"}
                            className="border-b-0 cursor-pointer"
                            arrow={{
                                type: ArrowTypeEnum.External,
                                onArrowClick: openFileInNewWindowTab,
                            }}
                            onPress={openFileInNewWindowTab}
                        >
                            {fileResource?.name}
                        </ListItem>
                    </ListItems>

                    <DocumentationTypesFilter
                        label="production_units.documentation.type_of_file"
                        placeholder="filters.select_type"
                        onFilterSet={(selectedTypes) => {
                            setSelectedTypes(selectedTypes);
                        }}
                        allowMultipleChoice={false}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions className="flex flex-row justify-end my-2 gap-4">
                <Button
                    onPress={createNewSupplierDocument}
                    primary
                >
                    <FormattedMessage id="globals.save" />
                </Button>
                <Button onPress={close}>
                    <FormattedMessage id="globals.confirmation.cancel" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
