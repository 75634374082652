import React, { useState } from 'react';
import { GPSLocation } from '../../../backend_api_2';
import Map from '../../../base/ui/components/map/Map';
import { getMapLocation, isUndefinedOrNull, twMerge } from '../../../base/utils';

type Props = {
    className?: string;
    location: GPSLocation;
    locationName?: string;
    zoomLevel?: number;
    setMapLocation?(gpsLocation: GPSLocation): void;
};

const ProductionUnitMap = (props: Props): React.ReactElement => {
    const { className, zoomLevel = 12, locationName } = props;
    const resetLocation = (first: boolean) => {
        return { latitude: props.location && props.location.latitude || undefined, longitude: props.location && props.location.longitude || undefined, first };
    }
    const [location, setLocation] = useState(resetLocation(true));
    const mapLocation = getMapLocation(location);
    const posLabel = !location.first ? (!isUndefinedOrNull(location.latitude) && location.latitude.toFixed(5) + ', ' + (!isUndefinedOrNull(location.longitude) && location.longitude.toFixed(5))) : '';

    const getMap = () => {
        return <Map
            onPress={(e) => setLocation({ latitude: e.lngLat.lat, longitude: e.lngLat.lng, first: false })}
            location={{ latitude: mapLocation[0], longitude: mapLocation[1] }}
            changeLocationName={locationName}
            zoomLevel={zoomLevel}
            edit={true}
            onLocationChangeConfirm={(loc) => {
                setLocation(resetLocation(true));
                props.setMapLocation({ latitude: loc.latitude, longitude: loc.longitude });
            }}
        />
    }
    return (
        <div>
            <div className={twMerge('w-full border', className)}>
                <div style={{ height: '500px' }}>{getMap()}</div>
            </div>
        </div>
    );
}
export default ProductionUnitMap;
