import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EditContextMenuButton from '../../../base/components/basic/EditContextMenuButton';
import Group from '../../groups/components/Group';
import { ControlledDelayedGroupsSelector, ControlledImmediateGroupsSelector } from '../../groups/components/GroupsSelector';
import { useGroups } from '../../groups/hooks';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getGroupsById } from '../../users/selectors';
import { Group as GroupModel } from '../../../backend_api/models';

/* Note that I have to use an alias here: Group -> GroupModel*/

type Props = {
    className?: string;
    groups: GroupModel[];
    productionUnitId: string;
    onGroupsUpdated(groups: GroupModel[]): void
    allowedToEdit: boolean

};

const DetailedSupplierGroups = (props: Props): React.ReactElement => {
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    const groups = props.groups;
    const selectableGroups = useGroups().filter(g => !g.is_supplier_group);

    return <div>
        <h2 className="text-primary !mt-6 !mb-2 font-bold text-2xl"><FormattedMessage id='production_units.detailed_page.groups.heading' /></h2>
        <ContentSection
            noHeaderBorder={true}
            content={<div className="flex">
                <div className="flex-1">
                    {!edit && groups.length === 0 && <div><FormattedMessage id='production_units.detailed_page.no_groups_added' /></div>}
                    {edit && <p><FormattedMessage id='production_units.detailed_page.groups.add_or_remove' /></p>}

                    {edit && <ControlledDelayedGroupsSelector
                        selectableGroups={selectableGroups}
                        onSelectingGroupsDone={(groupIds) => {
                            const groups = selectableGroups.filter((g) => groupIds.includes(g.id))
                            props.onGroupsUpdated(groups.map(g => {
                                const group: GroupModel = { id: g.id, is_supplier_group: g.is_supplier_group, name: g.name, supplier_id: '' }
                                return group;
                            }));
                        }}
                        selectedGroupIds={groups.map(g => g.id)}
                        className=''
                        onSelectingGroupsCanceled={() => undefined}
                    />}

                    {!edit && <div className='pb-4'>
                        {
                            groups
                                .map((group: GroupModel) => {
                                    if (group) {
                                        return <Group key={'group_' + group.id} group={group} />
                                    } else {
                                        return null;
                                    }
                                })
                                .filter(g => g != null)}
                    </div>}
                </div>
                <div className="">
                    {props.allowedToEdit && <EditContextMenuButton onEdit={setEdit} />}
                </div>
            </div>}
        />
    </div>
}
export default DetailedSupplierGroups;
