import { IDocument as IDocumentBase } from '@cyntler/react-doc-viewer';
import React, { useEffect, useMemo, useState } from 'react';
import './documentViewer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import RemoveIcon from '../../../components/basic/RemoveIcon';
import QModal from '../modals/Modal';
import SimpleLogo from '../../../../modules/header/components/SimpleLogo';
import { FileResource as ComplianceFileResource } from '../../../../compliance_api/models';
import { FileResource, FileResourceTypeEnum } from '../../../../backend_api/models';
import DocumentFilesViewer from '../../../../modules/compliance/components/document-viewer/DocumentFilesViewer';


type Props = {
    className?: string;
    show?: boolean;
    documentName?: string | React.ReactElement;
    fileResources?: (FileResource | ComplianceFileResource)[];
    contextMenu?: React.ReactElement;
    itemsList?: React.ReactElement;
    approvalAndValidity?: React.ReactElement;
    responsibles?: React.ReactElement;
    deleteFile(fileId: string): void;
    onClose?(): void;
    userCanAddAndRemoveFiles?: boolean;
};

export interface IDocument extends IDocumentBase { fileResourceId?: string };

const loadFiles = (files: (FileResource | ComplianceFileResource)[]): IDocument[] => {
    const _files: IDocument[] = [];
    files && files.map((file) => {
        if (file.type === FileResourceTypeEnum.File) {
            _files.push({ uri: decodeURIComponent(file.file.download_url), fileName: (file as ComplianceFileResource).file.name || (file as FileResource).name, fileResourceId: file.id })
        }
        if (file.type === FileResourceTypeEnum.Image) {
            _files.push({ uri: decodeURIComponent(file.image.image_url), fileName: (file as ComplianceFileResource).image.name || (file as FileResource).name, fileResourceId: file.id })
        }
    });
    return _files;
}
const DocumentViewer = (props: Props): React.ReactElement => {
    const { className, documentName, fileResources, deleteFile, approvalAndValidity, itemsList, responsibles, contextMenu, userCanAddAndRemoveFiles } = props;
    const [show, setShow] = useState(props.show || false);
    const [files, updateFiles] = useState<IDocument[]>(loadFiles(fileResources as any || [])); // TODO should be fixed by updating the FileResource type used by compliance models
    const [currentFile, setCurrentFile] = useState<IDocument>(null);
    const hasFiles = files.length > 0;
    
    const downloadFile = (fileId: string) => {
        window.open(
            fileId, '_blank',
        )
    }
    
    useEffect(() => {
        if (hasFiles && !currentFile) {
            setCurrentFile(files[0]);
        }
    }, [files]);

    useEffect(() => {
        updateFiles(loadFiles(fileResources));
    }, [fileResources]);

    useEffect(() => {
        setShow(props.show)
    }, [props.show]);

    useEffect(() => {
        setShow(props.show);
        return () => {
            setCurrentFile(null);
            updateFiles([]);
        }
    }, []);
    const filesViewer = <DocumentFilesViewer
        documents={files}
        onDeleteFile={deleteFile}
        onDownloadFile={downloadFile}
        userCanAddAndRemoveFiles={userCanAddAndRemoveFiles}
        key={'dv'}
    />
    const header = <div className='flex flex-1  max-w-[115rem] text-2xl text-on-surface-brand items-center space-x-2 justify-between'>
        <div className='flex items-center gap-x-6 py-4'>
            <SimpleLogo className='h-6' />
            <div className='flex gap-x-6'><div className='flex items-center font-thin text-base opacity-75'>|</div>{documentName}</div>
        </div>
        <div className='flex gap-4'>
            {contextMenu}
            <div className='px-2 cursor-pointer' onClick={() => {
                props.onClose();
                setShow(false);
            }}><RemoveIcon iconClassName='text-white' /></div>
        </div>
    </div>
    const content = <QModal
        noMaxHeight
        className='h-full border-none'
        animate={{ type: 'slide-up' }}
        header={header}
        headerClassName={'py-0 px-0  bg-branding-brand justify-center'}
        headerContentClassName={'justify-center'}
        widthFull
        isOpen={show}
        onOpenChange={(open) => {
            !open && props.onClose();
        }}
        showCloseButton={false}>
        <div className='bg-page flex justify-center'>
            <div className='flex flex-1 flex-col sm:flex-row max-w-[115rem]'>
                <div className='flex-1 bg-white h-[calc(100vh-57px)] overflow-y-scroll'>
                    {currentFile && filesViewer}
                </div>
                <div className='w-[500px] px-4 py-6 ml-4 sticky top-0 space-y-8'>
                    {approvalAndValidity}
                    {itemsList}
                    {responsibles}
                </div>
            </div>
        </div >
    </QModal >

    return show && content;
}
export default DocumentViewer;
