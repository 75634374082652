import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown, Popup } from 'semantic-ui-react';
import { InputCustomFieldGroups } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import { translateTextWithTranslation, useLocaleLanguageString } from '../../../base/components/basic/TextWithTranslation';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { getSupplierCustomFieldGroups } from '../../customFields/selectors';
import DnDList from '../../sortableList/components/DnDList';
import { ProductionUnitCustomFields } from '../types';
import { choicesForDropdown, twt } from '../customFieldsShared/dropdownShared';
import Icon from '../../../base/ui/components/icons/Icon';
import { twMerge } from 'tailwind-merge';

type ProductionUnitCustomFieldDetailProps = {
    data: ProductionUnitCustomFields;
    language: string;
    update(data: ProductionUnitCustomFields);
}

const SupplierCustomFieldDetails = (props: ProductionUnitCustomFieldDetailProps): React.ReactElement => {
    // get the locale, so we can translate custom field names
    const locale = useLocaleLanguageString();
    const intl = useIntl();
    const supplierCustomFieldGroups: InputCustomFieldGroups = useSelector(getSupplierCustomFieldGroups)
    const customFieldGroups = supplierCustomFieldGroups.groups;

    const [selectedValue, setSelectedValue] = React.useState<string | undefined>()

    // do not modify original list directly.
    const shallowCopy: ProductionUnitCustomFields = { custom_fields: props.data.custom_fields.map(x => x) }

    function buildChoicesForDropdown(query?: string) {
        return choicesForDropdown(customFieldGroups, intl, locale, query);
    }

    const choices = buildChoicesForDropdown()

    function isGroupId(id: string) {
        return customFieldGroups.some((group) => group.id === id);
    }

    function findGroup(id: string) {
        return customFieldGroups.find(group => group.id == id);
    }

    function onChoiceChanged(evt, item) {
        setSelectedValue(item.value)
    }

    function renderName(id) {
        let result = '-'
        customFieldGroups.forEach((group) =>
            group.custom_field_definitions.forEach((definition) => {
                if (definition.id == id) {
                    result = translateTextWithTranslation(definition.name, locale)
                }
            })
        )
        return result;
    }

    function removeCustomField(indexToRemove) {
        shallowCopy.custom_fields = shallowCopy.custom_fields.filter((_, index) => index !== indexToRemove)
        notifyFieldsChanged(shallowCopy);
    }

    function isDocumentationType(id: string) {
        let isDocument = false;
        customFieldGroups.forEach((group) =>
            group.custom_field_definitions.forEach((definition) => {
                if (definition.id === id && definition.type?.type === 'DocumentationType') {
                    isDocument = true;
                }
            })
        )
        return isDocument;
    }


    function addCustomField() {
        if (isGroupId(selectedValue)) {
            const group = findGroup(selectedValue);
            group.custom_field_definitions.forEach(definition => {
                /* Make sure not to add hidden definitions. */
                if (definition.hidden) {
                    return;
                }

                shallowCopy.custom_fields.push({
                    name: definition.name,
                    custom_field_definition_id: definition.id
                })
            })
        } else {
            shallowCopy.custom_fields.push({
                name: twt(),
                custom_field_definition_id: selectedValue
            })
        }

        notifyFieldsChanged(shallowCopy)
    }

    function notifyFieldsChanged(copy) {
        props.update(copy)
    }

    const driveFileIconWithTooltip = <Popup trigger={
        <div className={twMerge('leading-none rounded-full inline-block pr-1 h-min', 'cursor-default')}>
            <Icon name='object_file' className={twMerge(['text-primary'], 'leading-rem-none')} />
        </div>
    }>{intl.formatMessage({ id: 'production_units.supplier_document.assignee_needs_to_upload' })}</Popup>

    const dndItems = <DnDList items={shallowCopy.custom_fields.map((cf, index) => {
        return <div className=' pl-2 hover:bg-gray-200 border-gray-100 border items-center cursor-move py-2 w-full flex justify-between' key={cf.name}>
            <div className='flex items-center'>
                {isDocumentationType(cf.custom_field_definition_id) && driveFileIconWithTooltip}
                {renderName(cf.custom_field_definition_id)}
            </div>
            <Button className='bg-white text-xs' small onClick={() => removeCustomField(index)}>
                <FormattedMessage id='checklists.editor.custom_fields.remove' />
            </Button></div>
    })}
        itemMoved={(from, to) => {
            const temp = shallowCopy.custom_fields[from];
            shallowCopy.custom_fields[from] = shallowCopy.custom_fields[to];
            shallowCopy.custom_fields[to] = temp;
            notifyFieldsChanged(shallowCopy)
        }}
        enableDrage={true} />


    return (
        <div className='pb-4'>
            {dndItems}
            <div className='flex space-x-1  pt-2'>
                <Dropdown
                    fluid
                    placeholder={intl.formatMessage({ id: 'checklists.editor.custom_fields.selector.select_custom_field' })}
                    selection
                    closeOnBlur
                    search={function (choices, query: string) {
                        return buildChoicesForDropdown(query);
                    }}
                    options={choices.map(choice => ({
                        ...choice,
                        text: <div className='flex items-center'>
                            {isDocumentationType(choice.value) && driveFileIconWithTooltip}
                            {choice.text}
                        </div>
                    }))}
                    value={selectedValue}
                    onChange={onChoiceChanged}
                    data-test-id='checkpoint-type-dropdown'
                />
                <Button primary small disabled={isUndefinedOrNullOrEmptyString(selectedValue)} className='leading-normal whitespace-nowrap ' onClick={addCustomField}>

                    {intl.formatMessage({ id: customFieldGroups.map((gr) => gr.id).includes(selectedValue) ? 'checklists.editor.custom_fields.add_group' : 'checklists.editor.custom_fields.add' })}
                </Button>
            </div>
        </div>
    )
}

export default SupplierCustomFieldDetails;