import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Icon, Modal } from 'semantic-ui-react';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { generateInspectionChecklist } from '../../../checklists/actions/actions';
import { Inspection } from '../../types';

type EditChecklistActionProps = {
    inspection: Inspection;
    disabled: boolean;
    isSaving: boolean;
};

const EditChecklistAction = (props: EditChecklistActionProps): React.ReactElement => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { disabled, isSaving, inspection } = props;
    const inspectionId = inspection.inspection_id;
    const [showDialog, setShowDialog] = useState(false);

    const handleOpen = (): void => {
        setShowDialog(true);
    };

    const editChecklist = (): void => {
        dispatch(generateInspectionChecklist(inspectionId))
        history.push('/inspection/edit_checklist/' + inspectionId + '?orderId=' + inspection.order_id);
    }

    const handleClose = (): void => {
        setShowDialog(false);
    };
    return <Fragment>
        <Button tabIndex={-1} className='self-auto mr-0 border border-input bg-white md:px-1 lg:px-3 rounded-l-none' onBlur={(): void => null} disabled={disabled || isSaving} onClick={handleOpen}>
            <Icon className='ellipsis horizontal text-gray-600' />
        </Button>
        <Modal
            size='small'
            open={showDialog}
            closeIcon={true}
            onClose={handleClose}>
            <Modal.Header><FormattedMessage id='inspections.edit_checklist' /></Modal.Header>
            <Modal.Content><FormattedMessage id='inspections.confirm_edit_checklist' /></Modal.Content>
            <Modal.Actions className='flex'>
                <Button primary onClick={editChecklist}><FormattedMessage id='inspections.okay' /></Button>
                <Button onClick={handleClose}><FormattedMessage id='inspections.cancel' /></Button>
            </Modal.Actions>
        </Modal>
    </Fragment>;
};

export default EditChecklistAction;
