/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SetCustomFieldCheckpointDocument } from './SetCustomFieldCheckpointDocument';

/**
 *   Contains a list of supplier documents
 *
 */
export type SetCustomFieldSupplierDocuments = {
    supplier_documents: Array<SetCustomFieldCheckpointDocument>;
    type: SetCustomFieldSupplierDocuments.type;
};

export namespace SetCustomFieldSupplierDocuments {

    export enum type {
        SUPPLIER_DOCUMENTS = 'supplier_documents',
    }


}

