/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TextWithTranslation } from './TextWithTranslation';
import type { UUIDRequired } from './UUIDRequired';

/**
 * Simple supplier document for listing production unit.
 *
 */
export type SimpleSupplierDocument = {
    archived: boolean;
    documentation_type_code: string;
    documentation_type_id: string;
    documentation_type_name: TextWithTranslation;
    id: UUIDRequired;
    state: SimpleSupplierDocument.state;
    valid_from_date: string | null;
    valid_to_date: string | null;
};

export namespace SimpleSupplierDocument {

    export enum state {
        FILE_ADDED = 'file_added',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        ACTIVE = 'active',
        EXPIRED = 'expired',
    }


}

