import React, { useState } from 'react';
import { ReportLocation as Location } from '../../../backend_api/models/ReportLocation';
import { useTranslation } from '../../../base/hooks';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import Map from '../../../base/ui/components/map/Map';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';

type LocationMapProps = {
    location: Location;
    locationCountry?: string;
    label?: string;
}
let T;
const LocationMap = (props: LocationMapProps): React.ReactElement => {
    const { location, locationCountry } = props;
    const [showMap, setShowMap] = useState(false);
    const close = (): void => setShowMap(false);
    const label = location.formatted_string || props.label;
    const trigger = <div className='link noUnderline hover' onClick={(): void => setShowMap(true)}>{label}</div>;
    const centerLocation = [location && location.latitude || 0, location && location.longitude || 0];
    const getMap = () => {
        return <Map
            location={location}
        />
    }
    return (
        <div>
            <Modal
                isOpen={showMap}
                onClose={close}
                trigger={trigger}
                actionItems={[
                    { type: ActionTypes.CancelAction, text: useTranslation('globals.close'), buttonType: ButtonTypes.Plain, event: () => null }, //hide }
                ]}
                header={label}
                content={<div id='mapDiv' style={{ height: '70vh' }} className={'w-full pb-12'}>
                    {getMap()}                    
                </div>}
            >
            </Modal >
        </div >
    );
}

export default LocationMap;
