import React, { useEffect, useState } from 'react';
import { PressEvent, usePress } from 'react-aria';
import { Button, Input, Label, NumberField, NumberFieldProps, SearchField, SearchFieldProps, TextField, TextFieldProps, ValidationResult } from 'react-aria-components';
import { Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { isUndefinedOrNullOrEmptyString, twMerge } from '../../../utils';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';
import InvalidFieldMessage from '../labels/InvalidFieldMessage';
import { LabelProps } from '../labels/Label';
import Action, { ActionProps } from './Action';

interface CommonType {
    fieldType?: 'text' | 'numberInterval' | 'search';
    className?: string;
    value?: any;
    action?: ActionProps;
    label?: LabelProps;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    iconLeft?: Icons;
    iconLeftClass?: string;
    iconRight?: Icons | React.ReactElement;
    iconRightClass?: string;
    labelLeft?: ComponentOrStringType;
    context?: React.ReactElement;
    labelRight?: string;
    inlineAction?: InlineAction;
    placeholder?: string;
    search?: boolean;
    onValueChanged?(value: string): void;
    onSubmit?: (value: string) => void
}
export interface FieldProps extends Omit<CommonType & TextFieldProps, null> {
    fieldType?: 'text';
}
interface NumberIntervalProps extends Omit<CommonType & NumberFieldProps, null> {
    fieldType?: 'numberInterval';
}
interface SearchProps extends Omit<CommonType & SearchFieldProps, null> {
    fieldType?: 'search';
    search?: boolean;
}

type InlineAction = {
    name: Icons;
    onPress?(e: PressEvent): void;
}

export const GenericField = (props): React.ReactElement => {
    const { search = props.fieldType === 'search', className, iconLeft, iconRight, labelLeft, labelRight, context, placeholder, onValueChanged, label, action, inlineAction, fieldType = 'text', errorMessage, onSubmit } = props;
    const [value, setValue] = useState(props.value);
    const base = 'flex rounded-md flex-1';
    const text = twMerge('text-base text-primary');
    const border = twMerge('border-input border', (iconLeft || labelLeft || context) && 'rounded-l-none', (iconRight || labelRight || inlineAction || (!isUndefinedOrNullOrEmptyString(value) && search)) && 'rounded-r-none' || (search && 'pl-0'));
    const bg = 'bg-input';
    const inputPadding = twMerge('py-2', (iconLeft || labelLeft) ? 'px-0' : 'px-3');
    const iconClass = twMerge(bg, 'flex border-input border rounded items-center justify-center px-3');
    const iconLeftClass = twMerge('rounded-md rounded-r-none  border-r-0', props.iconLeftClass);
    const iconRightClass = twMerge('rounded-l-none  border-l-0', props.iconRightClass);
    const hasQuery = search && !isUndefinedOrNullOrEmptyString(value);
    const searchButtonClass = twMerge('rounded-md rounded-l-none border border-l-0 px-3 border-input bg-input', !hasQuery ? '-ml-[37px]' : 'rounded-r-none -ml-[57px]');
    const searchClearClass = twMerge('rounded-md rounded-l-none border border-l-0 px-3 border-input bg-input');

    const labelClass = twMerge(base, 'm-0 text-input-label text-sm items-center pb-1');
    const labelInputClass = twMerge('flex text-sm pb-1 gap-3 p-2 pl-3 items-center rounded justify-center bg-white border-input border',
        labelLeft && 'rounded-md rounded-r-none border-r-0',
        labelRight && 'rounded-md rounded-l-none border-l-0',
        iconLeft && 'rounded-none',
        iconRight && 'rounded-none',
    );
    const inlineActionClass = twMerge('flex text-sm pb-1 rounded-r rounded-l-none p-2 items-center justify-center bg-white border-input border border-l-0 cursor-pointer');
    const { pressProps, isPressed } = usePress({
        onPress: (e) => {
            inlineAction && inlineAction.onPress && inlineAction.onPress(e);
        }
    });
    
    const getValidationErrorMessage = (validationDetails: ValidityState) => {
        let message = '';
        /* switch (true) {
            case validationDetails.valueMissing:
                message = intl.formatMessage({ id: 'field.validation.error_message.value_missing' })
                break;
        } */
        return <InvalidFieldMessage message={JSON.stringify(validationDetails)} />
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const getFieldInner = (innerProps) => {
        return <div className={className}>
            <div className='flex justify-between'>
                <div>
                    {label && <Label className={twMerge('gap-1', labelClass)}>
                        {label.icon && <Icon name={label.icon} size={Sizes.XSmall} className='text-secondary' />}
                        {label.text}</Label>}
                    <InvalidFieldMessage message={errorMessage as string} />
                </div>
                {action && <Action label={action.label} destructable={action.destructable} event={action.event} />}
            </div>
            <div className='flex w-full'>
                {/* {search && <Icon name={'operation_search'} className={twMerge(iconClass, iconLeftClass, 'pl-3 pr-0')} />} */}
                {iconLeft && <Icon size={Sizes.Small} name={iconLeft} className={twMerge(iconClass, iconLeftClass)} />}
                {labelLeft && <div className={labelInputClass}>{labelLeft}</div>}
                {context && <div className={''}>{context}</div>}
                <Input                                    
                    inputMode={props.fieldType === 'numberInterval' ? 'numeric' : null}
                    className={twMerge(base, border, bg, text, inputPadding)}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => {
                        onValueChanged && onValueChanged(value);
                    }}
                    defaultValue={value}
                    value={value}
                />
                {search && <Button className={searchButtonClass} slot='increment' onPress={() => value ? onSubmit?.(value) : null}><Icon name='operation_search' /></Button>}
                {hasQuery && search && <Button className={searchClearClass} slot='increment' onPress={() => setValue('')}><Icon name='operation_remove' /></Button>}
                {inlineAction && <div className={inlineActionClass} {...pressProps}><Icon name={inlineAction.name} /></div>}
                {labelRight && <div className={labelInputClass}>{labelRight}</div>}
                {iconRight && typeof iconRight === 'string' && <Icon size={Sizes.Small} name={iconRight as any} className={twMerge(iconClass, iconRightClass)} />}
                {iconRight && (typeof iconRight !== 'string') && <span className={twMerge(iconClass, iconRightClass)} >{iconRight}</span>}
            </div>
        </div>
    }

    switch (props.fieldType) {
        case 'text': {
            return (
                <TextField {...props}>
                    {getFieldInner(props)}
                </TextField>
            );
        }
        case 'numberInterval': {
            return (
                <NumberField  {...props} >
                    {getFieldInner(props)}
                </NumberField>
            )
        }
        case 'search': {
            return (
                <SearchField  {...props}
                    onClear={() => setValue('')}>
                    {({ isEmpty,  }) => (
                        <>
                            {getFieldInner({ ...props, isEmpty })}
                        </>
                    )}
                </SearchField>
            )
        }
    }
}
export const Field = (props: FieldProps) => {

    return <GenericField fieldType='text' {...props} />
}
export const NumberInterval = (props: NumberIntervalProps) => {

    return <GenericField fieldType='numberInterval' {...props} />;

}
export const Search = (props: SearchProps) => {
    return <GenericField fieldType='search' {...props} placeholder={props.placeholder} />
}
export default { Search, Field, NumberField };


