import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Divider, Form, Popup } from 'semantic-ui-react';
import { CorrectiveAction, CorrectiveActionAccessibleFeaturesEnum, FileResource, TextWithTranslation } from '../../../backend_api/models';
import { CorrectiveActionsState } from '../../../backend_api/models/CorrectiveActionsState';
import { EditCorrectiveActionRequest } from '../../../backend_api/models/EditCorrectiveActionRequest';
import Loader from '../../../base/components/Loader';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { DEFAULT_DATE_FORMAT } from '../../../base/config';
import { useAppDispatch } from '../../../store';
import ConditionalDatePicker from '../../globals/components/ConditionalDatePicker';
import PageContainer from '../../globals/components/PageContainer';
import TranslatedLabel from '../../globals/components/TranslatedLabel';
import TranslatedTextField from '../../globals/components/TranslatedTextField';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import Groups from '../../groups/components/Groups';
import { Group } from '../../inspections/types';
import ContentSection from '../../pageLayouts/components/ContentSection';
import ExpandableArea from '../../pageLayouts/components/ExpandableArea';
import TwoColPageLayout from '../../pageLayouts/components/TwoColPageLayout';
import ConditionalUsersSelector from '../../users/components/ConditionalUsersSelector';
import { UserNameText } from '../../users/components/Widgets';
import { deleteCorrectiveAction, editCorrectiveAction, getCorrectiveAction, notifyResponsible } from '../correctiveActionsSlice';
import { getCorrectiveActionSelector, isFetchingSelector } from '../selectors/correctiveActionSelectors';
import ApproveCorrectiveActionDialog from './ApproveCorrectiveActionDialog';
import AuditLinkList from './AuditLinkList';
import CheckpointLinkList from './CheckpointLinkList';
import CorrectiveActionEvidenceSection from './CorrectiveActionEvidenceSection';
import CorrectiveActionHistory from './CorrectiveActionHistory';
import CorrectiveActionStatusSegment from './CorrectiveActionStatusSegment';
import DefectListLinks from './DefectListLinks';
import InspectionsLinkList from './InspectionsLinkList';
import ProductionUnitLinkList from './ProductionUnitLinkList';
import ResolveCorrectiveActionDialog from './ResolveCorrectiveActionDialog';
import SupplierLinkList from './SupplierLinkList';
import LightBox from '../../../base/components/LightBox';
import { convertFileResourcesToLightBoxMedias } from '../../../base/utils';

type Props = {
    correctiveActionId: string;
}

const CorrectiveActionDetails = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const isFetching = useSelector(isFetchingSelector);
    const correctiveAction: CorrectiveAction = useSelector(getCorrectiveActionSelector);
    const [id, setId] = useState<string>(null);
    const [resolveModalOpen, setResolveModalOpen] = useState<boolean>(false);
    const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false);
    const [finding, setFinding] = useState<TextWithTranslation>(null);
    const [description, setDescription] = useState<TextWithTranslation>(null);
    const [editing, setIsEditing] = useState<string>(null);
    const [responsible, setResponsible] = useState<string[]>([]);
    const [approver, setApprover] = useState<string[]>([]);
    const [dueDate, setDueDate] = useState<Date>(null);
    const [groups, setGroups] = useState<Group[]>([]);
    const [requireEvidenceFromApp, setRequireEvidenceFromApp] = useState<boolean>(null);
    const editable = correctiveAction && (correctiveAction.state == CorrectiveActionsState.Finding || correctiveAction.state == CorrectiveActionsState.InProgress);
    const canEdit = correctiveAction && correctiveAction.accessible_features.includes(CorrectiveActionAccessibleFeaturesEnum.EditDetails);

    const fileResources = fileResourcesFromCorrectiveAction(correctiveAction);
    const lightboxMedias = convertFileResourcesToLightBoxMedias(fileResources);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.correctiveActionId) {
            dispatch(getCorrectiveAction({ correctiveActionId: props.correctiveActionId, showLoader: true }));
        }
        return () => setResponsible([]);
    }, [])
    useEffect(() => {
        if (correctiveAction) {
            editing !== 'finding' && setFinding(correctiveAction.finding);
            editing !== 'description' && setDescription(correctiveAction.description);
            setResponsible(correctiveAction.responsible ? [correctiveAction.responsible.id] : []);
            setApprover(correctiveAction.approver ? [correctiveAction.approver.id] : []);
            setDueDate((correctiveAction.due_date_at));
            setRequireEvidenceFromApp(correctiveAction.requires_evidence_from_app);
            setGroups(correctiveAction.groups);
            setId(props.correctiveActionId);
        }
    }, [correctiveAction])

    const deleteAction = () => {
        dispatch(deleteCorrectiveAction({ id: correctiveAction.id, isICA: correctiveAction.inspection_links.length > 0 }))
    }

    const saveAction = (request: EditCorrectiveActionRequest) => {
        if (editable) {
            dispatch(editCorrectiveAction({ id: correctiveAction.id, request: request }));
        }
    }
    const updateFinding = () => {
        if (finding !== correctiveAction.finding) {
            saveAction({ finding: finding.text })
        }
    }

    const updateDescription = () => {
        if (description !== correctiveAction.description) {
            saveAction({ description: description.text })
        }
    }

    const updateResponsible = (value) => {
        setResponsible([value.value])
        saveAction({ responsible_id: value.value })
    }

    const updateApprover = (value) => {
        setApprover([value.value])
        saveAction({ approver_id: value.value })
    }

    const updateGroups = (evt, item) => {
        setGroups(item.value)
        saveAction({ groups: item.value.map((group) => group.id) })
    }

    const updateDueDate = (moment: moment.Moment) => {
        const date = moment ? moment.format(DEFAULT_DATE_FORMAT) : null
        setDueDate(new Date(date));
        saveAction({ due_date_at: date })
    }
    const toggleRequireEvidenceFromApp = () => {
        saveAction({ requires_evidence_from_app: !requireEvidenceFromApp });
        setRequireEvidenceFromApp(!requireEvidenceFromApp);
    }

    const saveAndNotifyResponsible = () => {
        dispatch(notifyResponsible(correctiveAction.id))
    }


    const resolveActionDisabled = correctiveAction && !correctiveAction.can_be_resolved;
    const disablePopup = correctiveAction && (correctiveAction.can_be_resolved || correctiveAction.state == CorrectiveActionsState.Closed || correctiveAction.state == CorrectiveActionsState.Review);
    const hideSaveAndNotify = correctiveAction && (correctiveAction.state == CorrectiveActionsState.Closed || correctiveAction.state == CorrectiveActionsState.Review);
    const resolveActions = <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.Resolve}>
        <Popup
            disabled={disablePopup}
            trigger={<Button disabled={resolveActionDisabled} secondary onClick={() => setResolveModalOpen(true)} data-test-id="resolve-button"><FormattedMessage id='corrective_actions.details.resolve_button' /></Button>}
        >
            {correctiveAction && correctiveAction.requires_evidence_from_app ? <FormattedMessage id="corrective_actions.resolve_tooltip_with_require" /> : <FormattedMessage id="corrective_actions.resolve_tooltip_without_require" />}
        </Popup>
    </HasFeatureAccess>

    const evidenceUploadActionDisabled = correctiveAction && (correctiveAction.state === CorrectiveActionsState.Closed || correctiveAction.state === CorrectiveActionsState.Review);
    const evidenceActions = <div className='flex space-x-2'>
        {resolveActions}
    </div>

    const evidence = correctiveAction && <ContentSection
        headerText={intl.formatMessage({ id: 'corrective_actions.evidence.header' })}
        headerActions={evidenceActions}
        content={<CorrectiveActionEvidenceSection correctiveAction={correctiveAction} allowUpload={!requireEvidenceFromApp && !evidenceUploadActionDisabled} disabled={evidenceUploadActionDisabled} />}
    />

    const groupsInfo = <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
        <Groups
            selectedGroups={groups}
            handleChange={updateGroups}
            hideHeader={true}
            disabled={!editable}
        />
    </HasFeatureAccess>

    const details = correctiveAction && <ContentSection
        headerText={intl.formatMessage({ id: 'corrective_actions.details.header' })}
        content={
            <Form>
                <Form.Field inline >
                    <div className="flex flex-row justify-between">
                        <div className="w-1/2">
                            <label><FormattedMessage id='corrective_actions.details.finding' /></label>
                            <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
                                <TranslatedTextField
                                    onFocus={() => setIsEditing('finding')}
                                    className='w-full!'
                                    type='text'
                                    value={finding}
                                    onChange={setFinding}
                                    onBlur={() => {
                                        setIsEditing('');
                                        updateFinding();
                                    }}
                                    disabled={!editable}
                                />
                            </HasFeatureAccess>
                        </div>
                        <div className="w-1/3 flex flex-col items-end">
                            <label><FormattedMessage id='corrective_actions.details.due_date' /></label>
                            <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
                                <ConditionalDatePicker
                                    date={dueDate}
                                    handleChange={updateDueDate}
                                    placeholderKey='corrective_actions.details.due_date.placeholder'
                                    pastDates={true}
                                    isEditable={editable}
                                    conditionalPlaceholder={intl.formatMessage({ id: 'corrective_actions.details.due_date.conditional_placeholder' })}
                                />
                            </HasFeatureAccess>
                        </div>
                    </div>
                </Form.Field>
                <Form.Field width={8} inline >
                    <label><FormattedMessage id='corrective_actions.details.description' /></label>
                    <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
                        <TranslatedTextField
                            onFocus={() => setIsEditing('description')}
                            type='text-area'
                            value={description}
                            onChange={setDescription}
                            onBlur={() => {
                                setIsEditing('');
                                updateDescription();
                            }}
                            disabled={!editable}
                            placeholder={intl.formatMessage({ id: 'corrective_actions.details.description.placeholder' })}
                        />
                    </HasFeatureAccess>
                </Form.Field>
                <Divider />
                <div className='space-y-4'>
                    <div className="flex flex-row justify-between">
                        <div className="w-1/2">
                            <Form.Field inline className='pb-2'>
                                <label>Created by</label>
                                <UserNameText user={correctiveAction.creator} />
                            </Form.Field>
                            <Form.Field inline >
                                <label><FormattedMessage id='corrective_actions.details.responsible' /></label>
                                <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
                                    <ConditionalUsersSelector
                                        fallbackUser={correctiveAction && correctiveAction.responsible}
                                        value={responsible}
                                        placeholder={intl.formatMessage({ id: 'corrective_actions.create_modal.responsible.placeholder' })}
                                        handleChange={updateResponsible}
                                        allowMultipleSelection={false}
                                        showEmailInSelector={true}
                                        isEditable={editable}
                                        conditionalPlaceholder={intl.formatMessage({ id: 'corrective_actions.create_modal.responsible.conditional_placeholder' })}
                                    />
                                </HasFeatureAccess>
                            </Form.Field>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="w-1/2">
                            <Form.Field inline >
                                <label><FormattedMessage id='corrective_actions.details.approver' /></label>
                                <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
                                    <ConditionalUsersSelector
                                        fallbackUser={correctiveAction && correctiveAction.approver}
                                        value={approver}
                                        placeholder={intl.formatMessage({ id: 'corrective_actions.create_modal.approver.placeholder' })}
                                        allowMultipleSelection={false}
                                        handleChange={updateApprover}
                                        showEmailInSelector
                                        name="approver-dropdown"
                                        dataTestId='dropdown-select-approver'
                                        isEditable={editable}
                                        conditionalPlaceholder={intl.formatMessage({ id: 'corrective_actions.create_modal.approver.conditional_placeholder' })}
                                    />
                                </HasFeatureAccess>
                            </Form.Field>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="w-1/2">
                            <Form.Field inline >
                                <ExpandableArea
                                    headerText={<label><FormattedMessage id='corrective_actions.details.groups' /></label>}
                                    content={groupsInfo}
                                />
                            </Form.Field>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="w-1/2">
                            <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={true}>
                                <div className='py-2'><Checkbox disabled={!editable || !canEdit} onClick={(editable && canEdit) ? () => toggleRequireEvidenceFromApp() : null} checked={requireEvidenceFromApp} label={intl.formatMessage({ id: 'corrective_actions.require_evidence_from_app' })} data-test-id="require-evidence-from-app" /></div>
                            </HasFeatureAccess>
                        </div>
                        {!hideSaveAndNotify && <div className="w-1/3 contents">
                            <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.EditDetails} showDisabled={false}>
                                <Button disabled={responsible.length == 0} onClick={saveAndNotifyResponsible} primary><FormattedMessage id='corrective_actions.details.save_and_notify' /></Button>
                            </HasFeatureAccess>
                        </div>}
                    </div>
                </div>
            </Form>}
    />

    const linkElements = []
    if (correctiveAction) {
        if (correctiveAction.defect_links.length > 0) {
            linkElements.push(<Form.Field inline>
                <label><FormattedMessage id='corrective_actions.details.defect_links' /></label>
                <DefectListLinks links={correctiveAction.defect_links} />
            </Form.Field>)
        }
        if (correctiveAction.inspection_links.length > 0) {
            linkElements.push(<Form.Field inline>
                <label><FormattedMessage id='corrective_actions.details.inspections_list' /></label>
                <InspectionsLinkList links={correctiveAction.inspection_links} />
            </Form.Field>)
        }
        if (correctiveAction.audit_links.length > 0) {
            linkElements.push(<Form.Field inline>
                <label><FormattedMessage id='corrective_actions.details.audit_list' /></label>
                <AuditLinkList auditLinks={correctiveAction.audit_links} />
            </Form.Field>)
        }
        if (correctiveAction.supplier_links.length > 0) {
            linkElements.push(<Form.Field>
                <label><FormattedMessage id='corrective_actions.details.supplier_list' /></label>
                <SupplierLinkList supplierLinks={correctiveAction.supplier_links} />
            </Form.Field>)
        }
        if (correctiveAction.production_unit_links.length > 0) {
            linkElements.push(<Form.Field>
                <label><FormattedMessage id='corrective_actions.details.production_list' /></label>
                <ProductionUnitLinkList productionUnitLinks={correctiveAction.production_unit_links} />
            </Form.Field>)
        }
        if (correctiveAction.checkpoint_links.length > 0) {
            linkElements.push(<Form.Field>
                <label><FormattedMessage id='corrective_actions.details.checkpoint' /></label>
                <CheckpointLinkList correctiveAction={correctiveAction} />
            </Form.Field>)
        }
    }

    const links = correctiveAction && <ContentSection
        headerText={intl.formatMessage({ id: 'corrective_actions.context.header' })}
        content={<Form>
            {linkElements.map((element, index) => <Fragment key={index}>
                {element}
                {index != linkElements.length - 1 && <Divider />}
            </Fragment>)}
        </Form>}
    />

    const statusBox = correctiveAction &&
        <CorrectiveActionStatusSegment correctiveAction={correctiveAction} />

    const historySection = correctiveAction && <ContentSection
        headerText={intl.formatMessage({ id: 'corrective_actions.history.header' })}
        content={<CorrectiveActionHistory correctiveActionId={id} />}
    />
    const secondColumn = <Fragment>
        {statusBox}
        {historySection}
    </Fragment>

    const firstColumnContent = <Fragment>
        {details}
        {evidence}
        {links}
        <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.DeleteAndRestore} showDisabled={false}>
            <DeleteConfirm deleteFunction={deleteAction} type='alert' alertText={intl.formatMessage({ id: 'corrective_actions.delete_modal.text' })} alertHeader={intl.formatMessage({ id: 'corrective_actions.delete_modal.header' })} trigger={<Button negative data-test-id="delete-button"><FormattedMessage id='globals.confirmation.delete' /></Button>} />
        </HasFeatureAccess>
    </Fragment>

    return <PageContainer header={correctiveAction && <TranslatedLabel text={correctiveAction.finding} />}>

        <Loader active={isFetching}>
            <LightBox images={lightboxMedias} metaItems={[]} />
            <div>
                {correctiveAction && <ResolveCorrectiveActionDialog id={props.correctiveActionId} open={resolveModalOpen} close={() => setResolveModalOpen(false)} description={correctiveAction.finding.text} />}
                {correctiveAction && <ApproveCorrectiveActionDialog id={props.correctiveActionId} open={approveModalOpen} close={() => setApproveModalOpen(false)} />}
            </div>
            {correctiveAction && <TwoColPageLayout
                firstColumnContent={firstColumnContent}
                secondColumnContent={secondColumn}
            />}
        </Loader>
    </PageContainer>
}

export default CorrectiveActionDetails;

function fileResourcesFromCorrectiveAction(correctiveAction: CorrectiveAction): FileResource[] {
    if (!correctiveAction) {
        return [];
    }

    let fileResources = correctiveAction.evidence || [];
    const defectLinks = correctiveAction.defect_links;
    defectLinks?.forEach(defectLink => {
        fileResources = fileResources.concat(defectLink.file_resources);
    });
    return fileResources;
}

