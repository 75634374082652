import { ReactElement } from 'react';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { SlideImage, SlideVideo } from 'yet-another-react-lightbox';
import { ApprovalFlowReportState, ApprovalFlowState } from '../modules/approval/types';
import { AttacmentsState } from '../modules/attachments/models';
import { AuditState } from '../modules/audit/types';
import { AuthenticationState } from '../modules/authentication/authenticationSlice';
import { ChecklistsState } from '../modules/checklists/models';
import { CommentsState } from '../modules/comments/types';
import { ComplianceState } from '../modules/compliance/types';
import { CorrectiveActionState } from '../modules/correctiveActions/types';
import { CustomFieldsState } from '../modules/customFields/types';
import { DashboardState } from '../modules/dashboard/types';
import { SupplierDocumentsState } from '../modules/supplierDocuments/supplierDocumentsSlice';
import { FiltersState } from '../modules/filters/types';
import { GlobalsState } from '../modules/globals/types';
import { GroupsState } from '../modules/groups/types';
import { TemplateState } from '../modules/header/models';
import { EditInspectionState } from '../modules/inspections/reducers/editInspectionReducer';
import { InspectionsState } from '../modules/inspections/reducers/reducers';
import { SplitShipmentsState } from '../modules/inspections/slices/splitShipmentsSlice';
import { WeekViewState } from '../modules/inspections/slices/weekViewSlice';
import { InspectionsListState, ReportDataState } from '../modules/inspections/types';
import { PdfState } from '../modules/pdf/reducers';
import { ProductionUnitsState } from '../modules/productionUnits/slice/productionUnitsSlice';
import { ProfileState } from '../modules/profile/types';
import { RoleState } from '../modules/roles/reducers';
import { SamplingProtocolState } from '../modules/samplingProtocols/slices/samplingProtocolsSlice';
import { SearchState } from '../modules/search/state';
import { StatisticsState } from '../modules/statistics/types';
import { SupplierAndProductionUnitRelationsState } from '../modules/supplierAndPuRelations/slice/relationSlice';
import { SuppliersState } from '../modules/suppliers/models';
import { SuppliersState as SupplierState2 } from '../modules/suppliers/slice/suppliersSlice';
import { UsersState } from '../modules/users/models';
import { VideoState } from '../modules/video/types';
import { UIState } from './../base/ui/uiSlice';
import { LayoutAttributes } from './baseSlice';

export type SiteType = 'quality' | 'compliance';
export type InspectionsGlobalState = InspectionsState;
export type AppState = {
    app: {
        users?: UsersState;
        profile?: ProfileState;
        inspections?: InspectionsGlobalState;
        inspectionsList?: InspectionsListState;
        inspectionReport?: ReportDataState;
        globals?: GlobalsState;
        groups?: GroupsState;
        template?: TemplateState;
        aql?: SamplingProtocolState;
        attachments?: AttacmentsState;
        suppliers?: SuppliersState;
        suppliers2?: SupplierState2;
        supplierAndProductionUnitRelations?: SupplierAndProductionUnitRelationsState;

        productionUnits: ProductionUnitsState;
        checklists: ChecklistsState;
        ui: UIState;
        BASE: BaseState;
        VIDEO: VideoState;
        approval: ApprovalFlowState & ApprovalFlowReportState;
        customFields: CustomFieldsState;
        filters: FiltersState;
        roles: RoleState;
        comments: CommentsState;
        statistics: StatisticsState;
        search: SearchState;
        audits: AuditState;
        authentication: AuthenticationState;
        samplingProtocols: SamplingProtocolState;
        pdf: PdfState;
        correctiveActions: CorrectiveActionState;
        weekView: WeekViewState;
        editInspections: EditInspectionState;
        compliance: ComplianceState;
        dashboard2: DashboardState;
        splitShipments: SplitShipmentsState;
        supplierDocuments: SupplierDocumentsState;
    };
};

export type BaseState = {
    networkBusy: boolean;
    applicationReady: boolean;
    errors: byId<any>;
    layout: LayoutAttributes;
    showModal: byId<{ show: boolean, content?: any, data?: any, id?: string }>;
    showLightBox: { show: boolean, index?: number, url?: string };
    showSingleMediaLightBox: { show: boolean, file: SlideImage | SlideVideo }
    loading: byId<boolean>;
    siteType: SiteType;
    currentContext: CurrentContext;
    contextData: byId<CurrentContext>;
    showFilters: boolean;
};

export enum Context {
    Inspections = 'inspections',
    Audits = 'audits',
    CorrectiveActions = 'ca',
    Suppliers = 'suppliers',
    ProductionUnits = 'production_units',
    Bookings = 'bookings',
}

export type CurrentContext = {
    context: Context;
    metaData?: {
        list?: {
            start?: number;
            perPage?: number;
            total?: number;
            isFetching?: boolean;
        }
    }
}
export type DataType = 'inspection' | 'audit';
export type ReportType = 'inspectionReport' | 'auditReport';

export type byId<TValue> = {
    [id: string]: TValue;
};

export type DropdownValue = (string | number | boolean) | (string | number | boolean)[];


export type FileAttachment = {
    type: File;
};

export type Language = {
    name: string;
    code: string; //ISO 639-1 Code
}

export type Dispatch = (action: Action) => void;

export type RequestError = {
    error?: boolean;
    status?: number;
    errorText?: string;
    errorDetail?: { detail: string | string[] };
};

export type SamplingProtocol = {
    id: string;
    name: string;
    description: string;
};

export type FormattedDate = {
    date: string;
};

export type MapLocation = {
    latitude: number;
    longitude: number;
    altitude?: number;
};

const Locale = 'Locale';
interface Locale {
    name: string;
    language: string;
    country: string;
    id: string;
}


const LocaleObj = 'LocaleObj';
interface LocaleObj {
    locales: Locale[];
    selected: Locale;
    default: Locale;
}

const FetchingData = 'FetchingData';
interface FetchingData {
    isFetching: boolean;
}
export { FetchingData, Locale, LocaleObj };


export const ERROR = 'ERROR';
export type ERROR = {
    type: string;
    error: any;
    status?: number;
};
export const CLEAR_ERROR = 'CLEAR_ERROR';
export type CLEAR_ERROR = {
    type: string;
};

export type GetState = () => AppState;

export type GlobalUFeatures =
    'u_grouped_inspections' |
    'u_dashboard' |
    'u_statistics' |
    'u_planning_view' |
    'u_show_booking_info' |
    'u_manage_booking_info' |
    'u_manage_user_roles' |
    'u_create_order_and_inspection' |
    'u_combine_split_inspections' |
    'u_users_admin' |
    'u_groups_admin' |
    'u_inspection_checklists' |
    'u_audit_checklists' |
    'u_supplier_admin' |
    'u_requirements_menu' |
    'u_inspections' |
    'u_change_groups_on_orders' |
    'supplier_qc' |
    'u_audits' |
    'generate_pdf_report_on_submit' |
    'u_add_suppliers' |
    'u_add_checklists' |
    'u_add_groups' |
    'u_add_users' |
    'u_create_reinspections' |
    'u_create_audits' |
    'u_corrective_actions' |
    'groups' |
    't_product_compliance' |
    'org_independent_production_units' |
    'u_production_unit_list' |
    'u_documentation_types';

export type Role = {
    description: { [langid: string]: string };
    id: string;
    name: {
        [langid: string]: string;
    };
};


export type OptionsType = {
    id?: string;
    key?: string;
    value?: any;
    text?: string | ReactElement;
    searchtext?: string;
    sorttext?: string;
    content?: string | ReactElement;
    description?: string | ReactElement;
};

export type AppThunk<ReturnType = void | any> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<AppState, unknown, Action<string>>;

/* export type ComponentOrStringType = Element | React.JSXElementConstructor<any> | string | string[] | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
export type ChildrenOrStringType = React.ReactChild | React.ReactChild[] | string | string[]; */
export type ComponentOrStringType = React.ReactNode | React.ReactNode[] | string | string[];
export type ChildrenOrStringType = React.ReactNode | React.ReactNode[] | string | string[];