import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { ChecklistType } from '../../../backend_api/models';
import { request, request2 } from '../../../base/api';
import { AppThunk, RequestError } from '../../../base/types';
import history from '../../../store/history';
import errorHandling from '../../errorHandling';
import messages from '../../messages';
import * as types from '../actionTypes';
import { Checklist, ChecklistData, Checkpoint } from '../types';


export type DispatchGetAuditChecklistsFn = () => Promise<void>;
export type DispatchGetInspectionChecklistsFn = () => Promise<void>;
export type DispatchCopyChecklistFn = (checklistId: string, description: string) => Promise<void>;
export type DispatchCreateChecklistFn = (description: string, type: ChecklistType) => Promise<void>;
export type DispatchDeleteChecklistFn = (checklistId: string) => Promise<void>;

const catchException = errorHandling.handler.catchException;
const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;

const getAuditChecklistsRequestSuccess = createAction(types.GET_AUDIT_CHECKLISTS_SUCCESS, (data: Checklist[]) => ({
    data,
    isFetching: false
}));

type dispatchFunction = (dispatch: Dispatch) => Promise<void>

export function getAuditChecklists(): dispatchFunction {
    return dispatchGetAuditChecklists;
}

export function dispatchGetAuditChecklists(dispatch: Dispatch): Promise<void> {
    return request('checklists/audits', {})
        .then((data: Checklist[]) => {
            dispatch(getAuditChecklistsRequestSuccess(data));
            return data;
        }, dispatch)
        .catch((error: RequestError) => {
            dispatch(clearAllMessages());
            dispatch(sendErrorMessage(['error_message.loading_checklist_failed'], 0));
            catchException('getChecklists', {
                endpoint: 'checklists/audits',
                request: 'checklists/audits',
                status: error.status,
            }, { error });
        });
}

const getInspectionChecklistsRequestSuccess = createAction(types.GET_INSPECTION_CHECKLISTS_SUCCESS, (data: Checklist[]) => ({
    data,
    isFetching: false
}));

export function getInspectionChecklists(): dispatchFunction {
    return dispatchGetInspectionChecklists;
}

export function dispatchGetInspectionChecklists(dispatch: Dispatch): Promise<void> {
    return request('checklists/inspections', {})
        .then((data: Checklist[]) => {
            dispatch(getInspectionChecklistsRequestSuccess(data));
            return data;
        }, dispatch)
        .catch((error: RequestError) => {
            dispatch(clearAllMessages());
            dispatch(sendErrorMessage(['error_message.loading_checklist_failed'], 0));
            catchException('getChecklists', {
                endpoint: 'checklists/inspections',
                request: 'checklists/inspections',
                status: error.status,
            }, { error });
        });
}

const getChecklistsRequestSuccess = createAction(types.GET_CHECKLISTS_SUCCESS, (data: Checklist[]) => ({
    data,
    isFetching: false
}));

export function getChecklists(): dispatchFunction {
    return dispatchGetChecklists;
}

export function dispatchGetChecklists(dispatch: Dispatch): Promise<void> {
    return request('checklists', {})
        .then((data: Checklist[]) => {
            dispatch(getChecklistsRequestSuccess(data));
            return data;
        }, dispatch)
        .catch((error: RequestError) => {
            dispatch(clearAllMessages());
            dispatch(sendErrorMessage(['error_message.loading_checklist_failed'], 0));
            catchException('getChecklists', {
                endpoint: 'checklists',
                request: 'checklists',
                status: error.status,
            }, { error });
        });
}

// Get data for a given checklist
const getChecklistRequest = createAction(types.GET_CHECKLIST_CONTENT, (isFetching: boolean) => ({ isFetching }));
const getChecklistRequestSuccess = createAction(types.GET_CHECKLIST_CONTENT_SUCCESS, (data: Checklist) => ({
    data,
    isFetching: false
}));
const getChecklistRequestFailure = createAction(types.GET_CHECKLIST_CONTENT_FAILURE, (data) => data);

export function getChecklist(checklistId: string) {
    return (dispatch): Promise<void> => dispatchGetChecklist(dispatch, checklistId);
}

export function dispatchGetChecklist(dispatch, checklistId: string): Promise<void> {
    dispatch(getChecklistRequest(true));
    return request('checklists_2/' + checklistId, {})
        .then((data: Checklist) => {
            // If description field is missing then set it to null
            if (!('description' in data)) {
                data.description = null;
            }
            dispatch(getChecklistRequestSuccess(data));
        }, dispatch)
        .catch((error: RequestError) => {
            catchException('getChecklists', {
                endpoint: 'checklists',
                request: 'checklists',
                status: error.status,
            }, { error });
            dispatch(getChecklistRequestFailure(error));
        });
}

export const generateInspectionChecklist = (inspectionId: string) => {
    return (dispatch): Promise<void> => {
        dispatch(sendStatusMessage(['status_message.getting_checklist_data'], 0, true));
        dispatch(getChecklistRequest(true));
        return request('inspections/' + inspectionId + '/checklist/generate', {
            method: 'post',
        })
            .then((data: any) => { // TODO: Need type for 
                dispatch(clearAllMessages());
                dispatch(getChecklist(data.checklist_id));
            }, dispatch)
            .catch((error) => {
                catchException('getInspectionChecklist', {
                    endpoint: 'inspections/[inspectionId]/checklist',
                    request: 'inspections/' + inspectionId + '/checklist',
                    status: error.status,
                }, { error, inspectionId });
                dispatch(getChecklistRequestFailure(error));
            });
    };
};

export const updateChecklist = (checklistId: string, checklist: Checklist, returnToChecklists: boolean, updateChecklist: boolean): AppThunk => {
    return async (dispatch): Promise<void> => {
        if (updateChecklist) {
            dispatch(getChecklistRequest(true))
        }
        return request('checklists_2/' + checklistId, {
            method: 'put',
            body: JSON.stringify(checklist),
        })
            .then((data: Checklist) => {
                if (updateChecklist) {
                    dispatch(getChecklistRequestSuccess(data));
                }
                if (returnToChecklists) {
                    history.push('/checklists');
                }
            })
            .catch((error: RequestError) => {
                dispatch(getChecklistRequest(false))
                dispatch(sendErrorMessage(['error_message.data_could_not_be_saved'], 0));
                catchException('updateChecklist', {
                    endpoint: 'checklists_2/[checklistId]',
                    request: 'checklists_2/' + checklistId,
                    status: error.status,
                }, { error, checklistId, body: checklist, method: 'PUT' });
            });
    };
};

export const updateInspectionChecklist = (inspectionId: string, checklist: ChecklistData, isFetching?: boolean): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(sendStatusMessage(['status_message.updating_checklist_data'], 0, true));
        dispatch(getChecklistRequest(isFetching !== null ? isFetching : true));

        return request('inspections/' + inspectionId + '/checklist2', {
            method: 'put',
            body: JSON.stringify(checklist),
        })
            .then((data: any) => {
                dispatch(clearAllMessages());
                dispatch(getChecklistRequestSuccess(data));
            })
            .catch((error) => {
                dispatch(clearAllMessages());
                dispatch(sendErrorMessage(['error_message.data_could_not_be_saved'], 0));
                catchException('updateInspectionChecklist', {
                    endpoint: 'inspections/[inspectionId]/checklist',
                    request: 'inspections/' + inspectionId + '/checklist',
                    status: error.status,
                }, { error, inspectionId, body: checklist, method: 'PUT' });
            });
    };
};

// Delete checklist
const deleteChecklistRequest = createAction(types.DELETE_CHECKLIST, (isFetching: boolean) => ({ isFetching }));

export const deleteChecklist = (checklistId: string) => {
    return (dispatch: Dispatch): Promise<void> => dispatchDeleteChecklist(dispatch, checklistId);
};

export function dispatchDeleteChecklist(dispatch: Dispatch<any>, checklistId: string): Promise<void> {
    dispatch(deleteChecklistRequest());
    return request('checklists/' + checklistId, {
        method: 'delete',
    })
        .then(() => {
            // TODO this isn't smart.
            dispatchGetChecklists(dispatch)
            dispatchGetAuditChecklists(dispatch)
            dispatchGetInspectionChecklists(dispatch)
        })
        .catch((error: RequestError) => {
            catchException('deleteChecklist', {
                endpoint: 'checklists/[checklistId]',
                request: 'checklists/' + checklistId,
                status: error.status,
            }, { error, checklistId, method: 'DELETE' });
        });
}

// Copy checklist
const copyChecklistRequest = createAction(types.COPY_CHECKLIST, (isFetching: boolean) => ({ isFetching }));
const copyChecklistRequestSuccess = createAction(types.COPY_CHECKLIST_SUCCESS, (data: Checkpoint) => ({
    data,
    isFetching: false
}));

export const copyChecklist = (checklistId: string, description: string) => {
    return (dispatch: Dispatch): Promise<void> => dispatchCopyChecklist(dispatch, checklistId, description);
};

export function dispatchCopyChecklist(dispatch: Dispatch<any>, checklistId: string, description: string): Promise<void> {
    dispatch(copyChecklistRequest());
    return request('checklists/' + checklistId + '/copy?description=' + encodeURIComponent(description) + ' Copy', {
        method: 'post',
    })
        .then((data: Checkpoint) => {
            dispatch(copyChecklistRequestSuccess(data));
            // TODO this isn't smart.
            dispatchGetChecklists(dispatch)
            dispatchGetAuditChecklists(dispatch)
            dispatchGetInspectionChecklists(dispatch)
        })
        .catch((error: RequestError) => {
            catchException('copyChecklist', {
                endpoint: 'checklists/[checklistId]/copy?description=[description] Copy',
                request: 'checklists/' + checklistId + '/copy?description=' + description + ' Copy',
                status: error.status,
            }, { error, checklistId, body: checklistId, description, method: 'POST' });
        });
}

// Create new checklist
const createChecklistRequest = createAction(types.CREATE_CHECKLIST, (isFetching: boolean) => ({ isFetching }));

export const createChecklist = (description: string, type: ChecklistType) => {
    return (dispatch): Promise<void> => dispatchCreateChecklist(dispatch, description, type);
};

export function dispatchCreateChecklist(dispatch: Dispatch<any>, description: string, type: ChecklistType): Promise<void> {
    dispatch(createChecklistRequest(true));
    return request('checklists', {
        method: 'post',
        body: JSON.stringify({ description, type }),
    })
        .then((data: Checklist) => {
            history.push(type === 'inspection' ? '/inspections/edit_checklist/' + data.id : '/audits/edit_checklist/' + data.id);
        })
        .catch((error: RequestError) => {
            catchException('createChecklist', {
                endpoint: 'checklists',
                request: 'checklists',
                status: error.status,
            }, { error, body: description, method: 'POST' });
        });
}

export const downloadChecklistAsExcel = createAsyncThunk<Blob, { checklistId: string, fileName: string, selectedLanguage?: string }>(
    'downloadAuditExcelReport',
    async (params, { dispatch }) => {
        let url = 'checklists/' + params.checklistId + '/download';
        if (params.selectedLanguage) {
            url += '?language=' + params.selectedLanguage;
        }
        const rq = await request2(url, {});
        if (!rq.ok) {
            console.log('There was an error downloading the checklist...');
            dispatch(sendErrorMessage(['error_message.checklist_excel_file_could_not_be_downloaded'], 3000));
            catchException('downloadChecklistAsExcel', {
                endpoint: 'checklists/[id]/download',
                request: 'checklists/' + params.checklistId + '/download',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
        } else {
            dispatch(sendStatusMessage(['status_message.checklist_excel_file_was_downloaded_with_success'], 3000));
            const blob = await rq.blob();
            FileSaver.saveAs(blob, params.fileName + '.xlsx');
            return blob;
        }
    });

