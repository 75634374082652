import React, { useState } from 'react';
import { Button, Header, Menu, MenuItem, MenuSection, MenuTrigger, OverlayArrow, Popover } from 'react-aria-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Feature } from '../../../backend_api/models';
import { useTranslation } from '../../../base/hooks';
import { getFeaturesSelector } from '../../../base/selectors';
import QButton from '../../../base/ui/components/buttons/Button';
import Icon from '../../../base/ui/components/icons/Icon';
import QModal from '../../../base/ui/components/modals/Modal';
import { Sizes } from '../../../base/ui/components/types';
import { twMerge, useIsTabletDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { logout, TEMPORARY_USER_MODE } from '../../authentication/authenticationSlice';
import { getComplianceIsEnabledSelector } from '../../compliance/selectors/ComplianceSelectors';
import { LocaleSelector } from '../../globals/components';
import QLink from '../../globals/components/QLink';
import { getSaving } from '../../inspections/selectors/selectors';
import { getProfile } from '../../profile/selectors';
import GlobalSearch from '../../search/components/GlobalSearch';
import OrganisationSwitcherModal from './GlobalNavigationOrganisationSwitcher';
import { useNavigationItems } from './hooks/useNavigationItems';
import Logo from './Logo';
import OrgMenuItem from './OrgMenuItem';
import { MenuItem as MenuItemType, Menu as MenuType, SecondaryMenu } from './types';

type Props = {
    className?: string;
    isAuthenticated?: boolean;
};

const GlobalNavigation = (props: Props): React.ReactElement => {
    const { className, isAuthenticated } = props;
    const location = useLocation();
    const isMobile = useIsTabletDevice();
    const dispatch = useAppDispatch();
    const [dir, setDir] = useState(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const translate = (key: string) => useTranslation(key);
    const isTemporaryUserMode = !!sessionStorage.getItem(TEMPORARY_USER_MODE);
    /* useScrollPosition(({ prevPos, currPos }) => {
        const dirTmp = (prevPos.y < currPos.y ? 'UP' : 'DOWN');
        if (dirTmp !== dir) {
            setDir(dirTmp);
        }
    }); */
    const isSaving = useSelector(getSaving);
    const intl = useIntl();
    const history = useHistory();
    const profile = useSelector(getProfile);
    const features = useSelector(getFeaturesSelector);
    const productComplianceEnabled = useSelector(getComplianceIsEnabledSelector);
    const showSearch = features.includes(Feature.Quality) || productComplianceEnabled;
    const navigationItems = useNavigationItems({
        profile,
    });

    const secondaryMenu: SecondaryMenu = navigationItems.secondaryMenus[0];
    const menuItemStyle = 'px-4 py-2 text-secondary hover:bg-surface-selected cursor-pointer focus:outline-none focus-visible:outline-2 focus:outline-offset-0 focus-visible:outline-[#0079D1]';
    const menuItemHeaderStyle = 'px-4 text-xs text-black font-bold px-4 pt-2 pb-1';
    const menuFlyoutStyle = twMerge(isMobile ? 'mt-4 w-[300px]' : 'mt-2 w-[200px]', 'outline-none entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards py-1 bg-white rounded-md border border-branding-brand shadow-md');
    const topMenuStyle = 'overflow-hidden px-1 py-2 cursor-pointer font-display text-on-surface-brand bg-branding-brand flex items-center  focus:outline-none focus-visible:outline-2 focus:outline-offset-0 focus-visible:outline-[#0079D1] focus-visible:rounded-sm';

    type RenderMenuProps = {
        menu: MenuType;
        isOpen?: boolean;
    }
    const getMenuItem = (item: MenuItemType) => {
        return <MenuItem
            data-test-id={'secondary-menu.item.' + item?.id}
            className={twMerge(menuItemStyle, item?.selected && 'cursor-crosshair', item?.selected && 'cursor-default bg-surface-selected')} id={item?.id} key={item?.id}
            onAction={() => {
                history.push(item?.link || '')
            }}>
            <QLink className={twMerge('text-secondary hover:text-secondary', item?.selected && 'cursor-default')} to={item?.link || ''}>{item?.name}</QLink>
        </MenuItem>
    }
    const RenderMenu = ({ menu, isOpen }: RenderMenuProps) => {
        const items = menu.items.filter((item) => item !== undefined && item !== null);
        const hasItems = items.length > 0;

        if (items.length === 1) {
            const item = items[0];
            return (
                <Menu>
                    <MenuItem
                        data-test-id={'top-menu.item.' + item?.id}
                        className={topMenuStyle}
                        id={item?.id} key={item?.id}
                        onAction={() => {
                            history.push(item?.link || '')
                        }}>
                        {item.name}
                    </MenuItem>
                </Menu>
            );
        }

        return hasItems && (
            <MenuTrigger defaultOpen={isOpen}>
                <Button className={twMerge(topMenuStyle, 'pr-0')}>
                    {menu.meta.name}
                    <Icon name='microinteraction_drop-down' className='text-on-surface-brand' />
                </Button>
                <Popover placement='top' className={menuFlyoutStyle}>
                    <OverlayArrow className={''}>
                        <svg
                            viewBox='0 0 12 12'
                            className='block fill-white rotate-180 group-placement-bottom:rotate-180 w-4 h-4'
                        >
                            <path d='M0 0L6 6L12 0' />
                        </svg>
                    </OverlayArrow>
                    <Menu className='outline-none py-1'>
                        {items.map((item) => getMenuItem(item))}
                    </Menu>
                </Popover>
            </MenuTrigger>
        );
    };
    const RenderSecondaryMenu = (props: RenderMenuProps) => {
        const menu = props.menu;
        const hasItems = menu.items.filter((item) => item !== undefined && item !== null).length > 0
        return hasItems && <MenuSection className='border-b py-1'>
            {menu.items.length > 1 && <Header className={twMerge(menuItemHeaderStyle)}>{menu.meta.name}</Header>}
            {menu.items?.map((m) => {
                if (m) { // TODO: fix the way the menu items are created - they can be undefined
                    if (menu.meta?.id === 'organisationsMenu' && !m.selected) {
                        return <OrgMenuItem className={menuItemStyle} key={m?.id} org={{ organization_id: m?.id, organization_name: m?.name, organization_logo_url: null, user_id: null }} />
                    }
                    return getMenuItem(m)
                }
            })}
        </MenuSection>
    }
    const renderNavigation = () => {
        const primaryMenuItems = navigationItems.primaryMenus && Object.values(navigationItems?.primaryMenus).filter((menu) => menu?.items.length > 0);
        const logo = <Logo
            isSaving={isSaving}
            isAuthenticated={props.isAuthenticated}
        />;
        const signOut = <MenuItem
            className={twMerge('border-t items-center justify-center flex', menuItemStyle)}
            id='logout'
            key='logout'
        >
            <QButton icon='operation_sign-out' size={Sizes.Small} onPress={() => dispatch(logout())}><FormattedMessage id='global_navigation.sign_out' /></QButton>
        </MenuItem>;
        return (
            <section className='navbar-section'>
                <div
                    role='navigation'
                    className='w-full p-4 text-on-surface-brand bg-branding-brand justify-center h-[65px] flex items-center'
                >
                    {(props.isAuthenticated) &&
                        <div className='flex w-[1275px] items-center justify-between gap-12'>
                            <Logo
                                className='flex-shrink-0'
                                isSaving={isSaving}
                                isAuthenticated={props.isAuthenticated}
                            />
                            {!isMobile && !isTemporaryUserMode && <div className='hidden flex-shrink-0 lg:flex gap-4 items-center'>
                                {primaryMenuItems?.map((menu) => (
                                    <RenderMenu key={menu.meta.name} menu={menu} />
                                ))}
                                {showSearch && <GlobalSearch className='' />}
                            </div>}
                            <div className={twMerge('flex overflow-hidden gap-x-3 items-center w-auto', isMobile && 'w-full')} data-test-id='secondary-menu'>
                                {isMobile && <div className='flex w-full ml-2'>
                                    <div className='flex gap-x-2 items-center w-full sm:justify-between'>
                                        {showSearch && <GlobalSearch className='' />}
                                        <div><Button onPress={() => setIsOpen(!isOpen)} ><Icon size={Sizes.XLarge} name={isOpen ? 'microinteraction_close' : 'microinteraction_menu'} className={twMerge('text-on-surface-brand', isOpen ? 'animate-in spin-in-90 duration-300' : 'animate-out spin-out-180 duration-300')} /></Button></div>
                                    </div>
                                    <QModal
                                        overlayClass='bg-gray-700/70 top-[65px]'
                                        isOpen={isOpen}
                                        onClose={() => setIsOpen(false)}
                                        hideHeaderAndFooter
                                        onOpenChange={setIsOpen}
                                        className='animate-in slide-in-from-right duration-300 absolute top-0 right-0 rounded-none' width={320} noMaxHeight>
                                        <div className='h-screen overflow-scroll'>
                                            <div>
                                                <Menu className='outline-none' >
                                                    {primaryMenuItems?.map((menu) => (
                                                        <RenderSecondaryMenu menu={menu} />
                                                    ))}
                                                    {secondaryMenu.items.filter((menu => menu.meta.id !== 'organisationsMenu')).map((menu) => (
                                                        <RenderSecondaryMenu menu={menu} />
                                                    ))}
                                                    {/* !isMobile && signOut */}
                                                </Menu>
                                            </div>
                                            <div className='sticky bottom-0 h-[170px] bg-secondary border-t p-4 z-50 space-y-2'>
                                                <div className='flex justify-between gap-x-3'>
                                                    <div>
                                                        <div className='text-base font-bold'>{secondaryMenu.meta.name}</div>
                                                        <div className='text-sm'>{secondaryMenu.meta.organisation}</div>
                                                    </div>
                                                    <div className='text-sm text-secondary'><LocaleSelector isMobile={true} /></div>
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <QButton fullWidth secondary icon='operation_sign-out' size={Sizes.Small} onPress={() => dispatch(logout())}><FormattedMessage id='global_navigation.sign_out' /></QButton>
                                                    <OrganisationSwitcherModal
                                                        isMobile={true}
                                                        organisations={profile.other_organizations}
                                                        currentOrganisation={profile.organization}
                                                        trigger={<QButton stopPropagation={true} className='py-2' fullWidth size={Sizes.Small}><FormattedMessage id='global_navigation.switch_organisation.action' /></QButton>} />

                                                </div>
                                            </div>
                                        </div>
                                    </QModal>
                                </div>}
                                {!isMobile && secondaryMenu.items.length > 0 && (
                                    <MenuTrigger onOpenChange={(isOpen) => setIsOpen(isOpen)} >
                                        <div className={topMenuStyle}>
                                            {!isMobile && <><Button className='font-display overflow-hidden text-on-surface-brand bg-branding-brand flex items-center'>
                                                <div className='truncate'>{secondaryMenu.meta.name}</div>
                                                <Icon name='microinteraction_drop-down' className='text-on-surface-brand' />
                                            </Button>

                                            </>}
                                        </div>
                                        <Popover className={menuFlyoutStyle}>
                                            <Menu className='outline-none' >
                                                {!isTemporaryUserMode && secondaryMenu.items.filter((menu => menu.meta.id !== 'organisationsMenu')).map((menu) => (
                                                    <RenderSecondaryMenu menu={menu} />
                                                ))}
                                                <OrganisationSwitcherModal
                                                    itemClassName={menuItemStyle}
                                                    organisations={profile.other_organizations}
                                                    currentOrganisation={profile.organization}
                                                // trigger={<QButton stopPropagation={true} className='py-2' fullWidth size={Sizes.Small}><FormattedMessage id='global_navigation.switch_organisation.action' /></QButton>} 
                                                />
                                                {signOut}
                                            </Menu>
                                        </Popover>
                                    </MenuTrigger>
                                )}
                                {!isMobile && <LocaleSelector isMobile={false} />}
                            </div>
                        </div>
                    }
                    {!isAuthenticated && !isTemporaryUserMode && logo}

                </div>
            </section >
        );
    };
    return renderNavigation();

}
export default GlobalNavigation;