import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextArea } from 'semantic-ui-react';
import SpinnerIcon from '../../../components/basic/SpinnerIcon';
import { Sizes } from '../../../components/types';
import Button, { ButtonTypes } from '../buttons/Button';
import Icon from '../icons/Icon';
import { ListItem, ListItems } from '../listitems/ListItem';
import QModal, { ActionTypes } from '../modals/Modal';
import { isUndefinedOrNullOrEmptyString, getPrettyDate, twMerge, useIsMobileDevice, isDateExpired } from '../../../utils';
import { useAppDispatch } from '../../../../store';
import { archiveSupplierDocument, createSupplierDocument, getAllDocumentsForProductionUnit, getDetailedProductionUnitListSelector, getProductionUnitDocumentsSelector, getProductionUnitPageLoadingSelector, getProductionUnitViewingDocumentSelector, getSupplierDocumentTypeGroups, getSupplierDocumentTypeGroupsSelector, productionUnitsSliceActions } from '../../../../modules/productionUnits/slice/productionUnitsSlice';
import { useSelector } from 'react-redux';
import { CustomFieldGroupsDocumentationTypes, DocumentationType, FileResource, SupplierDocumentExtendedStatusEnum } from '../../../../backend_api/models';
import FileUploadModal from '../../../../modules/files/components/FileUploadModal';
import DocumentValidity from './DocumentValidity';
import { DocumentViewerAction } from './SupplierDocumentViewer';
import { getUsersById } from '../../../../modules/users/selectors';

type Props = {
    className?: string;
    actions?: DocumentViewerAction;
    readOnly?: boolean
};

const SupplierDocumentApprovalAndValidity = (props: Props): React.ReactElement => {
    const {
        className,
        actions,
        readOnly
    } = props;

    const { archive, unarchive, remove, approve, reject, submit, unsubmit, activate, reset, updateValidity } = actions || {};

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const documentation = useSelector(getProductionUnitViewingDocumentSelector);
    const documentTypeGroups = useSelector(getSupplierDocumentTypeGroupsSelector);
    const productionUnit = useSelector(getDetailedProductionUnitListSelector);
    const documents = useSelector(getProductionUnitDocumentsSelector);
    const documentsWithSameType = documents.filter(doc => doc.documentation_type_id === documentation?.documentation_type_id && doc.id !== documentation?.supplier_document_id);
    const documentationType = getDocumentationTypeById(documentTypeGroups, documentation?.documentation_type_id);
    const loading = useSelector(getProductionUnitPageLoadingSelector);

    const [rejectModal, showRejectModal] = useState(false);
    const [rejectComment, setRejectComment] = useState<string>(null);
    const [resetApprovalModal, showResetApprovalModal] = useState(false);
    const [resetRejectModal, showResetRejectModal] = useState(false);
    const [unsubmitModal, showUnsubmitModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showBulkArchiveModal, setShowBulkArchiveModal] = useState(false);

    const [validAfter, setValidAfter] = useState(null);
    const [validBefore, setValidBefore] = useState(null);
    const state = documentation?.extended_status;
    const isExpired = validBefore && isDateExpired(validBefore);
    const isArchived = state === SupplierDocumentExtendedStatusEnum.Archived;
    const expiredOrArchived = isExpired || isArchived;
    const [validityRequiredMessage, setValidityRequiredMessage] = useState('');
    const isValidityNotSet = !validAfter || (documentation?.expiry_required && !validBefore);
    const isMobile = useIsMobileDevice();
    const users = useSelector(getUsersById);
    const uploaderId = documentation?.uploader_id;
    const uploader = users[uploaderId];
    // TODO Chris: Check if user is allowed to approve once access control is implemented
    const isAllowedToApprove = true;

    React.useEffect(() => {
        if(!!documentation) {
            setValidAfter(documentation?.start_date);
            setValidBefore(documentation?.expiry_date);
            if(documentation?.start_date && (documentation?.expiry_required ? documentation?.expiry_date : true)) {
                setValidityRequiredMessage('');
            }
        }
    }, [documentation]);

    React.useEffect(() => {
        if(productionUnit) {
            dispatch(getAllDocumentsForProductionUnit({ production_unit_id: productionUnit.id }));
        }
        dispatch(getSupplierDocumentTypeGroups());
    }, []);

    const showResetApproval = () => {
        showResetApprovalModal(true);
    }
    const showUnsubmit = () => {
        showUnsubmitModal(true);
    }

    const showResetReject = () => {
        showResetRejectModal(true);
    }

    const closeDocumentViewer = () => {
        dispatch(productionUnitsSliceActions.hideDocumentViewer());
    }

    const doApprove = () => {
        if(isValidityNotSet) {
            setValidityRequiredMessage('document_viewer.validity.validity_required_before_approving');
        } else {
            approve();
            if(documentsWithSameType.length > 0) {
                setTimeout(() => setShowBulkArchiveModal(true), 500);
            } else {
                closeDocumentViewer();
            }
        }
    }

    const bulkArchive = () => {
        documentsWithSameType.forEach(doc => {
            dispatch(archiveSupplierDocument({ id: doc.id }));
        });
        setShowBulkArchiveModal(false);
        closeDocumentViewer();
    }

    const doActivate = () => {
        if(isValidityNotSet) {
            setValidityRequiredMessage('document_viewer.validity.validity_required_before_activating');
        } else {
            activate();
        }
    }

    const uploadFileModal = <FileUploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onUploadComplete={(file) => {
            setShowUploadModal(false);
            uploadFilesToDocument(file);
        }}
        isMobile={isMobile}
        allowMultiUpload={false}
    />

    const uploadFilesToDocument = async (file: FileResource) => {
        dispatch(createSupplierDocument({
            documentation_type_id: documentation?.documentation_type_id,
            media_resource_id: file?.id,
            production_unit_id: productionUnit?.id
        }));
    }

    const fileAddedState = <>
        <ListItem className={`font-bold bg-status-in-progress text-on-status ${readOnly ? 'rounded-md' : 'rounded-t-md'}`}><FormattedMessage id='production_units.supplier_document.status.file_added' /></ListItem>
        {!readOnly && <ListItem>
            {
                documentation?.approval_required ?
                <Button buttonType={ButtonTypes.Secondary} className='flex-1' onPress={submit}><FormattedMessage id='production_units.document_viewer.submit_for_review' /></Button>
                :
                (!!activate ? 
                    <Button buttonType={ButtonTypes.Secondary} className='flex-1' onPress={doActivate}><FormattedMessage id='production_units.document_viewer.activate' /></Button>
                    :
                    <Button buttonType={ButtonTypes.Secondary} className='flex-1' onPress={closeDocumentViewer}><FormattedMessage id='production_units.document_viewer.done' /></Button>
                )
            }
        </ListItem>}
    </>;

    const activeState = <ListItem className='font-bold bg-status-approved text-on-status rounded-t-md'>
        <FormattedMessage id='production_units.supplier_document.status.active' /></ListItem>;

    const waitingForApprovalState = <>
        <ListItem
            meta={{ metaBelow: { firstItem: `${intl.formatMessage({ id: 'production_units.document_viewer.submitted_by' })} ${uploader?.firstname} ${uploader?.lastname}`, className: 'text-on-status' } }}
            inlineButton={!readOnly && { button: <Button className='font-normal' size={Sizes.XSmall} onPress={showUnsubmit}><FormattedMessage id='document_viewer.unsubmit' /></Button> }}
            className='bg-status-pending text-on-status font-bold rounded-t-md'><FormattedMessage id='production_units.supplier_document.status.waiting_for_approval' /></ListItem>
        {!readOnly && isAllowedToApprove && <ListItem className='rounded-b-md'>
            <div className='flex flex-1 gap-x-4'>
                {!loading && <Button onPress={doApprove}
                    className='bg-status-approved text-white flex gap-x-4 flex-1 grow'><Icon name='status_approved-circle' className='text-white' size={Sizes.Small} /><FormattedMessage id='compliance.document_viewer.approval.approve' /></Button>}
                {loading && <Button primary><SpinnerIcon className='bg-qarma-green flex-1' /></Button>}
                <Button className='bg-status-rejected text-white flex-1 grow-0'
                    onPress={() => showRejectModal(true)}><Icon name='status_rejected-circle' className='text-white' size={Sizes.Small} /><FormattedMessage id='compliance.document_viewer.approval.reject' /></Button>
            </div>
        </ListItem>}
    </>;

    const approvedState = <ListItem className='bg-status-approved text-on-status rounded-md font-bold'
        meta={{ metaBelow: { firstItem: 'Jim Johnson', secondItem: getPrettyDate(new Date()), className: 'text-on-status font-normal' } }}
        inlineButton={{ button: <Button size={Sizes.XSmall} onPress={showResetApproval} className='font-normal'><FormattedMessage id='compliance.document_viewer.approval.reset' /></Button> }}>
        <FormattedMessage id='compliance.document_viewer.approval.approved' />
    </ListItem>

    const rejectedState = <><ListItem className='bg-status-rejected text-on-status rounded-t-md font-bold'
        inlineButton={!readOnly && { button: <Button size={Sizes.XSmall} onPress={showResetReject} className='font-normal'><FormattedMessage id='compliance.document_viewer.approval.reset' /></Button> }}>
        <div className='text-on-status'><FormattedMessage id='compliance.document_viewer.approval.rejected' /></div>
    </ListItem>
        <ListItem
            meta={{ metaAbove: { firstItem: intl.formatMessage({ id: 'compliance.document.rejection_reason' }) } }}
            className='whitespace-pre-line'>
            {documentation?.rejection_reason}
        </ListItem>
        {!readOnly && <ListItem className='rounded-b-md'>
            <Button className='grow' buttonType={ButtonTypes.Secondary} onPress={() => setShowUploadModal(true)} icon='operation_add'><FormattedMessage id='production_units.document_viewer.add_new' /></Button>
            <Button className='grow-0' buttonType={ButtonTypes.Plain} onPress={archive} icon='operation_archive'><FormattedMessage id='production_units.document_viewer.archive' /></Button>
            <Button className='grow-0' buttonType={ButtonTypes.Plain} onPress={remove} icon='operation_delete'></Button>
        </ListItem>}
    </>;

    const expiredState = <>
        <ListItem className='bg-status-rejected text-on-status rounded-t-md font-bold'
            meta={{ metaBelow: { firstItem: getPrettyDate(documentation.expiry_date), className: 'text-on-status font-normal' } }}>
            <FormattedMessage id='compliance.document_viewer.approval.expired' />
        </ListItem>
        {!readOnly && <ListItem className='rounded-b-md'>
            <Button className='grow' buttonType={ButtonTypes.Secondary} icon='operation_add' onPress={() => setShowUploadModal(true)}><FormattedMessage id='production_units.document_viewer.add_new' /></Button>
            <Button className='grow-0' buttonType={ButtonTypes.Plain} icon='operation_archive' onPress={archive}><FormattedMessage id='production_units.document_viewer.archive' /></Button>
        </ListItem>}
    </>

    const archivedState = <ListItem className='bg-status-ignored text-on-status rounded-md font-bold'
        inlineButton={!readOnly && { button: <Button size={Sizes.XSmall} onPress={unarchive} className='font-normal'><FormattedMessage id='compliance.document_viewer.approval.unarchive' /></Button> }}>
        <FormattedMessage id='compliance.document_viewer.approval.archived' />
    </ListItem>

    const approvalSection = <>
        <ListItems aria-label="Document approval options" className={'border border-default border-b-0 rounded-md bg-default gap-3'}>
            {!expiredOrArchived && state === SupplierDocumentExtendedStatusEnum.FileAdded && fileAddedState}
            {!expiredOrArchived && state === SupplierDocumentExtendedStatusEnum.Active && activeState}
            {!expiredOrArchived && state === SupplierDocumentExtendedStatusEnum.WaitingForApproval && waitingForApprovalState}
            {!expiredOrArchived && state === SupplierDocumentExtendedStatusEnum.Approved && approvedState}
            {!expiredOrArchived && state === SupplierDocumentExtendedStatusEnum.Rejected && rejectedState}
            {isExpired && !isArchived && expiredState}
            {isArchived && archivedState}
        </ListItems>
        <QModal
            width={500}
            isOpen={unsubmitModal}
            header={intl.formatMessage({ id: 'compliance.document.unsubmit.header' })}
            content={<>
                <FormattedMessage id='document_viewer.unsubmit.warning' />
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: intl.formatMessage({ id: 'compliance.document.unsubmit.confirm' }), buttonType: ButtonTypes.Primary, event: unsubmit },
                { type: ActionTypes.CancelAction, text: intl.formatMessage({ id: 'globals.cancel' }), buttonType: ButtonTypes.Plain, event: () => showUnsubmitModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={resetRejectModal}
            header={intl.formatMessage({ id: 'compliance.document.reset_rejection' })}
            content={<>
                <FormattedMessage id='compliance.document.reset_rejection.text' />
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: intl.formatMessage({ id: 'compliance.document.reset_rejection.confirm' }), buttonType: ButtonTypes.Primary, event: () => { showResetRejectModal(false); reset(); } },
                { type: ActionTypes.CancelAction, text: intl.formatMessage({ id: 'globals.cancel' }), buttonType: ButtonTypes.Plain, event: () => showResetRejectModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={rejectModal}
            header={intl.formatMessage({ id: 'document_viewer.reject_document.reject_document' })}
            content={<>
                <div className='pb-1 text-sm text-input-text-label'><FormattedMessage id='document_viewer.reject_document.reason_for_rejection'/></div>
                <TextArea onChange={(e) => setRejectComment(e.currentTarget.value)}>
                    {rejectComment}
                </TextArea>
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: intl.formatMessage({ id: 'document_viewer.reject_document' }), buttonType: ButtonTypes.Danger, event: () => reject(rejectComment), disabled: isUndefinedOrNullOrEmptyString(rejectComment) },
                { type: ActionTypes.CancelAction, text: intl.formatMessage({ id: 'globals.cancel' }), buttonType: ButtonTypes.Plain, event: () => showRejectModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={resetApprovalModal}
            header='Reset approval'
            content={<>
                <FormattedMessage id='document_viewer.validity.change_date_and_remove_approval' />
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: 'Yes, reset approval', buttonType: ButtonTypes.Primary, event: () => { showResetApprovalModal(false); reset(); } },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showResetApprovalModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={showBulkArchiveModal}
            header={intl.formatMessage({ id: 'document_viewer.approval.bulk_archive.header' })}
            content={
                <>
                    <FormattedMessage 
                        id="document_viewer.approval.bulk_archive"
                        values={{
                            documentationType: documentationType?.name?.text,
                            b: (chunks) => <b>{chunks}</b>
                        }}
                    />
                </>
            }
            actionItems={[
                { type: ActionTypes.Action, text: intl.formatMessage({ id: 'document_viewer.approval.bulk_archive.yes' }), buttonType: ButtonTypes.Primary, event: bulkArchive },
                { type: ActionTypes.CancelAction, text: intl.formatMessage({ id: 'document_viewer.approval.bulk_archive.no' }), buttonType: ButtonTypes.Plain, event: () => setShowBulkArchiveModal(false) },
            ]}
        />
    </>

    return <div className={twMerge('flex flex-col gap-4', className)}>
            {approvalSection}
            <DocumentValidity validationError={validityRequiredMessage} readOnly={readOnly} setValidationError={setValidityRequiredMessage} updateValidity={updateValidity} resetApproval={reset} />
            {uploadFileModal}
        </div>
};

export function getDocumentationTypeById(documentTypeGroups: CustomFieldGroupsDocumentationTypes[], id: string): DocumentationType {
    let result: DocumentationType = null;
    documentTypeGroups.forEach((d) => {
        d.documentation_types.forEach((type) => {
            if (type.id === id) {
                result = type;
                return;
            }
        });
    });
    return result;
}

export default SupplierDocumentApprovalAndValidity;