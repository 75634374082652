import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AddCommentBody, AddReplyBody, CreateProductionUnitBody, DetailedProductionUnit, DetailedProductionUnitStatus, DisplayCustomFieldGroup, DisplayCustomFieldSupplierDocuments, DisplayDetailedSupplierDocument, DisplayDetailedSupplierDocumentStateEnum, InputCustomFieldDefinition, InputCustomFieldGroups, InputCustomFieldValue, ListableProductionUnit, PaginatedProductionUnits, ProductionUnitFilters, RejectSupplierDocumentBody, SetProductionUnitStatus, SimpleProductionUnit, SupplierDocument, SupplierDocumentExtendedStatusEnum, SupplierServiceHistoryEntry, UpdateProductionUnit, UpdateValidityIntervalBody } from '../../../backend_api/models';
import { request2 } from '../../../base/api';
import { setContextData } from '../../../base/baseSlice';
import { AppState, byId, Context } from '../../../base/types';
import { AppDispatch, useAppDispatch } from '../../../store';
import { catchException } from '../../errorHandling/handler';
import { sendErrorMessage, sendStatusMessage } from '../../messages/actions';
import { unlinkProductionUnitAndSupplier } from '../../supplierAndPuRelations/slice/relationSlice';
import { CustomFieldGroupsDocumentationTypes } from '../../../backend_api/models/CustomFieldGroupsDocumentationTypes';
import { CreateSupplierDocumentOnProductionUnitBody } from '../../../backend_api_2';

const initialState: ProductionUnitsState = {
    /* Production Units that are selectable in a specific supplier context. */
    productionUnitsInSupplierContext: {},

    /* Listable Production Units ( production units that are shown on the Production Unit List page */
    fetchingListableProductionUnits: 0,
    listableProductionUnits: undefined,
    limit: 0,
    offset: 0,
    total: 0,

    /* Selectable Production Units ( shown in dropdowns ) */
    selectableProductionUnits: {
        isFetching: 0,
        /* While undefined, show loading spinner */
        productionUnits: undefined
    },

    /* Detailed Production Unit for the detailed production unit page */
    error: false,
    isFetching: false,
    isFetchingDetailedProductionUnit: false,
    detailedProductionUnit: null,

    /* */
    productionUnitCustomFieldDefinitions: null,
    statusList: [],
    supplierDocumentTypeGroups: [],
    customFieldsList: null,
    historyEntries: [],
    fetchingHistoryEntries: false,
    documentViewer: null,
    showingDocumentViewer: false,

    /* Production Unit Documents */
    productionUnitDocuments: [],
    fetchingProductionUnitDocuments: false
};

export type SelectableProductionUnitsState = {
    isFetching: number;
    /* While undefined, show loading spinner */
    productionUnits: SimpleProductionUnit[] | undefined;
}

export type ProductionUnitInSupplierContextState = {
    isFetching: number;
    productionUnits: ListableProductionUnit[]
}

export type ProductionUnitsState = {

    /* 
     * Keep track of production units that can be listed in specific supplier contexts 
     * Data is from the '/production_units_on_supplier/:supplier_id' endpoint.
     * These production units are used, when org_independent_production_units is disabled.
     */
    productionUnitsInSupplierContext: { [supplierId: string]: ProductionUnitInSupplierContextState }

    /* */
    selectableProductionUnits: SelectableProductionUnitsState;

    /* Keeps track of concurrent requests for the list of production units */
    fetchingListableProductionUnits: number;
    listableProductionUnits: PaginatedProductionUnits;
    limit: number,
    offset: number,
    total: number;

    error: boolean;
    isFetching: boolean;
    isFetchingDetailedProductionUnit: boolean;
    detailedProductionUnit: DetailedProductionUnit;
    productionUnitCustomFieldDefinitions: InputCustomFieldGroups;
    statusList: DetailedProductionUnitStatus[];
    supplierDocumentTypeGroups: CustomFieldGroupsDocumentationTypes[];
    customFieldsList: InputCustomFieldGroups;
    historyEntries: SupplierServiceHistoryEntry[];
    fetchingHistoryEntries: boolean;
    documentViewer: SupplierDocument;
    showingDocumentViewer: boolean;

    /* Production Unit Documents */
    productionUnitDocuments: DisplayDetailedSupplierDocument[];
    fetchingProductionUnitDocuments: boolean;
};


export const fetchSelectableProductionUnits =
    createAsyncThunk<SimpleProductionUnit[]>(
        'fetchSelectableProductionUnits',
        async (params, { dispatch, rejectWithValue, getState }) => {
            return fetchSelectableProductionUnitsInner(
                dispatch,
                getState() as AppState,
                rejectWithValue,
            );
        }
    )


async function fetchSelectableProductionUnitsInner(dispatch, appState: AppState, rejectWithValue): Promise<SimpleProductionUnit[] | undefined> {
    if (appState.app.productionUnits.selectableProductionUnits.isFetching > 1) {
        /* already fetching */
        return;
    }

    const url = 'selectable_production_units';
    const options = { method: 'GET' }
    const rq = await request2(url, options)

    if (!rq.ok) {
        console.log('Error when fetching selectable production units', rq.statusText)
        dispatch(sendErrorMessage(['error_message.production_units.list_selectable_production_units'], 3000))
        catchException(
            'fetchSelectableProductionUnits',
            {
                endpoint: url,
                request: url,
                status: rq.status
            },
            {
                rq: rq
            }
        )
        return rejectWithValue(rq as Response)
    } else {
        return await rq.json() as SimpleProductionUnit[];
    }

}

export const fetchProductionUnitsInSupplierContext =
    createAsyncThunk<ListableProductionUnit[], { supplier_id: string }>(
        'fetchProductionUnitsInSupplierContext',
        async (params, { dispatch, rejectWithValue, getState }) => {
            return fetchProductionUnitInContext(
                dispatch,
                rejectWithValue,
                getState() as AppState,
                params.supplier_id
            );
        }
    )

async function fetchProductionUnitInContext(
    dispatch: AppDispatch,
    rejectWithValue,
    state: AppState,
    supplierId: string
) {
    if (state.app.productionUnits.productionUnitsInSupplierContext[supplierId]) {
        const context = state.app.productionUnits.productionUnitsInSupplierContext[supplierId]
        /* already fetching */
        if (context.isFetching > 1) {
            return undefined;
        }
    }

    const url = `production_units_on_supplier/${supplierId}`;
    const options = { method: 'GET' }
    const rq = await request2(url, options)

    if (!rq.ok) {
        console.log('Error when listing production units in supplier context', rq.statusText)
        dispatch(sendErrorMessage(['error_message.production_units.list_for_supper_context'], 3000))
        catchException(
            'fetchProductionUnitsInSupplierContext',
            {
                endpoint: 'production_units_on_supplier/[supplier_id]',
                request: url,
                status: rq.status
            },
            {
                rq: rq,
                params: { supplierId: supplierId }
            }
        )
        return rejectWithValue(rq as Response)
    } else {
        const detailedPU = await rq.json() as ListableProductionUnit[];
        return detailedPU
    }
}

export const createProductionUnitWithoutSupplier = createAsyncThunk<DetailedProductionUnit, CreateProductionUnitBody>(
    'createProductionUnitWithoutSupplier',
    async (params, { dispatch, rejectWithValue }) => {
        return requestCreateProductionUnitWitoutSupplier(params, dispatch, rejectWithValue);
    }
)

export const createProductionUnitWithSupplier = createAsyncThunk<DetailedProductionUnit, { supplier_id: string } & CreateProductionUnitBody>(
    'createProductionUnitWithSupplier',
    async (params, { dispatch, rejectWithValue }) => {
        return requestCreateProductionUnitWithSupplier(params, dispatch, rejectWithValue);
    }
)

async function requestCreateProductionUnitWitoutSupplier(
    params: CreateProductionUnitBody,
    dispatch: AppDispatch,
    rejectWithValue
): Promise<DetailedProductionUnit> {
    const url = 'production_units/create_production_unit_without_supplier/'
    const options = {
        method: 'POST',
        body: JSON.stringify(params)
    }
    const rq = await request2(url, options)

    if (!rq.ok) {
        console.log('Error when creating a production unit without supplier', rq.statusText)
        dispatch(sendErrorMessage(['error_message.production_unit.create_without_supplier'], 3000))
        catchException(
            'createProductionUnitWithoutSupplier',
            {
                endpoint: url,
                request: url,
                status: rq.status
            },
            {
                rq,
                params
            }
        )
        return rejectWithValue(rq as Response)
    } else {
        const detailedPU = await rq.json() as DetailedProductionUnit;
        return detailedPU
    }
}

async function requestCreateProductionUnitWithSupplier(
    params: CreateProductionUnitBody & { supplier_id: string },
    dispatch: AppDispatch,
    rejectWithValue
): Promise<DetailedProductionUnit> {
    const url = `production_units/create_production_unit_on_supplier/${params.supplier_id}`
    const options = {
        method: 'POST',
        /* Removed the supplier_id field from the params. JSON does not represent undefined */
        body: JSON.stringify({ ...params, supplier_id: undefined })
    }
    const rq = await request2(url, options)

    if (!rq.ok) {
        console.log('Error when creating a production unit with supplier', rq.statusText)
        dispatch(sendErrorMessage(['error_message.production_unit.create_without_supplier'], 3000))
        catchException(
            'createProductionUnitWithSupplier',
            {
                endpoint: 'production_units/create_production_unit_on_supplier/[supplier_id]',
                request: url,
                status: rq.status
            },
            {
                rq,
                params
            }
        )
        return rejectWithValue(rq as Response)
    } else {
        const detailedPU = await rq.json() as DetailedProductionUnit;
        return detailedPU
    }
}

export const deleteProductionUnit = createAsyncThunk<void, { production_unit_id: string }>(
    'deleteProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        return requestDeleteProductionUnit(params, dispatch, rejectWithValue)
    }
)

async function requestDeleteProductionUnit(
    params: { production_unit_id: string },
    dispatch: AppDispatch,
    rejectWithValue
): Promise<void> {
    const url = `production_units/${params.production_unit_id}/delete`;
    const options = {
        method: 'DELETE',
        body: ''
    }

    const rq = await request2(url, options)

    if (!rq.ok) {
        console.log('Error when deleting a production unit')
        dispatch(sendErrorMessage(['error_message.production_unit.delete_production_unit']))
        catchException(
            'deleteProductionUnit',
            {
                endpoint: '/production_units/[params.production_unit_id]/delete',
                request: url,
                status: rq.status
            },
            { rq, params }
        )
        return rejectWithValue(rq as Response)
    } else {
        return undefined;
    }
}

type ListableProductionUnitsParams = {
    limit?: number;
    offset?: number;
    search?: string;
}
export const getListableProductionUnits = createAsyncThunk<PaginatedProductionUnits | undefined, ProductionUnitFilters>(
    'getListableProductionUnits',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const state = (getState() as AppState).app.productionUnits
        /* Stop if there are simultaneous requests */
        if (state.fetchingListableProductionUnits > 1) {
            return undefined;
        }
        const url = 'production_units';
        const rq = await request2(url, { method: 'post', body: JSON.stringify({ search: params.search || null, pagination: params.pagination, custom_fields: params.custom_fields, status: params.status }) });
        if (!rq.ok) {
            console.log('getting production units list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_productions_list_failed'], 3000));
            catchException('getListableProductionUnits', {
                endpoint: 'production_units',
                request: 'production_units',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        const productionUnits = await rq.json();
        dispatch(setContextData({ context: Context.ProductionUnits, metaData: { list: { total: productionUnits.pagination.total } } }));
        return productionUnits;
    });

export const getDetailedProductionUnit = createAsyncThunk<DetailedProductionUnit, { id: string }>(
    'getDetailedProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'detailed_production_unit/' + params.id;
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting the production unit list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_production_unit_failed']));
            catchException('getDetailedProductionUnit', {
                endpoint: 'production_units/:id',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const getProductionUnitHistory = createAsyncThunk<SupplierServiceHistoryEntry[], { id: string }>(
    'getProductionUnitHistory',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'production_units/' + params.id + '/get_production_unit_history';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting the production unit list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_production_unit_failed']));
            catchException('getProductionUnitHistory', {
                endpoint: 'production_units/' + params.id + '/get_production_unit_history',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const getProductionUnitCustomFieldDefinitions = createAsyncThunk<InputCustomFieldGroups>(
    'getProductionUnitCustomFieldDefinitions',
    async (_, { dispatch, rejectWithValue }) => {
        const url = 'production_unit_custom_fields';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting the production unit custom fields definitions list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_production_unit_custom_field_definitions_failed'], 3000));
            catchException('getProductionUnitCustomFieldDefinitions', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const setProductionUnitCustomFieldValue = createAsyncThunk<DetailedProductionUnit, { productionUnitId, customFieldDefinitionId, cf: InputCustomFieldValue }>(
    'setProductionUnitCustomFieldValue',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'production_units/' + params.productionUnitId + '/set_custom_field_value/' + params.customFieldDefinitionId;
        const rq = await request2(url, { body: JSON.stringify(params.cf), method: 'post' });
        if (!rq.ok) {
            console.log('Setting the production unit custom field value did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.setting_production_unit_custom_field_value_failed'], 3000));
            catchException('getProductionUnitCustomFieldDefinitions', {
                endpoint: 'production_units/:productionUnitId/set_custom_field_value/:customFieldDefinitionId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.setting_production_unit_custom_field_value_was_successful'], 3000));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
        return await rq.json();
    });
export const addGroupToProductionUnit = createAsyncThunk<DetailedProductionUnit, { productionUnitId: string, groupId: string }>(
    'addGroupToProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'production_units/' + params.productionUnitId + '/add_group_to_production_unit/' + params.groupId;
        const rq = await request2(url, { method: 'post' });
        if (!rq.ok) {
            console.log('getting the production unit custom fields definitions list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.adding_production_unit_group_failed"'], 3000));
            catchException('getProductionUnitCustomFieldDefinitions', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.setting_production_unit_group_was_successful'], 3000));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
        return await rq.json();
    });
export const removeGroupFromProductionUnit = createAsyncThunk<DetailedProductionUnit, { productionUnitId: string, groupId: string }>(
    'removeGroupFromProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'production_units/' + params.productionUnitId + '/remove_group_from_production_unit/' + params.groupId;
        const rq = await request2(url, { method: 'post' });
        if (!rq.ok) {
            console.log('getting the production unit custom fields definitions list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.removing_production_unit_group_failed"'], 3000));
            catchException('getProductionUnitCustomFieldDefinitions', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.removing_production_unit_group_was_successful'], 3000));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
        return await rq.json();
    });

export const addUpstreamProductionUnit = createAsyncThunk<null, { type: 'upstream' | 'downstream', productionUnitId: string, upstreamProductionUnitId: string }>(
    'addUpstreamProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const isUpstream = params.type === 'upstream';
        const productionUnitId = isUpstream ? params.productionUnitId : params.upstreamProductionUnitId;
        const upstreamProductionUnitId = isUpstream ? params.upstreamProductionUnitId : params.productionUnitId;
        const url = 'production_units/' + productionUnitId + '/add_upstream_production_unit/';
        const rq = await request2(url, { method: 'post', body: JSON.stringify({ upstream_production_unit_id: upstreamProductionUnitId }) });
        if (!rq.ok) {
            if (isUpstream) {
                console.log('Adding the upstream production unit did not go ok...', rq.statusText)
                dispatch(sendErrorMessage(['error_message.setting_upstream_production_unit_failed'], 3000));

            } else {
                console.log('Adding the downstream production unit did not go ok...', rq.statusText);
                dispatch(sendErrorMessage(['error_message.setting_downstream_production_unit_failed'], 3000));

            }
            catchException('addUpstreamProductionUnit', {
                endpoint: 'production_units/:productionUnitId/add_upstream_production_unit/',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage([isUpstream ? 'status_message.setting_upstream_production_unit_was_succesful' : 'status_message.setting_downstream_production_unit_was_succesful'], 3000));
        dispatch(getDetailedProductionUnit({ id: params.productionUnitId }));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
    });

export const removeUpstreamProductionUnit = createAsyncThunk<null, { type: 'upstream' | 'downstream', productionUnitId: string, upstreamProductionUnitId: string }>(
    'removeUpstreamProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const isUpstream = params.type === 'upstream';
        const productionUnitId = isUpstream ? params.productionUnitId : params.upstreamProductionUnitId;
        const upstreamProductionUnitId = isUpstream ? params.upstreamProductionUnitId : params.productionUnitId;

        const url = 'production_units/' + productionUnitId + '/remove_upstream_production_unit/';
        const rq = await request2(url, { method: 'post', body: JSON.stringify({ upstream_production_unit_id: upstreamProductionUnitId }) });
        if (!rq.ok) {
            if (isUpstream) {
                console.log('Removing the upstream production unit did not go ok...', rq.statusText);
                dispatch(sendErrorMessage(['error_message.removing_upstream_production_unit_failed'], 3000));
            } else {
                console.log('Removing the downstream production unit did not go ok...', rq.statusText);
                dispatch(sendErrorMessage(['error_message.removing_downstream_production_unit_failed'], 3000));
            }
            catchException('removeUpstreamProductionUnit', {
                endpoint: 'production_units/:productionUnitId/remove_upstream_production_unit/',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage([isUpstream ? 'status_message.removing_upstream_production_unit_was_succesful' : 'status_message.removing_downstream_production_unit_was_succesful'], 3000));
        dispatch(getDetailedProductionUnit({ id: params.productionUnitId }));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
    });

export const getProductionUnitStatusList = createAsyncThunk<DetailedProductionUnitStatus[]>(
    'getProductionUnitStatusList',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'list_production_unit_statuses';
        const rq = await request2(url);
        if (!rq.ok) {
            console.log('Getting production unit statuses did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_production_unit_statuses_failed'], 3000));
            catchException('getProductionUnitStatusList', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const getSupplierDocumentTypeGroups = createAsyncThunk<CustomFieldGroupsDocumentationTypes[]>(
    'getSupplierDocumentTypeGroups',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'list_custom_field_groups_documentation_types';
        const rq = await request2(url);
        if (!rq.ok) {
            console.log('Getting document type groups did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_document_type_groups_failed'], 3000));
            catchException('getSupplierDocumentTypeGroups', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const setProductionUnitStatus = createAsyncThunk<DetailedProductionUnit, { productionUnitId: string, status: SetProductionUnitStatus }>(
    'setProductionUnitStatus',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'production_units/' + params.productionUnitId + '/set_production_unit_status';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(params.status) });
        if (!rq.ok) {
            console.log('Setting production unit statuse did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.setting_production_unit_status_failed'], 5000));
            catchException('getProductionUnitStatusList', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.setting_production_unit_status_was_succesful'], 3000));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
        return await rq.json();
    });

export const updateProductionUnitInfo = createAsyncThunk<DetailedProductionUnit, { productionUnitId: string, info: UpdateProductionUnit }>(
    'updateProductionUnitInfo',
    async (params, { dispatch, rejectWithValue }) => {
        const ps = {
            /* HACKS until endpoints are cleaned up*/
            address: [],
            contact_person: '',

            /* Actual content*/
            name: params.info.name,
            number: params.info.number,
            location: params.info.location
        }

        const url = 'production_units/' + params.productionUnitId + '/update_production_unit';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(ps) });
        if (!rq.ok) {
            const responseJSON = await rq.json();
            console.log('Updating production unit info did not go ok...', responseJSON);
            if (responseJSON.error.detail === 'production_unit_with_name_and_number_already_exists') {
                dispatch(sendErrorMessage(['error_message.updating_production_unit_info_failed.production_unit_with_name_and_number_already_exists'], 10000));
            } else {
                dispatch(sendErrorMessage(['error_message.updating_production_unit_info_failed'], 5000));
            }
            catchException('getProductionUnitStatusList', {
                endpoint: 'production_units/:productionUnitId/update_production_unit',
                request: url,
                status: rq.status,
            }, { error: responseJSON.error });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
            dispatch(sendStatusMessage(['status_message.updating_production_unit_info_was_succesful'], 3000));
            return await rq.json();
        }

    });

export const updateProductionUnitAddressContact = createAsyncThunk<DetailedProductionUnit, { productionUnitId: string, info: UpdateProductionUnit }>(
    'updateProductionUnitAddressContact',
    async (params, { dispatch, rejectWithValue }) => {

        const ps = {
            /* HACKS until the endpoints are cleaned up! */
            name: '',
            number: '',
            location: null,
            /* Actual content */
            address: params.info.address,
            contact_person: params.info.contact_person
        }

        const url = 'production_units/' + params.productionUnitId + '/update_production_unit_address_contact';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(ps) });
        if (!rq.ok) {
            console.log('Updating production unit info did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.updating_production_unit_info_failed'], 5000));
            catchException('getProductionUnitStatusList', {
                endpoint: 'production_units/:productionUnitId/update_production_unit_address_contact',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.updating_production_unit_info_was_succesful'], 3000));
        dispatch(getProductionUnitHistory({ id: params.productionUnitId }));
        return await rq.json();
    });

export const getProductionUnitsCustomFieldsList = createAsyncThunk<InputCustomFieldGroups>(
    'getProductionUnitsCustomFieldsList',
    async (_, { dispatch, rejectWithValue }) => {
        const url = 'production_unit_custom_fields';
        const rq = await request2(url, {});
        if (!rq.ok) {
            console.log('Getting the PU custom fields list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_pu_custom_field_list_failed'], 3000));
            catchException('getProductionUnitsCustomFieldsList', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        // return scf_data;
        return await rq.json();
    });

export const addCommentToProductionUnit = createAsyncThunk<string, AddCommentBody>(
    'addCommentToProductionUnit',
    async (commentBody: AddCommentBody, { dispatch, rejectWithValue }) => {
        const rq = await request2('comments/add_comment', { method: 'post', body: JSON.stringify(commentBody) });
        if (!rq.ok) {
            console.log('Adding comment to the production unit does not succeed...');
            dispatch(sendErrorMessage(['error_message.adding_pu_comment_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getProductionUnitHistory({ id: commentBody.entity_id }));
        const data = await rq.json()
        return data;
    });

export type AddReplyToProductionUnitCommentConfig = {
    productionUnitId: string,
    threadId: string,
    reply: AddReplyBody
}
export const replyToProductionUnitCommentThread = createAsyncThunk<string, AddReplyToProductionUnitCommentConfig>(
    'replyToProductionUnitCommentThread',
    async (replyBody: AddReplyToProductionUnitCommentConfig, { dispatch, rejectWithValue }) => {
        const rq = await request2(`comments/${replyBody.threadId}/add_reply`, { method: 'post', body: JSON.stringify(replyBody.reply) });
        if (!rq.ok) {
            console.log('Adding reply to the comment thread does not succeed...');
            dispatch(sendErrorMessage(['error_message.adding_reply_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getProductionUnitHistory({ id: replyBody.productionUnitId }));
        const data = await rq.json()
        return data;
    });

export const createSupplierDocument = createAsyncThunk<SupplierDocument, CreateSupplierDocumentOnProductionUnitBody>(
    'createSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/create_supplier_document_on_production_unit`, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.adding_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        // dispatch(getDetailedProductionUnit({ id: params.production_unit_id }));
        return data;
    }
);

export const fetchSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'fetchSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}`, { method: 'GET' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.fetching_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const submitForApproval = createAsyncThunk<SupplierDocument, { id: string }>(
    'submitForApproval',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/submit_for_approval`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.submitting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const approveSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'approveSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/approve`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.approving_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const activateSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'activateSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/activate`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.activating_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const rejectSupplierDocument = createAsyncThunk<SupplierDocument, { id: string, body: RejectSupplierDocumentBody }>(
    'rejectSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/reject`, { method: 'PUT', body: JSON.stringify(params.body) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.rejecting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const resetSupplierDocumentStatus = createAsyncThunk<SupplierDocument, { id: string }>(
    'resetSupplierDocumentStatus',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/reset_status`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.resetting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const unsubmitSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'unsubmitSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/unsubmit`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.unsubmitting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const updateValidityInterval = createAsyncThunk<SupplierDocument, { id: string, interval: UpdateValidityIntervalBody }>(
    'updateValidityInterval',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/update_validity_interval`, { method: 'PUT', body: JSON.stringify(params.interval) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.updating_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const archiveSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'archiveSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/archive`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.archiving_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const unarchiveSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'unarchiveSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/unarchive`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.unarchiving_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const deleteSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'deleteSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}`, { method: 'DELETE' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.deleting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const getAllDocumentsForProductionUnit = createAsyncThunk<DisplayDetailedSupplierDocument[], { production_unit_id: string }>(
    'getAllDocumentsForProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = `supplier_documents/get_all_for_production_unit/${params.production_unit_id}`;
        const rq = await request2(url, { method: 'get' });

        if (!rq.ok) {
            console.log('There was an error getting documents for production unit');
            dispatch(sendErrorMessage(['error_message.production_units.get_all_documents'], 3000));
            return rejectWithValue(rq as Response);
        }

        const data = await rq.json();
        return data as DisplayDetailedSupplierDocument[];
    }
);

export const productionUnitsSlice = createSlice({
    name: 'productionUnits',
    initialState,
    reducers: {
        addGroupToDetailedProductionUnit: (
            state: ProductionUnitsState,
            action: PayloadAction<{ productionUnitId: string, groupId: string }>) => {
            if (state.detailedProductionUnit && state.detailedProductionUnit.id == action.payload.productionUnitId) {
                state.detailedProductionUnit.group_ids.unshift(action.payload.groupId)
            }
        },
        removeGroupFromDetailedProductionUnit: (
            state: ProductionUnitsState,
            action: PayloadAction<{ productionUnitId: string, groupId: string }>) => {
            if (state.detailedProductionUnit && state.detailedProductionUnit.id == action.payload.productionUnitId) {
                state.detailedProductionUnit.group_ids = state.detailedProductionUnit.group_ids.filter(k => k !== action.payload.groupId)
            }
        },
        resetDetailedProductionUnit: (state) => {
            state.detailedProductionUnit = undefined;
            state.historyEntries = undefined;
        },
        showCustomFieldDocumentViewer: (
            state: ProductionUnitsState,
            action: PayloadAction<{ supplierDocument: SupplierDocument }>) => {
            state.documentViewer = action.payload.supplierDocument;
            state.showingDocumentViewer = true;
        },
        hideDocumentViewer: (state) => {
            state.showingDocumentViewer = false;
        }
    },
    extraReducers: builder => {

        /* Selectable production units */
        builder.addCase(fetchSelectableProductionUnits.pending, (state) => {
            state.selectableProductionUnits.isFetching += 1;
        });

        builder.addCase(fetchSelectableProductionUnits.rejected, (state) => {
            state.selectableProductionUnits.isFetching -= 1;
        });

        builder.addCase(fetchSelectableProductionUnits.fulfilled, (state, action) => {
            state.selectableProductionUnits.isFetching -= 1;

            if (action.payload) {
                state.selectableProductionUnits.productionUnits = action.payload;
            }
        });

        /* Production units in supplier context */
        builder.addCase(fetchProductionUnitsInSupplierContext.pending, (state, action) => {
            const supplierId = action.meta.arg.supplier_id;
            let context = state.productionUnitsInSupplierContext[supplierId];

            if (!context) {
                context = {
                    isFetching: 0,
                    productionUnits: undefined
                };
                state.productionUnitsInSupplierContext[supplierId] = context;
            }

            context.isFetching += 1;
        });

        builder.addCase(fetchProductionUnitsInSupplierContext.rejected, (state, action) => {
            const supplierId = action.meta.arg.supplier_id;
            const context = state.productionUnitsInSupplierContext[supplierId];
            context.isFetching -= 1;
        });

        builder.addCase(fetchProductionUnitsInSupplierContext.fulfilled, (state, action) => {
            const supplierId = action.meta.arg.supplier_id;
            const context = state.productionUnitsInSupplierContext[supplierId];
            context.isFetching -= 1;

            if (action.payload) {
                context.productionUnits = action.payload
            }
        });

        /* */
        builder.addCase(createProductionUnitWithoutSupplier.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(createProductionUnitWithoutSupplier.rejected, (state) => {
            state.isFetching = false;
        })
        builder.addCase(createProductionUnitWithoutSupplier.fulfilled, (state, action) => {
            const detailedProductionUnit = action.payload

            if (state.selectableProductionUnits) {
                state.selectableProductionUnits.productionUnits.push({
                    id: detailedProductionUnit.id,
                    name: detailedProductionUnit.name,
                    number: detailedProductionUnit.number
                })
            }

            state.listableProductionUnits.production_units.push({
                id: detailedProductionUnit.id,
                name: detailedProductionUnit.name,
                number: detailedProductionUnit.number,
                status: detailedProductionUnit.status,
                supplier_documents: []
            })
        })

        /*! Linked to another slice ! */
        builder.addCase(unlinkProductionUnitAndSupplier.pending, (state, action) => {
            /* Show the edit production unit page as loading */
            if (action.meta.arg.production_unit_id && state.detailedProductionUnit && action.meta.arg.production_unit_id === state.detailedProductionUnit.id) {
                state.isFetchingDetailedProductionUnit = true;
            }
        })
        builder.addCase(unlinkProductionUnitAndSupplier.rejected, (state, action) => {
            if (action.meta.arg.production_unit_id && state.detailedProductionUnit && action.meta.arg.production_unit_id === state.detailedProductionUnit.id) {
                state.isFetchingDetailedProductionUnit = false;
            }
        })
        builder.addCase(unlinkProductionUnitAndSupplier.fulfilled, (state, action) => {
            if (action.payload && action.payload.productionUnitId && action.payload.supplierId) {
                const puId = action.payload.productionUnitId
                const supplierId = action.payload.supplierId

                /* Remove the supplier from the visible production unit, if applicable. */
                if (puId === state.detailedProductionUnit.id) {
                    state.isFetchingDetailedProductionUnit = false;
                    state.detailedProductionUnit.related_suppliers = state.detailedProductionUnit.related_suppliers.filter((supplier) => supplier.id !== supplierId)
                }
            }
        })

        builder.addCase(deleteProductionUnit.pending, state => {
            state.isFetching = true
        })

        builder.addCase(deleteProductionUnit.rejected, state => { state.isFetching = false })

        builder.addCase(deleteProductionUnit.fulfilled, (state, action) => {
            state.isFetching = false
            const deletedProductionUnitId = action.meta.arg.production_unit_id

            if (state.selectableProductionUnits && state.selectableProductionUnits.productionUnits) {
                state.selectableProductionUnits.productionUnits =
                    state.selectableProductionUnits.productionUnits.filter(p => p.id !== deletedProductionUnitId)
            }


            if (state.productionUnitsInSupplierContext) {
                const keys = Object.keys(state.productionUnitsInSupplierContext)
                keys.forEach(k => {
                    const t = state.productionUnitsInSupplierContext[k]
                    t.productionUnits =
                        t.productionUnits
                        && t.productionUnits.filter(p => p.id !== deletedProductionUnitId);
                })
            }

            if (state.listableProductionUnits) {
                state.listableProductionUnits.production_units =
                    state.listableProductionUnits
                    && state.listableProductionUnits.production_units.filter(p => p.id !== deletedProductionUnitId)
            }
        })

        builder.addCase(createProductionUnitWithSupplier.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(createProductionUnitWithSupplier.rejected, (state) => {
            state.isFetching = false;
        })
        builder.addCase(createProductionUnitWithSupplier.fulfilled, (state, action) => {
            const detailedProductionUnit = action.payload
            state.isFetching = false;

            if (state.selectableProductionUnits) {
                state.selectableProductionUnits.productionUnits.push({
                    id: detailedProductionUnit.id,
                    name: detailedProductionUnit.name,
                    number: detailedProductionUnit.number
                })
            }

            if (state.productionUnitsInSupplierContext[action.meta.arg.supplier_id]) {
                const t = state.productionUnitsInSupplierContext[action.meta.arg.supplier_id]
                if (t.productionUnits) {
                    t.productionUnits.push({
                        id: detailedProductionUnit.id,
                        name: detailedProductionUnit.name,
                        number: detailedProductionUnit.number
                    } as ListableProductionUnit)
                }
            }

            state.listableProductionUnits.production_units.push({
                id: detailedProductionUnit.id,
                name: detailedProductionUnit.name,
                number: detailedProductionUnit.number,
                status: detailedProductionUnit.status,
                supplier_documents: []
            })
        })

        builder.addCase(getProductionUnitCustomFieldDefinitions.pending, (state) => { state.isFetching = true; });
        builder.addCase(getProductionUnitCustomFieldDefinitions.fulfilled, (state, action) => {
            state.productionUnitCustomFieldDefinitions = action.payload;
            state.isFetching = false;
        });

        builder.addCase(addUpstreamProductionUnit.fulfilled, (state, action) => {
            // state.detailedProductionUnit = action.payload; 
        });
        builder.addCase(removeUpstreamProductionUnit.fulfilled, (state, action) => {
            // state.detailedProductionUnit = action.payload;
        });
        builder.addCase(fetchSupplierDocument.fulfilled, (state, action) => {
            state.documentViewer = action.payload;
            state.showingDocumentViewer = true;
        });
        builder.addCase(approveSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(rejectSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(resetSupplierDocumentStatus.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });

        builder.addCase(unsubmitSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(submitForApproval.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(updateValidityInterval.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
                state.documentViewer.start_date = action.payload.start_date;
                state.documentViewer.expiry_date = action.payload.expiry_date;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                console.log('updateValidityInterval', action.payload)
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(archiveSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = deleteSupplierDocumentFromProductionUnit(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(unarchiveSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(deleteSupplierDocument.fulfilled, (state, action) => {
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = deleteSupplierDocumentFromProductionUnit(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(activateSupplierDocument.fulfilled, (state, action) => {
            state.documentViewer.extended_status = action.payload.extended_status;
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = updateSupplierDocumentState(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });
        builder.addCase(createSupplierDocument.fulfilled, (state, action) => {
            state.documentViewer = action.payload;
            state.showingDocumentViewer = true;
            if (state.detailedProductionUnit?.custom_field_groups?.length > 0) {
                const updatedCustomFieldGroups = addNewSupplierDocumentToProductionUnit(action.payload, state.detailedProductionUnit.custom_field_groups);
                state.detailedProductionUnit.custom_field_groups = updatedCustomFieldGroups;
            }
        });

        builder.addCase(fetchSupplierDocument.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getDetailedProductionUnit.rejected, (state) => {
            state.isFetchingDetailedProductionUnit = false;
        });
        builder.addCase(getDetailedProductionUnit.pending, (state) => {
            state.isFetching = true;
            state.isFetchingDetailedProductionUnit = true;
        });
        builder.addCase(getDetailedProductionUnit.fulfilled, (state, action) => {
            state.detailedProductionUnit = action.payload;
            state.isFetching = false;
            state.isFetchingDetailedProductionUnit = false;
        });
        builder.addCase(setProductionUnitCustomFieldValue.fulfilled, (state, action) => {
            state.detailedProductionUnit = action.payload;
        });
        /* getProductionUnitsList */
        builder.addCase(getListableProductionUnits.pending, (state) => {
            state.fetchingListableProductionUnits += 1;
            state.isFetching = true;
        });
        builder.addCase(getListableProductionUnits.rejected, (state) => {
            state.fetchingListableProductionUnits -= 1;
            state.error = true;
        });
        builder.addCase(getListableProductionUnits.fulfilled, (state, action) => {
            state.fetchingListableProductionUnits -= 1;
            /* If the payload is undefined, the actions was stopped because another request is in progress */
            if (action.payload !== undefined) {
                const limit = action.meta.arg.pagination && action.meta.arg.pagination.limit || null;
                const offset = action.meta.arg.pagination && action.meta.arg.pagination.offset || null;
                state.limit = limit;
                state.offset = offset;
                if (offset && offset > 0) {
                    state.listableProductionUnits.production_units = state.listableProductionUnits.production_units.concat(action.payload.production_units);
                    state.listableProductionUnits.pagination = action.payload.pagination;
                } else {
                    state.listableProductionUnits = action.payload;
                }
                state.isFetching = false;
            }
        });
        builder.addCase(getProductionUnitsCustomFieldsList.fulfilled, (state, action) => {
            state.customFieldsList = action.payload;
        });
        /* */
        builder.addCase(getProductionUnitStatusList.fulfilled, (state, action) => { state.statusList = action.payload });
        builder.addCase(getSupplierDocumentTypeGroups.fulfilled, (state, action) => { state.supplierDocumentTypeGroups = action.payload });
        builder.addCase(setProductionUnitStatus.fulfilled, (state, action) => {
            if (state.detailedProductionUnit && action.meta.arg && state.detailedProductionUnit.id === action.meta.arg.productionUnitId) {
                state.detailedProductionUnit = action.payload
            }
        });
        builder.addCase(addGroupToProductionUnit.fulfilled, (state, action) => {
            if (state.detailedProductionUnit && action.meta.arg && state.detailedProductionUnit.id === action.meta.arg.productionUnitId) {
                state.detailedProductionUnit = action.payload
            }
        });
        builder.addCase(removeGroupFromProductionUnit.fulfilled, (state, action) => {
            if (state.detailedProductionUnit && action.meta.arg && state.detailedProductionUnit.id === action.meta.arg.productionUnitId) {
                state.detailedProductionUnit = action.payload
            }
        });
        builder.addCase(updateProductionUnitInfo.fulfilled, (state, action) => {
            state.detailedProductionUnit = action.payload;
        });
        builder.addCase(getProductionUnitHistory.pending, (state, action) => {
            state.fetchingHistoryEntries = true;
        });
        builder.addCase(getProductionUnitHistory.rejected, (state, action) => {
            state.fetchingHistoryEntries = false;
        });
        builder.addCase(getProductionUnitHistory.fulfilled, (state, action) => {
            state.fetchingHistoryEntries = false;
            state.historyEntries = action.payload;
        });

        /* Handle getAllDocumentsForProductionUnit */
        builder.addCase(getAllDocumentsForProductionUnit.pending, (state) => {
            state.fetchingProductionUnitDocuments = true;
        });
        builder.addCase(getAllDocumentsForProductionUnit.fulfilled, (state, action) => {
            state.productionUnitDocuments = action.payload;
            state.fetchingProductionUnitDocuments = false;
        });
        builder.addCase(getAllDocumentsForProductionUnit.rejected, (state) => {
            state.fetchingProductionUnitDocuments = false;
        });
    },
});

export const getProductionUnitStatusListSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.statusList],
    (statusList) => statusList,
);

export const getSupplierDocumentTypeGroupsSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.supplierDocumentTypeGroups],
    (groups) => groups,
);

export const getProductionUnitCustomFieldDefinitionsSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.productionUnitCustomFieldDefinitions],
    (cf) => cf,
);
export const getDetailedProductionUnitListSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.detailedProductionUnit],
    (d) => d,
);
export const getListableProductionUnitsSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.listableProductionUnits && state.app.productionUnits.listableProductionUnits.production_units || []],
    (pu) => pu,
);
export const getPaginatedListableProductionUnitsSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.listableProductionUnits && state.app.productionUnits.listableProductionUnits],
    (pu) => pu,
);
export const getDetailedProductionUnitLoadingSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.isFetchingDetailedProductionUnit],
    (isFetching) => isFetching,
)

export const getProductionUnitPageLoadingSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.isFetching],
    (isFetching) => isFetching,
)

export const getProductionUnitHistoryLoadingSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.fetchingHistoryEntries],
    (isFetching) => isFetching,
)
export const getProductionUnitsListLoadingSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.fetchingListableProductionUnits],
    (number) => number > 0,
)
export const getProductionUnitsListSelector2 = createSelector(
    [(state: AppState): ProductionUnitsState => {
        return !state.app.productionUnits ? initialState : { ...state.app.productionUnits };
    }],
    (data) => data,
);

export const getProductionUnitsInSupplierContext = createSelector(
    [(state: AppState) => {
        return state.app.productionUnits.productionUnitsInSupplierContext
    }],
    (data) => data
)

export const getSelectableProductionUnitsSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.selectableProductionUnits],
    (data) => data
)
export const getProductionUnitsCustomFieldsListSelector = createSelector(
    [(state: AppState) => state.app.productionUnits.customFieldsList],
    (data) => data
)
export const hasProductionUnitsCustomFieldsSelector = createSelector(
    [(state: AppState): boolean => state.app.productionUnits.customFieldsList && state.app.productionUnits.customFieldsList.groups.length > 0 || false],
    (hasData) => hasData
)

export const getProductionUnitHistorySelector = createSelector(
    [(state: AppState): SupplierServiceHistoryEntry[] => state.app.productionUnits.historyEntries],
    (history) => history
)

export const getProductionUnitDocumentsSelector = createSelector(
    [(state: AppState): DisplayDetailedSupplierDocument[] => state.app.productionUnits.productionUnitDocuments],
    (documents) => documents
);

export const getProductionUnitDocumentsFetchingSelector = createSelector(
    [(state: AppState): boolean => state.app.productionUnits.fetchingProductionUnitDocuments],
    (isFetching) => isFetching
);

export const getProductionUnitViewingDocumentSelector = createSelector(
    [(state: AppState): SupplierDocument | null => state.app.productionUnits.documentViewer],
    (documentViewer) => documentViewer
)

export const getProductionUnitDocumentViewerVisibilitySelector = createSelector(
    [(state: AppState): boolean => state.app.productionUnits.showingDocumentViewer],
    (showingDocumentViewer) => showingDocumentViewer
)

export const getProductionUnitCustomFieldsListGroupEntriesByIdSelector = createSelector(
    [(state: AppState): byId<InputCustomFieldDefinition> => {
        const ret = {};
        const customFieldsList = state.app.productionUnits.customFieldsList;
        customFieldsList && customFieldsList.groups && customFieldsList.groups.map((g) => Object.fromEntries((g.custom_field_definitions).map((gh) => {
            ret[gh.id] = gh;
            return [gh.id, gh];
        })))
        return ret;
    }],
    (customFieldsList) => customFieldsList,
);


export function useSelectableProductionUnits(): SimpleProductionUnit[] | 'loading' {
    const dispatch = useAppDispatch();
    const data = useSelector(getSelectableProductionUnitsSelector)
    useEffect(() => {
        dispatch(fetchSelectableProductionUnits());
    }, [])

    if (data.productionUnits !== undefined) {
        return data.productionUnits;
    } else {
        return 'loading';
    }
}

/** This hook return the same as useSelectableProductionUnits, but it adds the fallback PU to the result, if that production unit is not represented in the list. */
export function useSelectableProductionUnitsWithFallback(fallback: SimpleProductionUnit | undefined): SimpleProductionUnit[] | 'loading' {
    const sourceData = useSelectableProductionUnits();

    return useMemo(() => {

        if (sourceData === 'loading') {
            return 'loading'
        }

        if (!fallback) {
            return sourceData;
        }

        if (sourceData.find(p => p.id === fallback.id)) {
            return sourceData
        }

        return sourceData.concat(fallback);

    }, [sourceData, fallback]);

}

function updateSupplierDocumentState(newDocument: SupplierDocument, customFieldGroups: DisplayCustomFieldGroup[]): DisplayCustomFieldGroup[] {
    const updatedGroups = customFieldGroups.map(group => {
        if (group.documentation_types?.find(docType => docType.detailed_supplier_documents.find(doc => doc.id === newDocument.supplier_document_id))) {
            return {
                ...group,
                documentation_types: group.documentation_types.map(docType => {
                    if (docType.id === newDocument.documentation_type_id) {
                        return {
                            ...docType,
                            detailed_supplier_documents: docType.detailed_supplier_documents?.map(doc => {
                                if (doc.id === newDocument.supplier_document_id) {
                                    return {
                                        ...doc,
                                        state: newDocument.extended_status === SupplierDocumentExtendedStatusEnum.Archived ? doc.state : mapDisplayedDetailSupplierDocumentState(newDocument.extended_status),
                                        archived: newDocument.extended_status == SupplierDocumentExtendedStatusEnum.Archived,
                                        valid_from_date: newDocument.start_date as any,
                                        valid_to_date: newDocument.expiry_date as any
                                    };
                                }
                                return doc;
                            })
                        };
                    }
                    return docType;
                })
            };
        }
        return group;
    });
    return updatedGroups;
}

function addNewSupplierDocumentToProductionUnit(newDocument: SupplierDocument, customFieldGroups: DisplayCustomFieldGroup[]): DisplayCustomFieldGroup[] {
    const updatedGroups = customFieldGroups.map(group => {
        return {
            ...group,
            documentation_types: group.documentation_types.map(docType => {
                if (docType.id === newDocument.documentation_type_id) {
                    return {
                        ...docType,
                        detailed_supplier_documents: docType.detailed_supplier_documents?.concat([{
                            approver: null,
                            archived: false,
                            documentation_type_code: docType.code,
                            documentation_type_description: docType.description,
                            documentation_type_id: newDocument.documentation_type_id,
                            documentation_type_name: docType.name,
                            id: newDocument.supplier_document_id,
                            media_resources: newDocument.media_resources,
                            rejector: null,
                            responsible_groups: [],
                            responsible_users: [],
                            state: mapDisplayedDetailSupplierDocumentState(newDocument.extended_status),
                            uploader: null,
                            valid_from_date: newDocument.start_date,
                            valid_to_date: newDocument.expiry_date
                        } as any])
                    };
                }
                return docType;
            })
        };
    });
    return updatedGroups;
}

function deleteSupplierDocumentFromProductionUnit(newDocument: SupplierDocument, customFieldGroups: DisplayCustomFieldGroup[]): DisplayCustomFieldGroup[] {
    const updatedGroups = customFieldGroups.map(group => {
        if (group.documentation_types?.find(docType => docType.detailed_supplier_documents.find(doc => doc.id === newDocument.supplier_document_id))) {
            return {
                ...group,
                documentation_types: group.documentation_types.map(docType => {
                    if (docType.id === newDocument.documentation_type_id) {
                        const documentIndex = docType.detailed_supplier_documents?.findIndex(doc => doc.id === newDocument.supplier_document_id);
                        if (documentIndex !== undefined && documentIndex !== -1) {
                            return {
                                ...docType,
                                detailed_supplier_documents: docType.detailed_supplier_documents?.filter(doc => doc.id !== newDocument.supplier_document_id)
                            };
                        }
                    }
                    return docType;
                })
            };
        }
        return group;
    });
    return updatedGroups;
}

function mapDisplayedDetailSupplierDocumentState(state: SupplierDocumentExtendedStatusEnum): DisplayDetailedSupplierDocumentStateEnum {
    switch (state) {
        case SupplierDocumentExtendedStatusEnum.Active:
            return DisplayDetailedSupplierDocumentStateEnum.Active;
        case SupplierDocumentExtendedStatusEnum.Approved:
            return DisplayDetailedSupplierDocumentStateEnum.Approved;
        case SupplierDocumentExtendedStatusEnum.Rejected:
            return DisplayDetailedSupplierDocumentStateEnum.Rejected;
        case SupplierDocumentExtendedStatusEnum.WaitingForApproval:
            return DisplayDetailedSupplierDocumentStateEnum.WaitingForApproval;
        case SupplierDocumentExtendedStatusEnum.FileAdded:
            return DisplayDetailedSupplierDocumentStateEnum.FileAdded;
        case SupplierDocumentExtendedStatusEnum.Expired:
            return DisplayDetailedSupplierDocumentStateEnum.Expired;
        case SupplierDocumentExtendedStatusEnum.Archived:
            return DisplayDetailedSupplierDocumentStateEnum.Archived;
        case SupplierDocumentExtendedStatusEnum.Deleted:
            return DisplayDetailedSupplierDocumentStateEnum.Deleted;
    }
}

export function useProductionUnitsInSupplierContext(supplierId: string): ProductionUnitInSupplierContextState {

    const dispatch = useAppDispatch()
    const data = useSelector(getProductionUnitsInSupplierContext)[supplierId]

    useEffect(() => {
        if (supplierId) {
            dispatch(fetchProductionUnitsInSupplierContext({ supplier_id: supplierId }))
        }
    }, [supplierId])

    if (data) {
        return data;
    }

    return {
        isFetching: 0,
        productionUnits: undefined
    }
}

const { actions } = productionUnitsSlice;
export const productionUnitsSliceActions = actions;
export default productionUnitsSlice.reducer;