import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { ComponentOrStringType, byId } from '../../../base/types';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { DetailedTag, DetailedTag as Tag, TagAsList } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { getProfile } from '../../profile/selectors';
import { getComplianceCategories } from '../complianceSlice';
import { getComplianceCategoriesSelector } from '../selectors/ComplianceSelectors';
import ComplianceCategorySelector from './ComplianceCategorySelector';
import ComplianceTag from './ComplianceTag';

type Props = {
    className?: string;
    tags?: TagAsList[];
    isModal?: boolean;
    isOpen?: boolean;
    onModalClose?(): void;
    trigger?: ComponentOrStringType;
    onSave?: (tags: TagAsList[]) => void;
    onSelected?: (tag: TagAsList, tagD?: DetailedTag) => void;
    editable: boolean,
    onlyShowLast?: boolean,
    downwardPropagation?: boolean
    showSelector?: boolean;
    disabled?: boolean;
    disabledWhenInProgress?: boolean;
};

const ComplianceTagsBrowser = (props: Props): React.ReactElement => {
    const { className, tags, isModal, onSelected, downwardPropagation, showSelector = false, disabled = false, disabledWhenInProgress = false, trigger, onModalClose } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const features = useSelector(getProfile).features;
    const _categories = useSelector(getComplianceCategoriesSelector);
    const [categories, setCategories] = useState(_categories);
    const [selected, setSelected] = useState([]);

    const [isOpen, setIsOpen] = useState(props.isOpen || false);
    const getSelectedTags = () => {
        const obj = {};
        tags && tags.forEach((tag) => {
            if (obj[tag.category_id]) {
                obj[tag.category_id].push(tag);
            } else {
                obj[tag.category_id] = [tag];
            }
        });
        return obj;
    };
    const [selectedData, setSelectedData] = useState<byId<DetailedTag[]>>(getSelectedTags());

    const onSave = () => {
        const selectedTags: TagAsList[] = selected.map(s => {
            return {
                category_id: s.category_id,
                tag_id: s.tag_id
            }
        });
        props.onSave?.(selectedTags);
        setIsOpen(false);
    }

    const removeTag = (index: number, t: Tag) => {
        const _data = { ...selectedData };
        const remaining = _data[t.category_id].filter((tag) => tag.path.toString() !== t.path.toString())
        const _selected = selected.filter((s, i) => i != index);
        _data[t.category_id] = remaining;
        // setSelected(_selected);
        // setSelectedData(_data);
        onSelected(t);
    };

    useEffect(() => {
        dispatch(getComplianceCategories());
    }, []);
    useEffect(() => {
        setCategories(_categories);
    }, [_categories]);
    useEffect(() => {
        setSelectedData(getSelectedTags());
    }, [tags]);
    useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen]);

    let content;
    const info = '';

    /* <InfoBox
        className='mb-4 border'
        header='Note'
        message={'Editing tags may affect any order from Jul 12, 2024 onwards of products that meet this requirement. If you want to avoid this, you can create a new version of the requirement and set a later start date'} /> */;

        
    const nonEditContent = <div className='flex flex-row flex-wrap gap-1'>
        {selectedData && Object.keys(selectedData).map((tag) => {
            return (selectedData[tag] && selectedData[tag].map((t, i) => {
                return <ComplianceTag key={'tag_' + i} tag={t} removeTag={() => removeTag(i, t)} index={i} isEditing={props.editable} onlyShowLast={props.onlyShowLast} disabled={false} />
            }))
        })}
    </div>
    if (props.editable) {
        content = categories.map((category, index) => {
            return <ComplianceCategorySelector

                disabled={false}
                showSelector={showSelector}
                key={index}
                index={index}
                isLast={index === categories.length - 1}
                category={category}
                tags={tags && tags.filter(t => t.category_id == category.id)}
                onSelected={props.onSelected}
                onlyShowLast={props.onlyShowLast}
                downwardPropagation={downwardPropagation}
                disabledWhenInProgress={disabledWhenInProgress} />
        })
    } else {
        content = nonEditContent
    }

    const m = <QModal
        width={500}
        isOpen={isOpen}
        onClose={() => {
            setIsOpen(false);
            onModalClose?.();
        }}
        header={<FormattedMessage id='compliance.tags_browser.edit_tags' />}
        content={[info, content]}
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'globals.save' }), event: onSave },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: onSave },
        ]}
    >
    </QModal>
    return <div className={twMerge('', className)}>
        {isModal && <div onClick={() => setIsOpen(true)}>{trigger && <span>{trigger}</span>}</div>}
        {isModal ? [m, nonEditContent] : [content]}
    </div>

}

export const addOrRemoveTag = (arr: (DetailedTag | TagAsList)[], tag: TagAsList | DetailedTag): (DetailedTag | TagAsList)[] => {
    let ret = [];
    if (tag) {
        if (arr.findIndex(i => i.tag_id == tag.tag_id) != -1) {
            const a = arr.filter(i => i.tag_id != tag.tag_id);
            ret = a;
        } else {
            arr.push(tag);
            ret = arr;
        }
    }
    return ret;
}

export default ComplianceTagsBrowser;



