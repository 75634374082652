import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Icon from '../../../base/ui/components/icons/Icon';
import { getLocaleString } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setLocale } from '../../globals';
import { getLocale } from '../selectors';

type OwnProps = {
    isMobile?: boolean;
}

const LocaleSelector = (props: OwnProps): React.ReactElement => {
    const locale = useSelector(getLocale);
    const dispatch = useAppDispatch();
    const locales = locale && locale.locales || [];
    const currentLocaleLabel = getLocaleString(locale.selected);
    const isMobile = props.isMobile || false;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatchLocale = (locale): void => {
        if (!props.isMobile) {
            setIsOpen(false)
        }
        dispatch(setLocale(locale))
    }
    return (
        <Fragment>
            {/* props.isMobile &&
                <span >
                    <div className='pl-4 pb-2 flex' onClick={(): void => setIsOpen(!isOpen)}>
                        <FormattedMessage id={'globals.language_own_short'} />
                        <Icon className='' name='microinteraction_drop-down'/>
                    </div>
                    {isOpen && locales.map((l, index) => {
                        return <div key={'current' + index} className='flex gap-2 pl-6 p-2' onClick={(): void => {
                            dispatchLocale(l);
                        }}>{l.name}
                            <span className='ic'>{currentLocaleLabel === getLocaleString(l) && <Icon name='status_checked-ok' />}</span></div>;
                    })}
                </span>
             */}
            {
                <Popup
                    className='localeSelector'
                    hideOnScroll={true}
                    onClose={(): void => setIsOpen(false)}
                    trigger={<span className={twMerge('localeSelectorTrigger flex gap-2 items-center')}>
                        <span className={twMerge('', isMobile && 'text-on-surface-plain')}><FormattedMessage id={'globals.language_own_short'} /></span>
                        <Icon className={twMerge('text-white', isMobile && 'text-on-surface-plain')} name='microinteraction_drop-down'/>
                        </span>}
                    on='click'
                    hoverable={false}
                    onOpen={(): void => {
                        setIsOpen(true);
                    }}
                    open={isOpen}>
                    <Popup.Content>
                        <Header as='h5'><FormattedMessage id='globals.select_language' /></Header>
                        {locales.map((l, index) => {
                            return <div key={'current' + index} className={currentLocaleLabel === getLocaleString(l) ? 'item selected' : 'item'} onClick={(): void => {
                                dispatchLocale(l);
                            }}>
                                {l.name}
                                <span className='ic'><Icon name='status_checked-ok' /></span>
                            </div>;
                        })}
                    </Popup.Content>
                </Popup>
            }
        </Fragment>
    );
}

export default LocaleSelector;
