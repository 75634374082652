import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QModal, { ActionTypes } from "../../../../../base/ui/components/modals/Modal";
import { ButtonTypes } from "../../../../../base/ui/components/buttons/Button";

export default function DeleteFileConfirmModal(props: {
    deleteFile: () => void;
    open: boolean;
    close: () => void;
}) {
    const { open, deleteFile, close } = props;
    const intl = useIntl();
    
    return (
        <QModal
            isOpen={open}
            header={intl.formatMessage({
                id: "compliance.document.file.delete.header",
            })}
            width={454}
            content={
                <FormattedMessage id="compliance.document.file.delete.text" />
            }
            onClose={close}
            actionItems={
                [
                    {
                        type: ActionTypes.Action,
                        buttonType: ButtonTypes.Danger,
                        text: "compliance.document.file.delete.button",
                        event: () => {
                            deleteFile();
                            close();
                        },
                        icon: "operation_delete",
                    },
                    {
                        type: ActionTypes.Action,
                        buttonType: ButtonTypes.Plain,
                        text: "globals.confirmation.cancel",
                        event: close,
                    },
                ]
            }
        />
    );
}
