import React, { useRef } from 'react';
import { PressEvent } from 'react-aria-components';
import { FormattedMessage, useIntl } from 'react-intl';
import AddFilesToDocument from '../../../../modules/compliance/components/AddFilesToDocument';
import Button from '../../../ui/components/buttons/Button';
import Icon from '../../../ui/components/icons/Icon';
import { twMerge } from '../../../utils';
import { Sizes } from '../../types';
import { FileResource } from '../../../../backend_api/models';

type Props = {
    className?: string;
    onDeleteFile(): void;
    onAddFile?(file: FileResource): void;
    onZoomOut(): void;
    onZoomIn(): void;
    onDownloadFile(): void;
    userCanAddAndRemoveFiles: boolean;
};

const FileActions = (props: Props): React.ReactElement => {
    const { className, onDeleteFile, onZoomIn, onZoomOut, userCanAddAndRemoveFiles, onDownloadFile, onAddFile } = props;
    const intl = useIntl();
    const fileAddRef = useRef(null);
    const zoomIn = (e: PressEvent) => {
        onZoomIn();
    }
    const zoomOut = (e: PressEvent) => {
        onZoomOut();
    }
    const deleteFile = (e: PressEvent) => {
        onDeleteFile();
    }
    const downloadFile = (e: PressEvent) => {
        onDownloadFile();
    }
    const fs = <AddFilesToDocument
        fileUploadRef={fileAddRef}
        hasDropArea={false}
        hideUploadActions={true}
        floating={true}
        onUploadComplete={(files) => onAddFile?.(files[0] as FileResource)}
    />


    return <div className={twMerge('flex p-1 items-center gap-x-6 whitespace-pre', className)}>
        <div className='gap-x-4 flex'>
            <div><Icon size={Sizes.XLarge} onPress={zoomIn} name='operation_zoom-in' className='hover:brightness-0 cursor-pointer' /></div>
            <div><Icon size={Sizes.XLarge} onPress={zoomOut} name='operation_zoom-out' className='hover:brightness-0 cursor-pointer' /></div>
            {userCanAddAndRemoveFiles && deleteFile && <button><Icon size={Sizes.XLarge} name='operation_delete' className='hover:brightness-0 cursor-pointer' onPress={deleteFile} /></button>}
            <div><Icon size={Sizes.XLarge} name='operation_download' className='hover:brightness-0 cursor-pointer' onPress={downloadFile} /></div>
        </div>
        { userCanAddAndRemoveFiles && onAddFile && 
            <>
            <div><Button size={Sizes.XSmall} onPress={() => fileAddRef?.current?.click()}><FormattedMessage id='file_toolbar.add files' /></Button></div>
            <div>{fs}</div>
            </>
        }
    </div>
}
export default FileActions;
