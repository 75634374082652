import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CorrectiveActionsState } from '../../../../../backend_api/models';
import Icon from '../../../../../base/components/basic/Icon';
import { twMerge } from '../../../../../base/utils';
import { getColor } from '../../../../correctiveActions/CorrectiveActionDisplayState';

type Props = {
    className?: string;
    correctiveActionStatus: CorrectiveActionsState;
};

const InspectionReportCorrectiveActionEvidenceMarker = (props: Props): React.ReactElement => {
    const { className, correctiveActionStatus } = props;
    const color = getColor(correctiveActionStatus);
    return <div className={twMerge('pb-1 pt-1 pr-3 pl-2 inline-flex text-sm justify-center rounded-br-md items-center space-x-1 text-white', 'bg-' + color, className)}
    ><Icon name='assignment_turned_in' className={twMerge('text-base', 'text-white')} />
        <div><FormattedMessage id='corrective_actions.inspections_evidence_marker' /></div>
    </div>
}
export default InspectionReportCorrectiveActionEvidenceMarker;
