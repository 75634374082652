import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownFilter } from '.';
import { getLocationEntry, preventDefaultAndStopPropagation } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getSupplierDocumentTypeGroups, getSupplierDocumentTypeGroupsSelector } from '../../productionUnits/slice/productionUnitsSlice';
import Filter from './Filter';
import { CustomFieldGroupsDocumentationTypes } from '../../../backend_api/models/CustomFieldGroupsDocumentationTypes';
import { DocumentationType } from '../../../backend_api/models/DocumentationType'
import { DropdownItemProps } from 'semantic-ui-react';

type Props = {
    selectedProductionUnitIds?: string[];
    disabled?: boolean;
    search?: boolean;
    className?: string;
    onFilterSet: (selectedTypes: string[]) => void;
    label: string;
    placeholder?: string;
    filters?: string[];
    allowMultipleChoice?: boolean
};

export default function DocumentationTypesFilter(props: Props): React.ReactElement {
    const { search = true, disabled = false, className, onFilterSet, label, placeholder, filters, allowMultipleChoice } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const [selectedTypes, setSelectedTypes] = React.useState<string[]>(filters || getLocationEntry(location, "documentation_type"));
    const selectedMap = {};
    selectedTypes.forEach((typeId) => { selectedMap[typeId] = true; });

    const documentTypeGroups = useSelector(getSupplierDocumentTypeGroupsSelector);

    useEffect(() => {
        dispatch(getSupplierDocumentTypeGroups());
    }, [])

    const toggleHeader = (group: CustomFieldGroupsDocumentationTypes) => {
        const notYetSelectedTypes = group.documentation_types.filter((type) => !selectedMap[type.id]).map((type) => type.id);
        setSelectedTypes(selectedTypes.concat(notYetSelectedTypes));
    }

    const options = getDocumentationTypeOptions({
        selectedMap: selectedMap,
        allowMultipleChoice: allowMultipleChoice,
        documentTypeGroups: documentTypeGroups,
        toggleHeader: toggleHeader,
        toggleOption: (docType: DocumentationType) => {
            if (selectedMap[docType.id]) {
                setSelectedTypes(selectedTypes.filter((typeId) => typeId !== docType.id));
            } else {
                setSelectedTypes([...selectedTypes, docType.id]);
            }
        }
    });

    return <Filter labelId={label} className={className}>
        <DropdownFilter
            name='documentation_type'
            options={options}
            multiple={allowMultipleChoice}
            placeholder={intl.formatMessage({ id: placeholder })}
            selectedItems={selectedTypes || []}
            handleSave={(): void => {
                onFilterSet(selectedTypes);
            }}
            handleChange={(items: string[] | string) => {
                if(allowMultipleChoice && Array.isArray(items)) {
                    setSelectedTypes(items);
                } else if (typeof items === 'string') {
                    setSelectedTypes([items]);
                }
            }}
            inline={false}
            showLoader={true}
            disabled={disabled}
            search={search}
            remove={(items) => {
                setSelectedTypes([]);
                onFilterSet([]);
            }}
        /></Filter >;
}

export function getDocumentationTypeOptions(config: {
    allowMultipleChoice?: boolean,
    selectedMap: Record<string, boolean>,
    documentTypeGroups: CustomFieldGroupsDocumentationTypes[],
    toggleHeader: (group: CustomFieldGroupsDocumentationTypes) => void,
    toggleOption: (document: DocumentationType) => void
}): DropdownItemProps[] {
    const { documentTypeGroups, toggleHeader, toggleOption, allowMultipleChoice, selectedMap } = config;

    const options = [];
    documentTypeGroups?.forEach((group) => {
        if (group.documentation_types.some((type) => !selectedMap[type.id])) {
            options.push({
                text: <FilterHeaderItem group={group} toggle={toggleHeader} allowMultipleChoice={allowMultipleChoice}/>,
                key: group.group_id,
                className: '!border-t !border-default',
            });
        }

        group?.documentation_types?.forEach((docType) => {
            options.push({
                id: docType.id,
                text: docType.name.text,
                value: docType.id,
                onClick: (e) => {
                    preventDefaultAndStopPropagation(e);
                    toggleOption(docType);
                },
            })
        });
    });
    return options;
}

function FilterHeaderItem(props: { group: CustomFieldGroupsDocumentationTypes, toggle: (group: CustomFieldGroupsDocumentationTypes) => void, allowMultipleChoice: boolean }) {
    const { group, toggle, allowMultipleChoice } = props;

    return (
        <div className='w-full flex flex-row justify-between gap-4' onClick={preventDefaultAndStopPropagation}>
            <div className='font-bold cursor-default'>{group.group_name.text}</div>
            {allowMultipleChoice && <button className='text-brand whitespace-nowrap' onClick={(e) => {
                preventDefaultAndStopPropagation(e);
                toggle(group);
            }}>
                <FormattedMessage id='filters.selected_filter.add_all' />
            </button>}
        </div>
    )
}