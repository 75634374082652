/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 114.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileResource,
    FileResourceFromJSON,
    FileResourceFromJSONTyped,
    FileResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuditReportCustomFieldsDocumentationDocument
 */
export interface AuditReportCustomFieldsDocumentationDocument {
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    document_viewer_url: string;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    expiry_date: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    expiry_required: boolean;
    /**
     * 
     * @type {Array<FileResource>}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    media_resources: Array<FileResource>;
    /**
     * 
     * @type {boolean}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    previously_added: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDocumentationDocument
     */
    status: AuditReportCustomFieldsDocumentationDocumentStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum AuditReportCustomFieldsDocumentationDocumentStatusEnum {
    FileAdded = 'file_added',
    Active = 'active',
    WaitingForApproval = 'waiting_for_approval',
    Approved = 'approved',
    Rejected = 'rejected',
    Expired = 'expired',
    Archived = 'archived',
    Deleted = 'deleted'
}

export function AuditReportCustomFieldsDocumentationDocumentFromJSON(json: any): AuditReportCustomFieldsDocumentationDocument {
    return AuditReportCustomFieldsDocumentationDocumentFromJSONTyped(json, false);
}

export function AuditReportCustomFieldsDocumentationDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditReportCustomFieldsDocumentationDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document_viewer_url': json['document_viewer_url'],
        'expiry_date': json['expiry_date'],
        'expiry_required': json['expiry_required?'],
        'media_resources': ((json['media_resources'] as Array<any>).map(FileResourceFromJSON)),
        'previously_added': json['previously_added?'],
        'start_date': json['start_date'],
        'status': json['status'],
    };
}

export function AuditReportCustomFieldsDocumentationDocumentToJSON(value?: AuditReportCustomFieldsDocumentationDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document_viewer_url': value.document_viewer_url,
        'expiry_date': value.expiry_date,
        'expiry_required?': value.expiry_required,
        'media_resources': ((value.media_resources as Array<any>).map(FileResourceToJSON)),
        'previously_added?': value.previously_added,
        'start_date': value.start_date,
        'status': value.status,
    };
}


