import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DocumentationType } from '../../backend_api/models/DocumentationType';
import { request2 } from '../../base/api';
import { AppState } from '../../base/types';
import { sendErrorMessage, sendStatusMessage } from '../messages/actions';
import { SetApprovalResponsibleBody } from '../../backend_api_2/models/SetApprovalResponsibleBody';

export interface SupplierDocumentsState {
    documentationTypes: DocumentationType[];
    isFetching: boolean;
    isUpdating: boolean;
}

const initialState: SupplierDocumentsState = {
    documentationTypes: [],
    isFetching: false,
    isUpdating: false
};

export const getDocumentationTypesList = createAsyncThunk<DocumentationType[]>(
    'getDocumentationTypesList',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            // Get documentation types for production units
            const productionUnitsRq = await request2('documentation_types/list_selectable_documentation_types_for_production_units', {
                method: 'get'
            });

            // Get documentation types for suppliers
            const suppliersRq = await request2('documentation_types/list_selectable_documentation_types_for_suppliers', {
                method: 'get'
            });

            // Check if either request failed
            if (!productionUnitsRq.ok || !suppliersRq.ok) {
                console.log('There was an error getting documentation types');
                dispatch(sendErrorMessage(['error_message.documentation_types.get_list'], 3000));
                return rejectWithValue(
                    !productionUnitsRq.ok ? productionUnitsRq : suppliersRq as Response
                );
            }

            // Parse the response data
            const productionUnitsData = await productionUnitsRq.json() as DocumentationType[];
            const suppliersData = await suppliersRq.json() as DocumentationType[];

            // Combine both lists
            return [...productionUnitsData, ...suppliersData];
        } catch (error) {
            console.log('There was an error getting documentation types:', error);
            dispatch(sendErrorMessage(['error_message.documentation_types.get_list'], 3000));
            return rejectWithValue(error);
        }
    }
);

// Create the async thunk for setting approval responsibles
export const setDocumentationTypeApprovalResponsible = createAsyncThunk<
    DocumentationType,
    { documentationTypeId: string, responsibles: SetApprovalResponsibleBody }
>(
    'setDocumentationTypeApprovalResponsible',
    async ({ documentationTypeId, responsibles }, { dispatch, rejectWithValue }) => {
        const rq = await request2(`documentation_types/${documentationTypeId}/set_approval_responsible`, {
            method: 'put',
            body: JSON.stringify(responsibles)
        });

        if (!rq.ok) {
            console.log('There was an error setting approval responsibles');
            dispatch(sendErrorMessage(['error_message.documentation_types.set_approval_responsible'], 3000));
            return rejectWithValue(rq as Response);
        }

        dispatch(sendStatusMessage(['status_message.documentation_types.approval_responsible_updated'], 3000));
        dispatch(getDocumentationTypesList());

        const data = await rq.json();
        return data as DocumentationType;
    }
);

export const supplierDocumentsSlice = createSlice({
    name: 'supplierDocuments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDocumentationTypesList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getDocumentationTypesList.fulfilled, (state, action) => {
            state.documentationTypes = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getDocumentationTypesList.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(setDocumentationTypeApprovalResponsible.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(setDocumentationTypeApprovalResponsible.fulfilled, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(setDocumentationTypeApprovalResponsible.rejected, (state) => {
            state.isUpdating = false;
        });
    }
});

export const getDocumentationTypesListSelector = createSelector(
    [(state: AppState): DocumentationType[] => state.app.supplierDocuments?.documentationTypes || []],
    (documentationTypes) => documentationTypes
);

export const getDocumentationTypesFetchingSelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.isFetching],
    (isFetching) => isFetching
);

export const getDocumentationTypesUpdatingSelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.isUpdating],
    (isUpdating) => isUpdating
);

export default supplierDocumentsSlice.reducer;
