/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventType {
    EDIT_EVENT = 'edit_event',
    COMMENT_EVENT = 'comment_event',
    APPROVED_EVENT = 'approved_event',
    RESOLVED_EVENT = 'resolved_event',
    CREATED_EVENT = 'created_event',
    EVIDENCE_ADDED_EVENT = 'evidence_added_event',
    AUDITS_ADDED_EVENT = 'audits_added_event',
    EVIDENCE_DELETED_EVENT = 'evidence_deleted_event',
    REOPEN_EVENT = 'reopen_event',
    DESCRIPTION_OF_RESOLUTION_EVENT = 'description_of_resolution_event',
    RESPONSIBLE_NOTIFIED_EVENT = 'responsible_notified_event',
    NOTIFIED_EVENT = 'notified_event',
    NOTIFIED_ON_OVERDUE_EVENT = 'notified_on_overdue_event',
    NOTIFIED_ON_EXPIRY_EVENT = 'notified_on_expiry_event',
    CREATED_BY_MIGRATION = 'created_by_migration',
    UPDATED_BY_IMPORTER = 'updated_by_importer',
    CREATED_BY_IMPORTER = 'created_by_importer',
}
