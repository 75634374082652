import classnames from 'classnames';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { ListViewHeader } from '../../../globals/components/views/list/ListView';
import { getInspectionByTypeSequence } from '../../Utils';
import { OrderListInspectionExtended, OrderListOrderExtended } from '../../slices/inspectionsListSlice';
import { Inspection, InspectionTypeViews } from '../../types';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import InspectionItem from './InspectionItem';
import { config } from './inspectionItemConfig';

interface InspectionsProps {
    order: OrderListOrderExtended;
    type: InspectionTypeViews;
    currentInspectionId: string;
    showCombine: boolean;
    hasInspectionTypes: boolean;
    inspectionsComponent: typeof InspectionItem;
}
const Inspections = (props: InspectionsProps): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const { type, currentInspectionId, showCombine, order, hasInspectionTypes } = props;
    const orderId = order.order_id;
    const inspectionsNoType = order.inspectionsNoTypes;
    const inspectionsGrouped = order.inspectionsGrouped;
    const commonProps = {
        orderId,
        type,
        currentInspectionId,
        showCombine,
        hasInspectionTypes,
    };
    const widths = config.widths;
    return (<Fragment>
        <ListViewHeader className='hidden md:flex md:mt-0 md:mb-0 md:py-0 text-sm border-b border-default bg-highlight-light-green' items={[
            { label: undefined, className: classnames(' py-2', { [widths.image]: !showCombine, 'w-2/24': showCombine }), key: 'spacer.1' },
            // 1 5 7 2 4 2 3
            { label: formatMessage({ id: 'inspections.item_no' }), className: classnames('py-2', widths.itemNo), key: 'item_no' },
            { label: formatMessage({ id: 'inspections.item' }), className: classnames('py-2', widths.itemName), key: 'item' },
            { label: formatMessage({ id: 'inspections.qty' }), className: classnames('py-2', widths.qty), key: 'qty' },
            { label: formatMessage({ id: 'inspections.inspection_types' }), className: classnames('py-2', widths.types), key: 'inspection_types' },
            { label: formatMessage({ id: 'inspections.date' }), className: classnames('py-2', widths.date), key: 'date' },
            { label: formatMessage({ id: 'inspections.status' }), className: classnames('py-2', widths.status), key: 'status' },
        ]} />
    
    {Object.keys(inspectionsGrouped).map((k: string) => {
            const inspectionsByGroup = inspectionsGrouped[k] as (OrderListInspectionExtended | Inspection) [];        
            if (inspectionsByGroup.length > 0) {
                const inspectionTypesStatus = <InspectionTypeMarker inspections={inspectionsByGroup} type={'dashboard'} />;
                const inspectionBySequence = getInspectionByTypeSequence([...inspectionsByGroup]);
                const InspectionsComponent =
                    props.inspectionsComponent({
                        inspectionTypesStatus,
                        typeGroupInspections: inspectionsByGroup,
                        inspection: inspectionBySequence || inspectionsByGroup[inspectionsByGroup.length - 1],
                        ...commonProps,
                    });

                return (InspectionsComponent);
            }
        })}
        {inspectionsNoType.map((inspection: OrderListInspectionExtended) => {
            const inspectionTypesStatus = null;
            const InspectionsComponent =
                props.inspectionsComponent({
                    inspectionTypesStatus,
                    inspection,
                    ...commonProps,
                });
            return (InspectionsComponent);
        })}
    </Fragment>
    );
};

export default Inspections;
