/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a checkpoint document
 *
 */
export type SetCustomFieldCheckpointDocument = {
    expiration_date: string | null;
    file_resource_id: string;
    start_date: string | null;
    status: SetCustomFieldCheckpointDocument.status;
};

export namespace SetCustomFieldCheckpointDocument {

    export enum status {
        FILE_ADDED = 'file_added',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        ACTIVE = 'active',
        EXPIRED = 'expired',
        ARCHIVED = 'archived',
        DELETED = 'deleted',
    }


}

