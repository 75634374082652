import React from 'react';
import { FormattedMessage } from 'react-intl';
import ComplianceDisplayResponsibles from '../../../../modules/compliance/components/ComplianceDisplayResponsibles';
import { DocumentationType } from '../../../../backend_api/models';

type Props = {
    className?: string;
    documentationType: DocumentationType;
};

const DocumentResponsibles = (props: Props): React.ReactElement => {
    const { className, documentationType} = props;
    if(!documentationType?.responsible_groups && !documentationType?.responsible_users) {
        return null;
    }

    return (
        <div className='flex flex-col absolute bottom-24 gap-4'>
            <div className='text-secondary'><FormattedMessage id='compliance.document_viewer.approval_responsibles' /></div>
            <div className='flex gap-1'>
                <ComplianceDisplayResponsibles
                    showAll={true}
                    groups={documentationType?.responsible_groups}
                    users={documentationType?.responsible_users} />
            </div>
        </div>
    ) 
}
export default DocumentResponsibles;
