import DocViewer, { BMPRenderer, CSVRenderer, DocViewerRef, HTMLRenderer, JPGRenderer, MSDocRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, VideoRenderer } from '@cyntler/react-doc-viewer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage } from 'react-intl';
import Measure from 'react-measure';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import FileCounter from '../../../../base/components/basic/files/FileCounter';
import FileToolBar from '../../../../base/components/basic/files/FileToolBar';
import Button from '../../../../base/ui/components/buttons/Button';
import Icon from '../../../../base/ui/components/icons/Icon';
import { twMerge } from '../../../../base/utils';
import { IDocument } from './ComplianceDocumentViewer';
import { HeicRenderer } from './renders/HeicRenderer';
import { PdfRenderer } from './renders/PdfRenderer';
import { FileResource } from '../../../../backend_api/models';
import { FileResource as ComplianceFileResource } from '../../../../compliance_api/models';

type Props = {
    className?: string;
    singleView?: boolean;
    documents?: IDocument[];
    onDeleteFile(fileId: string): void;
    onAddFile?(file: FileResource | ComplianceFileResource): void;
    onDownloadFile(fileId: string): void;
    userCanAddAndRemoveFiles: boolean;
};

const renderers =
    [
        VideoRenderer,
        JPGRenderer,
        CSVRenderer,
        TXTRenderer,
        TIFFRenderer,
        MSDocRenderer,
        HTMLRenderer,
        BMPRenderer,
        PNGRenderer,
        HeicRenderer,
        PdfRenderer,
    ];

const DocumentFilesViewer = (props: Props): React.ReactElement => {
    const { className, singleView = false, onDeleteFile, onAddFile, onDownloadFile, userCanAddAndRemoveFiles } = props;
    const docViewerRef = useRef<DocViewerRef>(null);
    const [documents, setDocuments] = useState(props.documents);
    const [activeDocument, setActiveDocument] = useState(props.documents[0]);
    const [currentFileNumber, setCurrentFileNumber] = useState<number>(0);
    const [dimension, setDimension] = useState(null);

    const deleteFile = () => {
        onDeleteFile(documents[currentFileNumber].fileResourceId);
    }
    const downloadFile = () => {
        onDownloadFile(documents[currentFileNumber].uri);
    }

    useEffect(() => {
        setDocuments(props.documents)
    }, [props.documents]);
    useEffect(() => {
        setActiveDocument(documents[0]);
        setCurrentFileNumber(0);
    }, [documents]);

    const documentCount = documents.length;

    const handleDocumentChange = (document) => {
        setActiveDocument(document);
    };

    const NoRenderer = ({ document, fileName }) => {
        const fileText = fileName || document?.fileType || "";
        const f = fileText.split('/');
        const fn = f[f.length - 1];
        const link = documents[currentFileNumber].uri;
        if (fileText) {
            return <div className='w-full flex justify-center flex-col items-center gap-4 pt-48'>
                <Icon name='object_file' className='text-6xl' />
                <a className='link' href={link}>{decodeURIComponent(fn)}</a>
                <Button primary icon='operation_download' onPress={() => {
                    window.open(
                        link, '_blank',
                    )
                }}><FormattedMessage id='compliance.file_viewer.download_file_button' /></Button>

            </div>;
        }

        return <div>No Renderer Error!</div>;
    };
    const docViewer = useMemo(() => {
        return <DocViewer
            key={'docViewer'}
            className=''
            ref={docViewerRef}
            activeDocument={activeDocument}
            onDocumentChange={handleDocumentChange}
            documents={documents}
            pluginRenderers={renderers}
            config={{
                noRenderer: {
                    overrideComponent: NoRenderer,
                },
                header: {
                    disableHeader: true,
                },
            }}
        />
    }, [documents, activeDocument]);


    useHotkeys('left', () => {
        if (currentFileNumber > 0) {
            setCurrentFileNumber(currentFileNumber - 1);
            docViewerRef.current && docViewerRef.current.prev()
        }
    });
    useHotkeys('right', () => {
        if (currentFileNumber < (documents.length - 1)) {
            setCurrentFileNumber(currentFileNumber + 1)
            docViewerRef.current && docViewerRef.current.next();
        }
    });


    return <div className={twMerge('bg-white border-x', className)}>
        <TransformWrapper
            wheel={{
                activationKeys: ['Control', 'Meta'],
            }}
            initialScale={1}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                useHotkeys("*", event => {
                    if (event.key === "+") {
                        zoomIn();
                    }
                });
                useHotkeys('-', () => {
                    zoomOut();
                });
                useHotkeys('shift+=', () => {
                    resetTransform();
                });

                return <>
                    <Measure
                        bounds
                        onResize={contentRect => {
                            setDimension(contentRect.bounds);
                        }}
                    >{({ measureRef }) => (
                        <div className='flex items-center py-4 px-4 border-b space-x-1 z-10 bg-white sticky top-0 w-full gap-x-4' ref={measureRef}>
                            {documentCount > 1 && <>
                                {/* <Icon name='operation_view-gallery' /> */}
                                <FileCounter
                                    onCountChanged={(n) => {
                                        setCurrentFileNumber(n);
                                    }}
                                    fileCount={documentCount}
                                    currentFileNumber={currentFileNumber}
                                    nextFile={docViewerRef?.current?.next}
                                    previousFile={docViewerRef?.current?.prev}
                                /></>}
                            <FileToolBar
                                fileName={decodeURIComponent(activeDocument?.fileName)}
                                onDeleteFile={deleteFile}
                                onAddFile={onAddFile}
                                onZoomIn={() => zoomIn()}
                                onZoomOut={() => zoomOut()}
                                onDownloadFile={() => downloadFile()}
                                userCanAddAndRemoveFiles={userCanAddAndRemoveFiles}
                            />
                        </div>
                    )}
                    </Measure>
                    <TransformComponent>
                        <div className='w-full p-4'>
                            <div className='pos' style={{ width: dimension && (dimension.width - 30) }}>
                                {docViewer}
                            </div>
                        </div>
                    </TransformComponent>
                </>
            }}
        </TransformWrapper>
    </div >
}
export default DocumentFilesViewer;
