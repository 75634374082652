import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, Modal, Radio } from 'semantic-ui-react';
import { DetailedSupplier, Group } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import { useAppDispatch } from '../../../store';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import ControlledProductionUnitSelector from '../../productionUnits/components/ControlledProductionUnitSelector';
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits';
import { areSupplierAndProductionUnitLinked, useSupplierAndProductionUnitSelection } from '../../productionUnits/hooks/useSupplierAndProductionUnitSelection';
import { useSupplierAndProductionUnitRelations } from '../../supplierAndPuRelations/slice/relationSlice';
import { createSupplier } from '../../suppliers/actions';
import ControlledSupplierSelector from '../../suppliers/components/ControlledSupplierSelector';
import { NewSupplierInputsWithHints } from '../../suppliers/components/NewSupplierInputs';
import { getNewSupplierSelector } from '../../suppliers/selectors';
import { createAudit } from '../slices/auditSlice';

type CreateAuditProps = {
    secondary?: boolean;
    buttonClass?: string;
    labelKey?: string;
    label?: string | React.ReactElement;
    initialProductionUnitId?: string;
    initialSupplierId?: string;
};

function CreateAuditModal(props: CreateAuditProps): React.ReactElement {
    const { secondary = false, labelKey, label, buttonClass } = props;
    const dispatch = useAppDispatch();

    /* Modal wide settings */
    const [showModal, setShowModal] = useState(false);
    const [useExistingSupplier, setUseExistingSupplier] = useState<boolean>(true);
    const independentProductionUnits = useHasIndependentProductionUnits();
    const relations = useSupplierAndProductionUnitRelations();

    /* Data for Supplier and Production unit selector */
    const {
        suppliers: selectableSuppliers,
        productionUnits: selectableProductionUnits,
        otherSuppliers,
        otherProductionUnits,
        selectedProductionUnitId,
        selectedSupplierId,
        setSelectedProductionUnitId,
        setSelectedSupplierId,
        linked,
        loading: useSupplierLoading,
    } = useSupplierAndProductionUnitSelection(independentProductionUnits);
    const supplierRequired = independentProductionUnits ? !selectedProductionUnitId : true;
    const productionUnitRequired = independentProductionUnits ? !selectedSupplierId : false;
    const loading = useSupplierLoading || relations == 'loading'

    useEffect(() => {
        if (props.initialProductionUnitId) {
            setSelectedProductionUnitId(props.initialProductionUnitId)
        }
    }, [props.initialProductionUnitId])

    useEffect(() => {
        if (props.initialSupplierId) {
            setSelectedSupplierId(props.initialSupplierId)
        }
    }, [props.initialSupplierId])

    /* Data for creating new supplier */
    const [groupsInput, setGroupsInput] = React.useState<Group[]>([]);
    const [supplierNameInput, setSupplierNameInput] = React.useState('');
    const [supplierNumberInput, setSupplierNumberInput] = React.useState('');
    const {
        requestNewSupplier: createAuditWithNewSupplier
    } = useCreateNewSupplier((newSupplier: DetailedSupplier) => {
        onNewSupplierCreated(newSupplier);
    });

    function onNewSupplierCreated(newSupplier: DetailedSupplier) {
        // Create an audit, with the new supplier as the target.
        dispatch(createAudit({
            target_supplier: {
                id: newSupplier.id
            }
        }));
        onCancel();
    }

    function canCreateAuditWithExistingSupplier(): boolean {
        /* If both are chosen, we can only use a combination where the two are linked. */
        if (selectedSupplierId && selectedProductionUnitId && !linked) {
            return false;
        }

        const validChosenSupplier = !!selectedSupplierId;
        const validChosenProductionUnit = !!selectedProductionUnitId;

        if (independentProductionUnits) {
            return validChosenProductionUnit || validChosenSupplier;
        } else {
            return validChosenSupplier;
        }
    }

    function onCancel() {
        setUseExistingSupplier(true);
        setShowModal(false);
        setSelectedProductionUnitId(props.initialProductionUnitId)
        setSelectedSupplierId(props.initialSupplierId)
    }

    function onUserWantedToCreateNewSupplier() {
        setUseExistingSupplier(false);
    }

    function createAuditWithExistingSupplier(): void {
        const createAuditParams = {
            target_supplier: {
                id: selectedSupplierId
            },
            target_production_unit: {
                id: selectedProductionUnitId
            }
        };
        dispatch(createAudit(createAuditParams));
        setShowModal(false);
        setUseExistingSupplier(true);
    }

    function renderModalTrigger() {
        return !buttonClass
            ? renderCreateAuditButton(secondary, setShowModal, labelKey)
            : renderCustomCreateAuditButton(buttonClass, setShowModal, label, labelKey)
    }

    function onSupplierIdSelectedByUser(supplierId: string) {
        if (loading) {
            return;
        }

        setUseExistingSupplier(true);
        setSelectedSupplierId(supplierId);

        if (supplierId && selectedProductionUnitId) {
            if (!areSupplierAndProductionUnitLinked(supplierId, selectedProductionUnitId, relations)) {
                setSelectedProductionUnitId(null);
            }
        }
    }

    function onProductionUnitIdSelectedByUser(productionUnitId: string) {
        if (loading) {
            return;
        }

        setUseExistingSupplier(true);
        setSelectedProductionUnitId(productionUnitId);

        if (selectedSupplierId && productionUnitId) {
            if (!areSupplierAndProductionUnitLinked(selectedSupplierId, productionUnitId, relations)) {
                setSelectedSupplierId(null);
            }
        }
    }

    function renderProductionUnitSelector() {
        return (
            <Form.Field required={productionUnitRequired}>
                <label><FormattedMessage id='audits.select_production_unit' /></label>
                <div className={'flex w-full'}>
                    <ControlledProductionUnitSelector
                        loading={loading}
                        dataTestId='create-audit-choose-production-unit'
                        productionUnits={selectableProductionUnits}
                        nonMatchingProductionUnits={otherProductionUnits}
                        productionUnitId={selectedProductionUnitId}
                        onProductionUnitIdSelected={onProductionUnitIdSelectedByUser}
                    />
                </div>
            </Form.Field>
        );
    }

    function renderSupplierSelector() {
        return (
            <Form.Field required={supplierRequired}>
                <label><FormattedMessage id='audits.select_supplier' /></label>
                <div className={'flex w-full'}>
                    <ControlledSupplierSelector
                        loading={loading}
                        dataTestId='audits-add-supplier'
                        className='w-full'
                        suppliers={selectableSuppliers}
                        nonMatchingSuppliers={otherSuppliers}
                        supplierId={selectedSupplierId}
                        onUserWantsToCreateNewSupplier={onUserWantedToCreateNewSupplier}
                        onSupplierIdSelected={onSupplierIdSelectedByUser}
                    />
                </div>
            </Form.Field>
        )
    }

    function renderContentForExistingSupplier() {
        return (
            <>
                <Modal.Content className='space-y-8'>
                    <CreateOrSelectSwitch
                        value={true}
                        onValueChanged={setUseExistingSupplier} />
                    <Form>
                        {renderSupplierSelector()}
                        {independentProductionUnits ? renderProductionUnitSelector() : null}
                    </Form>
                </Modal.Content>
                <Modal.Actions className='flex justify-end'>
                    <Button data-test-id='button-create-audit-save'
                        primary
                        disabled={!canCreateAuditWithExistingSupplier()}
                        onClick={createAuditWithExistingSupplier}>
                        <FormattedMessage id='globals.ok' />
                    </Button>
                    <Button onClick={onCancel}>
                        <FormattedMessage id='globals.cancel' />
                    </Button>
                </Modal.Actions>
            </>
        );
    }

    function renderContentForNewSupplier() {
        return (<>
            <Modal.Content className='space-y-8'>
                <CreateOrSelectSwitch
                    value={false}
                    onValueChanged={setUseExistingSupplier} />

                <NewSupplierInputsWithHints
                    selectableSuppliers={selectableSuppliers.concat(otherSuppliers)}
                    onExistingSupplierIdSelected={onSupplierIdSelectedByUser}
                    groupsInput={groupsInput}
                    onGroupsChanged={setGroupsInput}
                    supplierNameInput={supplierNameInput}
                    onSupplierNameChanged={setSupplierNameInput}
                    supplierNumberInput={supplierNumberInput}
                    onSupplierNumberChanged={setSupplierNumberInput}
                />
            </Modal.Content>
            <Modal.Actions className='flex justify-end'>
                <Button data-test-id='button-create-audit-save'
                    primary
                    disabled={!isValidSupplierName(supplierNameInput)}
                    onClick={() => createAuditWithNewSupplier({
                        name: supplierNameInput,
                        number: supplierNumberInput,
                        groups: groupsInput
                    })}>
                    <FormattedMessage id='globals.ok' />
                </Button>
                <Button onClick={onCancel}>
                    <FormattedMessage id='globals.cancel' />
                </Button>
            </Modal.Actions>
        </>)
    }

    return (
        <Modal
            closeOnEscape
            closeIcon
            onClose={onCancel}
            open={showModal}
            trigger={renderModalTrigger()}
        >
            <Modal.Header>
                <FormattedMessage id='audits.create_audit' />
            </Modal.Header>
            {
                useExistingSupplier
                    ? renderContentForExistingSupplier()
                    : renderContentForNewSupplier()
            }
        </Modal>
    )
}

function CreateOrSelectSwitch(props: {
    value: boolean,
    onValueChanged(value: boolean): void
}) {
    function toggle() {
        props.onValueChanged(!props.value)
    }

    const intl = useIntl()

    return (
        <HasFeatureAccess feature='u_add_suppliers'>
            <Form className='pb-4'>
                <Form.Field>
                    <Radio
                        label={intl.formatMessage({ id: 'audits.create_with_existing' })}
                        checked={props.value}
                        onChange={toggle}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={intl.formatMessage({ id: 'audits.create_without_existing' })}
                        checked={!props.value}
                        onChange={toggle}
                    />
                </Form.Field>
            </Form>
        </HasFeatureAccess>
    )
}

function renderCustomCreateAuditButton(buttonClass: string, setShowModal: React.Dispatch<React.SetStateAction<boolean>>, label: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>, labelKey: string) {
    return (
        <div
            data-test-id='btn-create-audit'
            className={buttonClass}
            onClick={(): void => setShowModal(true)}>
            {label ? label : <FormattedMessage id={labelKey || 'audits.create_audit'} />}
        </div>
    );
}

function renderCreateAuditButton(secondary: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>>, labelKey: string) {
    return (
        <Button
            data-test-id='btn-create-audit'
            primary={!secondary}
            onClick={(): void => setShowModal(true)}>
            <FormattedMessage id={labelKey || 'audits.create_audit'} />
        </Button>
    );
}

/**
 * This hook exposes a suppliers name and number. It lets you dispatch a network request to create a new supplier with that name and number, when the name and number are valid.
 * You should pass in a onNewSupplierCreated callback, to be notified, when the supplier has been created in the backend.
 */
// Can probably be refactored, now that NewSupplierInputs is moved out of CreateAudit.
function useCreateNewSupplier(
    onNewSupplierCreated: (newSupplier: DetailedSupplier) => void
) {
    const dispatch = useAppDispatch();

    const [isWaitingForSupplierToBeCreated, setIsWaitingForSupplier] = useState(false);
    const newSupplier = useSelector(getNewSupplierSelector);

    useEffect(() => {
        if (isWaitingForSupplierToBeCreated && newSupplier) {
            setIsWaitingForSupplier(false);
            onNewSupplierCreated(newSupplier)
        }
    }, [newSupplier])

    return {
        isWaitingForSupplier: isWaitingForSupplierToBeCreated,
        newSupplier: newSupplier,
        requestNewSupplier: (createSupplierParams: CreateSupplierParams) => {
            dispatch(createSupplier({
                name: createSupplierParams.name,
                number: createSupplierParams.number,
                groups: createSupplierParams.groups,
                deleted: false,
                features: [],
                id: '',
                primary_group: { id: '', is_supplier_group: false, name: '', supplier_id: '' },
                users: []
            }));
            setIsWaitingForSupplier(true);
        },
        isNewSupplierCreateable: isValidSupplierName
    }
}

function isValidSupplierName(newSupplierName: string) {
    return (typeof newSupplierName === 'string') && (newSupplierName.length > 0);
}

export type CreateSupplierParams = {
    name: string;
    number: string;
    groups: Group[];
}

export default CreateAuditModal;
