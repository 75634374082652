import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { DropdownFilter } from '.';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setFilter } from '../filtersActions';
import Filter from './Filter';
import InvalidFieldMessage from '../../../base/ui/components/labels/InvalidFieldMessage';

type Props = {
    selectedProductionUnitIds?: string[];
    filterName?: string;
    disabled?: boolean;
    search?: boolean;
};

enum DocumentationStateFilter {
    NO_DOCUMENTATION = 'no_documentation',
    EXPIRED_OR_REJECTED = 'expired_or_rejected',
    EXPIRED_WITHIN_2_MONTHS = 'expired_within_2_months',
    EXPIRED_WITHIN_6_MONTHS = 'expired_within_6_months',
    EXPIRED_WITHIN_A_YEAR = 'expired_within_a_year',
    PLANNED_OR_IN_PROGRESS = 'planned_or_in_progress',
    IN_REVIEW = 'in_review',
    ACTIVE_OR_APPROVED = 'active_or_approved'
};

export default function DocumentationStatesFilter(props: Props): React.ReactElement {
    const { search = true, disabled = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const filterName = props.filterName || 'documentation_state';
    const selectedFilters = getLocationEntry(location, filterName);

    useEffect(() => {
        if (selectedFilters.length === 0) {
            dispatch(setFilter(filterName, [DocumentationStateFilter.ACTIVE_OR_APPROVED]));
        }
    }, []);

    const options = [{
        id: DocumentationStateFilter.NO_DOCUMENTATION,
        text: intl.formatMessage({ id: 'filters.documentation_state.no_documentation' }),
        value: DocumentationStateFilter.NO_DOCUMENTATION,
    }, {
        id: DocumentationStateFilter.EXPIRED_OR_REJECTED,
        text: intl.formatMessage({ id: 'filters.documentation_state.expired_or_rejected' }),
        value: DocumentationStateFilter.EXPIRED_OR_REJECTED,
    }, {
        id: DocumentationStateFilter.EXPIRED_WITHIN_2_MONTHS,
        text: intl.formatMessage({ id: 'filters.documentation_state.expired_within_2_months' }),
        value: DocumentationStateFilter.EXPIRED_WITHIN_2_MONTHS,
    }, {
        id: DocumentationStateFilter.EXPIRED_WITHIN_6_MONTHS,
        text: intl.formatMessage({ id: 'filters.documentation_state.expired_within_6_months' }),
        value: DocumentationStateFilter.EXPIRED_WITHIN_6_MONTHS,
    }, {
        id: DocumentationStateFilter.EXPIRED_WITHIN_A_YEAR,
        text: intl.formatMessage({ id: 'filters.documentation_state.expired_within_a_year' }),
        value: DocumentationStateFilter.EXPIRED_WITHIN_A_YEAR,
    }, {
        id: DocumentationStateFilter.PLANNED_OR_IN_PROGRESS,
        text: intl.formatMessage({ id: 'filters.documentation_state.planned_or_in_progress' }),
        value: DocumentationStateFilter.PLANNED_OR_IN_PROGRESS,
    }, {
        id: DocumentationStateFilter.IN_REVIEW,
        text: intl.formatMessage({ id: 'filters.documentation_state.in_review' }),
        value: DocumentationStateFilter.IN_REVIEW,
    }, {
        id: DocumentationStateFilter.ACTIVE_OR_APPROVED,
        text: intl.formatMessage({ id: 'filters.documentation_state.active_or_approved' }),
        value: DocumentationStateFilter.ACTIVE_OR_APPROVED,
    }];

    return <Filter labelId='filters.documentation_state' className='w-full'>
        {
            selectedFilters.length === 0 &&
            <InvalidFieldMessage message={"filters.select_state.error_message"} />
        }
        <DropdownFilter
            name='documentation_state'
            options={options}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.select_state' })}
            selectedItems={selectedFilters || []}
            handleSave={(items): void => {
                dispatch(setFilter(filterName, items));
            }}
            inline={false}
            showLoader={true}
            disabled={disabled}
            search={search}
        /></Filter >;
}