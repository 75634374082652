/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileResource } from './FileResource';

/**
 * A supplier document.
 *
 */
export type SupplierDocument = {
    approval_required: boolean;
    approver_id: string;
    documentation_type_id: string;
    expiry_date: string;
    expiry_required: boolean;
    extended_status: SupplierDocument.extended_status;
    media_resources: Array<FileResource>;
    rejection_reason: string;
    rejector_id: string;
    start_date: string;
    supplier_document_id: string;
    uploader_id: string;
};

export namespace SupplierDocument {

    export enum extended_status {
        FILE_ADDED = 'file_added',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        ACTIVE = 'active',
        EXPIRED = 'expired',
        ARCHIVED = 'archived',
        DELETED = 'deleted',
    }


}

