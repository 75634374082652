/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 114.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Feature {
    BffProductionUnitListView = 'bff_production_unit_list_view',
    Developer = 'developer',
    Video = 'video',
    AutomaticTranslation = 'automatic_translation',
    TwoFactorAuthentication = 'two_factor_authentication',
    IndependentProductionUnits = 'independent_production_units',
    SupplierCompliance = 'supplier_compliance',
    ProductCompliance = 'product_compliance',
    SupplierBooking = 'supplier_booking',
    DefectCategory = 'defect_category',
    SupplierQc = 'supplier_qc',
    Quality = 'quality'
}

export function FeatureFromJSON(json: any): Feature {
    return FeatureFromJSONTyped(json, false);
}

export function FeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feature {
    return json as Feature;
}

export function FeatureToJSON(value?: Feature | null): any {
    return value as any;
}

