import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DocumentationType } from '../../../backend_api/models/DocumentationType';
import { AuditReportCustomFieldsDocumentation } from '../../../backend_api/models/AuditReportCustomFieldsDocumentation';
import { TranslateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import ComponentsSeparatedBy from '../../../base/ui/components/util/ComponentsSeparatedBy';
import SupplierPopup from '../../suppliers/components/SupplierPopup';
import GroupMembersPopup from '../../groups/components/GroupMembersPopup';
import UserPopup from '../../users/components/UserPopup';
import { BasicUser } from '../../../backend_api/models/BasicUser';
import { Group } from '../../../backend_api/models/Group';

interface DocumentationTypeTooltipContentProps {
    documentationType: AuditReportCustomFieldsDocumentation | DocumentationType;
}

// Transform AuditReportCustomFieldsDocumentation to match DocumentationType structure
const normalizeDocumentationType = (
    documentationType: AuditReportCustomFieldsDocumentation | DocumentationType
): DocumentationType => {
    if ('documentation_type_name' in documentationType) {
        return {
            id: documentationType.documentation_type_id,
            code: documentationType.documentation_type_code,
            name: documentationType.documentation_type_name,
            description: documentationType.documentation_type_description,
            requires_approval: documentationType.requires_approval,
            requires_expiry: documentationType.requires_expiry,
            responsible_groups: documentationType.responsible_groups,
            responsible_users: documentationType.responsible_users,
            detailed_supplier_documents: [] // This field might not be available in AuditReportCustomFieldsDocumentation
        } as DocumentationType;
    }
    return documentationType;
};

export const DocumentationTypeTooltipContent: React.FC<DocumentationTypeTooltipContentProps> = ({
    documentationType
}) => {
    // Transform the documentationType to ensure consistent structure
    const normalizedDocumentationType = normalizeDocumentationType(documentationType);

    const documentationTypeTooltipContent = (
        <div className='p-1'>
            <div className="text-normal text-base font-bold text-primary pb-3">
                <TranslateTextWithTranslation twt={normalizedDocumentationType.name} />
            </div>
            {normalizedDocumentationType.description.text && (
                <>
                    <div className="text-secondary text-normal text-sm">
                        <FormattedMessage id={"production_units.supplier_document.document_description"} />
                    </div>
                    <div className="text-normal text-base text-primary pb-3">
                        <TranslateTextWithTranslation twt={normalizedDocumentationType.description} />
                    </div>
                </>
            )}
            <div className="text-secondary text-normal text-sm">
                <FormattedMessage id={"production_units.supplier_document.responsible_for_approval"} />
            </div>
            {(normalizedDocumentationType.responsible_groups.length > 0 || normalizedDocumentationType.responsible_users.length > 0) ? (
                <ComponentsSeparatedBy
                    components={[
                        ...normalizedDocumentationType.responsible_groups.map(group => (
                            group.is_supplier_group ? (
                                <SupplierPopup
                                    key={group.id}
                                    supplier={{
                                        id: group.id,
                                        name: group.name
                                    }}
                                />
                            ) : (
                                <GroupMembersPopup
                                    key={group.id}
                                    group={{ ...group, is_supplier_group: false } as Group}
                                />
                            )
                        )),
                        ...normalizedDocumentationType.responsible_users.map(user => (
                            <UserPopup
                                key={user.id}
                                user={{
                                    ...user,
                                    disabled: false,
                                    last_activity: null,
                                    supplier_user: false
                                } as BasicUser}
                            />
                        ))
                    ]}
                    separateBy=","
                />
            ) : (
                <div className="text-normal text-secondary italic text-base text-primary pb-3">
                    <FormattedMessage id={"globals.not_set"} />
                </div>
            )}
        </div>
    );

    return documentationTypeTooltipContent;
};

export default DocumentationTypeTooltipContent;
