import classnames from 'classnames';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';
import { initializeAppData } from '../base/actions';
import ErrorBoundary from '../base/components/ErrorBoundary';
import Routes, { isWBARoute } from '../base/routes';
import { getApplicationReady, getLayoutSelector } from '../base/selectors';
import { AppState } from '../base/types';
import Snackbar from '../base/ui/components/snack/Snackbar';
import { setViewportSize } from '../base/utils';
import { initAuthorisation, setRedirectUrl, TEMPORARY_USER_MODE } from '../modules/authentication/authenticationSlice';
import SignedOutModal from '../modules/authentication/components/SignedOutModal';
import { getAuthenticated, getRedirectURL } from '../modules/authentication/selectors';
import { Footer } from '../modules/header/components';
import Header from '../modules/header/components/Header';
import Messages from '../modules/messages/containers/Messages';
import { useAppDispatch } from '../store';
import GlobalNavigation from '../modules/header/components/GlobalNavigation';

const App = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const applicationReady = useSelector(getApplicationReady);
    const isAuthenticated = useSelector(getAuthenticated);
    const temporaryUser = sessionStorage.getItem(TEMPORARY_USER_MODE) || isWBARoute(location.pathname);
    const layout = useSelector(getLayoutSelector);
    const redirectURL = useSelector(getRedirectURL);
    const networkBusy = useSelector((state: AppState) => state.app.BASE.networkBusy);
    let networkBusySpan = null;
    if (networkBusy) {
        networkBusySpan = (<span data-test-id='network-busy' />);
    }
    const configureViewPort = () => {
        if (window.location.pathname.indexOf('report') > -1 ||
            window.location.pathname === '/q/app/checklists' ||
            window.location.pathname === '/q/app/inspections' ||
            window.location.pathname === '/q/app/login' ||
            window.location.pathname === '/q/app/reset_pw' ||
            window.location.pathname === '/q/app/test' ||
            window.location.pathname === '/q/app/developer/frontend-elements' ||
            window.location.pathname.indexOf('compliance') > 0 ||
            window.location.pathname.indexOf('production_units') > 0 ||
            window.location.pathname.indexOf('suppliers') > 0 ||
            window.location.pathname.indexOf('system_settings/documentation_approval') > 0 ||
            window.location.pathname.indexOf('dashboard/defects/browse') > 0 ||
            window.location.pathname.indexOf('ca') > 0 ||
            isWBARoute(window.location.pathname) ||
            window.location.pathname.includes('/q/app/audits/perform_audit') ||
            window.location.pathname.includes('/q/app/audits/edit_audit') ||
            window.location.pathname === '/q/app/data_export_files' ||
            window.location.pathname === '/q/app/get_app' ||
            window.location.pathname === '/q/app/get_android_app' ||
            window.location.pathname.indexOf('developer') > 0 ||
            window.location.pathname === '/q/app') {
            setViewportSize('mobile');
        } else {
            setViewportSize('default');
        }
    };

    useEffect(() => {
        configureViewPort();
        dispatch(initAuthorisation());
    }, []);

    useEffect(() => {
        dispatch(initializeAppData(location, isAuthenticated, !!temporaryUser));
    }, [isAuthenticated, temporaryUser]);

    useEffect(() => {
        if (isAuthenticated && redirectURL && redirectURL !== 'done') {
            history.push(redirectURL);
            dispatch(setRedirectUrl('done'));
        }
    }, [redirectURL !== undefined && isAuthenticated]);

    return (
        <div className={classnames('main min-h-page', { fixed: layout.bodyFixed })}>
            <Dimmer active={!applicationReady && isAuthenticated} inverted>
                <Loader className='dimLoader'><FormattedMessage id='globals.initialising_app.please_wait' /></Loader>
            </Dimmer>
            {networkBusySpan}
            {/* <Header
                isAuthenticated={isAuthenticated}
            /> */}
            <GlobalNavigation isAuthenticated={isAuthenticated} />
            <SignedOutModal />
            <Snackbar />
            {isAuthenticated && <Messages />}
            <ErrorBoundary>
                <Routes />
            </ErrorBoundary>
            <Footer />
        </div>
    );
};

export default App;
