import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dimmer, Form, Input, Loader, Modal } from 'semantic-ui-react';
import { ChecklistType } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import { getPermissionsSelector } from '../../../base/selectors';
import { ListView } from '../../globals/components/views/list/ListView';
import { DispatchSendStatusMessageFn } from '../../messages/actions';
import {
    DispatchCopyChecklistFn,
    DispatchCreateChecklistFn,
    DispatchDeleteChecklistFn,
    DispatchGetInspectionChecklistsFn
} from '../actions/actions';
import { Checklist } from '../types';
import { ChecklistItem } from './ChecklistItem';

type InspectionChecklistsProps = {
    isStandalone: boolean;
    isFetching: boolean;
    actions: {
        copyChecklist: DispatchCopyChecklistFn;
        createChecklist: DispatchCreateChecklistFn;
        deleteChecklist: DispatchDeleteChecklistFn;
        sendStatusMessage: DispatchSendStatusMessageFn;
        getInspectionChecklists: DispatchGetInspectionChecklistsFn;
    };
    checklists: Checklist[];
};

// TODO: AuditChecklists and InspectionChecklists are similar, we could refactor more, but it is not important right now.
export function InspectionChecklists(props: InspectionChecklistsProps): React.ReactElement {
    const [newChecklistName, setNewChecklistName] = React.useState('');
    const [shouldShowDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [shouldShowCreateDialog, setShowCreateDialog] = React.useState(false);
    const [toBeDeleted, setToBeDeleted] = React.useState({
        description: undefined,
        id: undefined,
    });

    React.useEffect(() => {
        props.actions.getInspectionChecklists();
    }, []);

    const permissions = useSelector(getPermissionsSelector);

    const allowedToCreateChecklists = (permissions || []).includes('u_add_checklists');

    const canDelete = props.checklists && props.checklists.length > 1

    function handleChange(evt: ChangeEvent, { value }: { value: string }): void {
        setNewChecklistName(value);
    }

    function handleCancel(): void {
        setShowCreateDialog(false)
        window.scrollTo(0, 0);
    }

    function handleClose(): void {
        setShowCreateDialog(false);
        window.scrollTo(0, 0);
    }

    function doShowCreateDialog(): void {
        setShowCreateDialog(true)
    }

    function doCreateChecklist(): void {
        if (newChecklistName && newChecklistName != '') {
            props.actions.createChecklist(newChecklistName, ChecklistType.Inspection);
            setShowCreateDialog(false);
        }
    }

    function doShowDeleteDialog(checklistItem: Checklist): void {
        const id = checklistItem.id;
        const description = checklistItem.description;
        setShowDeleteDialog(true)
        setToBeDeleted({ description, id });
    }

    function doDeleteChecklist(): void {
        props.actions.deleteChecklist(toBeDeleted.id);
        setShowDeleteDialog(false);
    }

    function handleDeleteCancel(): void {
        setShowDeleteDialog(false)
        window.scrollTo(0, 0);
    }

    function doCopyChecklist(checklistId: string, description: string): void {
        props.actions.copyChecklist(checklistId, description);
    }

    const createChecklistLabel = 'checklists.inspections.create';
    const headerLabel = 'checklists.inspections.checklists';

    return (
        <span className='checklists mb-16'>

            <div className='flex justify-between'>
                <h2 className='px-4 xl:px-0'><FormattedMessage id={headerLabel} /></h2>
                {allowedToCreateChecklists && <Button onClick={doShowCreateDialog} secondary dataTestId='create-inspection-checklist' className='text-sm sm:text-base'><FormattedMessage id={createChecklistLabel} /></Button>}
            </div>
            <Dimmer active={props.isFetching} inverted>
                <Loader className='dimLoader'><FormattedMessage
                    id='checklists.getting_data_please_wait' /></Loader>
            </Dimmer>
            <ListView className='my-8'>
                {props.checklists.map((item: Checklist) => {
                    return <ChecklistItem
                        type='inspection'
                        key={item.id}
                        item={item}
                        showDeleteDialog={doShowDeleteDialog}
                        canCreate={allowedToCreateChecklists}
                        canDelete={canDelete && item.features && item.features.includes('delete')}
                        canEdit={item.features && item.features.includes('edit')}
                        copyChecklist={doCopyChecklist} />
                })}
            </ListView>
            <Modal
                open={shouldShowCreateDialog}
                onClose={handleClose}
                closeIcon={true}
                size='small'
            >
                <Modal.Header><FormattedMessage id={createChecklistLabel} /></Modal.Header>
                <Modal.Content>
                    <Form onSubmit={doCreateChecklist}>
                        <Form.Field width={8}>
                            <label><FormattedMessage id='checklists.enter_checklist_name' /></label>
                            <Input
                                type='text'
                                autoFocus={true}
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions className='flex'>
                    <Button dataTestId='createInspectionChecklistButton' disabled={newChecklistName.length === 0} onClick={doCreateChecklist} primary>
                        <FormattedMessage id={createChecklistLabel} />
                    </Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </Modal.Actions>
            </Modal>
            <Modal
                open={shouldShowDeleteDialog}
                onClose={handleDeleteCancel}
                closeIcon={true}
                size='small'
            >
                <Modal.Header><FormattedMessage id='checklists.delete_checklist' /><FormattedMessage
                    id='checklists.question_marck' /></Modal.Header>
                <Modal.Content>
                    <span><FormattedMessage id='checklists.are_you_sure_you_want_to_delete_checklist' /><span
                        className='toBeDeleted'>{toBeDeleted.description}</span><FormattedMessage
                            id='checklists.this_action_cannot_be_undone' /></span>
                </Modal.Content>
                <Modal.Actions className='flex'>
                    <Button negative onClick={doDeleteChecklist}><FormattedMessage
                        id='checklists.delete_checklist' /></Button>
                    <Button onClick={handleDeleteCancel}><FormattedMessage id='checklists.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        </span>
    );
}