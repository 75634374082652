import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { getPrettyDate } from '../../../base/utils';
import { ShallowProduct } from '../../../compliance_api/models';

type Props = {
    className?: string;
    product: ShallowProduct;
};

const ComplianceProductDeadline = (props: Props): React.ReactElement => {
    const { product } = props;
    const deadlineClosed = false;
    const daysUntil = product.primary_deadline && product.primary_deadline.days_to_deadline || 0;
    const deadlineOverdue = daysUntil < 0;
    const hasDeadline = product.primary_deadline && product.primary_deadline.name !== null;
    
    const getText = (): React.ReactElement => {
        if (deadlineOverdue) {
            return deadlineOverdue && <FormattedMessage id={'compliance.products_list.deadline_missed_by_days'} values={{ days: Math.abs(daysUntil) }} />;
        }
        if (deadlineClosed) {
            return <FormattedMessage id='compliance.products_list.deadline_closed' />;
        }
        return <FormattedMessage id='compliance.products_list.deadline_days_until' values={{ days: daysUntil }} />;
    }
    return hasDeadline ? <Popup trigger={
        <div className={twMerge('space-x-1', deadlineOverdue && 'border-b-2 border-red-500')}>
            <span className='font-bold'>{getPrettyDate(product.primary_deadline && product.primary_deadline.date)}</span>
            <span>{product.primary_deadline && product.primary_deadline.name}</span></div>}>
        <span>{getText()}</span>
    </Popup> : <span>-</span>;
}
export default ComplianceProductDeadline;
