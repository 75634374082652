import React from 'react';
import { PressEvent, usePress } from 'react-aria';

import { DivProps } from 'react-html-props';
import { IconMappings } from '../../../../../figma/figma-icon-mappings';
import { SizeMap, Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import { Icons } from './iconTypes';

export const IconSizeMappings: SizeMap = {
    xsmall: 'text-small',
    small: 'text-base',
    medium: 'text-lg',
    large: 'text-xl',
    xlarge: 'text-2xl',
    '2xlarge': 'text-3xl',
    '3xlarge': 'text-4xl',
};

export enum LineHeights {
    Auto = 'leading-none',
    XSmall = 'leading-rem-xs',
    Small = 'leading-rem-sm',
    Base = 'leading-rem-base',
    Large = 'leading-rem-lg',
    XLarge = 'leading-rem-xl',
    XXLarge = 'leading-rem-2xl',
    XXXLarge = 'leading-rem-3xl',
}

interface Props extends DivProps {
    className?: string;
    size?: Sizes;
    name: Icons;
    label?: ComponentOrStringType;
    lineHeight?: LineHeights;
    onPress?(e: PressEvent): void;
}

const Icon = (props: Props): React.ReactElement => {
    const { className, size = Sizes.Medium, name, lineHeight = LineHeights.Auto, ...rest } = props;
    const iconMappedName = IconMappings[name];
    const sizeValue = IconSizeMappings[size];
    const base = '';
    const color = 'text-icon-default';
    const { pressProps } = usePress({
        onPress: (e) => {
            props.onPress && props.onPress(e);
            e.continuePropagation();
        }
    });
    return <div {...(props.onPress && pressProps)} className={twMerge('materialIcon grow-0', base, color, sizeValue, lineHeight, className)}  {...rest} >{iconMappedName}</div>
}

export default Icon;
 