import { add, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import DatePicker3 from '../../../../base/components/basic/DatePicker3';
import { Sizes } from '../../../../base/components/types';
import { DEFAULT_DATE_FORMAT2 } from '../../../../base/config';
import Button, { ButtonTypes } from '../../../../base/ui/components/buttons/Button';
import Label from '../../../../base/ui/components/labels/Label';
import { ListItem, ListItems, MainActionEnum } from '../../../../base/ui/components/listitems/ListItem';
import QModal, { ActionTypes } from '../../../../base/ui/components/modals/Modal';
import { getPrettyDate, isUndefinedOrNull, twMerge } from '../../../../base/utils';
import { DetailedDocumentViewApprovalEnum, DetailedDocumentViewTimeframe } from '../../../../compliance_api/models';
import { useAppDispatch } from '../../../../store';
import { complianceResetDocument, complianceSetDocumentExpiration, complianceSetDocumentStartDate } from '../../complianceSlice';
import { useSelector } from 'react-redux';
import { getComplianceDetailedDocumentIdSelector } from '../../selectors/ComplianceSelectors';

type Props = {
    className?: string;
    timeframe: DetailedDocumentViewTimeframe;
    productId: string;
    deadlineId: string;
    orderName?: string;
    state: DetailedDocumentViewApprovalEnum;
    displayDatesAreNotValid?: boolean;
    userCanSetTimeframe?: boolean;
    onValidityUpdated(valid: boolean): void;
    onClose(): void;

};

const ComplianceDocumentValidity = (props: Props): React.ReactElement => {
    const { className, timeframe, orderName, onValidityUpdated, state, onClose, displayDatesAreNotValid = false, userCanSetTimeframe, productId, deadlineId } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const documentId = useSelector(getComplianceDetailedDocumentIdSelector);

    const resetApproval = () => {
        dispatch(complianceResetDocument({ document_id: documentId, context: { product_id: productId, deadline_id: deadlineId } }));
    }
    const hasDates = () => {
        if (timeframe.type === 'no_expiration') {
            if (isUndefinedOrNull(timeframe.valid_after) || !isUndefinedOrNull(validAfter)) {
                return false;
            }
        }

        if (timeframe.type === 'interval') {
            if (isUndefinedOrNull(timeframe.valid_after) && isUndefinedOrNull(timeframe.valid_before)) {
                return false;
            };
        }

        return true;
    }
    const datesAreValid = (checkStored: boolean) => {
        if (timeframe.type === 'order') {
            return true;
        }
        if (timeframe.type === 'no_expiration') {
            return checkStored ? !isUndefinedOrNull(timeframe.valid_after) : !isUndefinedOrNull(validAfter);;
        }
        if (timeframe.type === 'frequency') {
            return checkStored ? !isUndefinedOrNull(timeframe.valid_after) : !isUndefinedOrNull(validAfter);;
        }

        if (timeframe.type === 'interval') {
            return checkStored ?
                ((!isUndefinedOrNull(timeframe.valid_after) && !isUndefinedOrNull(timeframe.valid_before)))
                :
                ((!isUndefinedOrNull(validAfter) && !isUndefinedOrNull(validBefore)));
        }
        return false;
    }

    const setExpirationDate = () => {
        // It is not possible to set expiration date, if timeframe.type is "no_expiration" or "order"
        dispatch(complianceSetDocumentExpiration({ document_id: documentId, expiration_date: validBefore, context: { product_id: productId, deadline_id: deadlineId } }));
    }
    const setStartDate = () => {
        // It is not possible to set start date, if timeframe.type is "order"
        dispatch(complianceSetDocumentStartDate({ document_id: documentId, start_date: validAfter, context: { product_id: productId, deadline_id: deadlineId } }))
    }
    const [showPickers, setShowPickers] = useState(!hasDates() && userCanSetTimeframe);
    const [validAfter, setValidAfter] = useState(null);
    const [validBefore, setValidBefore] = useState(null);
    const [displayNotValid, setDisplayNotValid] = useState(displayDatesAreNotValid || false);
    const [currentDateType, setCurrentDateType] = useState<'expiry' | 'start'>(null);
    const [resetApprovalModal, showResetApprovalModal] = useState(false);
    const setDates = () => {
        if (timeframe.type === 'no_expiration') {
            if (validAfter !== timeframe.valid_after) {
                setStartDate();
            }
        }
        if (timeframe.type === 'interval') {
            if (validBefore !== timeframe.valid_before) {
                setExpirationDate();
            }
            if (validAfter !== timeframe.valid_after) {
                setStartDate();
            }
        }
    }
    const getExpiryTypeLabel = () => {
        if (timeframe.type === 'no_expiration') {
            return "Validity";
        }
        if (timeframe.type === 'interval') {
            return "Validity"
        }
        if (timeframe.type === 'frequency') {
            return "Renewal period"
        }
        if (timeframe.type === 'order') {
            return <span>Order</span>
        }
        return 'Unsupported Timeframe'
    }

    const checkValidity = (checkStored: boolean) => {
        const valid = (datesAreValid(checkStored));
        onValidityUpdated(valid);
        setDisplayNotValid(!valid);
        return valid;
    }

    useEffect(() => {
        setDisplayNotValid(displayDatesAreNotValid);
    }, [displayDatesAreNotValid]);

    useEffect(() => {
        if (timeframe.type === 'interval') {
            (!validAfter || (validAfter !== timeframe.valid_after)) && setValidAfter(timeframe.valid_after);
            (!validBefore || (validBefore !== timeframe.valid_before)) && setValidBefore(timeframe.valid_before);
        }
        if (timeframe.type === 'no_expiration') {
            !validAfter && setValidAfter(timeframe.valid_after)
        }
        if (timeframe.type === 'frequency') {
            !validAfter && setValidAfter(timeframe.valid_after)
        }
        checkValidity(true);
    }, [timeframe]);

    useEffect(() => {
        // checkValidity();
    }, [validAfter, validBefore]);


    const showNoExpirationDatePicker = timeframe && timeframe.type === 'no_expiration';
    const showIntervalDatePicker = timeframe && timeframe.type === 'interval';

    return <div className={twMerge('', className)}>

        <h4 className='mb-2'>{getExpiryTypeLabel()}</h4>
        <ListItems className={'border bg-default border-b-0 border-default items-center w-full rounded-md'}>
            {(displayNotValid && !datesAreValid(true)) && <ListItem
                className='bg-surface-attention'>
                <div className=''>
                    <FormattedMessage id='compliance.document.validity.select_valid_time_frame' />
                </div>
            </ListItem>}
            {timeframe.type === 'order' && <ListItem
                icon={'expiry_by-order'}
            >
                <span className='font-bold'>{orderName}</span>{getPrettyDate(timeframe.date)}
            </ListItem>}
            {timeframe.type === 'no_expiration' && <ListItem
                icon={'expiry_never'}
                className='flex'
                mainAction={userCanSetTimeframe && !showPickers && {
                    type: MainActionEnum.Edit, onMainActionClick: () => {
                        if (state === DetailedDocumentViewApprovalEnum.Approved) {
                            showResetApprovalModal(true);
                        } else {
                            setShowPickers(true);
                        }
                    }
                }}
                inlineButton={showPickers && {
                    className: 'flex place-self-end justify-center pr-2 py-4',
                    button: <Button
                        className=''
                        size={Sizes.Small} primary
                        stopPropagation={true}
                        onPress={(e) => {
                            const valid = checkValidity(false);
                            if (valid) {
                                if (validAfter !== timeframe.valid_after) {
                                    setStartDate();
                                }
                                setShowPickers(false);
                            }
                        }}><FormattedMessage id='globals.ok' /></Button>
                }}
            >
                {showNoExpirationDatePicker && <>
                    {showPickers ? <div className='flex flex-col 2xl:flex-row gap-4'>
                        <div className='flex flex-col'>
                            <Label text={intl.formatMessage({ id: 'compliance.document.validity.label.valid_from' })} />
                            <DatePicker3
                                showClose={false}
                                maxDate={validBefore && new Date(validBefore)}
                                date={timeframe.valid_after}
                                handleChange={(d) => {
                                    setValidAfter(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    setCurrentDateType('start');
                                }} />
                        </div>
                        <div className='flex flex-col'>
                            <Label text='Expires' />
                            <div className='py-2'><FormattedMessage id='compliance.document.validity.label.never' /></div>
                        </div>
                    </div> : <div className='gap-2 flex'>
                        <div className='space-x-1'>
                            <span className='font-bold'><FormattedMessage id='compliance.document.validity.document_never_expires.text' /></span>
                            <span><FormattedMessage id='compliance.document.validity.document_never_expires.valid_from' values={{ date: getPrettyDate(timeframe.valid_after) }} /></span>
                        </div>
                    </div>}
                </>}
            </ListItem>}

            {timeframe.type === 'interval' && <ListItem
                icon={'operation_pick-date'}
                className='flex'
                mainAction={userCanSetTimeframe && !showPickers && {
                    type: MainActionEnum.Edit, onMainActionClick: () => {
                        if (state === DetailedDocumentViewApprovalEnum.Approved) {
                            showResetApprovalModal(true);
                        } else {
                            setShowPickers(true);
                        }
                    }
                }}
                inlineButton={showPickers && {
                    className: 'flex place-self-end justify-center pr-2 py-4',
                    button: <Button
                        className=''
                        size={Sizes.Small}
                        primary
                        stopPropagation={true}
                        onPress={() => {
                            const valid = checkValidity(false);
                            if (valid) {
                                setShowPickers(false);
                                if (state === DetailedDocumentViewApprovalEnum.Approved) {
                                    showResetApprovalModal(true);
                                } else {
                                    if (validBefore !== timeframe.valid_before) {
                                        setExpirationDate();
                                    }
                                    if (validAfter !== timeframe.valid_after) {
                                        setStartDate();
                                    }
                                }
                            }
                        }}><FormattedMessage id='globals.ok' /></Button>
                }}
            >
                {showIntervalDatePicker && <>
                    {showPickers ? <div className='flex flex-col 2xl:flex-row gap-4 items-end'>
                        <div className='flex flex-col'>
                            <Label text={intl.formatMessage({ id: 'compliance.document.validity.label.valid_from' })} />
                            <DatePicker3
                                className=''
                                showClose={false}
                                maxDate={validBefore && new Date(validBefore)}

                                date={timeframe.valid_after} handleChange={(d) => {
                                    setValidAfter(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    setCurrentDateType('start');
                                }} />
                        </div>
                        <div className='flex flex-col'>
                            <Label text={intl.formatMessage({ id: 'compliance.document.validity.label.to' })} />
                            <DatePicker3
                                className=''
                                showClose={false}
                                minDate={validAfter && new Date(validAfter)}
                                date={timeframe.valid_before} handleChange={(d) => {
                                    setValidBefore(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    setCurrentDateType('expiry');
                                }} /></div>
                    </div> :
                        <div className='gap-2 flex'><FormattedMessage id='compliance.document.validity.document_valid' /> <span className='font-bold'>{getPrettyDate(validAfter)}</span>-<span className='font-bold'>{getPrettyDate(validBefore)}</span></div>}
                </>}
            </ListItem>}
            {timeframe.type === 'frequency' && <ListItem
                icon={'expiry_renewal'}
                className='flex'
                mainAction={userCanSetTimeframe && !showPickers && {
                    type: MainActionEnum.Edit, onMainActionClick: () => {
                        if (state === DetailedDocumentViewApprovalEnum.Approved) {
                            showResetApprovalModal(true);
                        } else {
                            setShowPickers(true);
                        }
                    }
                }}
                inlineButton={showPickers && {
                    className: 'flex place-self-end justify-center pr-2 py-4',
                    button: <Button
                        className=''
                        size={Sizes.Small}
                        primary
                        stopPropagation={true}
                        onPress={() => {
                            const valid = checkValidity(false);
                            if (valid) {
                                setShowPickers(false);
                                if (state === DetailedDocumentViewApprovalEnum.Approved) {
                                    showResetApprovalModal(true);
                                } else {
                                    if (validAfter !== timeframe.valid_after) {
                                        setStartDate();
                                    }
                                }
                            }
                        }}><FormattedMessage id='globals.ok' /></Button>
                }}
            >

                {showPickers ? <div className='flex flex-col 2xl:flex-row gap-4 items-end'>
                    <div className='flex flex-col'>
                        <Label text={intl.formatMessage({ id: 'compliance.document.validity.label.valid_from' })} />
                        <DatePicker3
                            className=''
                            showClose={false}
                            maxDate={validBefore && new Date(validBefore)}

                            date={timeframe.valid_after} handleChange={(d) => {
                                setValidAfter(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                setCurrentDateType('start');
                            }} />
                    </div>
                    <div className='flex flex-col'>
                        <Label text={intl.formatMessage({ id: 'compliance.document.validity.label.next_renewal' })} />
                        <div className='py-2 flex gap-x-1 bg-secondary px-3 whitespace-nowrap leading-7'>
                            <div className='text-secondary'>
                                +{timeframe.frequency_value}{intl.formatMessage({ id: 'compliance.document.validity.frequency_type.' + timeframe.frequency_type })}
                            </div>
                            {getPrettyDate(add(new Date(validAfter), { months: timeframe.frequency_value }))}
                        </div>
                    </div>
                </div> :
                    <div className='gap-2 flex'>
                        <FormattedMessage id='compliance.document.validity.frequency.starts' /> <span className='font-bold'>{getPrettyDate(validAfter)}</span>
                        -
                        <span className='flex gap-x-1'><FormattedMessage id='compliance.document.validity.frequency.next_renewal' /><span className='font-bold'>{getPrettyDate(add(new Date(validAfter), { months: timeframe.frequency_value }))}</span></span>
                    </div>}

            </ListItem>}
        </ListItems>
        <QModal
            width={500}
            isOpen={resetApprovalModal}
            header='Reset approval'
            content={<>
                <FormattedMessage id='compliance.document.validity.change_date_and_remove_approval' />
            </>}
            actionItems={[
                {
                    type: ActionTypes.Action, text: 'Yes, reset approval', buttonType: ButtonTypes.Primary, event: () => {
                        resetApproval();
                        setShowPickers(true);
                        // onClose();
                    }
                },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showResetApprovalModal(false) },
            ]}
        />
    </div>
}
export default ComplianceDocumentValidity;
