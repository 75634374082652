/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 112.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetailedGroup,
    DetailedGroupFromJSON,
    DetailedGroupFromJSONTyped,
    DetailedGroupToJSON,
    DisplayUser,
    DisplayUserFromJSON,
    DisplayUserFromJSONTyped,
    DisplayUserToJSON,
    FileResource,
    FileResourceFromJSON,
    FileResourceFromJSONTyped,
    FileResourceToJSON,
    TextWithTranslation,
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
} from './';

/**
 * Production Unit detailed supplier document.
 * @export
 * @interface DisplayDetailedSupplierDocument
 */
export interface DisplayDetailedSupplierDocument {
    /**
     * 
     * @type {DisplayUser}
     * @memberof DisplayDetailedSupplierDocument
     */
    approver: DisplayUser | null;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayDetailedSupplierDocument
     */
    archived: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisplayDetailedSupplierDocument
     */
    documentation_type_code: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof DisplayDetailedSupplierDocument
     */
    documentation_type_description: TextWithTranslation;
    /**
     * 
     * @type {string}
     * @memberof DisplayDetailedSupplierDocument
     */
    documentation_type_id: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof DisplayDetailedSupplierDocument
     */
    documentation_type_name: TextWithTranslation;
    /**
     * 
     * @type {string}
     * @memberof DisplayDetailedSupplierDocument
     */
    id: string;
    /**
     * 
     * @type {Array<FileResource>}
     * @memberof DisplayDetailedSupplierDocument
     */
    media_resources: Array<FileResource>;
    /**
     * 
     * @type {DisplayUser}
     * @memberof DisplayDetailedSupplierDocument
     */
    rejector: DisplayUser | null;
    /**
     * 
     * @type {Array<DetailedGroup>}
     * @memberof DisplayDetailedSupplierDocument
     */
    responsible_groups: Array<DetailedGroup>;
    /**
     * 
     * @type {Array<DisplayUser>}
     * @memberof DisplayDetailedSupplierDocument
     */
    responsible_users: Array<DisplayUser>;
    /**
     * 
     * @type {string}
     * @memberof DisplayDetailedSupplierDocument
     */
    state: DisplayDetailedSupplierDocumentStateEnum;
    /**
     * 
     * @type {DisplayUser}
     * @memberof DisplayDetailedSupplierDocument
     */
    uploader: DisplayUser | null;
    /**
     * 
     * @type {Date}
     * @memberof DisplayDetailedSupplierDocument
     */
    valid_from_date: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DisplayDetailedSupplierDocument
     */
    valid_to_date: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum DisplayDetailedSupplierDocumentStateEnum {
    FileAdded = 'file_added',
    WaitingForApproval = 'waiting_for_approval',
    Approved = 'approved',
    Rejected = 'rejected',
    Active = 'active',
    Expired = 'expired',
    Archived = 'archived',
    Deleted = 'deleted'
}

export function DisplayDetailedSupplierDocumentFromJSON(json: any): DisplayDetailedSupplierDocument {
    return DisplayDetailedSupplierDocumentFromJSONTyped(json, false);
}

export function DisplayDetailedSupplierDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayDetailedSupplierDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approver': DisplayUserFromJSON(json['approver']),
        'archived': json['archived'],
        'documentation_type_code': json['documentation_type_code'],
        'documentation_type_description': TextWithTranslationFromJSON(json['documentation_type_description']),
        'documentation_type_id': json['documentation_type_id'],
        'documentation_type_name': TextWithTranslationFromJSON(json['documentation_type_name']),
        'id': json['id'],
        'media_resources': ((json['media_resources'] as Array<any>).map(FileResourceFromJSON)),
        'rejector': DisplayUserFromJSON(json['rejector']),
        'responsible_groups': ((json['responsible_groups'] as Array<any>).map(DetailedGroupFromJSON)),
        'responsible_users': ((json['responsible_users'] as Array<any>).map(DisplayUserFromJSON)),
        'state': json['state'],
        'uploader': DisplayUserFromJSON(json['uploader']),
        'valid_from_date': (json['valid_from_date'] === null ? null : new Date(json['valid_from_date'])),
        'valid_to_date': (json['valid_to_date'] === null ? null : new Date(json['valid_to_date'])),
    };
}

export function DisplayDetailedSupplierDocumentToJSON(value?: DisplayDetailedSupplierDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approver': DisplayUserToJSON(value.approver),
        'archived': value.archived,
        'documentation_type_code': value.documentation_type_code,
        'documentation_type_description': TextWithTranslationToJSON(value.documentation_type_description),
        'documentation_type_id': value.documentation_type_id,
        'documentation_type_name': TextWithTranslationToJSON(value.documentation_type_name),
        'id': value.id,
        'media_resources': ((value.media_resources as Array<any>).map(FileResourceToJSON)),
        'rejector': DisplayUserToJSON(value.rejector),
        'responsible_groups': ((value.responsible_groups as Array<any>).map(DetailedGroupToJSON)),
        'responsible_users': ((value.responsible_users as Array<any>).map(DisplayUserToJSON)),
        'state': value.state,
        'uploader': DisplayUserToJSON(value.uploader),
        'valid_from_date': (value.valid_from_date === null ? null : value.valid_from_date.toISOString().substr(0,10)),
        'valid_to_date': (value.valid_to_date === null ? null : value.valid_to_date.toISOString().substr(0,10)),
    };
}


