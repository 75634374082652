import React from 'react';
import { twMerge } from '../../../utils';
import { FormattedMessage } from 'react-intl';

type Props = {
    className?: string;
    status: Status;
    text: string
    inverted?: boolean;
    border?: boolean;
};

export type Status = 'planned' | 'in-progress' | 'pending' | 'approved' | 'rejected' | 'expired' | 'ignored' | 'not-set' | 'archived';
type StatusIconColors = { background: string, color: string, invertedColor: string, invertedTextColor?: string, borderColor?: string };
type StatusMap = { [s in Status]: { color: StatusIconColors } };

const StatusMappings: StatusMap = {
    planned: { color: { background: 'status-planned', color: 'on-status', invertedColor: 'default', invertedTextColor: 'brand', borderColor: 'status-planned' } },
    "in-progress": { color: { background: 'status-in-progress', color: 'on-status', invertedColor: 'default', invertedTextColor: 'brand', borderColor: 'status-in-progress' } },
    pending: { color: { background: 'status-pending', color: 'on-status', invertedColor: 'default', invertedTextColor: 'status-pending', borderColor: 'status-pending' } },
    approved: { color: { background: 'status-approved', color: 'on-status', invertedTextColor: 'status-approved', invertedColor: null, borderColor: 'status-approved' } },
    rejected: { color: { background: 'status-rejected', color: 'on-status', invertedColor: 'default', invertedTextColor: 'status-rejected', borderColor: 'status-rejected' } },
    expired: { color: { background: 'status-rejected', color: 'on-status', invertedColor: 'default', invertedTextColor: 'status-rejected', borderColor: 'status-rejected' } },
    ignored: { color: { background: 'status-ignored', color: 'on-status', invertedTextColor: 'secondary', invertedColor: 'status-ignored', borderColor: 'status-ignored' } },
    archived: { color: { background: 'status-ignored', color: 'on-status', invertedTextColor: 'secondary', invertedColor: 'status-ignored', borderColor: 'status-ignored' } },
    "not-set": { color: { background: 'status-none-planned', color: 'secondary', invertedTextColor: 'secondary', invertedColor: null, borderColor: 'secondary' } },
}

const StatusMarker = (props: Props): React.ReactElement => {
    const { className, status, inverted = false, text, border } = props;
    const item = StatusMappings[status];
    return <div className={twMerge('justify-center items-center leading-none rounded-md whitespace-nowrap inline-block px-3 flex flex-row', border && ['border rounded-full', 'border-' + item.color.borderColor], inverted ? ['bg-' + item.color.invertedColor, 'text-' + item.color.invertedTextColor] : ['bg-' + item.color.background, 'text-' + item.color.color], className)}>
        <span><FormattedMessage id={text} /></span>
    </div>;
}
export default StatusMarker;
