/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileResource } from './FileResource';

/**
 *
 *
 */
export type AuditReportCustomFieldsDocumentationDocument = {
    document_viewer_url: string;
    expiry_date: string;
    'expiry_required?': boolean;
    media_resources: Array<FileResource>;
    'previously_added?': boolean;
    start_date: string;
    status: AuditReportCustomFieldsDocumentationDocument.status;
};

export namespace AuditReportCustomFieldsDocumentationDocument {

    export enum status {
        FILE_ADDED = 'file_added',
        ACTIVE = 'active',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        EXPIRED = 'expired',
        ARCHIVED = 'archived',
        DELETED = 'deleted',
    }


}

