import React, { ReactNode, useEffect, useState } from 'react';
import { Button as AriaButton, Dialog, DialogTrigger, Form, Modal, ModalOverlay, PressEvent } from 'react-aria-components';
import RemoveIcon from '../../../components/basic/RemoveIcon';
import { Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import Button, { ButtonTypes } from '../buttons/Button';
import { Icons } from '../icons/iconTypes';
import './modal.css';
import { FormattedMessage } from 'react-intl';

type Props = {
    className?: string;
    headerClassName?: string;
    headerContentClassName?: string;
    triggerContainerClass?: string;
    overlayClass?: string;
    header?: ComponentOrStringType;
    subHeader?: ComponentOrStringType;
    content?: ReactNode;
    children?: ComponentOrStringType;
    action?: typeof Button;
    secondaryAction?: React.ReactElement;
    cancelAction?: typeof Button;
    altAction?: typeof Button;
    trigger?: ReactNode;
    isOpen?: boolean;
    width?: number;
    widthFull?: boolean;
    noMaxHeight?: boolean;
    hideHeaderAndFooter?: boolean;
    animate?: { type: 'slide-up' }
    onOpenChange?(open: boolean): void;
    onAction?(e: PressEvent): void;
    onCancelAction?(e: PressEvent): void;
    onSecondaryAction?(e: PressEvent): void;
    onAltAction?(e: PressEvent): void;
    actionItems?: ActionItem[];
    showCloseButton?: boolean;
    cancelClose?: boolean;
    onClose?();
    
};
type ActionItem = {
    type: ActionTypes,
    buttonType: ButtonTypes;
    icon?: Icons;
    event(e: PressEvent): void
    text: string;
    disabled?: boolean;
    dataTestId?: string;
    stayOpen?: boolean;
}
export enum ActionTypes {
    AltAction = 'altAction',
    SecondaryAction = 'secondaryAction',
    Action = 'primaryAction',
    CancelAction = 'cancelAction',
}

const QModal = (props: Props): React.ReactElement => {
    const { className, actionItems, trigger, onOpenChange, width, animate, header, content, subHeader, children, widthFull = true, hideHeaderAndFooter = false, headerClassName, headerContentClassName, triggerContainerClass, showCloseButton = true, cancelClose = false, noMaxHeight = false, onClose } = props;
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const animateClass = animate && 'modal-' + animate.type;
    const widthClass = width ? '' : widthFull ? 'w-full' : 'w-full sm:w-1/2 lg:w-1/3';
    const contentCss = twMerge('', !noMaxHeight && 'max-h-[70vh] overflow-y-auto', !noMaxHeight ? 'p-6' : 'p-0');
    const actionsCss = 'border-t px-6 py-4 bg-surface-plain justify-end flex gap-4';
    const overlayClass = twMerge('fixed top-0 overflow-y-auto left-0 w-screen h-screen flex items-center justify-center bg-gray-700/70 z-10', !noMaxHeight && '', props.overlayClass);
    const close = () => setIsOpen(false);
    const closeButton =
        <RemoveIcon onClick={() => {
            close();
            onClose && onClose();
        }} />

    const actions = actionItems && actionItems.filter((a) => a && a.type !== ActionTypes.AltAction);
    const altActions = actionItems && actionItems.filter((a) => a && a.type === ActionTypes.AltAction);
    const getButton = (ai: ActionItem) => {
        return ai && <Button
            type='submit'
            buttonType={ai.buttonType}
            icon={ai.icon}
            disabled={ai.disabled}
            onPress={!ai.disabled ? (e) => {
                ai.event ? ai.event(e) : null;
                (!cancelClose && !ai.stayOpen) && close();
            } : null}
            size={Sizes.Small}
            dataTestId={ai.dataTestId}
            className=''><FormattedMessage id={ai.text} /></Button>
    }
    const getButtons = () => {
        let _actions = [];
        let _altActions = [];
        (Object.values(ActionTypes) as Array<ActionTypes>).map((at) => {
            (actions && actions.filter((ai) => {
                if (ai.type.toLowerCase() === at.toLowerCase()) {
                    _actions.push(ai);
                }
            }));
            altActions && altActions.filter((ai) => {
                if (ai.type.toLowerCase() === at.toLowerCase()) {
                    _altActions.push(ai);
                }
            });
        });
        return <div className={twMerge('flex gap-4 w-full', _altActions.length > 0 ? 'justify-between' : 'justify-end')}>
            {_altActions && _altActions.map((ai) => getButton(ai))}
            <div className='flex gap-4 '>{_actions && _actions.map((ai) => getButton(ai))}</div>
        </div>
    }
    useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen]);
    return <DialogTrigger isOpen={isOpen} onOpenChange={(open) => {
        onOpenChange && onOpenChange(open);
        setIsOpen(open);
    }}>
        {trigger && <AriaButton className={triggerContainerClass}>{trigger}</AriaButton>}
        <ModalOverlay className={twMerge(overlayClass)}>
            <Modal isDismissable
                onOpenChange={setIsOpen}
                isOpen={isOpen}
                style={width ? { width: width + 'px' } : null} className={twMerge('bg-white border shadow-xl rounded w-full', widthClass, animateClass, className)}>
                <Dialog className='outline-none'>
                    {({ close }) =>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            {!hideHeaderAndFooter &&
                                <div className={twMerge('border-b py-5 px-6 font-bold text-xl flex flex-col gap-3', !noMaxHeight && 'sticky top-0 bg-white ', subHeader && 'gap-0', headerClassName)}>
                                    <div className={twMerge('flex w-full', header ? 'justify-between' : 'justify-end', headerContentClassName)}>
                                        {header}
                                        {showCloseButton && closeButton}
                                    </div>
                                    {subHeader && <div className='flex text-secondary text-base font-normal'>{subHeader}</div>}

                                </div>}
                            {content && <div className={twMerge(contentCss)}>
                                {content}</div>}
                            {children && <div className={twMerge(contentCss)}>{children}</div>}
                            {!hideHeaderAndFooter && actionItems?.length > 0 &&
                                <div className={twMerge(!noMaxHeight && 'sticky bottom-0', actionsCss)}>
                                    {getButtons()}
                                </div>}
                        </Form>}
                </Dialog>
            </Modal>
        </ModalOverlay>
    </DialogTrigger>

}
export default QModal;
