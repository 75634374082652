/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DisplayCustomFieldSupplierDocumentValue } from './DisplayCustomFieldSupplierDocumentValue';

/**
 * Production Unit Custom Field definition inside detailed production unit.
 *
 */
export type DisplayCustomFieldSupplierDocuments = {
    supplier_documents: Array<DisplayCustomFieldSupplierDocumentValue>;
    type: DisplayCustomFieldSupplierDocuments.type;
};

export namespace DisplayCustomFieldSupplierDocuments {

    export enum type {
        SUPPLIER_DOCUMENT = 'SupplierDocument',
    }


}

