import React from 'react';
import { useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from '../../../base/hooks';
import { Search } from '../../../base/ui/components/fields/Field';
import { twMerge, useIsTabletDevice } from '../../../base/utils';
import useCurrentSearchContext, { searchContext, SearchContextEnum } from './useCurrentSearchContext';

type Props = {
    className?: string;
};

const GlobalSearch = (props: Props): React.ReactElement => {
    const { className } = props;
    const history = useHistory();
    const isMobile = useIsTabletDevice();
    const { context: currentSearchContext, changeContext, showContextSwitcher, showSearchField } = useCurrentSearchContext();
    const contextOptions = Object.values(searchContext).map((searchContext) => ({
        key: searchContext.id,
        value: searchContext.id,
        text: useTranslation(searchContext.switcherText),
    }));

    const renderContextSwitch = (): React.ReactElement => {

        return (<div>
            <Dropdown
                style={{ borderRadius: '0.375rem 0 0 0.375rem', borderRight: 0, color: 'var(--text-text-secondary)' }} //TODO: using styles to overwrite Semantic-ui default styles
                value={currentSearchContext.id}
                onChange={(e, item) => changeContext(item.value as SearchContextEnum)}
                options={contextOptions}
                selection
                fluid
            />
            </div>
        );
    };

    const contextSwitch = !isMobile && showContextSwitcher && renderContextSwitch();
    const handleSearch = (query: string): void => {
        history.push(currentSearchContext.url + '?' + currentSearchContext.query + '=' + query);
    }

    return (
            showSearchField && <Search
                context={contextSwitch}
                className={twMerge('text-primary', className)}
                onSubmit={handleSearch}
                placeholder={useTranslation(showContextSwitcher ? 'globals.search' : currentSearchContext.placeholderText) as string}
                data-test-id='input-search'
            />
        
    );

}
export default GlobalSearch;
