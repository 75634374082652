export const IconMappings = {
    "callout_warning-circle": "error",
    "callout_assistant": "auto_awesome",
    "callout_required": "emergency",
    "callout_no-media": "no_photography",
    "callout_info": "info",
    "callout_tip": "tips_and_updates",
    "callout_link-active": "link",
    "callout_link-inactive": "link_off",
    "callout_tap-here": "touch_app",
    "callout_auto-translated": "translate",
    "microinteraction_close": "close",
    "microinteraction_drop-down": "arrow_drop_down",
    "microinteraction_expand": "expand_more",
    "microinteraction_collapse": "expand_less",
    "microinteraction_open": "navigate_next",
    "microinteraction_context-menu": "more_horiz",
    "microinteraction_external-link": "launch",
    "microinteraction_navigate-next-item": "navigate_next",
    "microinteraction_navigate-previous-item": "navigate_before",
    "microinteraction_back": "arrow_back",
    "microinteraction_menu": "menu",
    "microinteraction_next": "arrow_forward",
    "microinteraction_open-modal": "open_in_full",
    "operation_pick-date": "calendar_today",
    "operation_search": "search",
    "operation_get-info": "info_outline",
    "operation_delete": "delete",
    "operation_add": "add",
    "operation_download": "download",
    "operation_review": "assignment",
    "operation_replace": "sync",
    "operation_edit": "edit",
    "operation_remove": "close",
    "operation_settings": "settings",
    "operation_show-filter": "filter_alt",
    "operation_submit": "arrow_forward",
    "operation_zoom-in": "zoom_in",
    "operation_zoom-out": "zoom_out",
    "operation_view-gallery": "apps",
    "operation_take-photo": "photo_camera",
    "operation_sign-out": "logout",
    "operation_get-help": "help_outline",
    "operation_switch-organisation": "switch_camera",
    "operation_transfer-down": "move_down",
    "operation_reorder": "import_export",
    "operation_attach": "attach_file",
    "operation_sign-in": "login",
    "operation_upload": "file_upload",
    "operation_send": "send",
    "operation_add_media": "add_a_photo",
    "operation_use-wizard": "auto_fix_high",
    "operation_rotate-left": "rotate_left",
    "operation_rotate-right": "rotate_right",
    "operation_drag": "drag_indicator",
    "operation_copy": "content_copy",
    "operation_transfer-up": "move_up",
    "operation_action-undo": "undo",
    "operation_action-redo": "redo",
    "operation_archive": "archive",
    "operation_unarchive": "unarchive",
    "status_approved": "check",
    "status_approved-circle": "task_alt",
    "status_ignored": "visibility_off",
    "status_rejected": "remove",
    "status_rejected-circle": "remove_circle_outline",
    "status_checked-ok": "check",
    "status_checked-not-ok": "priority_high",
    "status_pending-circle": "help_outline",
    "status_not-applicable": "do_disturb",
    "status_planned": "pause",
    "status_in-progress": "play_arrow",
    "status_report": "check",
    "status_checked-ok-circle": "task_alt",
    "status_checked-not-ok-circle": "error_outline",
    "status_pending": "question_mark",
    "status_expired": "report_problem",
    "user_user": "person",
    "user_group": "group",
    "user_supplier": "apartment",
    "user_production-unit": "factory",
    "user_product-responsible": "assignment_ind",
    "user_approver": "how_to_reg",
    "user_watcher": "visibility",
    "user_user-external": "forward_to_inbox",
    "object_file": "insert_drive_file",
    "object_product": "local_offer",
    "object_products-plural": "discount",
    "object_comment": "chat_bubble_outline",
    "object_number": "numbers",
    "object_checklist": "assignment",
    "object_corrective-action": "assignment_turned_in",
    "object_audit": "apartment",
    "object_inspection": "playlist_add_check",
    "object_finding": "assignment_late",
    "object_attachement": "attachment",
    "object_qr-code": "qr_code_2",
    "object_note": "sticky_note_2",
    "object_audit-type": "manage_search",
    "object_timescale": "timer",
    "object_media": "photo_camera",
    "object_map-point": "place",
    "object_due-date": "calendar_today",
    "object_evidence-written": "edit_note",
    "object_report": "description",
    "object_order-data": "list_alt",
    "object_archive": "inventory",
    "expiry_time-based": "date_range",
    "expiry_never": "all_inclusive",
    "expiry_by-order": "local_shipping",
    "expiry_renewal": "event_repeat",
    "toggle_check-box": "check",
    "toggle_switch-on": "toggle_on",
    "toggle_switch-off": "toggle_off",
    "toggle_radio-button": "fiber_manual_record",
    "toggle_visibility-on": "visibility",
    "toggle_visibility-off": "visibility_off",
    "cloud_synced": "cloud_queue",
    "cloud_locked": "lock",
    "cloud_download": "cloud_download",
    "cloud_upload": "cloud_upload",
    "cloud_finished": "cloud_done"
}
