import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ListableProductionUnit, SimpleSupplierDocument, SimpleSupplierDocumentStateEnum } from '../../../backend_api/models';
import { Feature } from '../../../backend_api/models';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { getFeaturesSelector, getPermissionsSelector } from '../../../base/selectors';
import { getLocaleLanguageString, getPrettyDate, getTranslation, isUndefinedOrNull, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { ListView, ListViewHeader, ListViewHeaderItem, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import ListProductionUnitStatus from '../ListProductionUnitStatus';
import { fetchSupplierDocument, getListableProductionUnitsSelector, productionUnitsSliceActions } from '../slice/productionUnitsSlice';
import { Sizes } from '../../../base/ui/components/types';
import { Popup } from 'semantic-ui-react';
import StatusMarker, { Status } from '../../../base/ui/components/status/StatusMarker';
import Icon from '../../../base/ui/components/icons/Icon';
import Button from '../../../base/ui/components/buttons/Button';
import { useAppDispatch } from '../../../store';
// import SupplierDocumentViewer from '../../../base/ui/components/documentViewer/SupplierDocumentViewer';

type Props = {
    productionUnits?: ListableProductionUnit[];
    containerClassName?: string;
    listClassName?: string;
    confirmDeleteTextId: string;
    removeProductionUnit?(productionUnitId: string): void
}
export const BasicProductionUnitList = (props: Props): React.ReactElement => {
    const { containerClassName, listClassName } = props;
    const intl = useIntl();
    const canShowStatus = useSelector(getFeaturesSelector).includes(Feature.SupplierCompliance);
    const canShowDocumentation = useSelector(getPermissionsSelector).includes('u_documentation_types');
    const productionUnits = !isUndefinedOrNull(props.productionUnits) ? props.productionUnits : useSelector(getListableProductionUnitsSelector);
    const hasProductionUnits = productionUnits.length > 0;
    const formatMessage = intl.formatMessage;
    const dispatch = useAppDispatch();

    const handleViewDocument = (documentId: string) => {
        dispatch(fetchSupplierDocument({ id: documentId }));
    }

    let headerItems: ListViewHeaderItem[] = [];

    if (canShowStatus) {
        headerItems = [
            { label: formatMessage({ id: 'production_units.input_label.status' }), className: "sm:pr-4 sm:w-2/16", key: 'spacer.2' },
            { label: formatMessage({ id: 'production_units.input_label.unit_number' }), className: canShowDocumentation ? "w-3/16" : "w-6/16", key: 'item_number' },
            { label: formatMessage({ id: 'production_units.input_label.unit_name' }), className: canShowDocumentation ? "w-5/16" : "w-8/16", key: 'item_name' },
        ];
    } else {
        headerItems = [
            { label: formatMessage({ id: 'production_units.input_label.unit_number' }), className: canShowDocumentation ? "w-4/16" : "w-7/16", key: 'item_number' },
            { label: formatMessage({ id: 'production_units.input_label.unit_name' }), className: canShowDocumentation ? "w-6/16" : "w-9/16", key: 'item_name' },
        ];
    }

    if (canShowDocumentation) {
        headerItems.push({
            label: formatMessage({ id: 'production_units.input_label.documentation' }),
            className: props.removeProductionUnit ? 'w-5/16' : 'w-6/16',
            key: 'item_documentation'
        });
    }

    if (props.removeProductionUnit) {
        headerItems.push({ label: undefined, className: 'w-1/16 justify-end', key: 'spacer.delete' });
    }

    const content = <>
        {/* !hasProductionUnits && <div className='justify-center w-full flex'><InfoIcon text={intl.formatMessage({ id: 'production_units.no_production_units' })} /></div> */}
        {/* <SupplierDocumentViewer /> */}
        <ListView className={twMerge('mt-0 rounded-md', containerClassName)}>
            {hasProductionUnits && <ListViewHeader items={headerItems} />}
            {hasProductionUnits && <ListViewLines className={listClassName}>
                {productionUnits.map((productionUnit, i) => {
                    return <ListViewLine link={'/production_units/' + productionUnit.id} className={twMerge('border-t sm:border-t-0 justify-start pb-6 pt-6 hover:bg-highlight-green')} key={'production_units_' + productionUnit.id + '_' + i}>
                        {canShowStatus && <ListViewItem className='pr-1 sm:pr-4 sm:w-2/16 w-[100px]'><ListProductionUnitStatus status={productionUnit.status} /></ListViewItem>}
                        <ListViewItem className={`${canShowStatus ? (canShowDocumentation ? 'w-3/16' : 'w-6/16') : (canShowDocumentation ? 'w-4/16' : 'w-7/16')} pr-1 pl-3 sm:pl-0 font-bold`}>{productionUnit.number}</ListViewItem>
                        <ListViewItem className={`${canShowStatus ? (canShowDocumentation ? 'w-5/16' : 'w-8/16') : (canShowDocumentation ? 'w-6/16' : 'w-9/16')} grow pr-1`}>{productionUnit.name}</ListViewItem>
                        {canShowDocumentation &&
                            <ListViewItem className={props.removeProductionUnit ? 'w-5/16' : 'w-6/16 grow sm:grow-0'}>
                                <DocumentationPopup
                                    viewDocument={document => handleViewDocument(document.id)}
                                    productionUnit={productionUnit}
                                    trigger={<div className='flex flex-wrap gap-1 pt-3 sm:pt-0'>
                                        {
                                            productionUnit.supplier_documents.map((doc, i) => {
                                                return <div className={twMerge('justify-center inline px-1 min-w-[2rem] text-on-status rounded text-sm flex', mapDocumentationStateWithColor(doc))}>{doc.documentation_type_code}</div>;
                                            })
                                        }
                                    </div>}
                                />
                            </ListViewItem>
                        }
                        {
                            props.removeProductionUnit &&
                            <ListViewItem className='w-1/16 justify-end'>
                                <div onClick={(event) => preventDefaultAndStopPropagation(event)}>
                                    <DeleteConfirm
                                        deleteText={intl.formatMessage({ id: props.confirmDeleteTextId })}
                                        deleteFunction={() => props.removeProductionUnit(productionUnit.id)}
                                        trigger={<Button size={Sizes.Small}>
                                            <FormattedMessage id={props.confirmDeleteTextId} />
                                        </Button>} />
                                </div>
                            </ListViewItem>
                        }
                    </ListViewLine>
                })}
            </ListViewLines>}
        </ListView>
    </>
    return content
}

function mapDocumentationStateWithColor(documentation: SimpleSupplierDocument): string {
    if (documentation.archived) {
        return 'bg-status-ignored';
    }
    switch (documentation.state) {
        case SimpleSupplierDocumentStateEnum.FileAdded:
            return 'bg-status-in-progress';
        case SimpleSupplierDocumentStateEnum.WaitingForApproval:
            return 'bg-status-pending';
        case SimpleSupplierDocumentStateEnum.Active:
            return 'bg-status-planned';
        case SimpleSupplierDocumentStateEnum.Rejected:
            return 'bg-status-rejected';
        case SimpleSupplierDocumentStateEnum.Approved:
            return 'bg-status-approved';
        case SimpleSupplierDocumentStateEnum.Expired:
            return 'bg-status-rejected';
    }
}

function mapDocumentationStateWithName(documentation: SimpleSupplierDocument): string {
    if (documentation.archived) {
        return 'production_units.list.documentation.state.archived';
    }

    switch (documentation.state) {
        case SimpleSupplierDocumentStateEnum.FileAdded:
            return 'production_units.list.documentation.state.file_added';
        case SimpleSupplierDocumentStateEnum.WaitingForApproval:
            return 'production_units.list.documentation.state.in_review';
        case SimpleSupplierDocumentStateEnum.Active:
            return 'production_units.list.documentation.state.active';
        case SimpleSupplierDocumentStateEnum.Rejected:
            return 'production_units.list.documentation.state.rejected';
        case SimpleSupplierDocumentStateEnum.Approved:
            return 'production_units.list.documentation.state.approved';
        case SimpleSupplierDocumentStateEnum.Expired:
            return 'production_units.list.documentation.state.expired';
    }
}

function mapDocumentationStateWithStatus(documentation: SimpleSupplierDocument): Status {
    if (documentation.archived) {
        return 'archived';
    }

    switch (documentation.state) {
        case SimpleSupplierDocumentStateEnum.FileAdded:
            return 'in-progress';
        case SimpleSupplierDocumentStateEnum.WaitingForApproval:
            return 'pending';
        case SimpleSupplierDocumentStateEnum.Active:
            return 'planned';
        case SimpleSupplierDocumentStateEnum.Rejected:
            return 'rejected';
        case SimpleSupplierDocumentStateEnum.Approved:
            return 'approved';
        case SimpleSupplierDocumentStateEnum.Expired:
            return 'rejected';
    }
}

function mapDocumentationStateWithDate(documentation: SimpleSupplierDocument): string {
    const intl = useIntl();
    if (new Date(documentation.valid_to_date) < new Date()) {
        return intl.formatMessage({ id: 'production_units.list.documentation.date.expires' }) + " " + getPrettyDate(documentation.valid_to_date);
    }
    switch (documentation.state) {
        case SimpleSupplierDocumentStateEnum.FileAdded:
            return intl.formatMessage({ id: 'production_units.list.documentation.date.valid_from' }) + " " + getPrettyDate(documentation.valid_from_date);
        case SimpleSupplierDocumentStateEnum.WaitingForApproval:
            return intl.formatMessage({ id: 'production_units.list.documentation.date.valid_from' }) + " " + getPrettyDate(documentation.valid_from_date);
        case SimpleSupplierDocumentStateEnum.Active:
            return intl.formatMessage({ id: 'production_units.list.documentation.date.valid_from' }) + " " + getPrettyDate(documentation.valid_from_date);
        case SimpleSupplierDocumentStateEnum.Rejected:
            return intl.formatMessage({ id: 'production_units.list.documentation.date.expires' }) + " " + getPrettyDate(documentation.valid_to_date);
        case SimpleSupplierDocumentStateEnum.Approved:
            return intl.formatMessage({ id: 'production_units.list.documentation.date.expires' }) + " " + getPrettyDate(documentation.valid_to_date);
        case SimpleSupplierDocumentStateEnum.Expired:
            return intl.formatMessage({ id: 'production_units.list.documentation.date.expired' }) + " " + getPrettyDate(documentation.valid_to_date);
    }
}

function DocumentationPopup(props: { productionUnit: ListableProductionUnit, trigger: React.ReactElement, viewDocument: (doc: SimpleSupplierDocument) => void }): React.ReactElement {
    const { productionUnit, trigger, viewDocument } = props;
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);

    const [showPopup, setShowPopup] = React.useState(false);

    const handleViewDocument = (e, doc: SimpleSupplierDocument) => {
        preventDefaultAndStopPropagation(e);
        viewDocument(doc);
        setShowPopup(false);
    }

    return <>

        <Popup
            on="hover"
            trigger={trigger}
            position="top center"
            hideOnScroll={true}
            closeOnPortalMouseLeave={true}
            closeOnTriggerMouseLeave={true}
            closeOnDocumentClick={true}
            closeOnTriggerClick={true}
            open={showPopup}
            onOpen={() => setShowPopup(true)}
            onClose={() => setShowPopup(false)}
        >
            <Popup.Content>
                <div className='text-primary font-bold text-lg'>{<FormattedMessage id='production_units.input_label.documentation' />}</div>
                <div className="mb-4">
                    <div className='text-secondary text-sm flex flex-row gap-1 mb-4'>
                        <span className='font-bold whitespace-nowrap'>{productionUnit.number}</span>
                        <span>{productionUnit.name}</span>
                    </div>
                    {productionUnit.supplier_documents.map((doc, i) => {
                        return <div key={doc.id} className='flex flex-row justify-between gap-4 sm:gap-16 my-2 items-center cursor-pointer' onClick={(e) => handleViewDocument(e, doc)}>
                            <div className='flex flex-row gap-4 items-center'>
                                <StatusMarker className='min-w-[6.75rem] h-[1.5rem]' status={mapDocumentationStateWithStatus(doc)} text={mapDocumentationStateWithName(doc)} />
                                <div className='flex flex-row gap-1 items-center'>
                                    <div className='font-bold whitespace-nowrap'>{getTranslation(doc.documentation_type_name, lang)}</div>
                                    <Icon name='microinteraction_open' size={Sizes.Small} />
                                </div>
                            </div>
                            <div className='whitespace-nowrap'>{mapDocumentationStateWithDate(doc)}</div>
                        </div>
                    })
                    }

                    {productionUnit.supplier_documents.length === 0 &&
                        <div className='text-secondary border flex justify-center items-center p-4 sm:px-9 whitespace-nowrap mt-4 italic rounded-md text-secondary'>
                            <FormattedMessage id='production_units.popup.no_documents' />
                        </div>
                    }
                </div>
            </Popup.Content>
        </Popup>
    </>


}