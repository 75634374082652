import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SERVICES_PREFIX_COMPLIANCE } from '../../../base/config';
import { useIsMobileDevice } from '../../../base/utils';
import { DocumentAppliesToProduct, FileResource as ComplianceFileResource } from '../../../compliance_api/models';
import { FileResource } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import FileUpload from '../../files/components/FileUpload';
import { complianceAddFileToDocument } from '../complianceSlice';
import { getComplianceCurrentDocumentDataSelector } from '../selectors/ComplianceSelectors';


type Props = {
    className?: string;
    hasDropArea?: boolean;
    fileUploadRef?: any;
    hideUploadActions?: boolean;
    fileSelectorId?: string;
    fileSelectorLabelClass?: string;
    fileSelectorLabelText?: string;
    floating?: boolean;
    onUploadComplete(files: FileResource[] | ComplianceFileResource[]): void;
};

const AddFilesToDocument = (props: Props): React.ReactElement => {
    const isMobile = useIsMobileDevice();
    const { className, fileUploadRef, hideUploadActions = false, fileSelectorId, hasDropArea = false, floating = false } = props;
    const documentData = useSelector(getComplianceCurrentDocumentDataSelector);
    const hasDrop = !hasDropArea ? false : (!hideUploadActions && !isMobile);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const appliesTo: DocumentAppliesToProduct = {
        type: 'document_applies_to_product',
        product_id: ''
    }
    const styleProps = {
        fileSelectorLabelText: props.fileSelectorLabelText || intl.formatMessage({ id: 'compliance.document.add_files_.upload_text' }),
        fileSelectorCustomIconClass: 'text-on-surface-brand',
        fileSelectorLabelClass: props.fileSelectorLabelClass || 'bg-branding-brand text-on-surface-brand px-8 py-3 rounded',
        dropClass: hasDrop && 'px-48 pt-8 pb-72 sm:block',
    }
    const fsProps = {
        asFileResource: true,
        fileSelectorRef: fileUploadRef,
        fileSelectorId,
        hasDrop,
        endpoint: SERVICES_PREFIX_COMPLIANCE + '/upload_media',
        autoClearAfterUpload: true,
        returnMultipleWhenAllDone: true,
        inline: !floating,

    }
    const onUploadComplete = (_files: FileResource[]) => {
        const fileIds = _files.map((file) => file.id);
        dispatch(complianceAddFileToDocument({
            appliesTo,
            requirementId: documentData?.document?.requirement_specification_id,
            documentTemplateId: documentData?.document?.document_specification_id,
            productId: documentData?.context?.product_id,
            documentId: documentData?.documentId,
            fileIds,
            supplierId: '',
            orderId: '',
            supplierType: 'supplier',
            context: documentData?.context,
        }));

    }
    const upload = <FileUpload
        className={className}
        {...!hideUploadActions && styleProps}
        {...fsProps}
        dropPlacement='bottom'
        onUploadComplete={
            (file, files) => {
                onUploadComplete(files as FileResource[]); 
                props.onUploadComplete?.(files as FileResource[]);
            }}
    >
        {hasDrop && <div className='hidden sm:block'>
            <FormattedMessage id='compliance.document.add_files_.drop_area_text' /></div>}
    </FileUpload>

    return upload;
}
export default AddFilesToDocument;
