import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, Form, Grid, Icon, Popup } from 'semantic-ui-react';
import { ChecklistType, Feature } from '../../../backend_api/models';
import { useCheckHasFeature } from '../../../base/hooks';
import { getFeaturesSelector } from '../../../base/selectors';
import { AttachmentResponse } from '../../Images/actions/actions';
import FileGallery from '../../Images/components/FileGallery';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { MultiLanguageText } from '../../globals/types';
import { CheckpointData, CheckpointType, MeasurementTablesData, TextData, TextTable } from '../types';
import BarcodeDetails from './BarcodeDetails';
import InspectionCustomFields from './InspectionCustomFields';
import MeasurementTableDetails from './MeasurementTableDetails';
import MultipleChoiceDetails from './MultipleChoiceDetails';
import ProductionUnitCustomFieldDetails from './ProductionUnitCustomFieldDetails';
import SupplierCustomFieldDetails from './SupplierCustomFieldDetails';
import TextDetails from './TextDetails';
import TextTableDetails from './TextTableDetails';

const boxIndex = 5;
const borderColor = '#c3d0d9';
const backgroundColor = '#f3f6f9';


type CheckpointEditorProps = {
    checkpoint: CheckpointData;
    saveAndUpdate(checkpoint: CheckpointData);
    save(checkpoint: CheckpointData);
    close();
    language: string;
    reffOuter;
    reffInner;
    arrowRef;
    checklistType: ChecklistType;
}
const CheckpointEditor = (props: CheckpointEditorProps): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;

    const features = useSelector(getFeaturesSelector);
    const productionUnitCustomFieldsAllowed = useCheckHasFeature(features, Feature.SupplierCompliance);
    const supplierCustomFieldsAllowed = useCheckHasFeature(features, Feature.SupplierCompliance);

    function changeStatusEnabled(evt, p): void {
        props.saveAndUpdate({ ...props.checkpoint, status_enabled: p.checked, })
    }

    function changeSpaceEnabled(evt, p): void {
        props.saveAndUpdate({ ...props.checkpoint, has_spacer: p.checked, })
    }

    function changeMandatory(evt, p): void {
        props.saveAndUpdate({ ...props.checkpoint, mandatory: p.checked })
    }

    function changeIsPictureMandatory(evt, p): void {
        props.saveAndUpdate({ ...props.checkpoint, is_picture_mandatory: p.checked, })
    }

    function changeType(value): void {
        props.saveAndUpdate({ ...props.checkpoint, checkpoint_type: value })
    }

    function changeInstructionFiles(value): void {
        props.saveAndUpdate({ ...props.checkpoint, instruction_files: value })
    }

    function saveAndUpdateMeasurementTables(table: MeasurementTablesData): void {
        props.saveAndUpdate({ ...props.checkpoint, measurement_tables: table })
    }

    function saveMeasurementTables(table: MeasurementTablesData): void {
        props.save({ ...props.checkpoint, measurement_tables: table });
    }

    function saveTextInputs(table: TextData): void {
        props.save({ ...props.checkpoint, text_inputs: table });
    }

    function saveTextTable(table: TextTable): void {
        props.save({ ...props.checkpoint, text_table: table });
    }

    function saveInspectionCustomFields(customFieldIds: string[]): void {
        props.saveAndUpdate({
            ...props.checkpoint,
            custom_fields: customFieldIds
        });
    }

    function saveProductionUnitCustomFields(fields: any): void {
        props.saveAndUpdate({
            ...props.checkpoint,
            production_unit_custom_fields: fields
        });
    }

    function saveSupplierUnitCustomFields(fields: any): void {
        props.saveAndUpdate({
            ...props.checkpoint,
            supplier_custom_fields: fields
        })
    }

    function changeInstructionDetail(value: MultiLanguageText): void {
        props.saveAndUpdate({
            ...props.checkpoint,
            instruction_details: value
        })
    }

    function changeMultipleChoiceDetails(value): void {
        props.save({ ...props.checkpoint, multiple_choice: value })
    }

    function changeBarcodeDetails(value): void {
        props.save({ ...props.checkpoint, barcode_table: value })
    }

    const makeOptionForCheckpointType = (type: CheckpointType): any => {
        const translationId = 'checkpoint.type.' + type;
        return {
            text: formatMessage({ id: translationId }),
            key: type,
            value: type,
            description: undefined,
        };
    }

    const makeCheckpointTypes = (checklistType: ChecklistType): any[] => {
        const options = [];

        options.push(makeOptionForCheckpointType(CheckpointType.Default))
        options.push(makeOptionForCheckpointType(CheckpointType.MeasurementTables))
        options.push(makeOptionForCheckpointType(CheckpointType.MultipleChoice))
        options.push(makeOptionForCheckpointType(CheckpointType.Text))
        options.push(makeOptionForCheckpointType(CheckpointType.TextTable))

        if (checklistType === 'audit') {
            // custom fields for production units can only be set through audits.
            // The feature is protected by the SupplierCompliance feature flag.
            if (productionUnitCustomFieldsAllowed) {
                options.push(makeOptionForCheckpointType(CheckpointType.ProductionUnitCustomFields))
            }
            if (supplierCustomFieldsAllowed) {
                options.push(makeOptionForCheckpointType(CheckpointType.SupplierCustomFields))
            }
        }

        if (checklistType !== 'audit') {
            options.push(makeOptionForCheckpointType(CheckpointType.BarcodeTable))
        }

        return options;
    }

    const options = React.useMemo(() => makeCheckpointTypes(props.checklistType), [props.checklistType]);

    const instructionPlaceholderText = props.language === 'C' ? formatMessage({ id: 'checklists.editor.instruction' }) : props.checkpoint.instruction_details['C']


    return (
        <Fragment>
            {props.checkpoint &&
                <div ref={props.reffOuter} style={{ position: 'relative', width: '49%' }}>
                    <div ref={props.reffInner} style={{ width: '100%' }}>
                        <Grid.Column style={{ padding: '14px', width: '100%', zIndex: boxIndex, backgroundColor: backgroundColor, top: '7px', marginRight: '1%', borderRadius: '5px', border: '1px solid ' + borderColor }}>
                            <Form className='u-flex-align-center'>
                                <Form.Group inline>
                                    <Form.Field style={{ width: '100%', paddingRight: '0px' }}>
                                        <Dropdown selection
                                            closeOnBlur
                                            value={props.checkpoint.checkpoint_type}
                                            options={options}
                                            onChange={(evt, item): void => changeType(item.value)}
                                            data-test-id='checkpoint-type-dropdown'
                                        />
                                        <Icon name="delete" onClick={props.close} style={{ float: 'right' }} />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                            {props.checkpoint.checkpoint_type === CheckpointType.MeasurementTables &&
                                <MeasurementTableDetails
                                    checklistType={props.checklistType}
                                    data={props.checkpoint.measurement_tables}
                                    saveAndUpdate={saveAndUpdateMeasurementTables}
                                    save={saveMeasurementTables}
                                    language={props.language}
                                />
                            }
                            {props.checkpoint.checkpoint_type === CheckpointType.MultipleChoice &&
                                <MultipleChoiceDetails
                                    data={props.checkpoint.multiple_choice}
                                    update={changeMultipleChoiceDetails}
                                    language={props.language}
                                />
                            }
                            {props.checkpoint.checkpoint_type === CheckpointType.BarcodeTable &&
                                <BarcodeDetails
                                    data={props.checkpoint.barcode_table}
                                    language={props.language}
                                    update={changeBarcodeDetails}
                                />
                            }
                            {props.checkpoint.checkpoint_type === CheckpointType.Text &&
                                <TextDetails
                                    data={props.checkpoint.text_inputs}
                                    language={props.language}
                                    update={saveTextInputs}
                                />
                            }
                            {props.checkpoint.checkpoint_type === CheckpointType.TextTable &&
                                <TextTableDetails
                                    data={props.checkpoint.text_table}
                                    language={props.language}
                                    update={saveTextTable}
                                />
                            }
                            {props.checkpoint.checkpoint_type === CheckpointType.ProductionUnitCustomFields &&
                                <ProductionUnitCustomFieldDetails
                                    data={props.checkpoint.production_unit_custom_fields}
                                    language={props.language}
                                    update={saveProductionUnitCustomFields} />}
                            {props.checkpoint.checkpoint_type === CheckpointType.SupplierCustomFields &&
                                <SupplierCustomFieldDetails
                                    data={props.checkpoint.supplier_custom_fields}
                                    language={props.language}
                                    update={saveSupplierUnitCustomFields} />}
                            <Form className='u-flex-align-center'>
                                <MultiLangualText
                                    originalText={props.checkpoint.instruction_details}
                                    placeholder={instructionPlaceholderText}
                                    focus={false}
                                    presentation='area'
                                    save={changeInstructionDetail}
                                    language={props.language}
                                    style={{ width: '100%' }}
                                />
                            </Form>

                            {
                                props.checklistType == "inspection" && <InspectionCustomFields data={props.checkpoint.custom_fields} update={saveInspectionCustomFields} />
                            }

                            <FileGallery
                                images={
                                    (props.checkpoint.instruction_files || [])
                                        .filter(attachment => attachment.type == 'image')
                                        .map(a => a.url)
                                }
                                imageRemoved={(imageToRemove): void => {
                                    const images = (props.checkpoint.instruction_files || []).filter((attachment) => attachment.url !== imageToRemove);
                                    changeInstructionFiles(images)
                                }}
                                imagesAdded={(addedAttachments: AttachmentResponse[]): void => {
                                    const images = (props.checkpoint.instruction_files || []).concat(addedAttachments);
                                    changeInstructionFiles(images);
                                }}
                                disabled={false}
                                fileSelectorLableText={formatMessage({ id: 'attachments.upload.upload_checkpoint_attachment' })}
                                header={'checklists.editor.attachments.header'}
                                listView={true}
                                files={props.checkpoint.instruction_files}
                                allowMultiUpload
                            />
                            <Popup
                                on='hover'
                                trigger={
                                    <Checkbox
                                        onChange={changeStatusEnabled}
                                        checked={props.checkpoint.status_enabled}
                                        label={formatMessage({ id: 'checklists.editor.status' })} />
                                }>
                                <Popup.Content><FormattedMessage id="checklists.editor.status.tooltip" /></Popup.Content>
                            </Popup><br />

                            <Popup
                                on='hover'
                                trigger={
                                    <Checkbox
                                        onChange={changeSpaceEnabled}
                                        checked={props.checkpoint.has_spacer}
                                        label={formatMessage({ id: 'checklists.editor.spacer' })} />
                                }>
                                <Popup.Content><FormattedMessage id="checklists.editor.spacer.tooltip" /></Popup.Content>
                            </Popup><br />

                            <Popup
                                on='hover'
                                trigger={
                                    <Checkbox
                                        onChange={changeMandatory}
                                        checked={props.checkpoint.mandatory}
                                        label={formatMessage({ id: 'checklists.editor.mandatory' })} />
                                }>
                                <Popup.Content><FormattedMessage id="checklists.editor.mandatory.tooltip" /></Popup.Content>
                            </Popup><br />

                            <Popup
                                on='hover'
                                trigger={
                                    <Checkbox
                                        onChange={changeIsPictureMandatory}
                                        checked={props.checkpoint.is_picture_mandatory}
                                        label={formatMessage({ id: 'checklists.editor.is_picture_mandatory' })} />
                                }>
                                <Popup.Content><FormattedMessage id="checklists.editor.is_picture_mandatory.tooltip" /></Popup.Content>
                            </Popup><br />
                        </Grid.Column>
                    </div>
                    <div ref={props.arrowRef} style={{ borderBottom: '1px solid ' + borderColor, borderLeft: '1px solid ' + borderColor, position: 'relative', height: '14px', width: '14px', left: '-6px', backgroundColor: backgroundColor, zIndex: boxIndex + 1, transform: 'rotate(45deg)' }}></div>
                </div>}
        </Fragment>
    )
}

export default CheckpointEditor;