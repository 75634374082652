import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type FilterProps = {
    labelId?: string;
    className?: string;
    children: ReactNode;
};

const Filter = (props: FilterProps): React.ReactElement => {
    const { labelId, className, children } = props;
    const content = <>{children}</>
    if (labelId) {
        return <div className={className}><div className='text-sm sm:text-secondary text-primary mb-1 font-bold sm:font-normal'><FormattedMessage id={labelId} /></div>{content}</div>;
    }
    return content;
}
export default Filter;
