import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_LIMIT_LIST_SIZE } from '../config';
import { twMerge } from '../utils';
import Button from './basic/Button';
import SpinnerIcon from './basic/SpinnerIcon';

type Props = {
    className?: string;
    chunk: number;
    total: number;
    count?: number;
    search?: string;
    status?: string[];
    isLoading?: boolean;
    disabled?: boolean;
    onLoadMore(limit: number, offset: number, count: number, search?: string, status?: string[]): void;
};

const LoadMore = (props: Props): React.ReactElement => {
    const { className, chunk = DEFAULT_LIMIT_LIST_SIZE, onLoadMore, total, search, disabled = false, status } = props;
    const PER_PAGE = DEFAULT_LIMIT_LIST_SIZE;
    const [loading, setLoading] = useState(props.isLoading);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(props.count);
    const [noMore, setNoMore] = useState(false);

    const loadMore = () => {
        setPage(page + 1);
    };
    const getCount = (c) => {
        if (c >= total) {
            setNoMore(true);
            return total;
        } else {
            setNoMore(false);
        }
        return c;
    };
    useEffect(() => {
        if (page > 1) {
            onLoadMore(chunk, ((page - 1) * PER_PAGE), getCount(props.count), search, status);
        }
    }, [page]);

    useEffect(() => {
        setLoading(props.isLoading);
    }, [props.isLoading]);

    useEffect(() => {
        setNoMore(total < PER_PAGE || false);
        setPage(1);
    }, [total, search]);

    useEffect(() => {
        setCount(getCount(props.count));
    }, [props.count, total]);
    return <div className={twMerge('flex flex-col bg-white rounded-b mb-4 py-4 items-center justify-center', className)}>
        <div className='p-2 text-secondary'><FormattedMessage id={total === 1 ? 'load_more.showing_one_item' : (noMore ? 'load_more.showing_items_no_more' : 'load_more.showing_items')} values={{ count, total }} /></div>
        {!noMore && <div className='flex mb-3 pt-4 items-center justify-center'>
            <Button primary onClick={(): void => loadMore()} disabled={loading || disabled}>
                {loading && <SpinnerIcon className='mr-2' />}
                <div><FormattedMessage id={loading ? 'load_more.loading_more' : 'load_more.load_more'} /></div>
            </Button>
        </div>}</div >

}
export default LoadMore;
