import React, { Fragment, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Dimmer, Form, Loader, TextArea } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import DatePicker from '../../../base/components/basic/DatePicker3';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Icon from '../../../base/components/basic/Icon';
import { Sizes } from '../../../base/components/types';
import { Breakpoints } from '../../../base/config';
import { useTranslation } from '../../../base/hooks';
import Button from '../../../base/ui/components/buttons/Button';
import { getPrettyDate, useMinWidthBreak } from '../../../base/utils';
import { DetailedOrder } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import { ListViewHeader } from '../../globals/components/views/list/ListView';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { deleteComplianceOrder, getComplianceOrder, removeProductFromOrder } from '../complianceSlice';
import { getComplianceOrderSelector, isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceProductsItem from './ComplianceProductsItem';
import { EditableContent } from './ComplianceRequirementTemplate';



type ComplianceOrderProps = {
    className?: string;
    id: string;
};

const ComplianceOrderDetail = (props: ComplianceOrderProps): React.ReactElement => {
    const { id } = props;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const isMinMD = useMinWidthBreak(Breakpoints.MD);

    const isFetching = useSelector(isFetchingSelector);
    const orderData = useSelector(getComplianceOrderSelector);

    const userCanEditOrder = orderData?.permissions.includes('orders_edit');
    const userCanDeleteOrder = orderData?.permissions.includes('orders_delete');
    const userCanViewOrder = orderData?.permissions.includes('orders_show');
    const userCanCreateOrder = orderData?.permissions.includes('orders_create');

    const [comment, setComment] = useState('');
    const [order, setOrder] = useState<DetailedOrder>(null);
    const openProduct = (id: string) => {
        history.push('/compliance/products/' + id);
    };
    const removeProduct = (productId: string) => {
        dispatch(removeProductFromOrder({ product_id: productId, order_id: order.order_id })).then(
            () =>
                dispatch(getComplianceOrder(id))
        );
    }
    const deleteOrder = () => {
        dispatch(deleteComplianceOrder(order.order_id));
    }
    useEffect(() => {
        dispatch(getComplianceOrder(id));
    }, [id]);

    useEffect(() => {
        setOrder(orderData);
    }, [orderData]);

    if (order) {
        const products = orderData.products;
        const productsSection = <ContentSection
            contentClassName='pt-0  w-full'
            headerText={<div><FormattedMessage id="compliance.order_detail.products"></FormattedMessage></div>}
            content={<div>
                {
                    products.map((product, i) => {
                        return <>
                            {i === 0 && <ListViewHeader className='w-full hidden md:flex md:mt-0 md:mb-0 md:py-0 text-sm border-b border-default' items={[
                                { label: undefined, className: twMerge(' py-2 w-1/16'), key: 'spacer.1' },
                                { label: formatMessage({ id: 'compliance.order_list.product.product_name' }), className: twMerge(' py-2 w-6/16'), key: 'item_name' },
                                { label: formatMessage({ id: 'compliance.order_list.product.product_number' }), className: twMerge(' py-2 w-5/16'), key: 'item_no' },
                                { label: formatMessage({ id: 'compliance.order_list.product.product_deadline' }), className: twMerge(' py-2 w-4/16'), key: 'deadline' },

                            ]} />}

                            <ComplianceProductsItem product={product} onClick={openProduct} canDelete={product?.permissions?.includes('product_delete')} canRemove={true} removeFunction={(productId) => removeProduct(productId)} />
                        </>
                    })
                }

            </div>} />
        const status = <div>
            <Fragment>
                {/* <ComplianceProductBundleStatusComponent /> */}
            </Fragment>
        </div>;
        const history = <ContentSection
            headerText={intl.formatMessage({ id: 'compliance.history_and_comments' })}
            content={
                <Form className='mt-4'>
                    <Form.Field >
                        <TextArea
                            value={comment}
                            // onChange={(evt) => setComment(evt.currentTarget.value)}
                            /* onBlur={updateResponsibleComment}
                            disabled={disabled} */
                            placeholder={intl.formatMessage({ id: 'comments.write_a_comment' })}
                        />
                    </Form.Field>
                    <Button primary><FormattedMessage id='compliance.history_and_comments.send' /></Button>
                </Form>
            }
        />;
        const info = <ContentSection
            headerText={<span>Order info</span>}
            content={<Fragment>
                <Form>
                    <div className='flex sm:flex-row sm:justify-between flex-col space-y-4 sm:space-y-0'>
                        <div className='w-full sm:w-4/12'>
                            <Form.Field width={16} className='mx-4'>
                                <label><FormattedMessage id='compliance.order_detail.order_number' /></label>
                                <EditableContent
                                    isDisabled={!userCanEditOrder}
                                    type='text'
                                    inputType='input'
                                    name='order_number'
                                    value={order.order_number}
                                    onBlur={() => null}
                                />
                            </Form.Field>
                        </div>
                        <div className='w-full sm:w-4/12'>
                            <Form.Field width={16} className='mx-4'>
                                <label><FormattedMessage id='compliance.order_detail.deadline' /></label>
                                <DatePicker
                                    disabled={true}
                                    date={getPrettyDate(order.order_deadline)}
                                    handleChange={null}
                                />
                            </Form.Field>
                        </div>
                    </div>
                </Form>
            </Fragment>}
        />
        return <PageContainer
            header={<span><FormattedMessage id='compliance.order_detail.header_order_no' values={{ orderNo: order.order_number }} /></span>}
        >
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.order' }, { orderNumber: order.order_number })} />

            <Dimmer active={isFetching} inverted>
                <Loader className='dimLoader'><FormattedMessage id='inspections.loading_data_please_wait' /></Loader>
            </Dimmer>
            {userCanViewOrder && <div className='flex flex-col'>
                <div className='flex-col md:flex-row w-full flex md:space-x-4'>
                    <div className='w-full md:w-8/12 order-last md:order-first'>
                        {info}
                        {productsSection}
                        {/* !isMinMD && history */}
                    </div>
                    <div className='w-full md:w-4/12 order-first md:order-last pt-0 pb-2'>
                        {status}
                        {/* isMinMD && history */}
                    </div>
                </div>
            </div>}
            <DeleteConfirm
                deleteFunction={() => deleteOrder()}
                type='alert'
                alertHeader={useTranslation('compliance.order.delete_order.header')}
                alertText={useTranslation('compliance.order.delete_order.text')}
                alertButtonText={useTranslation('compliance.order.delete_order')}
                trigger={<span><Button stopPropagation={true} size={Sizes.Small} danger><FormattedMessage id='compliance.order.delete_order.action' /></Button></span>}
            />

            <Link className='link noUnderline items-center flex' to={'/compliance/orders'}><Icon name='chevron_left' className='text-xl' /><FormattedMessage id='compliance.order_detail.products.back' /></Link>
        </PageContainer >
    }
    return null;
}

export default ComplianceOrderDetail;
