import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { Sizes } from '../../../base/ui/components/types';
import Button from '../../../base/components/basic/Button';
import ChipStack from '../../../base/ui/components/chips/ChipStack';
import { twMerge } from 'tailwind-merge';
import { getGroups, getGroupsById, getUsers, getUsersById } from '../../users/selectors';
import UsersSelector from '../../users/components/UsersSelector';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import { getUserNameText } from '../../users/components/Widgets';
import { useAppDispatch } from '../../../store';
import { getDocumentationTypesUpdatingSelector, setDocumentationTypeApprovalResponsible } from '../supplierDocumentsSlice';
import { DocumentationType } from '../../../backend_api/models/DocumentationType';

type Props = {
    className?: string;
    documentationType: DocumentationType;
    onClose?: () => void;
};

const DocumentationTypeApprovalResponsiblesSelector = ({ className, documentationType, onClose }: Props): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const responsiblesById = useSelector(getUsersById);
    const users = useSelector(getUsers);
    const groups = useSelector(getGroups);
    const groupsById = useSelector(getGroupsById);
    const isUpdating = useSelector(getDocumentationTypesUpdatingSelector);

    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [showUsersList, setShowUsersList] = useState(false);
    const [showGroupsSelector, setShowGroupsSelector] = useState(false);

    // Initialize selected users and groups from documentationType
    useEffect(() => {
        if (documentationType) {
            setSelectedUsers(documentationType.responsible_users.map(user => user.id));
            setSelectedGroups(documentationType.responsible_groups.map(group => group.id));
        }
    }, [documentationType]);

    const setupGroups = () => {
        return groups
            .filter((g) => !selectedGroups?.includes(g.id))
            .map((g) => ({
                key: g.id,
                id: g.id,
                value: g.id,
                text: g.name
            }));
    };

    const [groupsOptions, setGroupOptions] = useState(setupGroups());

    // Update group options when selected groups change
    useEffect(() => {
        setGroupOptions(setupGroups());
    }, [selectedGroups, groups]);

    const handleSave = () => {
        dispatch(setDocumentationTypeApprovalResponsible({
            documentationTypeId: documentationType.id,
            responsibles: {
                user_approval_responsible: selectedUsers,
                group_approval_responsible: selectedGroups
            }
        }));

        if (onClose) {
            onClose();
        }
    };

    const content = (
        <div className={twMerge('gap-y-2', className)}>
            <div className='flex flex-col gap-2'>
                {/* Users Section */}
                <ChipStack
                    noValueLabel={intl.formatMessage({ id: 'compliance.product_detail.responsible_users.select_user' })}
                    showAdd={true}
                    onAdd={() => {
                        setShowUsersList(true);
                        setShowGroupsSelector(false);
                    }}
                    onRemoveClip={(index) => {
                        const su = [...selectedUsers];
                        su.splice(index, 1);
                        setSelectedUsers(su);
                    }}
                    size={Sizes.Small}
                    clips={selectedUsers.map((id) => {
                        const user = responsiblesById[id];
                        return {
                            label: getUserNameText(user),
                            removable: true
                        };
                    })}
                />

                {showUsersList && (
                    <div className='flex w-full gap-2 pb-8'>
                        <UsersSelector
                            fluid
                            hideSelected={true}
                            name='approver'
                            users={{ ...responsiblesById }}
                            placeholder={intl.formatMessage({ id: 'users.select_users' })}
                            allowMultipleSelection={true}
                            value={selectedUsers}
                            showEmailInSelector
                            handleChange={(selected) => {
                                setSelectedUsers(selected.value);
                            }}
                            withIcon
                        />
                        <Button
                            secondary
                            small
                            onClick={() => setShowUsersList(false)}
                        >
                            <FormattedMessage id='globals.done' />
                        </Button>
                    </div>
                )}

                {/* Groups Section */}
                <ChipStack
                    noValueLabel={intl.formatMessage({ id: 'compliance.product_detail.responsible_users.select_group' })}
                    showAdd={true}
                    onAdd={() => {
                        setShowGroupsSelector(true);
                        setShowUsersList(false);
                    }}
                    onRemoveClip={(index) => {
                        const sg = [...selectedGroups];
                        sg.splice(index, 1);
                        setSelectedGroups(sg);
                    }}
                    size={Sizes.Small}
                    clips={selectedGroups.map((group) => {
                        const gs = groupsById[group];
                        if (gs) {
                            return { label: gs.name, removable: true, icon: 'user_group' };
                        }
                    })}
                />

                {showGroupsSelector && (
                    <div className='flex w-full gap-2 pb-8'>
                        <Dropdown
                            value={selectedGroups}
                            placeholder={intl.formatMessage({ id: 'groups.select_groups' })}
                            fluid
                            multiple
                            selection
                            options={groupsOptions}
                            onChange={(e, item) => {
                                const value = item.value[(item.value as string[]).length - 1];
                                setSelectedGroups([...selectedGroups, value as string]);
                            }}
                        />
                        <Button
                            secondary
                            small
                            onClick={() => setShowGroupsSelector(false)}
                        >
                            <FormattedMessage id='globals.done' />
                        </Button>
                    </div>
                )}

                {/* Save Button */}
                <div className='flex justify-end mt-4'>
                    <Button
                        primary
                        onClick={handleSave}
                        disabled={isUpdating}
                    >
                        {isUpdating ?
                            <FormattedMessage id='globals.saving' /> :
                            <FormattedMessage id='globals.save' />
                        }
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <KeyValuePair
            keyItem={{
                name: intl.formatMessage({ id: 'production_units.supplier_document.responsible_for_approval' })
            }}
            value={{ value: content }}
        />
    );
};

export default DocumentationTypeApprovalResponsiblesSelector;