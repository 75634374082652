import React, { ReactNode, useRef } from 'react';
import { useButton, usePress } from 'react-aria';
import { ButtonProps, PressEvent } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
// import { ButtonProps } from 'react-html-props';
import { Sizes } from '../../../components/types';
import { twMerge } from '../../../utils';
import Icon, { LineHeights } from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

export type SIZES =
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'xlarge'
    ;

export enum ButtonTypes {
    Plain = 'plain',
    Primary = 'primary',
    Secondary = 'secondary',
    Ghost = 'ghost',
    Danger = 'danger',
    Context = 'context',
    Inline = 'inline',
};

export enum ButtonSizes {
    XSmall = 'xsmall',
    Small = 'small',
    Medium = 'medium',
    XLarge = 'xlarge',
};

export type SizeMap = { [SIZE in SIZES]: string; }

export const ButtonSizeMappings: SizeMap = {
    xsmall: 'text-xs',
    small: 'text-sm',
    medium: 'text-base',
    xlarge: 'text-lg',
};

interface Props extends ButtonProps {
    className?: string;
    iconClassName?: string;
    size?: Sizes.XSmall | Sizes.Small | Sizes.Medium | Sizes.XLarge;
    label?: string;
    labelLeft?: boolean;
    labelRight?: boolean;
    children?: ReactNode;
    buttonType?: ButtonTypes;
    icon?: Icons;
    dataTestId?: string;
    value?: any;
    ref?: React.MutableRefObject<null>;
    primary?: boolean;
    secondary?: boolean;
    danger?: boolean;
    ghost?: boolean;
    inline?: boolean;
    context?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    onPress?(e: PressEvent): void;
    stopPropagation?: boolean;
}

const Button = (props: Props): React.ReactElement => {
    const getButtonType = () => {
        if (primary) {
            return ButtonTypes.Primary
        }
        if (secondary) {
            return ButtonTypes.Secondary
        }
        if (danger) {
            return ButtonTypes.Danger;
        }
        if (ghost) {
            return ButtonTypes.Ghost;
        }
        if (context) {
            return ButtonTypes.Context;
        }
        if (inline) {
            return ButtonTypes.Inline;
        }
        return props.buttonType || ButtonTypes.Plain;
    };

    const { className, size = Sizes.Small, children, primary, secondary, danger, ghost, context, inline, icon, iconClassName, disabled, label, labelLeft = false, labelRight = false, fullWidth = false, stopPropagation = false, ...rest } = props;
    const buttonType = getButtonType();
    const sizeValue = ButtonSizeMappings[size];
    const base = twMerge('rounded-md whitespace-pre items-center justify-center self-center', fullWidth ? 'flex flex-1' : 'inline-flex w-min');

    const paddingBySizeMap = {
        medium: 'px-8 py-3',
        small: 'px-5 py-2',
        xsmall: 'px-3 py-1',
    }
    const paddingMap = {
        plain: paddingBySizeMap,
        primary: paddingBySizeMap,
        secondary: paddingBySizeMap,
        danger: paddingBySizeMap,
        ghost: paddingBySizeMap,
        context: paddingBySizeMap,
        inline: {
            medium: 'p-0',
            small: 'p-0',
            xsmall: 'p-0',
        },
    }
    const padding = paddingMap[buttonType][size];
    const textSizeMap = {
        medium: 'text-base',
        small: 'text-sm',
        xsmall: 'text-xs',
    }
    const textSize = textSizeMap[size];
    const backgroundColorMap = {
        plain: 'bg-surface-plain',
        primary: 'bg-surface-brand',
        secondary: 'bg-surface-secondary',
        danger: 'bg-surface-danger',
        ghost: 'bg-default',
        inline: 'bg-default',
        context: 'bg-surface-interactive',
    }
    const textColorMap = {
        plain: 'text-on-surface-plain',
        primary: 'text-on-surface-brand',
        secondary: 'text-on-surface-secondary',
        danger: 'text-on-surface-danger',
        ghost: 'text-brand',
        context: 'text-on-surface-interactive',
        inline: 'text-brand',

    }
    const iconColorMap = {
        plain: 'text-on-surface-plain',
        inline: 'text-on-surface-plain',
        primary: 'text-on-surface-brand',
        secondary: 'text-on-surface-secondary',
        danger: 'text-on-surface-danger',
        ghost: 'text-brand',
        context: 'text-on-surface-interactive',
    }
    const borderColorMap = {
        plain: 'border border-default',
        primary: 'border border-default',
        secondary: 'border border-default',
        danger: 'border border-default',
        ghost: 'border-none',
        inline: 'border-none',
        context: 'border-none',
    }
    const leadingMapBySize = {
        medium: 'leading-rem-base',
        small: 'leading-rem-sm',
        xsmall: 'leading-rem-xs',
    }
    const leading = leadingMapBySize[size];
    const backgroundColor = backgroundColorMap[buttonType ? buttonType : ButtonTypes.Plain];
    const textColor = textColorMap[buttonType ? buttonType : ButtonTypes.Plain];
    const borderColor = borderColorMap[buttonType ? buttonType : ButtonTypes.Plain];

    let { pressProps, isPressed } = usePress({
        onPressStart: (e) => {
            !stopPropagation && e.continuePropagation();
            (!disabled && !props.isDisabled) && props.onPress?.(e);
        },
    });
    let ref = props.ref || useRef();
    let { buttonProps } = useButton(props, ref);

    const button = <button
        {...buttonProps}
        {...pressProps}
        disabled={props.isDisabled}
        ref={ref}
        data-test-id={props.dataTestId || props['data-test-id']}
        className={twMerge('', base, textSize, sizeValue, leading, padding, backgroundColor, textColor, borderColor, className)}
    >
        <div className='flex items-center gap-1'>
            {label && labelLeft && <FormattedMessage id={label} />}
            {icon && <Icon lineHeight={LineHeights.Auto} name={icon} size={size} className={iconClassName || iconColorMap[buttonType]} />}
            {label && labelRight && <FormattedMessage id={label} />}
            {children}</div>
    </button>

    return button;
}
export default Button;
