import { DisplayDetailedSupplierDocument } from '../../../backend_api/models/DisplayDetailedSupplierDocument';
import React from 'react';
import { TranslateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { getLocaleLanguageString, getPrettyDate, getTranslation, isDateExpired, useIsMobileDevice } from '../../../base/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ContextMenu, { ContextMenuActionItemType, ContextMenuOverflowItemType } from '../../../base/ui/components/contextMenu/ContextMenu';
import Button from '../../../base/ui/components/buttons/Button';
import Icon from '../../../base/ui/components/icons/Icon';
import { ArrowTypeEnum, ListItem } from '../../../base/ui/components/listitems/ListItem';
import Popup from '../../../base/ui/components/popup/Popup';
import { ViewProductionUnitDocumentsModal } from './ViewProductionUnitDocumentsModal';
import { Sizes } from '../../../base/ui/components/types';
import { archiveSupplierDocument, createSupplierDocument, deleteSupplierDocument, getDetailedProductionUnitListSelector, productionUnitsSliceActions, unarchiveSupplierDocument } from '../slice/productionUnitsSlice';
import { useAppDispatch } from '../../../store';
import { DocumentationType, FileResource } from '../../../backend_api/models';
import FileUploadModal from '../../files/components/FileUploadModal';
import { useSelector } from 'react-redux';
import DeleteDocumentConfirmModal from './DeleteDocumentConfirmModal';

type Props = {
    className?: string;
    allowedToEdit: boolean;
    isEditMode: boolean;
    detailedSupplierDocument: DisplayDetailedSupplierDocument;
    documentationTypeTooltipContent?: React.ReactElement;
    isCheckpointDocument?: boolean;
    isNewCheckpointDocument?: boolean;
    documentationType?: DocumentationType;
    onDelete?: () => void;
    viewDocument?: () => void;
};


const DetailedSupplierDocument = (props: Props): React.ReactElement => {
    const { className, isCheckpointDocument = false, isNewCheckpointDocument = false, detailedSupplierDocument, allowedToEdit, isEditMode = false, documentationTypeTooltipContent, documentationType, viewDocument } = props;
    const { formatMessage, locale } = useIntl();
    const lang = getLocaleLanguageString(locale);
    const dispatch = useAppDispatch();
    const productionUnit = useSelector(getDetailedProductionUnitListSelector);
    const isMobile = useIsMobileDevice();

    const [showUploadModal, setShowUploadModal] = React.useState(false);
    const [showDeleteDocumentConfirmModal, setShowDeleteDocumentConfirmModal] = React.useState(false);

    const uploadFilesToDocument = (file: FileResource) => {
        dispatch(createSupplierDocument({
            documentation_type_id: detailedSupplierDocument?.documentation_type_id,
            media_resource_id: file?.id,
            production_unit_id: productionUnit?.id
        }));
        setShowUploadModal(false);
    }

    const archiveDocument = () => {
        dispatch(archiveSupplierDocument({ id: detailedSupplierDocument?.id }));
    };

    const deleteDocument = () => {
        dispatch(deleteSupplierDocument({ id: detailedSupplierDocument?.id }));
        setShowDeleteDocumentConfirmModal(false);
    };

    const uploadFileModal = <FileUploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onUploadComplete={(file) => {
            setShowUploadModal(false);
            uploadFilesToDocument(file);
        }}
        isMobile={isMobile}
        allowMultiUpload={false}
    />

    const deleteDocumentConfirmModal = <DeleteDocumentConfirmModal
        open={showDeleteDocumentConfirmModal}
        close={() => setShowDeleteDocumentConfirmModal(false)}
        archiveDocument={detailedSupplierDocument?.archived || isCheckpointDocument ? null : archiveDocument}
        deleteDocument={isCheckpointDocument ? props.onDelete : deleteDocument}
    />

    const addNewDocumentContextMenuItem: ContextMenuOverflowItemType = {
        label: `${formatMessage({ id: 'production_units.supplier_document.context_menu.add_new' })} ${getTranslation(detailedSupplierDocument.documentation_type_name, lang)}`,
        icon: 'operation_add',
        onPress: () => {
            setShowUploadModal(true);
        }
    }
    const archiveDocumentContextMenuItem: ContextMenuOverflowItemType = {
        label: 'production_units.supplier_document.context_menu.archive_document',
        icon: 'operation_archive',
        onPress: () => {
            dispatch(archiveSupplierDocument({ id: detailedSupplierDocument.id }));
        }
    }
    const unarchiveDocumentContextMenuItem: ContextMenuOverflowItemType = {
        label: 'production_units.supplier_document.context_menu.unarchive_document',
        icon: 'operation_archive',
        onPress: () => {
            dispatch(unarchiveSupplierDocument({ id: detailedSupplierDocument.id }));
        }
    }
    const [showAllDocuments, setShowAllDocuments] = React.useState(false);
    const viewAllContextMenuItem: ContextMenuOverflowItemType = {
        label: 'production_units.supplier_document.context_menu.view_all',
        onPress: () => {
            setShowAllDocuments(true);
        }
    }
    const deleteContextMenuItem: ContextMenuOverflowItemType = {
        label: 'production_units.supplier_document.context_menu.delete',
        icon: 'operation_delete',
        className: 'text-danger',
        onPress: () => setShowDeleteDocumentConfirmModal(true)
    }

    const splitterContextMenuItem: ContextMenuOverflowItemType = {
        isSplitter: true
    }

    const DeleteContextMenuItemAction: ContextMenuActionItemType = {
        icon: 'operation_delete',
        label: 'production_units.supplier_document.context_menu.delete',
        className: 'bg-default px-0',
        buttonSize: Sizes.XLarge,
        onPress: () => {
            setShowDeleteDocumentConfirmModal(true);
        }
    }

    const createContextMenuItems = () => {
        const key = `${allowedToEdit}_${isEditMode}`;

        switch (key) {
            case 'true_true':
                return [
                    detailedSupplierDocument?.archived ? unarchiveDocumentContextMenuItem : archiveDocumentContextMenuItem,
                    deleteContextMenuItem
                ];

            case 'true_false':
                return [
                    addNewDocumentContextMenuItem,
                    splitterContextMenuItem,
                    detailedSupplierDocument?.archived ? unarchiveDocumentContextMenuItem : archiveDocumentContextMenuItem,
                    deleteContextMenuItem,
                    splitterContextMenuItem,
                    viewAllContextMenuItem
                ];

            default:
                return [viewAllContextMenuItem];
        }
    }

    const editMode = isCheckpointDocument ?
        <ListItem
            className='border-b'
            arrow={{ type: ArrowTypeEnum.Open }}
            slim={true}
            graphicItemClass='min-w-10'
            icon={isNewCheckpointDocument &&
                <ContextMenu
                    trigger={<Button stopPropagation ghost className='px-2 py-1 bg-default'>
                        <Icon size={Sizes.XLarge} name='operation_delete' />
                    </Button>}
                    actions={[DeleteContextMenuItemAction]}
                    className='h-fit-content'
                />
            }
            iconOnRight={true}
        >
            <div className="flex items-center relative w-full bg-default pl-2" onClick={viewDocument}>
                <div className="flex gap-4">
                    <div className={`${getStatusColor(detailedSupplierDocument.state, detailedSupplierDocument.archived)} text-normal text-sm text-on-status px-3 py-1 flex items-center justify-center rounded-md`}>
                        <FormattedMessage id={"production_units.supplier_document.status." + (detailedSupplierDocument?.archived ? "archived" : detailedSupplierDocument.state)} />
                    </div>

                    <div className="flex items-center">
                        <span className="text-normal text-base text-primary">
                            {renderDocumentationStateDescription({ documentationType, detailedSupplierDocument })}
                        </span>
                    </div>
                </div>
            </div>
        </ListItem>
        :
        <ListItem
            className='border-b'
            arrow={{ type: ArrowTypeEnum.Open, onArrowClick: viewDocument }}
            slim={true} graphicItemClass='min-w-10'
            icon={<ContextMenu trigger={<Button stopPropagation ghost className='px-2 py-1 bg-default'><Icon name='microinteraction_context-menu' /></Button>} overflowItems={createContextMenuItems()} className='h-fit-content'></ContextMenu>} iconOnRight={true} >
            <div className="flex items-center relative w-full bg-default pl-2" onClick={viewDocument}>
                <div className="flex gap-4">
                    <div className={`${getStatusColor(detailedSupplierDocument.state, detailedSupplierDocument.archived)} text-normal text-sm text-on-status px-3 py-1 flex items-center justify-center rounded-md`}>
                        <FormattedMessage id={"production_units.supplier_document.status." + (detailedSupplierDocument?.archived ? "archived" : detailedSupplierDocument.state)} />
                    </div>

                    <div className="flex items-center">
                        <span className="text-normal text-base text-primary">
                            {renderDocumentationStateDescription({ documentationType, detailedSupplierDocument })}
                        </span>
                    </div>
                </div>
            </div>
        </ListItem >;
    const viewMode = <>
        <button className="flex items-center relative w-full bg-default p-3 border-b" onClick={viewDocument}>
            <div className="flex gap-4">
                <div className={`${getStatusColor(detailedSupplierDocument.state, detailedSupplierDocument.archived)} text-on-status px-3 py-3 min-w-[100px] flex items-center justify-center rounded-md`}>
                    <FormattedMessage id={"production_units.supplier_document.status." + (detailedSupplierDocument?.archived ? "archived" : detailedSupplierDocument.state)} />
                </div>

                <div className="">
                    <Popup trigger={<div className="text-normal text-base font-bold"><TranslateTextWithTranslation twt={detailedSupplierDocument.documentation_type_name} /></div>} on="hover">
                        {documentationTypeTooltipContent}
                    </Popup>
                    <span className="text-normal text-sm text-primary">
                        {renderDocumentationStateDescription({ documentationType, detailedSupplierDocument })}
                    </span>
                </div>
            </div>
            <div className="flex gap-14px ml-auto">
                <ContextMenu trigger={<Button stopPropagation ghost className='px-2 py-1 bg-surface-interactive rounded-md'><Icon name='microinteraction_context-menu' /></Button>} overflowItems={createContextMenuItems()} className='h-fit-content'></ContextMenu>
            </div>
        </button>
    </>

    return (
        <>
            {isEditMode ? editMode : viewMode}
            {uploadFileModal}
            {deleteDocumentConfirmModal}
            {showAllDocuments && <ViewProductionUnitDocumentsModal filters={[detailedSupplierDocument.documentation_type_id]} setShowing={setShowAllDocuments} showing={showAllDocuments} addDocumentation={() => {
                // TODO Chris Handle add documentation for supplier
            }} />}
        </>
    );
}

export function renderDocumentationStateDescription(props: {
    documentationType: DocumentationType,
    detailedSupplierDocument: DisplayDetailedSupplierDocument
}) {
    const { documentationType, detailedSupplierDocument } = props;
    if (!documentationType && !detailedSupplierDocument) {
        return null;
    }

    if (!detailedSupplierDocument.valid_from_date || (documentationType?.requires_expiry && !detailedSupplierDocument.valid_to_date)) {
        return <FormattedMessage id="production_units.documentation.validity_not_set" />;
    }

    if (detailedSupplierDocument.valid_to_date) {
        const isExpired = isDateExpired(detailedSupplierDocument.valid_to_date);
        if (isExpired) {
            return <FormattedMessage id="production_units.list.documentation.date.expired" values={{ date: getPrettyDate(detailedSupplierDocument.valid_to_date) }} />;
        }
        return <FormattedMessage id="production_units.list.documentation.date.expires" values={{ date: getPrettyDate(detailedSupplierDocument.valid_to_date) }} />;
    }

    if (!documentationType?.requires_expiry && detailedSupplierDocument.valid_from_date) {
        return <FormattedMessage id="production_units.list.documentation.date.active_from" values={{ date: getPrettyDate(detailedSupplierDocument.valid_from_date) }} />;
    }
}

const getStatusColor = (status: string, archived: boolean) => {
    if (archived) {
        return "bg-status-ignored";
    }

    switch (status) {
        case 'approved':
            return "bg-status-approved"

        case 'waiting_for_approval':
            return "bg-status-pending"

        case 'file_added':
            return "bg-status-in-progress"

        case 'rejected':
            return "bg-status-rejected"

        case 'active':
            return "bg-status-approved"

        case 'expired':
            return "bg-status-rejected"

        case 'archived':
            return "bg-status-ignored"

    }
};

export default DetailedSupplierDocument