import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import LoadMore from '../../../base/components/LoadMore';
import Loader from '../../../base/components/Loader';
import TabsMenu from '../../../base/components/TabsMenu';
import Button from '../../../base/components/basic/Button';
import { DEFAULT_LIMIT_LIST_SIZE } from '../../../base/config';
import { useCheckHasPermission, useQuery } from '../../../base/hooks';
import { getPermissions } from '../../../base/selectors';
import { useAppDispatch } from '../../../store';
import FiltersList from '../../filters/components/FiltersList';
import PageContainer from '../../globals/components/PageContainer';
import { ListView, ListViewHeader, ListViewLines } from '../../globals/components/views/list/ListView';
import { getComplianceProductsList, getComplianceProductsSearchResult } from '../complianceSlice';
import { getCompliancePaginatedProductsSelector, isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceProductsItem from './ComplianceProductsItem';

const ComplianceProductsList = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isFetching = useSelector(isFetchingSelector);
    const permissions = useSelector(getPermissions);
    const location = useLocation();
    const paginatedProducts = useSelector(getCompliancePaginatedProductsSelector);
    const history = useHistory();
    const hasProducts = paginatedProducts?.products.length > 0;
    const userCanCreateProducts = useCheckHasPermission(permissions, 'u_compliance_create_products')
    const [total, setTotal] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT_LIST_SIZE);
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        if (paginatedProducts?.products) {
            setOffset(paginatedProducts.pagination.offset);
            setLimit(paginatedProducts.pagination.limit);
            setTotal(paginatedProducts.pagination.total);
            setCount((paginatedProducts.pagination.limit) + paginatedProducts.pagination.offset);
        }
    }, [paginatedProducts]);

    const openProduct = (id: string) => {
        history.push('/compliance/products/' + id);
    };
    const status = useQuery().get('status') || 'all';
    const query = useQuery().get('q');

    useEffect(() => {
        if (query) {
            dispatch(getComplianceProductsSearchResult({ search_string: query, status: 'all', pagination: { limit: DEFAULT_LIMIT_LIST_SIZE, offset: 0 } }));
        } else {
            if (status) {
                dispatch(getComplianceProductsList({ status, limit: DEFAULT_LIMIT_LIST_SIZE, offset: 0 }));
            }
        }
    }, [query || status])
    return <PageContainer header={<FormattedMessage id='compliance.products_list.title' />}>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.products' })} />
        <div className='flex space-x-2'>
            <TabsMenu
                active={status}
                path=''
                items={[
                    { labelId: 'compliance.products_list.tabs.item.all', query: { status: 'all' }, id: 'all' },
                    { labelId: 'compliance.products_list.tabs.item.todo', query: { status: 'todo' }, id: 'todo' },
                ]} />
            {/* <SelectedFilters hideDate={true} showCompact={true} hideEmpty={false} /> */}
            {userCanCreateProducts &&
                <div className='flex justify-end w-full items-center'><Button secondary onClick={() => history.push('products/create')}><FormattedMessage id='compliance.products_list.create_product' /></Button></div>
            }

        </div>
        <div className='pt-8'>
            <FiltersList
                filters={['supplier-filter', 'compliance-requirements-category-filter']}
            />
        </div>

        <ListView className='mb-0'>
            <Loader active={isFetching}>
                {hasProducts && <ListViewHeader
                    className={classnames('top-0')}
                    items={[
                        { label: undefined, className: 'w-1/16', key: 'spacer.1', },
                        { label: intl.formatMessage({ id: 'compliance.products_list.header.number' }), className: 'w-3/16', key: 'product_name' },
                        { label: intl.formatMessage({ id: 'compliance.products_list.header.name' }), className: 'w-7/16', key: 'product_number' },
                        { label: undefined, className: 'w-1/16', key: 'product_stats' },
                        { label: intl.formatMessage({ id: 'compliance.products_list.header.deadline' }), className: 'w-4/16', key: 'product_deadline' },
                    ]} />}
                {hasProducts && <ListViewLines className='border border-b-0'>

                    {paginatedProducts?.products.map((product, i) => {
                        const canDelete = product.permissions?.includes('product_delete');
                        return <ComplianceProductsItem canDelete={canDelete} showDeleteFunction={false} product={product} key={'product_item' + product.product_id + i} onClick={openProduct} />;
                    })}
                </ListViewLines>}
                {!hasProducts && <div className='justify-center md:justify-start flex py-8 px-2 md:px-0'><FormattedMessage id='compliance.products_list.no_products_found' /></div>}
            </Loader>

        </ListView>
        {total > 0 && <LoadMore className='border border-t-0'
            disabled={isFetching}
            isLoading={isFetching}
            onLoadMore={(limit: number, offset: number, count: number, search: string) => {
                if (query) {
                    dispatch(getComplianceProductsSearchResult({ search_string: query, status: 'all', pagination: { limit, offset } }));
                }
                else
                    dispatch(getComplianceProductsList({ status, limit, offset }));
            }}
            chunk={limit}
            total={total}
            count={count}
        />}
    </PageContainer>
}

export default ComplianceProductsList;