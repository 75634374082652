import { useMemo } from 'react';
import { Feature, GetProfileResponse } from '../../../../backend_api/models';
import navigationObject from '../navigationObject.json';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../base/hooks';
import { getComplianceIsEnabledSelector } from '../../../compliance/selectors/ComplianceSelectors';
import { Menu, MenuItem, SecondaryMenu } from '../types';

interface UseNavigationItemsProps {
    profile: GetProfileResponse;
    tmpFeatures?: Feature[];
}
const nav = navigationObject.navigation;
const translate = (string): string => {
    return useTranslation(string) as string;
}
export const useNavigationItems = ({
    profile,
    tmpFeatures,
}: UseNavigationItemsProps) => {
    const features = tmpFeatures || profile?.organization_features;
    const permissions = profile?.permissions;
    const multipleOrgs = profile?.other_organizations.length > 0;
    const activeOrg = multipleOrgs && profile ? profile.organization.name : null
    const productComplianceEnabled = useSelector(getComplianceIsEnabledSelector);
    const intl = useIntl();

    const items = useMemo(() => {
        const baseItems: Record<string, MenuItem> = {

            products: { name: translate('global_navigation.products'), key: 'products', id: 'products', link: nav.products },
            orders: { name: translate('global_navigation.orders'), key: 'orders', id: 'orders', link: nav.orders },
            dashboard: { name: translate('global_navigation.dashboard'), key: 'dashboard', id: 'dashboard', link: nav.dashboard },
            profile: { name: translate('global_navigation.profile'), key: 'profile', id: 'profile', link: nav.profile },
        }

        if (permissions?.includes('u_inspections')) {
            baseItems.inspectionsList = { name: translate('global_navigation.inspections_list'), key: 'inspectionsList', id: 'inspectionsList', link: nav.inspectionsList };
        }
        if (permissions?.includes('u_inspection_checklists')) {
            baseItems.checklists = { name: translate('global_navigation.checklists'), key: 'checklists', id: 'checklists', link: nav.checklists };
        }
        if (permissions?.includes('u_audit_checklists')) {
            baseItems.auditChecklists = { name: translate('global_navigation.checklists'), key: 'audit_checklists', id: 'audit_checklists', link: nav.audit_checklists };

        }
        if (permissions?.includes('u_corrective_actions_inspection')) {
            baseItems.correctiveActionsInspections = { name: translate('global_navigation.corrective_actions_inspections'), key: 'corrective_actions_inspections', id: 'correctiveActionsInspections', link: nav.correctiveActionsInspections };
        }
        if (permissions?.includes('u_corrective_actions')) {
            baseItems.correctiveActions = { name: translate('global_navigation.corrective_actions'), key: 'corrective_actions', id: 'correctiveActions', link: nav.correctiveActions };
        }
        if (permissions?.includes('u_manage_booking_info')) {
            baseItems.bookings = { name: translate('global_navigation.bookings'), key: 'bookings', id: 'bookings', link: nav.bookings };
        }
        if (permissions?.includes('u_audits')) {
            baseItems.audits = { name: translate('global_navigation.audits'), key: 'audits', id: 'audits', link: nav.audits };
        }
        if (permissions?.includes('u_planning_view')) {
            baseItems.planning = { name: translate('global_navigation.planning'), key: 'planning', id: 'planning', link: nav.planning };
        }
        if (permissions?.includes('u_users_admin')) {
            baseItems.users = { name: translate('global_navigation.users'), key: 'users', id: 'users', link: nav.users };
        }
        if (permissions?.includes('u_groups_admin')) {
            baseItems.groups = { name: translate('global_navigation.groups'), key: 'groups', id: 'groups', link: nav.groups };
        }
        if (permissions?.includes('u_statistics')) {
            baseItems.dataExport = { name: translate('global_navigation.data_export'), key: 'data_export', id: 'dataExport', link: nav.dataExport };
        }
        if (permissions?.includes('u_manage_user_roles')) {
            baseItems.roles = { name: translate('global_navigation.roles'), key: 'roles', id: 'roles', link: nav.roles };
        }
        if (permissions?.includes('u_supplier_admin')) {
            baseItems.productionUnits = { name: translate('global_navigation.production_units'), key: 'production_units', id: 'productionUnits', link: nav.productionUnits };
            baseItems.suppliers = { name: translate('global_navigation.suppliers'), key: 'suppliers', id: 'suppliers', link: nav.suppliers };
        }
        if (permissions?.includes('u_documentation_types')) {
            baseItems.documentationApproval = { name: translate('global_navigation.documentation_approvals'), key: 'documentation_approval', id: 'documentationApproval', link: nav.documentationApproval };
        }
        if (permissions?.includes('u_requirements_menu')) {
            baseItems.requirements = { name: translate('global_navigation.requirements'), key: 'requirements', id: 'requirements', link: nav.requirements };
        }

        const menus: Record<string, Menu> = {
            inspectionsMenu: { meta: { name: translate('global_navigation.top_level.inspections') }, items: [] },
            auditsMenu: { meta: { name: translate('global_navigation.top_level.audits') }, items: [] },
            supplyChainMenu: { meta: { name: translate('global_navigation.top_level.supply_chain') }, items: [] },
            productComplianceMenu: { meta: { name: translate('global_navigation.top_level.product_compliance') }, items: [] },
            productsMenu: { meta: { name: translate('global_navigation.top_level.products') }, items: [] },
            ordersMenu: { meta: { name: translate('global_navigation.top_level.orders') }, items: [] },
            requirementsMenu: { meta: { name: translate('global_navigation.top_level.requirements') }, items: [] },
            checklistMenu: { meta: { name: translate('global_navigation.top_level.checklists') }, items: [] },
            productionUnitsMenu: { meta: { name: translate('global_navigation.top_level.production_units') }, items: [] },
            suppliersMenu: { meta: { name: translate('global_navigation.top_level.suppliers') }, items: [] },
            correctiveActionsMenu: { meta: { name: translate('global_navigation.top_level.corrective_actions') }, items: [] },
            systemSettingsMenu: { meta: { name: translate('global_navigation.top_level.system_settings') }, items: [baseItems.users, baseItems.roles, baseItems.groups, baseItems.documentationApproval] },
            organisationsMenu: { meta: { id: 'organisationsMenu', name: translate('global_navigation.top_level.organisations') }, items: [] },
            profileMenu: { meta: { name: translate('global_navigation.top_level.profile') }, items: [baseItems.profile] },
            dataMenu: { meta: { name: translate('global_navigation.top_level.data') }, items: [baseItems.dataExport,] },
        }

        if (permissions?.includes('u_dashboard')) {
            menus.dashboardMenu = { meta: { name: translate('global_navigation.top_level.dashboard') }, items: [baseItems.dashboard] };
        }

        multipleOrgs && menus.organisationsMenu.items.push({ name: profile?.organization.name, key: 'current_organisation', id: 'current_organisation', link: nav.organisation1, selected: true });
        multipleOrgs && profile && profile.other_organizations.map((org) => {
            menus.organisationsMenu.items.push({ name: org.organization_name, key: org.user_id, id: org.organization_id, link: nav.organisation1, selected: activeOrg === org.organization_name });
        })

        return { baseItems, menus };
    }, [intl.locale, features, permissions]);

    const primaryMenus = useMemo((): any[] => {
        const _primaryMenus: Menu[] = [];
        const quality = features?.includes(Feature.Quality);
        const productCompliance = productComplianceEnabled;
        const supplierCompliance = features?.includes(Feature.SupplierCompliance);
        if (quality && productCompliance && supplierCompliance) {
            items.menus.inspectionsMenu.items.push(items.baseItems.inspectionsList, items.baseItems.planning, items.baseItems.correctiveActionsInspections, items.baseItems.bookings, items.baseItems.checklists);
            items.menus.supplyChainMenu.items.push(items.baseItems.audits, items.baseItems.correctiveActions, items.baseItems.suppliers, items.baseItems.productionUnits, items.baseItems.auditChecklists);
            items.menus.productComplianceMenu.items.push(items.baseItems.products, items.baseItems.orders, items.baseItems.requirements);
            _primaryMenus.push(items.menus.dashboardMenu, items.menus.inspectionsMenu, items.menus.supplyChainMenu, items.menus.productComplianceMenu);
        }
        if (quality && productCompliance && !supplierCompliance) {
            items.menus.inspectionsMenu.items.push(items.baseItems.inspectionsList, items.baseItems.planning, items.baseItems.correctiveActionsInspections, items.baseItems.bookings, items.baseItems.checklists);
            items.menus.productComplianceMenu.items.push(items.baseItems.products, items.baseItems.orders, items.baseItems.requirements);
            items.menus.dataMenu.items.unshift(items.baseItems.suppliers, items.baseItems.productionUnits);
            _primaryMenus.push(items.menus.dashboardMenu, items.menus.inspectionsMenu, items.menus.supplyChainMenu, items.menus.productComplianceMenu);
        }
        if (quality && !productCompliance && supplierCompliance) {
            items.menus.inspectionsMenu.items.push(items.baseItems.inspectionsList, items.baseItems.planning, items.baseItems.correctiveActionsInspections, items.baseItems.bookings, items.baseItems.checklists);
            items.menus.supplyChainMenu.items.push(items.baseItems.audits, items.baseItems.correctiveActions, items.baseItems.suppliers, items.baseItems.productionUnits, items.baseItems.auditChecklists);
            _primaryMenus.push(items.menus.dashboardMenu, items.menus.inspectionsMenu, items.menus.supplyChainMenu, items.menus.productComplianceMenu);
        }
        if (quality && !productCompliance && !supplierCompliance) {
            items.menus.inspectionsMenu.items.push(items.baseItems.inspectionsList, items.baseItems.planning, items.baseItems.correctiveActionsInspections, items.baseItems.bookings);
            items.menus.checklistMenu.items.push(items.baseItems.checklists);
            items.menus.dataMenu.items.unshift(items.baseItems.suppliers, items.baseItems.productionUnits);
            _primaryMenus.push(items.menus.dashboardMenu, items.menus.inspectionsMenu, items.menus.checklistMenu);
        }

        if (!quality && productCompliance && supplierCompliance) {
            items.menus.supplyChainMenu.items.push(items.baseItems.audits, items.baseItems.correctiveActions, items.baseItems.suppliers, items.baseItems.productionUnits, items.baseItems.auditChecklists);
            items.menus.productComplianceMenu.items.push(items.baseItems.products, items.baseItems.orders, items.baseItems.requirements);
            _primaryMenus.push(items.menus.dashboardMenu, items.menus.supplyChainMenu, items.menus.productComplianceMenu);
        }
        if (!quality && !productCompliance && supplierCompliance) {
            items.menus.auditsMenu.items.push(items.baseItems.audits, items.baseItems.auditChecklists);
            items.menus.correctiveActionsMenu.items.push(items.baseItems.correctiveActions);
            items.menus.productionUnitsMenu.items.push(items.baseItems.productionUnits);
            items.menus.suppliersMenu.items.push(items.baseItems.suppliers);

            _primaryMenus.push(items.menus.auditsMenu, items.menus.correctiveActionsMenu, items.menus.suppliersMenu, items.menus.productionUnitsMenu);
        }
        if (!quality && productCompliance && !supplierCompliance) {
            items.menus.productsMenu.items.push(items.baseItems.products);
            items.menus.ordersMenu.items.push(items.baseItems.orders);
            items.menus.requirementsMenu.items.push(items.baseItems.requirements);
            items.menus.dataMenu.items.unshift(items.baseItems.suppliers, items.baseItems.productionUnits);
            _primaryMenus.push(items.menus.productsMenu, items.menus.ordersMenu, items.menus.requirementsMenu);
        }

        return _primaryMenus;
    }, [intl.locale, features]);

    const secondaryMenus = useMemo(() => {
        const secondaryMenu: SecondaryMenu = { meta: { organisation: profile?.organization.name, name: [profile?.firstname, profile?.lastname].join(' ') }, items: [items.menus.profileMenu, items.menus.dataMenu, items.menus.systemSettingsMenu, items.menus.organisationsMenu] };
        const quality = features?.includes(Feature.Quality);
        const supplierCompliance = features?.includes(Feature.SupplierCompliance);
        if (!quality && productComplianceEnabled && !supplierCompliance) {
            const m = secondaryMenu.items[1];
            m.items.splice(m.items.length - 1, 1);
        }
        return [secondaryMenu];
    }, [intl.locale, features, permissions]);

    return { primaryMenus: primaryMenus, secondaryMenus };
};