import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import Image from '../../../base/components/Image';
import LoadMore from '../../../base/components/LoadMore';
import Loader from '../../../base/components/Loader';
import Button from '../../../base/components/basic/Button';
import { DEFAULT_LIMIT_LIST_SIZE } from '../../../base/config';
import { useCheckHasPermission } from '../../../base/hooks';
import { getPermissions } from '../../../base/selectors';
import { getLocationEntries, getLocationEntry, getPrettyDate, preventDefaultAndStopPropagation } from '../../../base/utils';
import { ShallowOrder } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { ListView } from '../../globals/components';
import PageContainer from '../../globals/components/PageContainer';
import LineTogglerButton from '../../globals/components/views/list/LineTogglerButton';
import { ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import { getComplianceOrderList, removeProductFromOrder } from '../complianceSlice';
import { getCompliancePaginatedOrdersSelector, isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceDocumentStatusPie from './ComplianceDocumentStatusPie';
import ComplianceProductsItem from './ComplianceProductsItem';


const ComplianceOrderList = (): React.ReactElement => {
    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isFetching = useSelector(isFetchingSelector);
    const permissions = useSelector(getPermissions);
    const ids = getLocationEntries(location).reqId || [];
    const isTodo = getLocationEntry(location, 'status')[0] === 'todo';
    const formatMessage = intl.formatMessage;
    const paginatedOrders = useSelector(getCompliancePaginatedOrdersSelector);
    const orders = paginatedOrders?.orders;
    const hasOrders = orders?.length > 0;
    const userCanCreateOrders = useCheckHasPermission(permissions, 'u_compliance_create_orders');
    const [total, setTotal] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT_LIST_SIZE);
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        if (paginatedOrders?.orders) {
            setOffset(paginatedOrders.pagination.offset);
            setLimit(paginatedOrders.pagination.limit);
            setTotal(paginatedOrders.pagination.total);
            setCount(parseInt(paginatedOrders.pagination.limit as unknown as string, 10) + parseInt(paginatedOrders.pagination.offset as unknown as string, 10));
        }
    }, [paginatedOrders]);


    const openProduct = (id: string) => {
        history.push('/compliance/products/' + id);
    };
    const [openIds, setOpenIds] = useState(ids);
    const toggle = (e, id: string) => {
        const isOpen = openIds.includes(id);
        const _ids = [...openIds];
        isOpen ? _ids.splice(_ids.indexOf(id), 1) : _ids.push(id);
        history.push('?' + qs.stringify({ reqId: _ids, status: isTodo ? 'todo' : 'all' }));
        setOpenIds(_ids);
        preventDefaultAndStopPropagation(e);
        return false;
    };
    const getToggler = (cnt: number, isOpen: boolean) => {
        return <>
            <span className='hidden md:flex '><LineTogglerButton open={isOpen} itemsCnt={cnt} /></span>
            <span className='md:hidden justify-center items-center flex'>{cnt}<i className='mi text-secondary'>{isOpen ? 'expand_less' : 'expand_more'}</i></span>
        </>
    }
    const getOrderImage = (order: ShallowOrder) => {
        const img = order.products.filter((product) => product.product_image);
        return img.length > 0 ? <Image squared className='rounded bg-cover bg-center w-12 h-12' url={img[0].product_image.image.image_url} /> : null;
    }
    const removeProduct = (productId: string, orderId: string) => {
        dispatch(removeProductFromOrder({ product_id: productId, order_id: orderId })).then(
            () => dispatch(getComplianceOrderList({ limit, offset }))
        );

    }
    useEffect(() => {
        dispatch(getComplianceOrderList({ limit, offset }));
    }, []);
    return <PageContainer header={<FormattedMessage id='compliance.order_list.title' />}>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.orders' })} />
        {/* <TabsMenu
                active={getLocationEntry(location, 'status')[0] || 'all'}
                path=''
                items={[
                    { labelId: 'compliance.order_list.tabs.item.all', query: { status: 'all' }, id: 'all' },
                    { labelId: 'compliance.order_list.tabs.item.todo', query: { status: 'todo' }, id: 'todo' },
                ]} /> */}

        {userCanCreateOrders && <div className='flex justify-end w-full items-center'>
            <Button secondary onClick={() => history.push('orders/create')}><FormattedMessage id='compliance.order_list.create_order' /></Button>
        </div>}


        <ListView className='mb-0'>
            <Loader active={isFetching}>
                {hasOrders && <ListViewHeader
                    className={twMerge('top-0')}
                    items={[
                        { label: undefined, className: 'w-1/16', key: 'spacer.1', },
                        { label: intl.formatMessage({ id: 'compliance.order_list.header.order_number' }), className: 'w-3/16', key: 'order_number' },
                        { label: intl.formatMessage({ id: 'compliance.order_list.header.order_date' }), className: 'w-3/16', key: 'order_date' },
                        { label: undefined, className: 'w-9/16', key: 'spacer.2', },
                    ]} />}
                {hasOrders && <ListViewLines className='border border-b-0'>
                    {orders.map((order) => {
                        const canEdit = order.permissions?.includes('orders_edit');
                        const isOpen = openIds.includes(order.order_id);
                        return <div className={twMerge('w-full', isOpen && 'border-l-0 md:border-l-4 border-l-brand')} key={'c_order_' + order.order_id} >
                            <ListViewLine onClick={(e) => toggle(e, order.order_id)} className='hover:bg-highlight-green cursor-pointer flex-row items-start sm:items-center'>
                                <ListViewItem className='w-1/16'>{getOrderImage(order)}</ListViewItem>
                                <ListViewItem className='w-3/16'>{order.order_number}</ListViewItem>
                                <ListViewItem className='w-9/16'>
                                    {getPrettyDate(order.order_date)}
                                </ListViewItem>
                                <ListViewItem className='w-1/16'>
                                    <ComplianceDocumentStatusPie
                                        productName={order.order_number}
                                        statistics={order.order_statistics}
                                    />
                                </ListViewItem>
                                <ListViewItem className='w-2/16 flex justify-end'>{getToggler(order.products.length, isOpen)}</ListViewItem>

                            </ListViewLine>

                            {isOpen && order.products.map((product, i) => {
                                const canDelete = product.permissions?.includes('product_delete');
                                return <>
                                    {i === 0 && <ListViewHeader className='w-full hidden md:flex md:mt-0 md:mb-0 md:py-0 text-sm border-b border-default bg-highlight-light-green' items={[
                                        { label: undefined, className: twMerge(' py-2 w-1/16'), key: 'spacer.1' },
                                        { label: formatMessage({ id: 'compliance.order_list.product.product_number' }), className: twMerge(' py-2 w-3/16'), key: 'item_no' },
                                        { label: formatMessage({ id: 'compliance.order_list.product.product_name' }), className: twMerge(' py-2 w-7/16'), key: 'item_name' },
                                        { label: undefined, className: twMerge(' py-2 w-1/16'), key: 'stat' },
                                        { label: formatMessage({ id: 'compliance.order_list.product.product_deadline' }), className: twMerge(' py-2 w-4/16'), key: 'deadline' },

                                    ]} />}
                                    <ComplianceProductsItem product={product} key={'product_item' + product.product_id + i} onClick={openProduct}
                                        canDelete={false}
                                        canRemove={true}
                                        showDeleteFunction={true}
                                        removeFunction={(productId) => removeProduct(productId, order.order_id)}
                                    />
                                </>
                            })}
                            {isOpen && <div className={twMerge('border-0 flex justify-center py-3 md:p-2 md:bg-white border-b-4 border-b-brand')}>
                                {canEdit && <Button behaveAsLink link={'/compliance/orders/' + order.order_id} small><FormattedMessage id='compliance.order_list.edit_order' /></Button>}
                            </div>}


                        </div>
                    })}
                </ListViewLines>}
                {!hasOrders && <div className='py-8'><FormattedMessage id='compliance.order_list.no_orders_found' /></div>}
            </Loader>
        </ListView>
        {total > 0 && <LoadMore className='border border-t-0'
            disabled={isFetching}
            isLoading={isFetching}
            onLoadMore={(limit: number, offset: number) => {
                dispatch(getComplianceOrderList({ limit, offset }));
            }}
            chunk={limit}
            total={total}
            count={count}
        />}
    </PageContainer>
}
export default ComplianceOrderList;
