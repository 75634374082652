/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCustomFieldDocumentValue } from './AuditCustomFieldDocumentValue';
import type { TextWithTranslation } from './TextWithTranslation';

/**
 *   Value for a supplier document based custom field.
 *
 */
export type AuditCustomFieldSupplierDocumentValue = {
    documentation_type_code: string | null;
    documentation_type_description: TextWithTranslation | null;
    documentation_type_id: string;
    documentation_type_name: TextWithTranslation;
    existing_documents: Array<AuditCustomFieldDocumentValue>;
    new_documents: Array<AuditCustomFieldDocumentValue>;
    requires_approval: boolean;
    requires_expiry: boolean;
    type: AuditCustomFieldSupplierDocumentValue.type;
};

export namespace AuditCustomFieldSupplierDocumentValue {

    export enum type {
        SUPPLIER_DOCUMENT = 'SupplierDocument',
    }


}

