import mimeType from 'mime';

import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { FileResource, FileResourceTypeEnum } from '../../../backend_api/models';
import { getShowSingleMediaLightBoxSelector, showSingleMediaLightBox } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { useAppDispatch } from '../../../store';
import Video from '../../video/components/Video';
import { calculatePrettySize } from './handlers';
import { convertFileResourceToSingleLightBoxMedia, useIsMobileDevice } from '../../../base/utils';
import { useSelector } from 'react-redux';

type Props = {
    file: FileResource;
    disabled?: boolean;
    displayAsFile?: boolean;
    // basic?: boolean;
    showDelete?: boolean;
    showFilename?: boolean;
    className?: string;
    imageClassName?: string;
    showOddEven?: boolean;
    border?: boolean;
    highlightColor?: Text;
    deleteFunction?(fileId: string);
}
const FileItem = (props: Props) => {
    const { file, disabled = false, showDelete = true, className, showOddEven = false, imageClassName, showFilename = true, border = true, displayAsFile = false } = props;
    const dispatch = useAppDispatch();
    const openLB = useSelector(getShowSingleMediaLightBoxSelector);
    const intl = useIntl();
    const isMobile = useIsMobileDevice();
    
    const getDeleteAction = (type: 'icon' | 'button'): React.ReactElement => {
        return <DeleteConfirm trigger={
            <div className='w-4 text-secondary cursor-pointer items-center'>
                {type === 'icon' ?
                    <RemoveIcon className='text-gray-400 hover:text-secondary flex items-center' /> :
                    <Button onClick={(e) => e.preventDefault()} className={twMerge('text-sm p-1 border-0 hover:text-primary text-secondary bg-transparent')}>
                        <FormattedMessage id='globals.confirmation.delete' />
                    </Button>
                }
            </div>}
            deleteFunction={() => props.deleteFunction(file.id)}
            type={isMobile ? 'alert' : 'default'}
            alertText={intl.formatMessage({ id: 'corrective_actions.delete_evidence.text' })}
            alertHeader={intl.formatMessage({ id: 'corrective_actions.delete_evidence.header' })} />
    }


    const openLightBox = () => {
        dispatch(showSingleMediaLightBox({ show: true, file: convertFileResourceToSingleLightBoxMedia(file) }))
    }

    return <Fragment>
        {file.type == FileResourceTypeEnum.Image &&
            <div className={twMerge('flex m-2 hover:cursor-pointer w-full', displayAsFile ? 'w-full' : 'rounded flex-col sm:w-1/4 hover:shadow-md p-2  pt-4', border && !displayAsFile ? 'border hover:border-gray-500 ' : ' border-none', className)}>
                <div onClick={openLightBox} className='flex justify-center'>
                    {displayAsFile ? <Popup hoverable trigger={<div className='link noUnderline'>{file.name}</div>}>
                        <div className='link noUnderline'>{file.name}</div>
                        <div className='text-sm'><FormattedMessage id='corrective_actions.evidence.click_to_open_large_view' /></div>
                    </Popup> :
                        <Popup hoverable trigger={<Image className={twMerge(imageClassName, !border && 'rounded')} src={file.preview_url + '?w=185&h=185'} />}>
                            <div className='link noUnderline'>{file.name}</div>
                            <div className='text-sm'><FormattedMessage id='corrective_actions.evidence.click_to_open_large_view' /></div>
                        </Popup>}
                </div>
                {!displayAsFile && <div className='flex justify-between items-center'>
                    {showFilename && <div className='text-secondary text-sm pt-1 truncate'>
                        {file.name}
                    </div>}
                    {(!disabled && showDelete) && getDeleteAction('icon')}
                </div>}
            </div>
        }
        {props.file.type == FileResourceTypeEnum.Video &&
            (
                displayAsFile ?
                    <div className={twMerge('cursor-pointer w-full', className)}>
                        <div onClick={openLightBox} className='flex justify-start'>
                            <Popup hoverable trigger={<div className='link noUnderline'>{file.name}</div>}>
                                <div className='link noUnderline'>{file.name}</div>
                                <div className='text-sm'><FormattedMessage id='corrective_actions.evidence.click_to_open_large_view' /></div>
                            </Popup>
                        </div>
                    </div> :
                    <div className={twMerge('flex m-2 w-1/4 px-2 pt-4 border rounded flex-col h-full space-x-2 justify-between', 'items-center cursor-pointerw-full')}>
                        <div className={!openLB.show ? 'h-56 overflow-hidden w-full flex' : 'w-full'}>
                            <Video
                                thumbnail={file.preview_url}
                                videoResources={file.video.video_resources}
                                usePlayIcon={true}
                                preload={true}
                            />
                        </div>
                        <div className='flex justify-between items-center w-full'>
                            <div className='text-secondary text-sm pt-1 truncate' title={file.name} onClick={openLightBox}>
                                {file.name}
                            </div>
                            {(!disabled && showDelete) && <div>
                                {getDeleteAction('icon')}
                            </div>}
                        </div>
                    </div>
            )
        }
        {props.file.type == FileResourceTypeEnum.File &&
            <Popup
                mouseEnterDelay={300}
                // position='top center'
                trigger={<a className={twMerge('flex items-baseline w-full cursor-pointer hover:bg-gray-200 p-1', showOddEven && 'odd:bg-gray-100', 'link noUnderline', className)} href={props.file.file.download_url}>
                    {/* !basic && getIcon(file.file.mime_type) */}
                    <span className={twMerge('items-center flex p-0 sm:py-2', 'w-11/12 sm:w-11/12')}>
                        <span className={twMerge('link break-words')}>
                            {decodeURIComponent(props.file.name)}
                        </span>
                    </span>
                    {(!disabled && showDelete) && <div className='flex sm:w-1/12 w-12'>{getDeleteAction('button')}</div>}
                </a>}>
                <div className='pb-2'>{decodeURIComponent(props.file.name)}</div>
                <div className='pb-2'>{mimeType.getExtension(props.file.file.mime_type)}</div>
                <div className='text-secondary text-sm'>{calculatePrettySize(props.file.file.size)}</div>
            </Popup>
        }
    </Fragment >
}

export default FileItem;