import React, { useEffect, useState } from 'react';
import { twMerge } from '../../utils';
import ContextMenu from '../../ui/components/contextMenu/ContextMenu';
import { Sizes } from '../../ui/components/types';

type Props = {
    className?: string;
    edit?: boolean;
    onEdit(isEditable: boolean): void;
};

const EditContextMenuButton = (props: Props): React.ReactElement => {
    const { className, onEdit } = props;
    const [edit, setEdit] = useState<boolean>(props.edit || false);

    useEffect(() => {
        setEdit(props.edit || false);
    }, [props.edit]);

    const handleEditToggle = () => {
        const newEditState = !edit;
        setEdit(newEditState);
        onEdit(newEditState);
    };

    const contextMenu = <ContextMenu
        className={twMerge('h-fit-content', className)}
        actions={edit ? [
            {
                label: 'globals.done',
                onPress: handleEditToggle,
                labelRight: true
            }
        ] : [
            {
                label: 'globals.edit',
                icon: 'operation_edit',
                className: '',
                labelLeft: true,
                onPress: handleEditToggle,
                buttonSize: Sizes.Small
            }
        ]}
    />;

    return contextMenu;
}

export default EditContextMenuButton;
