import React, { useEffect } from "react";
import QModal, { ActionTypes } from "../../../base/ui/components/modals/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import ContextMenu, {
    ContextMenuActionItemType,
} from "../../../base/ui/components/contextMenu/ContextMenu";
import { useSelector } from "react-redux";
import { archiveSupplierDocument, deleteSupplierDocument, fetchSupplierDocument, getAllDocumentsForProductionUnit, getDetailedProductionUnitListSelector, getProductionUnitDocumentsFetchingSelector, getProductionUnitDocumentsSelector, getSupplierDocumentTypeGroupsSelector, unarchiveSupplierDocument } from "../slice/productionUnitsSlice";
import DocumentationTypesFilter from "../../filters/components/DocumentationTypesFilter";
import StatusMarker, {
    Status,
} from "../../../base/ui/components/status/StatusMarker";
import {
    DisplayDetailedSupplierDocument,
    DisplayDetailedSupplierDocumentStateEnum,
    DocumentationType,
} from "../../../backend_api/models";
import {
    getLocaleLanguageString,
    getTranslation,
    isDateExpired,
    twMerge,
} from "../../../base/utils";
import Button, { ButtonTypes } from "../../../base/ui/components/buttons/Button";
import { useAppDispatch } from "../../../store";
import DeleteDocumentConfirmModal from "./DeleteDocumentConfirmModal";
import { renderDocumentationStateDescription } from "./DetailedSupplierDocument";
import { getDocumentationTypeById } from "../../../base/ui/components/documentViewer/SupplierDocumentApprovalAndValidity";
import { getPermissionsSelector } from "../../../base/selectors";

export function ViewProductionUnitDocumentsModal(props: {
    showing: boolean;
    setShowing: (showing: boolean) => void;
    addDocumentation: () => void
    filters?: string[];
}) {
    const { showing, setShowing, filters, addDocumentation } = props;
    const dispatch = useAppDispatch();
    const productionUnit = useSelector(getDetailedProductionUnitListSelector);
    const documents = useSelector(getProductionUnitDocumentsSelector);
    const isLoading = useSelector(getProductionUnitDocumentsFetchingSelector);
    const userHasDocumentationTypes = useSelector(getPermissionsSelector).includes('u_documentation_types');
    const [documentFilters, setDocumentFilters] = React.useState<string[]>(filters || []);
    const showingDocuments = documentFilters.length > 0 ? documents.filter(doc => documentFilters.includes(doc.documentation_type_id)) : documents;
    const documentTypeGroups = useSelector(getSupplierDocumentTypeGroupsSelector);

    useEffect(() => {
        if (showing && productionUnit) {
            dispatch(getAllDocumentsForProductionUnit({ production_unit_id: productionUnit.id }));
        }
    }, [showing, productionUnit, dispatch]);

    const handleViewDocument = (documentId: string) => {
        dispatch(fetchSupplierDocument({ id: documentId }));
    };

    return (
        <>
            <QModal
                className="min-h-[70vh]"
                isOpen={showing}
                width={883}
                header={
                    <div className="flex flex-row justify-between items-center w-full font-normal pr-4">
                        <div className="font-bold">
                            <FormattedMessage id="production_units.detailed_page.all_documents" />
                        </div>
                    </div>
                }
                content={
                    <>
                        <DocumentationTypesFilter
                            label="filters.type"
                            placeholder="filters.any_type"
                            className="max-w-[340px]"
                            onFilterSet={(selectedTypes) => {
                                setDocumentFilters(selectedTypes);
                            }}
                            filters={filters}
                            allowMultipleChoice={true}
                        />
                        <div className="mt-6">
                            {isLoading ? (
                                <div className="border py-6 flex flex-col justify-center items-center rounded-md">
                                    <div className="italic text-secondary border-b pb-6">
                                        <FormattedMessage id="globals.loading" />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {showingDocuments.map((doc, index) => {
                                        return (
                                            <DocumentItem
                                                key={doc.id}
                                                documentationType={getDocumentationTypeById(documentTypeGroups, doc.documentation_type_id)}
                                                show={handleViewDocument}
                                                document={doc}
                                                className={twMerge(index == showingDocuments.length - 1 ? "border-b rounded-b-md" : "", index == 0 ? "rounded-t-md" : "")}
                                            />
                                        );
                                    })}
                                    {(!documents || documents?.length == 0 || showingDocuments.length == 0) &&
                                        <div className="border py-6 flex flex-col justify-center items-center rounded-md">
                                            <div className="italic text-secondary border-b pb-6">
                                                {documents?.length == 0 ?
                                                    <FormattedMessage id="production_units.detailed_page.view_all_documents.no_documents" />
                                                    :
                                                    <FormattedMessage id="production_units.detailed_page.view_all_documents.no_matched_documents" />
                                                }
                                            </div>
                                            {
                                                userHasDocumentationTypes ?
                                                    <Button
                                                        className="mt-6"
                                                        buttonType={ButtonTypes.Primary}
                                                        onPress={() => {
                                                            setShowing(false);
                                                            addDocumentation();
                                                        }}
                                                    >
                                                        <FormattedMessage id="compliance.documentation.waiting_for_documentation.add" />
                                                    </Button>
                                                    :
                                                    <div className="mt-6">
                                                        <FormattedMessage id="production_units.detailed_page.view_all_documents.try_to_clear_filters" />
                                                    </div>
                                            }
                                        </div>
                                    }
                                </>
                            )}
                        </div>
                    </>
                }
                onClose={() => setShowing(false)}
            />
        </>
    );
}

function DocumentItem(props: {
    className?: string;
    documentationType: DocumentationType;
    document: DisplayDetailedSupplierDocument;
    show: (id: string) => void;
}) {
    const { className, document, documentationType } = props;
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const dispatch = useAppDispatch();
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] =
        React.useState(false);

    const doArchive = () => {
        dispatch(archiveSupplierDocument({ id: document?.id }));
    };

    const doUnarchive = () => {
        dispatch(unarchiveSupplierDocument({ id: document?.id }));
    };

    const doDelete = () => {
        dispatch(deleteSupplierDocument({ id: document?.id }));
    };

    const archiveDocumentContextMenuItem: ContextMenuActionItemType = {
        label: "production_units.detailed_page.context_menu.archive_document",
        icon: "operation_archive",
        onPress: doArchive,
    };
    const unarchiveDocumentContextMenuItem: ContextMenuActionItemType = {
        label: "production_units.detailed_page.context_menu.unarchive_document",
        icon: "operation_unarchive",
        onPress: doUnarchive,
    };
    const deleteDocumentContextMenuItem: ContextMenuActionItemType = {
        className: "text-danger",
        label: "production_units.detailed_page.context_menu.delete_document",
        labelLeft: true,
        icon: "operation_delete",
        onPress: () => {
            setShowDeleteDocumentModal(true);
        },
    };

    return (
        <div
            onClick={() => props.show(document.id)}
            className={twMerge(
                "cursor-pointer flex flex-row justify-between py-[0.625rem] px-[0.875rem] items-center border-t border-x border-default hover:bg-gray-50",
                className
            )}
        >
            <div className="flex flex-row gap-4">
                <StatusMarker
                    className="min-w-[7.2rem] h-[2.8rem]"
                    status={mapDocumentStateWithStatus(document)}
                    text={mapDocumentStateWithName(document)}
                />
                <div className="space-y-1">
                    <div className="font-medium">
                        {getTranslation(document.documentation_type_name, lang)}
                    </div>
                    <div className="text-sm text-primary">
                        {renderDocumentationStateDescription({
                            documentationType,
                            detailedSupplierDocument: document
                        })}
                    </div>
                </div>
            </div>
            <ContextMenu
                overflowItems={
                    document.archived
                        ? [
                            unarchiveDocumentContextMenuItem,
                            deleteDocumentContextMenuItem,
                        ]
                        : [
                            archiveDocumentContextMenuItem,
                            deleteDocumentContextMenuItem,
                        ]
                }
            />
            <DeleteDocumentConfirmModal
                open={showDeleteDocumentModal}
                close={() => setShowDeleteDocumentModal(false)}
                archiveDocument={document.archived ? null : doArchive}
                deleteDocument={doDelete}
            />
        </div>
    );
}

function mapDocumentStateWithName(
    document: DisplayDetailedSupplierDocument
): string {
    if (isDateExpired(document.valid_to_date)) {
        return "production_units.list.documentation.state.expired";
    }

    if (document.archived) {
        return "production_units.list.documentation.state.archived";
    }
    switch (document.state) {
        case DisplayDetailedSupplierDocumentStateEnum.FileAdded:
            return "production_units.list.documentation.state.file_added";
        case DisplayDetailedSupplierDocumentStateEnum.WaitingForApproval:
            return "production_units.list.documentation.state.in_review";
        case DisplayDetailedSupplierDocumentStateEnum.Active:
            return "production_units.list.documentation.state.active";
        case DisplayDetailedSupplierDocumentStateEnum.Rejected:
            return "production_units.list.documentation.state.rejected";
        case DisplayDetailedSupplierDocumentStateEnum.Approved:
            return "production_units.list.documentation.state.approved";
    }
}

function mapDocumentStateWithStatus(
    document: DisplayDetailedSupplierDocument
): Status {
    if (isDateExpired(document.valid_to_date)) {
        return "expired";
    }

    if (document.archived) {
        return "archived";
    }

    switch (document.state) {
        case DisplayDetailedSupplierDocumentStateEnum.FileAdded:
            return "in-progress";
        case DisplayDetailedSupplierDocumentStateEnum.WaitingForApproval:
            return "pending";
        case DisplayDetailedSupplierDocumentStateEnum.Active:
            return "planned";
        case DisplayDetailedSupplierDocumentStateEnum.Rejected:
            return "rejected";
        case DisplayDetailedSupplierDocumentStateEnum.Approved:
            return "approved";
    }
}
