import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { ChecklistSummary } from '../../../backend_api_2';
import { getPrettyDate, preventDefaultAndStopPropagation } from '../../../base/utils';
import { ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import { Checklist } from '../types';
import DownloadChecklist from './DownloadChecklist';

export function ChecklistItem(props: {
    canCreate: boolean;
    canEdit: boolean;
    canDelete: boolean;
    type: "inspection" | "audit";
    item: ChecklistSummary | Checklist;
    showDeleteDialog(item: ChecklistSummary | Checklist): void;
    copyChecklist(id: string, description: string): void;
}): React.ReactElement {
    const intl = useIntl();

    const item = props.item;
    const url = props.type === 'inspection' ? '/inspections/edit_checklist/' + item.id : '/audits/edit_checklist/' + item.id;
    const intlMessages = defineMessages({
        edit: {
            id: 'checklists.edit_checklist'
        },
        copy: {
            id: 'checklists.copy_checklist'
        },
        delete: {
            id: 'checklists.delete_checklist'
        }
    });

    const editTxt: string = intl && intl.formatMessage(intlMessages.edit);
    const copyTxt: string = intl && intl.formatMessage(intlMessages.copy);
    const deleteTxt: string = intl && intl.formatMessage(intlMessages.delete);

    if (!item) {
        return null;
    }

    return (<ListViewLine
        key={item.id}
        className='hover:bg-highlight-light-green md:py-6 py-3 border-b flex-col sm:flex-row'
        link={props.canEdit ? url : undefined}>
        <ListViewItem className='sm:w-7/16'>
            {item.description}
        </ListViewItem>
        <ListViewItem className='sm:w-8/16'>
            <span className='space-x-1'>
                <span>{getPrettyDate(item.updated_at)}</span>
                <span>{item.updated_by && <span className='space-x-1'><span>by {item.updated_by.firstname}</span><span>{item.updated_by.lastname}</span></span>}</span>
            </span>
        </ListViewItem>
        <ListViewItem className='sm:w-1/16 justify-center hover:border-gray-400 border border-transparent rounded'>
            <Dropdown onClick={(e) => preventDefaultAndStopPropagation(e)} className='w-full flex-important flex-row-reverse pr-2'>
                <Dropdown.Menu direction='left'>
                    {props.canEdit && <Dropdown.Item text={editTxt} as={Link} to={url} />}
                    {props.canCreate && <Dropdown.Item text={copyTxt} onClick={(): void => props.copyChecklist(item.id, item.description)} />}
                    {props.canDelete && <Dropdown.Item onClick={(): void => props.showDeleteDialog(item)} text={deleteTxt} />}
                    {/* {!props.canDelete && <Dropdown.Item disabled text={deleteTxt} />} */}
                    <div className=' hover:bg-surface-selected rounded-none'>
                        <DownloadChecklist className='bg-transparent px-4 pt-3 pb-4 flex rounded-none w-full' skipTranslation={true} checklistId={item.id} checklistDescription={item.description} checklistLanguages={['C']} /></div>
                </Dropdown.Menu>
            </Dropdown>

        </ListViewItem>
    </ListViewLine>
    );
}