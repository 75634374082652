import React, { useState } from 'react';
import { MenuItem, MenuSection } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AuthenticationAuthorization, OrganizationNameAndLogo, OtherOrganization, TwoFactorRequired } from '../../../backend_api/models';
import { Sizes } from '../../../base/components/types';
import { ComponentOrStringType } from '../../../base/types';
import Button, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { ListItem, ListItems } from '../../../base/ui/components/listitems/ListItem';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { switchOrganisation } from '../../authentication/authenticationSlice';
import { getAuthenticationList } from '../../authentication/selectors';

type Props = {
    className?: string;
    organisations: OtherOrganization[];
    currentOrganisation: OrganizationNameAndLogo;
    trigger?: ComponentOrStringType;
    itemClassName?: string;
    isMobile?: boolean;
};

const GlobalNavigationOrganisationSwitcher = (props: Props): React.ReactElement => {
    const { className, trigger, organisations, currentOrganisation, itemClassName, isMobile = false } = props;
    const location = useLocation();
    const authentications = useSelector(getAuthenticationList);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    const selectOrganisation = (orgId: string): void => {
        let authorization: AuthenticationAuthorization | TwoFactorRequired = undefined;
        authentications && authentications.forEach((auth) => {
            if (auth.organization_id === orgId) {
                authorization = auth;
            }
        })
        if (authorization) {
            dispatch(switchOrganisation(authorization))
        }
        else {
            setOpenConfirm(true);
        }
    };
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const items = [];
    organisations.map((organisation) => {
        items.push(!isMobile ? <MenuItem className={twMerge('cursor-pointer', itemClassName)} key={organisation.organization_id} onAction={() => selectOrganisation(organisation.organization_id)}>{organisation.organization_name}</MenuItem>
            :
            <ListItem className='cursor-pointer' onPress={() => selectOrganisation(organisation.organization_id)} key={organisation.organization_id} inlineButton={{ button: <Button size={Sizes.XSmall}>Switch</Button> }}>{organisation.organization_name}</ListItem>)
    });
    if (isMobile) {
        items.unshift(<ListItem meta={{ metaBelow: { firstItem: 'Current organisation' } }}>{currentOrganisation.name}</ListItem>)
    } else {
        items.push(<MenuItem className={twMerge(itemClassName, 'cursor-default bg-surface-selected font-bold')}>{currentOrganisation.name}</MenuItem>)
    }
    const content = items.length > 0 && (isMobile ? <ListItems className={'border border-b-0 rounded-md'}>{items}</ListItems> : <MenuSection className='py-1'>{items}</MenuSection>);
    return isMobile ? <div className={twMerge('', className)}>
        <Modal
            width={500}
            trigger={trigger}
            header={intl.formatMessage({ id: 'global_navigation.switch_organisation.header' })}
            content={content}
            actionItems={[
                { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: null },
            ]}
        >

        </Modal>
    </div>
        : content;
}
export default GlobalNavigationOrganisationSwitcher;
