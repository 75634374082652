/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SetCustomFieldNotSet } from './SetCustomFieldNotSet';
import type { SetCustomFieldSupplierDocuments } from './SetCustomFieldSupplierDocuments';

/**
 *   Value for a supplier document based custom field.
 *
 */
export type SetFieldSupplierDocumentValue = {
    new_value: (SetCustomFieldNotSet | SetCustomFieldSupplierDocuments);
    type: SetFieldSupplierDocumentValue.type;
};

export namespace SetFieldSupplierDocumentValue {

    export enum type {
        SUPPLIER_DOCUMENT = 'SupplierDocument',
    }


}

