/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileResource } from './FileResource';
import type { ModelDate } from './ModelDate';

/**
 *   Document Value for a supplier document based custom field.
 *
 */
export type AuditCustomFieldDocumentValue = {
    expiration_date: ModelDate | null;
    file_resource: FileResource | null;
    start_date: ModelDate | null;
    status: AuditCustomFieldDocumentValue.status | null;
};

export namespace AuditCustomFieldDocumentValue {

    export enum status {
        FILE_ADDED = 'file_added',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        ACTIVE = 'active',
        EXPIRED = 'expired',
        ARCHIVED = 'archived',
        DELETED = 'deleted',
    }


}

