import moment from 'moment';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DocumentTitle from 'react-document-title';
import { HotKeys } from 'react-hotkeys';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { Conclusion, InspectionsWeekviewInspection, InspectionsWeekviewOrder, InspectionsWeekviewRow, InspectionsWeekviewSupplierOrders, Supplier, WeekviewInspectionDetailsResponse, WeekviewUser } from '../../../../../../backend_api/models';
import Loader from '../../../../../../base/components/Loader';
import Button from '../../../../../../base/components/basic/Button';
import Icon from '../../../../../../base/components/basic/Icon';
import { Breakpoints } from '../../../../../../base/config';
import { getMoment, getWeekNumberByDate, useMaxWidthBreak } from '../../../../../../base/utils';
import { useAppDispatch } from '../../../../../../store';
import { InspectionTypesFilter } from '../../../../../filters/components';
import GroupsFilter from '../../../../../filters/components/GroupsFilter';
import { ProductionUnit } from '../../../../../suppliers/types';
import { getWeekViewUsersSelector } from '../../../../../users/selectors';
import { setFavoriteInspectorsManually } from '../../../../actions/dashboard/weekViewActions';
import { WeekViewOrder, getWeekViewDataFiltered, getWeekViewDataSelector, getWeekViewFavoriteInspectorsSetManually } from '../../../../slices/weekViewSlice';
import { Inspection, InspectionType } from '../../../../types';
import InspectorList from './InspectorList';
import { WeekToggler } from './WeekToggler';
import { WeekViewInspector } from './WeekViewInspector';
import WeekViewDayItem from './views/WeekViewDayItem';


type WeekView2Props = {
    className?: string;
};

export type InspectionsWeekviewOrderExtended = InspectionsWeekviewOrder & { features: string[] } & { inspections: InspectionsWeekviewInspectionExtended[] };
export type InspectionsWeekviewInspectionExtended = WeekviewInspectionDetailsResponse & InspectionsWeekviewInspection &
{ master_inspection: boolean } & { is_master: boolean } & { sequence_identifier: string[] } & { destination_split_shipments: Inspection['destination_split_shipments'] } & { source_split_shipments: Inspection['source_split_shipments'] }
    & { conclusion: Conclusion } & { inspector_conclusion: Conclusion } & { inspection_type: InspectionType } & { features: string[] } & { supplier_entity: any }
    & { linked_production_unit: ProductionUnit }
    & { supplier_entity: Supplier };
export enum DragType { WEEKVIEW_INSPECTION_ITEM = 'inspection', WEEKVIEW_ORDER_ITEM = 'order', WEEKVIEW_SUPPLIER_ITEM = 'supplier', WEEKVIEW_EMPTY_ITEM = 'empty', WEEK_VIEW_PARKING_ITEM = 'parking' }
export type WeekViewItemType = 'booked' | 'planned' | 'supplier_qc' | 'user' | 'parking';
export type WeekViewType = 'inspection' | 'order' | 'supplier';
export type WeekViewDataItem = {
    type: WeekViewItemType;
    user: WeekviewUser;
    days: InspectionsWeekviewSupplierOrders[];
}
export type WeekViewDayItemType = {
    supplier: Supplier;
    orders: WeekViewOrder[];
}
export type WeekViewDay = InspectionsWeekviewSupplierOrders[];

export type WeekViewFromDataItem = {
    date: Date;
    user: WeekviewUser;
    data: WeekViewDayItemType;
    itemType: WeekViewItemType;
    inspection?: InspectionsWeekviewInspectionExtended;
    order?: InspectionsWeekviewOrderExtended;
    type: WeekViewType;
    id: string;
}
export const getHeaderDate = (date: Date, short?: boolean) => {
    return short ? getMoment(date).format('ddd MMM DD') : getMoment(date).format('ddd MMMM DD');
}
const keyMap = {
    nextWeek: 'right',
    prevWeek: 'left',
};
const WeekView2 = (props: WeekView2Props): React.ReactElement => {
    const { className } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const intl = useIntl();
    const handlers = {
        nextWeek: (): void => changeWeek(true),
        prevWeek: (): void => changeWeek(false),
    };
    const [isCompact, setIsCompact] = useState<boolean>(true);
    const [openItems, setOpenItems] = useState<string[]>([]);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [scrollBarItems, setScrollBarItem] = useState<string[]>([]);
    const data: any = useSelector(getWeekViewDataSelector);
    const favoriteInspectorsAreSetManually = useSelector(getWeekViewFavoriteInspectorsSetManually);
    const usersByGroup = useSelector(getWeekViewUsersSelector);
    const getInspectionsCount = (inspector: InspectionsWeekviewRow): number => {
        let cnt = 0;
        inspector.days.forEach((day) => {
            if (day.length > 0) {
                day.forEach((d) => {
                    d && d.orders.forEach((order) => {
                        if (order.inspections[0] && order.inspections[0].sub_inspections.length > 0) {
                            cnt += order.inspections[0].sub_inspections.length;
                        } else {
                            cnt += order.inspections.length;
                        }
                    });
                });
            }
        });
        return cnt;
    }
    const toggleCompact = (e, id: string): void => {
        const items = [...openItems];
        if (items.includes(id)) {
            const index = items.indexOf(id);
            items.splice(index, 1);
        } else {
            items.push(id);
        }
        setOpenItems(items);
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }
    const compactAll = (collapse: boolean): void => {
        const openItems = [];
        if (!collapse) {
            data.data.rows.map((inspector, i: number) => {
                const user = inspector.user && inspector.user;
                inspector.days.map((day, j: number) => {
                    day.map((supplierObj) => {
                        const email = user && user.email || 'unassigned';
                        const id = email + '_' + j + '_' + i + '_' + (supplierObj.supplier && supplierObj.supplier.id || 'none');
                        openItems.push(id);
                    });
                });
            });
        }
        setOpenItems(openItems);
    }

    const toggleCompactAll = (): void => {
        compactAll(!isCompact);
        setIsCompact(!isCompact);
    }
    const q = qs.parse(location.search);
    const labelsShort = {
        booked: intl.formatMessage({ id: 'inspections.week_view.booked_inspections_short' }),
        supplier_qc: intl.formatMessage({ id: 'inspections.week_view.supplier_qc_short' }),
        planned: intl.formatMessage({ id: 'inspections.week_view.unassigned_inspections_short' }),
    };
    const changeWeekByNumber = (date: string): void => {
        setOpenItems([]);
        dispatch(getWeekViewDataFiltered({ date }));
    }

    const changeWeek = (next: boolean): void => {
        const currentDate = data.dates && data.dates.startDate;
        const nextWeek = next ? moment(currentDate).add(1, 'w').format('YYYY-MM-DD') : moment(currentDate).add(-1, 'w').format('YYYY-MM-DD');
        setOpenItems([]);
        dispatch(getWeekViewDataFiltered({ date: nextWeek }));
    };
    const toggleInspectorsManually = (): void => {
        dispatch(setFavoriteInspectorsManually(!favoriteInspectorsAreSetManually));
    }
    const toggleInspectorList = (save: boolean) => {
        if (sidebarOpen) {
            dispatch(getWeekViewDataFiltered({ date: data.dates && data.dates.startDate }));
        }
        setSidebarOpen(!sidebarOpen);
    }
    const currentDate: string = data.dates.startDate;
    const currentWeek: number = getWeekNumberByDate(currentDate);
    moment.locale(intl.locale);
    const currentDateMoment = getMoment(currentDate);
    const getAlphaData = () => {
        const list = {};
        data.data && data.data.rows.map((item) => {
            if (item.user) {
                const fn = item.user.firstname && item.user.firstname.trim()[0];
                if (!list[fn]) {
                    list[fn] = [];
                }
                list[fn].push(item.user)

            }
        });
        return list;
    }
    const isSameYear = currentDateMoment.endOf('isoWeek').format('YY') === currentDateMoment.startOf('isoWeek').format('YY');
    const startFormat = isSameYear ? 'DD MMM' : 'DD MMM YYYY';
    const currentWeekLabel = currentWeek + ', ' + getMoment(currentDate).startOf('isoWeek').format(startFormat) + ' - ' + getMoment(currentDate).endOf('isoWeek').format('DD MMM YYYY');
    const gf = <div className='w-64'><GroupsFilter showLabel={false} handleChange={(): void => changeWeekByNumber(currentDate)} /></div>;
    const itf = <div className='w-64'><InspectionTypesFilter showLabel={false} filterId='inspection_type_ids' handleChange={(): void => changeWeekByNumber(currentDate)} /></div>;
    useEffect(() => {
        const date = q.d || data.dates.startDate || getMoment().startOf('isoWeek').format('YYYY-MM-DD');
        dispatch(getWeekViewDataFiltered({ date: date as string }));
    }, [])
    if (data && data.dates) {
        const rows = data.data && data.data.rows;
        // const items = data.data && data.data.rows.filter((inspector) => inspector.type !== 'parking');
        // const itemsParking = data.data && data.data.rows.filter((inspector) => inspector.type === 'parking');
        const dates = data.data && data.data.dates || [];
        // const alphaData = getAlphaData();
        // const hasAlphaData = Object.keys(alphaData).length > 8;
        const hasAlphaData = false;
        // const hasAlphaData = false;
        const isMaxXL = useMaxWidthBreak(Breakpoints.XL);
        return <DndProvider backend={HTML5Backend}>
            <HotKeys keyMap={keyMap} handlers={handlers} key='weekviewDndProvider'>
                <DocumentTitle title={intl.formatMessage({ id: 'page_title.week_view' }, { currentWeekLabel })} />
                <div className='flex'>
                    {/* data.data && <WeekViewAlphaList alphaList={alphaData} /> */}
                    <div className={twMerge('weekView pt-16 w-full pb-4 pr-2', hasAlphaData ? 'ml-12' : 'pl-2')}>
                        <h2>
                            <FormattedMessage id='inspections.week_view.week_label' /> {currentWeekLabel}
                        </h2>
                        <Loader active={data.isLoading}>
                            {!data.data && <div className='h-48 border'></div>}
                            {data.data && <div className='border flex bg-white'>
                                {/* <WeekViewAlphaList alphaList={getAlphaData()} /> */}

                                {sidebarOpen && <InspectorList
                                    inspectors={usersByGroup}
                                    close={() => setSidebarOpen(false)}
                                    toggleManually={favoriteInspectorsAreSetManually}
                                    setToggleManually={toggleInspectorsManually}>
                                    {sidebarOpen && <Button className='py-2 px-4' primary onClick={(): void => toggleInspectorList(true)}><FormattedMessage id='globals.ok' /></Button>}
                                </InspectorList>}

                                <div className='w-full'>
                                    {/* <div>P{itemsParking.map((inspector, i) => {
                                        return <WeekViewDayItem days={inspector.days[0]} inspector={inspector} date={dates[i]} i={i} j={0} key={i} label={'label'} toggleCompact={toggleCompact} openItems={openItems} />
                                    })} </div> */}

                                    <div className='flex p-2 pb-8 justify-center items-center'>
                                        <div className='w-1/12'>
                                            {!sidebarOpen && <Button primary className='items-center text-sm py-2 px-3' onClick={(): void => setSidebarOpen(true)}><Icon className='' name='chevron_left' />
                                                <FormattedMessage id='inspections.week_view.favorite_inspectors' />
                                            </Button>}
                                        </div>
                                        <div className='w-10/12'>
                                            <WeekToggler
                                                changeWeek={changeWeek}
                                                changeWeekByNumber={changeWeekByNumber}
                                                currentWeek={currentWeek}
                                                currentDate={currentDate}
                                                groupsFilter={gf}
                                                filters={[itf]}
                                            />
                                        </div>
                                        <div className='w-1/12'></div>
                                    </div>
                                    <div className='flex border-t sticky top-0 border-b bg-white'>
                                        <div className={'flex-1 w-2/16 px-3 py-3 '}>
                                            <div className='link noUnderline justify-center flex' onClick={() => toggleCompactAll()}>
                                                <Icon className='text-xl' name={isCompact ? 'keyboard_double_arrow_down' : 'keyboard_double_arrow_up'} />
                                            </div>
                                        </div>
                                        <div className={'text-secondary flex-1 p-3 w-2/16 bg-week-view-odd'}>{getHeaderDate(dates[0], isMaxXL)}</div>
                                        <div className={'text-secondary flex-1 p-3 w-2/16'}>{getHeaderDate(dates[1], isMaxXL)}</div>
                                        <div className={'text-secondary flex-1 p-3 w-2/16 bg-week-view-odd'}>{getHeaderDate(dates[2], isMaxXL)}</div>
                                        <div className={'text-secondary flex-1 p-3 w-2/16'}>{getHeaderDate(dates[3], isMaxXL)}</div>
                                        <div className={'text-secondary flex-1 p-3 w-2/16 bg-week-view-odd'}>{getHeaderDate(dates[4], isMaxXL)}</div>
                                        <div className={'text-secondary flex-1 p-3 w-2/16'}>{getHeaderDate(dates[5], isMaxXL)}</div>
                                        <div className={'flex-1 text-secondary p-3 w-2/16 bg-week-view-odd'}>{getHeaderDate(dates[6], isMaxXL)}</div>

                                    </div>
                                    <div className={twMerge('flex flex-col', className)}>
                                        {rows?.map((row, i) => {
                                            const firstLetter = row?.user?.firstname?.substring(0, 1);
                                            const showLetter = !scrollBarItems.includes(firstLetter);
                                            return <div key={'inspector_' + i + (row.user && row.user.id)} className='flex w-full border-t hover:bg-week-view-odd'>
                                                <div className='w-2/16 flex-1 items-center'>
                                                    {row.type === 'user' &&
                                                        <WeekViewInspector
                                                            inspector={row.user}
                                                            inspCnt={getInspectionsCount(row)}
                                                        />
                                                    }
                                                    {row.type !== 'user' && <div className='flex px-2 py-2 items-center space-x-1 text-sml'>{labelsShort[row.type]}</div>}
                                                    {showLetter && <div><a id={'alpha_' + firstLetter} /></div>}
                                                </div>
                                                {row.days.map((day, j: number) => {
                                                    return <WeekViewDayItem days={day} inspector={row} date={dates[j]} i={i} j={j} key={i} label={intl.formatMessage({ id: 'inspections.week_view.type.' + row.type })} toggleCompact={toggleCompact} openItems={openItems} />
                                                })}
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>}</Loader>
                    </div>
                </div>
            </HotKeys>
        </DndProvider >
    }

    return <div className='p-4'>No data</div>;

}
export default WeekView2;

