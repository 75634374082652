import queryString from 'query-string';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Feature } from '../../../backend_api/models';
import { useCheckHasFeature } from '../../../base/hooks';
import { getFeaturesSelector } from '../../../base/selectors';
import PageContainer from '../../globals/components/PageContainer';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getIsSavingSelector } from '../selectors';
import DataExportAudit from './DataExportAudit';
import DataExportInspection from './DataExportInspection';
import DataExportSuppliers from './DataExportSuppliers';

const DataExportContainer = (): React.ReactElement => {
    const intl = useIntl();
    const isSaving = useSelector(getIsSavingSelector);
    const history = useHistory();
    const qs = queryString.parse(history.location.search);
    const features = useSelector(getFeaturesSelector);
    const canExportInspections = useCheckHasFeature(features, Feature.Quality);
    const canExportAudits = useCheckHasFeature(features, Feature.SupplierCompliance);
    const canExportSupplierData = useCheckHasFeature(features, Feature.SupplierCompliance);
    return (
        <PageContainer header={<FormattedMessage id='statistics.export_data_to_excel' />}>
            <Dimmer active={isSaving} inverted>
                <Loader className='dimLoader'><FormattedMessage id='statistics.exporting_data_please_wait' /></Loader>
            </Dimmer>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.statistics' })} />
            {canExportInspections && <ContentSection content={<DataExportInspection email={qs.email as string} />}
                headerText={intl.formatMessage({ id: 'statistics.export_inspections_to_excel' })} />}
            {canExportAudits && <ContentSection content={<DataExportAudit email={qs.email as string} />}
                headerText={intl.formatMessage({ id: 'statistics.export_audits_to_excel' })} />}
            {canExportSupplierData && <ContentSection content={<DataExportSuppliers email={qs.email as string} />}
                headerText={intl.formatMessage({ id: 'statistics.export_suppliers_to_excel' })} />}
        </PageContainer>
    );
};

export default DataExportContainer;