import React from 'react';
import { twMerge } from 'tailwind-merge';
import { translateTextWithTranslation, useLocaleLanguageString } from '../../base/components/basic/TextWithTranslation';
import { DetailedProductionUnitStatus } from '../../backend_api/models';

type ListProductionUnitStatusProps = {
    status: DetailedProductionUnitStatus
    className?: string;
}
const ListProductionUnitStatus = (props: ListProductionUnitStatusProps) => {
    const { status, className } = props;
    return <div className={twMerge('px-2 py-3 w-full flex text-sm justify-center items-center rounded w-[100px]', className)} style={{ backgroundColor: status.background_color, color: status.text_color }}>
        {translateTextWithTranslation(status.name, useLocaleLanguageString())}
    </div>
        ;
}
export default ListProductionUnitStatus;
